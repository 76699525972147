import { isPlatformBrowser } from '@angular/common';
import type { OnDestroy } from '@angular/core';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Auth } from '@freelancer/auth';
import { isEqual } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import djv from 'djv';
import type { Observable, SchedulerLike } from 'rxjs';
import {
  asyncScheduler,
  BehaviorSubject,
  firstValueFrom,
  fromEvent,
  of,
  Subscription,
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import type { LocalStorageTypes } from './interface';
import { definitions, topLevelSchemas } from './json-schemas';
import { LOCAL_STORAGE_TEST_SCHEDULER } from './local-storage.config';

/**
 * LOCAL STORAGE API:
 *
 * Use Cases of `undefined` and `null`:
 * When an observable for an item is created, if the item does not exist yet,
 * then `undefined` is initially emitted, indicating 'no value set yet'.
 * Since items in localStorage must be JSON serialisable, we can use `null` to indicate
 * when an item has been removed from localStorage.
 * These items will still have meta-information and are treated like any other item.
 *
 */

/**
 * The meta-object for storing into local storage.
 */
export interface LocalStorageMetaObject<T extends keyof LocalStorageTypes> {
  readonly timeCreated: number;
  readonly timeUpdated: number;
  // TTL?: number;
  readonly item: LocalStorageTypes[T];
}

/**
 * A map of the local storage objects indexed by the authenticated user who created them.
 */
export interface LocalStorageEntry<T extends keyof LocalStorageTypes> {
  readonly [authId: string]: LocalStorageMetaObject<T>;
}

@UntilDestroy({ className: 'LocalStorage' })
@Injectable({
  providedIn: 'root',
})
export class LocalStorage implements OnDestroy {
  private subjects: {
    [key in keyof LocalStorageTypes]: {
      [authId: string]: BehaviorSubject<LocalStorageTypes[key] | undefined>;
    };
  };
  private authId$: Observable<string>;
  private readonly env = djv();
  private readonly LOGGED_OUT_KEY = 'logged-out';
  private isInitialized = false;
  private subscriptions = new Subscription();

  constructor(
    private auth: Auth,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional()
    @Inject(LOCAL_STORAGE_TEST_SCHEDULER)
    private scheduler: SchedulerLike | null,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /*
   * Initialisation only occurs when the functions are being called,
   * allowing for no side effects during injection
   * http://misko.hevery.com/code-reviewers-guide/flaw-constructor-does-real-work/
   */
  private init(): void {
    this.subjects = {
      abTestVariations: {},
      aiPromptEnabled: {},
      aiPromptSelection: {},
      aiConsultantChatHistory: {},
      aiConsultantGeneratedProjectDraft: {},
      complianceQueueApproveDenyFormStates: {},
      dashboardMyProjectsUserType: {},
      developersPreference: {},
      deviceId: {},
      dontAskAgainLastChecked: {},
      enterpriseContactFormSubmitted: {},
      externalQuotation: {},
      flCardsExpandedStatus: {},
      freelancerOnboardingResumeUploaded: {},
      freelancerOnboardingParsedResume: {},
      freelancerOnboardingResumeParsingStatus: {},
      giveGetFooterClosed: {},
      giveGetReferrerUsername: {},
      groupsHomepageSelectedView: {},
      hideMobileNewMessageToast: {},
      hireMeDraft: {},
      inviteToBidDiscardedFreelancers: {},
      nativeRatingPrompt: {},
      lastSeenPjpAssistant: {},
      lastUsedForms: {},
      loadIdPendingAction: {},
      manageRecentTable: {},
      navSavedAlertsLastReadTime: {},
      navUpdatesLastReadTime: {},
      navNotificationUpdatesLastReadTime: {},
      notificationsRequested: {},
      postJobPageDraft: {},
      postProjectObject: {},
      projectOverlayFullFlowComplete: {},
      projectOverlayThreadComplete: {},
      searchServiceFilters: {},
      taskList: {},
      taskListCurrentTaskClean: {},
      testName: {},
      testProfile: {},
      testProperty: {},
      theme: {},
      userOriginalTheme: {},
      threeDSChallengeCookie: {},
      viewedItems: {},
      waitingScreenSteps: {},
      webappChatDraftMessages: {},
      webappChats: {},
      webappThreadListMinimise: {},
    };

    this.authId$ = this.auth.authState$.pipe(
      map(authState =>
        authState ? `id-${authState.userId}` : this.LOGGED_OUT_KEY,
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.env.addSchema('defs.json', definitions);

    this.subscriptions.add(
      fromEvent(window, 'storage').subscribe(e => {
        const event = e as StorageEvent;
        this.handleStorageEvent(event);
      }),
    );

    this.isInitialized = true;
  }

  private async handleStorageEvent<T extends keyof LocalStorageTypes>(
    e: StorageEvent,
  ): Promise<void> {
    if (!e.key || !this.isValidLocalStorageType(e.key)) {
      return;
    }

    const authId = await firstValueFrom(
      this.authId$.pipe(untilDestroyed(this)),
    );
    const subject = this.subjects[e.key];
    if (subject && subject[authId]) {
      let newObject: LocalStorageEntry<T>;
      try {
        newObject = JSON.parse(e.newValue || '{}');
      } catch (err: unknown) {
        return;
      }

      subject[authId].next(
        // there's no way to know what type `subject` expects so we have to use `any` here
        newObject[authId]?.item as any,
      );
    }
  }

  /**
   * Returns an OBSERVABLE of the current item under `key` for the current authId
   * or under the `loggedOutKey` if `loggedOutMode` is true.
   */
  get<T extends keyof LocalStorageTypes>(
    key: T,
    loggedOutMode?: boolean,
  ): Observable<LocalStorageTypes[T] | undefined> {
    return of(undefined).pipe(
      switchMap(() => {
        if (!isPlatformBrowser(this.platformId)) {
          throw new Error(
            'LocalStorage::get() should not be called on the server: make sure your application logic is correct',
          );
        }

        if (!this.isInitialized) {
          this.init();
        }
        return this.authId$;
      }),
      // TODO: T267853 - Check if this still needed when LocalStorage has been migrated
      // to use @ionic/storage-angular. We want LocalStorage::get() to be consistently
      // async, no matter the storage backend, in order to avoid storage
      // backend-specific issues.
      delay(0, this.scheduler ?? asyncScheduler),
      switchMap(authId => {
        const authKey = loggedOutMode ? this.LOGGED_OUT_KEY : authId;
        const entryObj = getEntryObject(key);

        if (!entryObj) {
          // parsing error occured
          return of(undefined);
        }

        // item obtained does not conform to allowed LocalStorageTypes
        if (entryObj[authId] && !this.isValidItem(key, entryObj[authId].item)) {
          return of(undefined);
        }

        // typescript can't tell that `key` indexes into `LocalStorageTypes[T]` for some reason,
        // so we have to cast with `any` because it would otherwise treat it as a union of all possible subjectMaps
        const subjectMap: {
          [authUid: string]: BehaviorSubject<LocalStorageTypes[T] | undefined>;
        } = this.subjects[key] as any;
        if (!subjectMap[authKey]) {
          subjectMap[authKey] = new BehaviorSubject(
            entryObj[authKey] ? entryObj[authKey].item : undefined, // if object has never been set in local storage, emit undefined.
          );
        }

        /**
         * The calls to `subject.next` come from either:
         * - Someone calling `setItem`
         * - A window 'storage' event with a JSON.parse
         *
         * These can't be a pointer check so let's do a deep check.
         */
        return subjectMap[authKey].pipe(distinctUntilChanged(isEqual));
      }),
    );
  }

  /**
   * Stores `item` in local storage for `key` under the current authId
   * or under the `loggedOutKey` if `loggedOutMode` is true.
   * If there is a subject for that particular entry, notify the observers (of that subject) of the change.
   */
  async set<T extends keyof LocalStorageTypes>(
    key: T,
    item: LocalStorageTypes[T],
    loggedOutMode?: boolean,
  ): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      throw new Error(
        'LocalStorage::set() should not be called on the server: make sure your application logic is correct',
      );
    }

    if (!this.isInitialized) {
      this.init();
    }

    const authId = await firstValueFrom(
      this.authId$.pipe(untilDestroyed(this)),
    );

    // We want set() to be async as updating a local storage key when rendering
    // a component is a legitimate use case (e.g. mark as read), and
    // get()/set() being synchronous would trigger
    // "ExpressionChangedAfterItHasBeenChecked" errors
    // Promise.resolve().then() is equivalent to setTimeout()
    // TODO: T267853 - check if this still needed when LocalStorage has been migrated
    // to use @ionic/storage-angular
    return Promise.resolve().then(() => {
      const authKey = loggedOutMode ? this.LOGGED_OUT_KEY : authId;
      setRaw(key, authKey, item);
      this.subjects[key][authKey]?.next(item);
    });
  }

  /**
   * Checks whether the item is valid against the JSON Schema.
   */
  private isValidItem<T extends keyof LocalStorageTypes>(
    key: T,
    item: unknown,
  ): boolean {
    this.env.addSchema(key, topLevelSchemas[key]);
    if (this.env.validate(key, item)) {
      console.warn(
        `Failed to load ${key} from local storage as its format has changed. Check that it still validates against the schema at 'local-storage/json-schemas.ts'`,
      );
      return false;
    }
    return true;
  }

  private isValidLocalStorageType(key: string): key is keyof LocalStorageTypes {
    return key in this.subjects;
  }
}

/* HELPER FUNCTIONS */
/**
 * Directly store a @param item for a given @param authId.
 */
function setRaw<T extends keyof LocalStorageTypes>(
  key: T,
  authId: string,
  item: LocalStorageTypes[T],
): void {
  const entryObj = getEntryObject(key);

  if (!entryObj) {
    // parsing error occurred.
    return;
  }

  const currentTime = Date.now();

  const addItem: LocalStorageMetaObject<T> = entryObj[authId]
    ? {
        ...entryObj[authId],
        timeUpdated: currentTime,
        item,
      }
    : {
        timeUpdated: currentTime,
        timeCreated: currentTime,
        item,
      };

  const newEntryObj = {
    ...entryObj,
    [authId]: addItem,
  };
  try {
    // if doesn't exist, creates, otherwise overrides.
    window.localStorage.setItem(key, JSON.stringify(newEntryObj));
  } catch (e: unknown) {
    // Do nothing
  }
}

/**
 * Fetches an object from local storage. Returns `null` if there's an error.
 */
function getEntryObject<T extends keyof LocalStorageTypes>(
  key: T,
): LocalStorageEntry<T> | null {
  let entryObj: LocalStorageEntry<T>;

  try {
    entryObj = JSON.parse(window.localStorage.getItem(key) || '{}');
  } catch (e: unknown) {
    return null;
  }

  return entryObj;
}

import {
  CommonModule,
  isPlatformBrowser,
  ViewportScroller,
} from '@angular/common';
import { ApplicationRef, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import type { Routes } from '@angular/router';
import { NavigationStart, Router, RouterModule, Scroll } from '@angular/router';
import { ABTestOverridesGuard } from '@freelancer/abtest';
import { MessagingChatOverridesGuard } from '@freelancer/messaging-chat';
import { OnDemandPreloadingStrategy } from '@freelancer/preload-route';
import { PwaInstalledModeGuard } from '@freelancer/pwa';
import { ToastAlertOverridesGuard } from '@freelancer/ui';
import { PermissionsOverridesGuard } from '@freelancer/ui/permissions';
import { firstValueFrom } from 'rxjs';
import {
  buffer,
  filter,
  map,
  // eslint-disable-next-line local-rules/no-skip
  skip,
  withLatestFrom,
} from 'rxjs/operators';
import { LoggedOutRestrictedGuard } from './logged-out-restricted-guard.service';
import { ShellGuard } from './shell-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    // always run guards to get the latest state of overrides
    runGuardsAndResolvers: 'always',
    canActivate: [
      ABTestOverridesGuard,
      MessagingChatOverridesGuard,
      PermissionsOverridesGuard,
      PwaInstalledModeGuard,
      ToastAlertOverridesGuard,
    ],
    children: [
      {
        path: '',
        canActivate: [ShellGuard, LoggedOutRestrictedGuard],
        // always run guard, so we can swap shells when auth state changes
        runGuardsAndResolvers: 'always',
        data: {
          // "argument" for shell guard to avoid duplicating reroute logic
          loggedIn: false,
        },
        // if not logged in, lazy-load only the logged-out shell
        loadChildren: () =>
          import('app/app-shell/logged-out-routing.module').then(
            m => m.LoggedOutRoutingModule,
          ),
      },
      {
        path: '',
        canActivate: [ShellGuard],
        runGuardsAndResolvers: 'always',
        data: {
          loggedIn: true,
        },
        loadChildren: () =>
          import('app/app-shell/logged-in-routing.module').then(
            m => m.LoggedInRoutingModule,
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      // FIXME: T267853 - re-enable when the issue is fixed on the Anglar side
      // We effectively use 'enabled' here, but we need the hack in the
      // ShellRoutingModule constructor below in order to prevent the page from
      // scrolling back to the top with SSR (when the app itself bootstrap, if
      // the user had already started to scroll down)
      // scrollPositionRestoration: 'enabled',
      // anchorScrolling: 'enabled',
      urlUpdateStrategy: 'eager',
      preloadingStrategy: OnDemandPreloadingStrategy,
    }),
    CommonModule,
  ],
  providers: [OnDemandPreloadingStrategy],
  exports: [RouterModule],
})
export class ShellRoutingModule {
  // eslint-disable-next-line local-rules/no-code-in-constructor
  constructor(
    appRef: ApplicationRef,
    router: Router,
    viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // FIXME: T267853 - find a better way to detect that.
      const hasRenderedOnServer =
        !!document.querySelector(`style[ng-transition]`); // eslint-disable-line no-restricted-globals
      router.events
        .pipe(
          filter((e): e is Scroll => e instanceof Scroll),
          // Skip(1) here skips the initial navigation which fixes an issue with
          // the page being scrolled back to the top with SSR (when the app
          // itself bootstrap, if the user had already started to scroll down)
          skip(hasRenderedOnServer ? 1 : 0),
          withLatestFrom(
            // This buffers the navigation events between two Scroll events and
            // emits `true` when at least one of them was not using
            // `replaceUrl: true`, i.e. when the navigation did create a new
            // state, the rational being that pure URL replacements should not
            // trigger a scroll to top as those are not perceived as a
            // navigation from the user's point of view.
            router.events.pipe(
              filter((e): e is NavigationStart => e instanceof NavigationStart),
              map(() => !router.getCurrentNavigation()?.extras?.replaceUrl),
              buffer(
                router.events.pipe(
                  filter((e): e is Scroll => e instanceof Scroll),
                ),
              ),
              map(shouldScrollArray =>
                shouldScrollArray.some(shouldScroll => shouldScroll),
              ),
            ),
          ),
        )
        // eslint-disable-next-line local-rules/no-ignored-subscription
        .subscribe(([e, shouldScrollToTop]) => {
          if (e.position) {
            // backward navigation
            const { position } = e;
            // setTimeout here is needed to ensure the previous page has been
            // rendered
            setTimeout(() => viewportScroller.scrollToPosition(position));
          } else if (e.anchor) {
            // anchor navigation
            const { anchor } = e;
            // FIXME: T267853 - The isStable hack is needed as the Angular's default
            // `anchorScrolling` is broken since it makes no guarantee that the
            // anchor will be in the DOM before trying to scroll to it, i.e. in
            // ViewportScrolling the this.document.querySelector won't match
            // anything.
            firstValueFrom(appRef.isStable.pipe(filter(stable => stable))).then(
              () => viewportScroller.scrollToAnchor(anchor),
            );
          } else if (shouldScrollToTop) {
            // forward navigation: unlike scroll when the navigation created a
            // new state in the history
            viewportScroller.scrollToPosition([0, 0]);
          }
        });
    }
  }
}

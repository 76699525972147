import type { AnimationEvent } from '@angular/animations';

export enum ToastAlertType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  LOADING = 'loading',
}

export enum ToastAlertColor {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ToastAlertComponentInterface {
  element: HTMLElement;
  state: string;

  // Inputs
  closeable: boolean;
  color: ToastAlertColor;
  id: string;
  timeout?: number;
  type: ToastAlertType;

  handleClose(): void;
  move(position: number): void;
  startTimer(): void;
  resetTimer(): void;
  unsubscribeTimer(): void;
  toggleVisibility(visibility: string): void;
  handleAnimationDone(event: AnimationEvent): void;
}

import type { Backend } from '@freelancer/datastore/core';
import {
  getQueryParamValue,
  OrderByDirection,
} from '@freelancer/datastore/core';
import { ServiceOfferingStatusApi } from 'api-typings/service_offerings/service_offerings';
import type { ServiceOfferingsCollection } from './service-offerings.types';

export function serviceOfferingsBackend(): Backend<ServiceOfferingsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.DESC,
    },
    fetch: (_authUid, ids, query, _order) => {
      const status = getQueryParamValue(query, 'status');

      const projections = {
        job_details: true,
        attachment_details: true,
      };

      return {
        endpoint: 'service_offerings/0.1/service_offerings',
        params: {
          ids,
          creator_ids: getQueryParamValue(query, 'creatorId'),
          statuses: status.length ? [status.toString()] : undefined, // The backend accepts only one status filter
          seo_urls: getQueryParamValue(query, 'seoUrl'),
          limit: query?.searchQueryParams.limit,
          offset: query?.searchQueryParams.offset,
          ...projections,
        },
      };
    },
    push: _authUid => {
      return {
        endpoint: 'service_offerings/0.1/service_offerings',
        method: 'POST',
        payload: {},
      };
    },
    remove: (authUid, id, originalDocument) => ({
      endpoint: `service_offerings/0.1/service_offerings/${originalDocument.id}`,
      method: 'PUT',
      payload: {
        id: originalDocument.id,
        status: ServiceOfferingStatusApi.DELETED,
      },
    }),
    set: undefined,
    update: (authUid, partial, original) => {
      if (partial.attachments) {
        return {
          endpoint: `service_offerings/0.1/service_offerings/${original.id}/attachments`,
          method: 'PUT',
          payload: {
            id: original.id,
            service_offering_attachments: partial.attachments,
          },
        };
      }
      return {
        endpoint: `service_offerings/0.1/service_offerings/${original.id}`,
        method: 'PUT',
        payload: {
          id: original.id,
          description: partial.description,
          rich_description: partial.richDescription,
          job_ids: partial.jobIds,
          status: partial.status,
          title: partial.title,
        },
      };
    },
  };
}

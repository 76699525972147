import type { ContestEngagementAjax } from './contest-engagement.backend-model';
import type { ContestEngagement } from './contest-engagement.model';

export function transformContestEngagement(
  contestEngagement: ContestEngagementAjax,
): ContestEngagement {
  return {
    id: contestEngagement.id,
    totalEngageableEntries: contestEngagement.total_engageable_entries,
    unratedEntries: contestEngagement.unrated_entries_count,
    engagementRatio: calculateEngagementRatio({
      totalEngageableEntries: contestEngagement.total_engageable_entries,
      unratedEntries: contestEngagement.unrated_entries_count,
    }),
  };
}

export function calculateEngagementRatio({
  totalEngageableEntries,
  unratedEntries,
}: {
  readonly totalEngageableEntries: number;
  readonly unratedEntries: number;
}): number {
  return totalEngageableEntries === 0
    ? 0
    : Math.round(
        ((totalEngageableEntries - unratedEntries) / totalEngageableEntries) *
          100,
      );
}

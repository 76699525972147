import { isPlatformBrowser } from '@angular/common';
import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { Location } from '@freelancer/location';
import { Pwa } from '@freelancer/pwa';
import { SpinnerColor, SpinnerSize } from '@freelancer/ui/spinner';
import { UserAgent } from '@freelancer/user-agent';
import { isLinkWhitelisted } from '../helpers/helpers';
import { LinkType, QueryParamsHandling } from '../link';
import { ButtonSize } from './button-size';
import {
  ButtonColor,
  ButtonDisplay,
  ButtonGroupPosition,
} from './button.types';

@Component({
  selector: 'fl-button',
  template: `
    <a
      *ngIf="link && linkType === LinkType.INTERNAL"
      class="ButtonElement"
      tabindex="0"
      [routerLink]="link"
      [routerLinkActive]="linkActive || ''"
      [routerLinkActiveOptions]="linkActiveOptions || { exact: false }"
      [queryParams]="queryParams"
      [queryParamsHandling]="queryParamsHandling"
      [fragment]="fragment"
      [rel]="allRel"
      [target]="attrTarget"
      [attr.aria-label]="label"
      [attr.data-color]="color"
      [attr.data-display]="display"
      [attr.data-display-tablet]="displayTablet"
      [attr.data-display-desktop]="displayDesktop"
      [attr.disabled]="attrDisabled || busy ? true : undefined"
      aria-live="assertive"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <a
      *ngIf="link && linkType === LinkType.EXTERNAL"
      class="ButtonElement"
      tabindex="0"
      [href]="link"
      [rel]="allRel"
      [target]="attrTarget"
      [attr.aria-label]="label"
      [attr.data-color]="color"
      [attr.data-display]="display"
      [attr.data-display-tablet]="displayTablet"
      [attr.data-display-desktop]="displayDesktop"
      [attr.disabled]="attrDisabled || busy ? true : undefined"
      aria-live="assertive"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <a
      *ngIf="link && linkType === LinkType.TEL"
      class="ButtonElement"
      tabindex="0"
      [href]="link"
      [rel]="linkType === LinkType.TEL"
      [attr.aria-label]="label"
      [attr.data-color]="color"
      [attr.data-display]="display"
      [attr.data-display-tablet]="displayTablet"
      [attr.data-display-desktop]="displayDesktop"
      [attr.disabled]="attrDisabled || busy ? true : undefined"
      aria-live="assertive"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>

    <button
      *ngIf="!link"
      class="ButtonElement"
      tabindex="0"
      [type]="buttonType"
      [attr.aria-label]="label"
      [attr.data-color]="color"
      [attr.data-display]="display"
      [attr.data-display-tablet]="displayTablet"
      [attr.data-display-desktop]="displayDesktop"
      [attr.disabled]="attrDisabled || busy ? true : undefined"
      aria-live="assertive"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <fl-spinner
      *ngIf="busy"
      class="LoadingSpinner"
      [size]="SpinnerSize.SMALL"
      [color]="spinnerColor"
    ></fl-spinner>

    <ng-template #content>
      <div
        *ngIf="backIcon"
        class="IconContainer"
        [flMarginRight]="'xxsmall'"
      >
        <!-- SVG added into this component to been able to access the css class when the button is on hover state -->
        <svg
          class="ArrowIcon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="ArrowIcon-body"
            d="M20.25 13.857h-15v-3.712h15z"
            fill-rule="nonzero"
          />
          <path
            class="ArrowIcon-head ArrowLeftHead"
            d="m15.53 18.297-4.385-4.385L9.114 12l2.031-1.912 4.384-4.384L12.828 3l-9.002 9 9.002 9"
            fill-rule="nonzero"
          />
        </svg>
      </div>
      <ng-content></ng-content>
      <div
        *ngIf="nextIcon"
        class="IconContainer"
      >
        <svg
          class="ArrowIcon ArrowRight"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="ArrowIcon-body"
            d="M3.75 10.144h15v3.712h-15z"
            fill-rule="nonzero"
          />
          <path
            class="ArrowIcon-head ArrowRightHead"
            d="m8.47 5.704 4.385 4.384L14.886 12l-2.031 1.912-4.384 4.384L11.172 21l9.002-9-9.002-9"
            fill-rule="nonzero"
          />
        </svg>
      </div>
    </ng-template>
  `,
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges {
  LinkType = LinkType;
  SpinnerColor = SpinnerColor;
  SpinnerSize = SpinnerSize;

  spinnerColor: SpinnerColor;

  /**
   * Use only when necessary. This adds in aria-label for links / buttons that doesn't have a descriptive text. For example, a button that only has an icon inside it.
   */

  @Input() label?: string;
  @Input() buttonGroupPosition?: ButtonGroupPosition;
  /** Url used for button link case */
  @Input() link?: string;
  @Input() fragment?: string;
  /**
   * Whether to open the link in a new tab in desktop.
   */
  @Input() newTab?: boolean;
  @Input() queryParams?: { [k: string]: any };
  @Input() queryParamsHandling?: QueryParamsHandling;
  @Input() linkActive?: string[] | string;
  @Input() linkActiveOptions?: { exact: boolean };
  @Input() backIcon?: boolean;
  @Input() nextIcon?: boolean;

  @HostBinding('class.ButtonGroupFirst')
  @Input()
  buttonGroupFirst = false;
  @HostBinding('class.ButtonGroupMiddle')
  @Input()
  buttonGroupMiddle = false;
  @HostBinding('class.ButtonGroupLast')
  @Input()
  buttonGroupLast = false;
  @HostBinding('attr.data-busy')
  @Input()
  busy?: boolean;
  @HostBinding('attr.data-color')
  @Input()
  color?: ButtonColor;

  @HostBinding('attr.data-display')
  @Input()
  display: ButtonDisplay = 'inline';
  @HostBinding('attr.data-display-tablet')
  @Input()
  displayTablet?: ButtonDisplay;
  @HostBinding('attr.data-display-desktop')
  @Input()
  displayDesktop?: ButtonDisplay;

  @HostBinding('attr.data-size')
  @Input()
  size?: ButtonSize;
  @HostBinding('attr.data-size-tablet')
  @Input()
  sizeTablet?: ButtonSize;
  @HostBinding('attr.data-size-desktop')
  @Input()
  sizeDesktop?: ButtonSize;
  @Input() buttonType: 'submit' | 'button' = 'button';

  @Input() set submit(value: boolean) {
    this.buttonType = value ? 'submit' : 'button';
  }

  @HostBinding('attr.disabled') attrDisabled: true | undefined;

  @Input() rel = '';

  @Input() set disabled(value: boolean | undefined) {
    this.attrDisabled = value ? true : undefined;
  }

  get disabled(): boolean | undefined {
    // FIXME: T273072 This return type is weaker than it should be
    return !!this.attrDisabled;
  }

  allRel = '';
  attrTarget: string;
  external = false;
  linkType: LinkType;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private location: Location,
    private pwa: Pwa,
    private userAgent: UserAgent,
  ) {}

  parseLink(link?: string): LinkType {
    // Check if the link is http(s) or protocol relative.
    if (!!link && (link.startsWith('http') || link.startsWith('//'))) {
      return LinkType.EXTERNAL;
    }
    // Check if the link is a telephone link.
    if (!!link && link.startsWith('tel')) {
      return LinkType.TEL;
    }

    return LinkType.INTERNAL;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.spinnerColor =
      this.color === ButtonColor.DEFAULT || ButtonColor.TRANSPARENT_DARK
        ? SpinnerColor.GRAY
        : SpinnerColor.LIGHT;

    if (this.color) {
      this.spinnerColor = [
        ButtonColor.SECONDARY,
        ButtonColor.SUCCESS,
        ButtonColor.DANGER,
        ButtonColor.PRIMARY,
        ButtonColor.RECRUITER,
        ButtonColor.PREFERRED_FREELANCER,
      ].includes(this.color)
        ? SpinnerColor.LIGHT
        : SpinnerColor.GRAY;
    } else {
      this.spinnerColor = SpinnerColor.LIGHT;
    }

    if (this.buttonGroupPosition) {
      this.buttonGroupFirst =
        this.buttonGroupPosition === ButtonGroupPosition.FIRST;
      this.buttonGroupMiddle =
        this.buttonGroupPosition === ButtonGroupPosition.MIDDLE;
      this.buttonGroupLast =
        this.buttonGroupPosition === ButtonGroupPosition.LAST;
    }

    if ('link' in changes) {
      // Check if the link is http(s) or protocol relative.
      this.linkType = this.parseLink(this.link);

      if (this.linkType === LinkType.EXTERNAL) {
        this.attrTarget = this.getExternalTarget();
      }

      if (this.linkType === LinkType.INTERNAL) {
        this.attrTarget = this.getInternalTarget();
      }
    }

    if ('link' in changes || 'rel' in changes) {
      // Add noopener to rel if external link, due to security exploits
      // Ref: https://mathiasbynens.github.io/rel-noopener/
      if (
        this.linkType === LinkType.EXTERNAL &&
        !this.rel.includes('noopener')
      ) {
        this.allRel = `${this.rel} noopener`;
      } else {
        this.allRel = this.rel;
      }
    }
  }

  private getExternalTarget(): string {
    // Never open in new tab on native as this bypasses Capacitor's own logic
    if (this.pwa.isNative()) {
      return '_self';
    }

    // Open in new tab on mobile unless whitelisted
    if (isPlatformBrowser(this.platformId) && this.userAgent.isMobileDevice()) {
      return this.isWhitelisted() ? '_self' : '_blank';
    }

    // Open on new tab by default on desktop
    if (this.newTab === undefined) {
      return '_blank';
    }

    return this.newTab ? '_blank' : '_self';
  }

  private getInternalTarget(): string {
    // Always open links inside the Capacitor app
    if (this.pwa.isNative()) {
      return '_self';
    }

    // Open in current tab on mobile browser unless whitelisted
    if (isPlatformBrowser(this.platformId) && this.userAgent.isMobileDevice()) {
      return this.isWhitelisted() ? '_blank' : '_self';
    }

    // Open on current tab by default on desktop
    if (this.newTab === undefined) {
      return '_self';
    }

    return this.newTab ? '_blank' : '_self';
  }

  private isWhitelisted(): boolean {
    if (!this.link) {
      return false;
    }

    return isLinkWhitelisted(
      {
        source: this.location.pathname,
        destination: this.link,
      },
      this.linkType === LinkType.EXTERNAL,
    );
  }
}

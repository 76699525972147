import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { Subscription } from 'rxjs';
import { MemoryLeakTracking } from './memory-leak-tracking.class';
import { MemoryTracking } from './memory-tracking.service';

@Component({
  selector: 'fl-memory-tracking',
  template: ` <ng-container></ng-container> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemoryTrackingComponent implements OnInit, OnDestroy {
  private memoryTrackingSub?: Subscription;

  constructor(private memoryTracking: MemoryTracking) {}

  ngOnInit(): void {
    if (window.webapp) {
      window.webapp.memoryLeakTracking = new MemoryLeakTracking();
    }

    if (!window.performance?.memory) {
      return;
    }

    // Filter out events from compat pages to reduce its amount and make it less noisy.
    if (window.webappCompat) {
      return;
    }

    this.memoryTrackingSub = this.memoryTracking.monitor().subscribe();
  }

  ngOnDestroy(): void {
    this.memoryTrackingSub?.unsubscribe();
  }
}

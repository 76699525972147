import {
  OrderByDirection,
  type Backend,
  getQueryParamValue,
} from '@freelancer/datastore/core';
import { toNumber } from '@freelancer/utils';
import type { ServiceOfferingAttachmentsCollection } from './service-offering-attachments.types';

export function serviceOfferingAttachmentsBackend(): Backend<ServiceOfferingAttachmentsCollection> {
  return {
    defaultOrder: {
      field: 'position',
      direction: OrderByDirection.ASC,
    },

    fetch: (_authUid, ids, query, _order) => {
      const id = ids ? ids[0] : undefined;
      const serviceOfferingId = getQueryParamValue(
        query,
        'serviceOfferingId',
      )[0];
      if (!serviceOfferingId) {
        throw new ReferenceError('Service offering ID is missing.');
      }

      let endpoint = `service_offerings/0.1/service_offerings/${serviceOfferingId}/attachments`;
      if (id !== undefined) {
        endpoint += `/${id}`;
      }

      return { endpoint };
    },

    push: (authUid, document, original) => {
      return {
        endpoint: `service_offerings/0.1/service_offerings/${document.serviceOfferingId}/attachments`,
        method: 'POST',
        payload: {
          id: document.serviceOfferingId,
          uuids: [document.attachmentUuid],
        },
      };
    },

    set: undefined,

    update: (authUid, delta, originalDocument) => {
      return {
        endpoint: `service_offerings/0.1/service_offerings/${originalDocument.serviceOfferingId}/attachments`,
        method: 'PUT',
        payload: {
          id: originalDocument.serviceOfferingId,
          service_offering_attachments: [
            {
              id: originalDocument.id,
              service_offering_id: originalDocument.serviceOfferingId,
              attachment_uuid: originalDocument.attachmentUuid,
              create_date: originalDocument.createDate,
              position: delta.position ? delta.position : undefined,
            },
          ],
        },
      };
    },

    remove: (_, serviceOfferingAttachmentId, serviceOfferingAttachment) => {
      return {
        endpoint: `service_offerings/0.1/service_offerings/${serviceOfferingAttachment.serviceOfferingId}/attachments`,
        method: 'PUT',
        payload: {
          id: serviceOfferingAttachment.serviceOfferingId,
          service_offering_attachments: [
            {
              id: toNumber(serviceOfferingAttachmentId),
              delete_date: Math.floor(Date.now() / 1000),
            },
          ],
        },
      };
    },
  };
}

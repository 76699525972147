export enum HeadingType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum HeadingColor {
  DARK = 'dark',
  LIGHT = 'light',
  INHERIT = 'inherit',
  PRIMARY = 'primary',
  FOREGROUND = 'foreground',
}

export enum HeadingWeight {
  BLACK = 'black',
  BOLD = 'bold',
  MEDIUM = 'medium',
  NORMAL = 'normal',
  LIGHT = 'light',
  INHERIT = 'inherit',
}

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type { CustomTrackingEvent, TrackingEventData } from './tracking.model';

export enum QuoraStandardEvents {
  GENERATE_LEAD = 'GenerateLead',
  PURCHASE = 'Purchase',
  ADD_TO_CART = 'AddToCart',
}

@Injectable({
  providedIn: 'root',
})
export class QuoraPixelTracking {
  private isEnabled = false;
  private isInitialized = false;

  constructor(
    private ngZone: NgZone,
    private trackingConsent: TrackingConsent,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();

    if (
      this.config.quoraPixelId &&
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED
    ) {
      this.isEnabled = true;
    }
    this.isInitialized = true;
  }

  async trackPageView(): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.loadTrackingSnippet();
      window.qp('track', 'ViewContent');
    });
  }

  async trackCustomEvent(
    payload: CustomTrackingEvent & TrackingEventData,
  ): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.loadTrackingSnippet();
      window.qp('track', payload.quoraEventName);
    });
  }

  private loadTrackingSnippet(): void {
    if (window.qp) {
      return;
    }

    window.qp = function qp() {
      if (window.qp.qp) {
        // eslint-disable-next-line prefer-rest-params, prefer-spread
        window.qp.qp.apply(window.qp, arguments);
      } else {
        // eslint-disable-next-line prefer-rest-params
        window.qp.queue.push(arguments);
      }
    };
    window.qp.queue = [];
    const e = document.createElement('script');
    e.async = true;
    e.src = 'https://a.quora.com/qevents.js';
    const s = document.getElementsByTagName('script')[0];
    (s.parentNode as Node).insertBefore(e, s);
    window.qp('init', this.config.quoraPixelId);
  }
}

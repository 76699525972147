import type { Observable } from 'rxjs';
import type {
  ProjectExperiments,
  SessionExperiments,
  UserExperiments,
} from '../experiments';

export enum ABTestType {
  SESSION_BASED = 1,
  USER_BASED = 2,
  PROJECT_BASED = 3,
}

/**
 * A valid proportion for ABTest::shouldEnrol
 * This is a special type so we can enforce 0 <= p <= 1
 */
export type EnrolmentProportion =
  | 0
  | 0.01
  | 0.02
  | 0.05
  | 0.1
  | 0.2
  | 0.25
  | 0.3
  | 0.4
  | 0.5
  | 0.6
  | 0.7
  | 0.75
  | 0.8
  | 0.9
  | 1.0;

export type VariationName = string;

export interface RateLimitingExperiments {
  // Experiment name for rate limiting voluntary KYC, which came from ABTest.php::hideVoluntaryKyc
  mosshide_voluntary_kyc: 'test';
  'T276556-contact-shipper-mandatory-kyc': 'test';
  'T290984-logged-out-pvp-staged-rollout': 'test';
}

export type Experiments = SessionExperiments &
  UserExperiments &
  ProjectExperiments &
  RateLimitingExperiments;

export interface ABTestApiResponse<T extends keyof Experiments> {
  state: 'in_test';
  test_id: number;
  variation_name: Experiments[T];
}

export type OverridesMap = {
  [K in keyof Experiments]?: Experiments[K];
};

export interface ABTestVariationRequestMap<T extends keyof Experiments> {
  [requestIdentifier: string]: Observable<Experiments[T] | undefined>;
}

export interface EnrollmentConditions<T extends keyof Experiments> {
  enrollmentProportion: EnrolmentProportion;
  controlVariation: Experiments[T];
}

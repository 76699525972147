import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { isDefined } from '@freelancer/utils';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type { CustomTrackingEvent, TrackingEventData } from './tracking.model';

export interface LinkedInTrackingEvent {
  readonly conversion_currency?: string;
  readonly conversion_id?: LinkedInPixelTrackingEventConversionId;
  readonly conversion_type?: string;
  readonly conversion_url?: string;
  readonly conversion_value?: string | number;
  readonly order_id?: string | number;
  readonly tmsource?: string;
}

export enum LinkedInPixelTrackingEventConversionId {
  FREELANCER_SIGN_UP = 7_251_484,
  SEND_QUOTE = 7_251_492,
}

@Injectable({
  providedIn: 'root',
})
export class LinkedInPixelTracking {
  private isEnabled = false;
  private isInitialized = false;

  constructor(
    private ngZone: NgZone,
    private trackingConsent: TrackingConsent,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    this.isEnabled =
      isDefined(this.config.linkedInPixelId) &&
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED;

    this.isInitialized = true;
  }

  async trackPageView(): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }
    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.loadTrackingSnippet();
      window.lintrk('track', 'PageView');
    });
  }

  async trackCustomEvent(
    payload: CustomTrackingEvent & TrackingEventData,
  ): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }
    if (!this.isEnabled || !isDefined(payload.linkedInEventData)) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      this.loadTrackingSnippet();
      window.lintrk('track', payload.linkedInEventData);
    });
  }

  private loadTrackingSnippet(): void {
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    if (
      !window._linkedin_data_partner_ids.includes(this.config.linkedInPixelId)
    ) {
      window._linkedin_data_partner_ids.push(this.config.linkedInPixelId);
    }

    if (!window.lintrk) {
      window.lintrk = function lintrk(a: any, b: any): void {
        window.lintrk.q.push([a, b]);
      };
      window.lintrk.q = [];
    }

    const s = document.getElementsByTagName('script')[0];
    const b = document.createElement('script');
    b.type = 'text/javascript';
    b.async = true;
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    s.parentNode?.insertBefore(b, s);
  }
}

import { NgModule } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

// FIXME: T267853 - https://github.com/ionic-team/ionic-native/issues/3091
// Ionic native providers should use providedIn: 'root' in the first place
@NgModule({
  providers: [AndroidPermissions, File, FileTransfer],
})
export class FileDownloadModule {}

import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  forwardRef,
} from '@angular/core';
import { ContainerSize } from './container.types';

@Component({
  selector: 'fl-container',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent implements OnInit {
  @HostBinding('attr.size')
  @Input()
  size: ContainerSize = ContainerSize.DESKTOP_LARGE;

  @HostBinding('attr.size-tablet')
  @Input()
  sizeTablet?: ContainerSize;

  @HostBinding('attr.size-desktop-small')
  @Input()
  sizeDesktopSmall?: ContainerSize;

  @HostBinding('attr.size-desktop-large')
  @Input()
  sizeDesktopLarge?: ContainerSize;

  @HostBinding('attr.size-desktop-xlarge')
  @Input()
  sizeDesktopXLarge?: ContainerSize;

  @HostBinding('attr.size-desktop-xxlarge')
  @Input()
  sizeDesktopXXLarge?: ContainerSize;

  @HostBinding('attr.data-mobile-padding')
  @Input()
  mobilePadding = false;

  @HostBinding('attr.data-padding')
  @Input()
  padding = true;

  /**
   * Whether or not to support notch.
   * This turns off automatically if fl-container is nested
   * to avoid excessive notch padding.
   */
  @HostBinding('attr.data-support-notch')
  supportNotch = true;

  // eslint-disable-next-line @angular-eslint/no-forward-ref
  @ContentChildren(forwardRef(() => ContainerComponent))
  innerContainer: QueryList<ContainerComponent>;

  ngOnInit(): void {
    if (this.innerContainer) {
      this.innerContainer.forEach(component => {
        component.supportNotch = true;
      });
    }
  }
}

/**
 * Sales tax of a freelancer
 */
export interface SalesTax {
  readonly id: number;
  readonly countryCode: string;
  readonly taxType: string;
  readonly taxNumber: string;
  readonly taxRate: number;
}

export type CreatedBidSalesTax = Pick<
  SalesTax,
  'taxType' | 'taxNumber' | 'taxRate'
>;

export enum TaxType {
  GST = 'GST',
  VAT = 'VAT',
  SALES_TAX = 'Sales Tax',
}

interface TaxConfig {
  readonly type: string;
  readonly rate: number;
}

export const AU_TAX_CONFIG: TaxConfig = {
  type: TaxType.GST,
  rate: 10,
};

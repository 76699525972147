import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  makeStateKey,
  Optional,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@freelancer/auth';
import { FreelancerHttp } from '@freelancer/freelancer-http';
import { LocalStorage } from '@freelancer/local-storage';
import type { ABTestVariationCache } from '@freelancer/local-storage/interface';
import { Location } from '@freelancer/location';
import { Tracking } from '@freelancer/tracking';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Request, Response } from 'express';
import { CookieService } from 'ngx-cookie';
import type { Observable } from 'rxjs';
import { firstValueFrom, NEVER, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { REQUEST, RESPONSE } from '../../express.tokens';
import {
  ABTEST_WHITELIST_COOKIE,
  LEGACY_STORAGE_KEY,
  STORAGE_KEY,
  VARIATION_TTL,
} from './abtest.config';
import type {
  ProjectExperiments,
  SessionExperiments,
  UserExperiments,
} from './experiments';
import type {
  ABTestApiResponse,
  ABTestVariationRequestMap,
  EnrollmentConditions,
  EnrolmentProportion,
  Experiments,
  OverridesMap,
} from './interface';
import { ABTestType } from './interface';

// FIXME: T66231: we should make the A/B test service return a tuple of
// `response | error` instead of throwing exceptions, as handling of these
// later can't be enforced in TypeScript.
//
// For now, make sure you catch any ABTest service failure or bad things will
// happen when the backend is down.
@UntilDestroy({ className: 'ABTest' })
@Injectable({
  providedIn: 'root',
})
export class ABTest {
  private overridesMap: OverridesMap;
  private globalOptOut = false;
  private abTestVariationRequestMap: ABTestVariationRequestMap<
    keyof Experiments
  > = {};

  private isCacheCleaned = false;

  constructor(
    private auth: Auth,
    private cookies: CookieService,
    private freelancerHttp: FreelancerHttp,
    private tracking: Tracking,
    private localStorage: LocalStorage,
    private transferState: TransferState,
    private location: Location,
    private router: Router,
    @Inject(ABTEST_WHITELIST_COOKIE) private whitelistCookie: string,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
  ) {}

  getSessionExperimentVariation<T extends keyof SessionExperiments>(
    experimentName: T,
    enrollmentOptions?: EnrollmentConditions<T>,
  ): Observable<SessionExperiments[T] | undefined> {
    const transferStateKey = makeStateKey<SessionExperiments[T]>(
      `${STORAGE_KEY}|${ABTestType.SESSION_BASED}|${experimentName}`,
    );

    // Checking overrides early here to prevent getSessionId to be called
    // getSessionId adds a Set-Cookie header which prevents Caching
    const override = this.getOverride(experimentName);
    if (override) {
      if (isPlatformServer(this.platformId)) {
        // On a real webapp server with Varnish, overrides are used to cache variants,
        // so they can be set even if the client doesn't actually have the override.
        // Set the SSR transfer state here so it gets remembered for the client side.
        this.transferState.set(transferStateKey, override);
      }
      return of(override);
    }

    if (this.hasABTestOptOutCookie() || this.globalOptOut) {
      return of(undefined);
    }

    // Check if the variation is already in the SSR transfer state
    // FIXME: T304949 This is currently superceding the code in the getVariation method
    // because fetching the tracking session adds a delay that causes pages to flicker
    const transferredState = this.transferState.get(
      transferStateKey,
      undefined,
    );
    if (transferredState) {
      return of(transferredState);
    }

    this.tracking.bypassCachingInitServerSideSessionId();

    return this.tracking.getSessionId().pipe(
      take(1),
      switchMap(sessionId => {
        return this.getVariation(
          ABTestType.SESSION_BASED,
          experimentName,
          sessionId,
          enrollmentOptions,
        );
      }),
    );
  }

  // userId is optional, as it'll default to the user's own id
  getUserExperimentVariation<T extends keyof UserExperiments>(
    experimentName: T,
    userId?: number,
    enrollmentOptions?: EnrollmentConditions<T>,
  ): Observable<UserExperiments[T] | undefined> {
    if (isPlatformServer(this.platformId)) {
      throw new Error(
        'getUserExperimentVariation() cannot be ran on the server',
      );
    }
    const userId$ = userId
      ? of(userId)
      : this.auth.getUserId().pipe(map(uid => parseInt(uid, 10)));
    return userId$.pipe(
      take(1),
      switchMap(uid =>
        this.getVariation(
          ABTestType.USER_BASED,
          experimentName,
          uid,
          enrollmentOptions,
        ),
      ),
    );
  }

  getProjectExperimentVariation<T extends keyof ProjectExperiments>(
    experimentName: T,
    projectId: number,
    enrollmentOptions?: EnrollmentConditions<T>,
  ): Observable<ProjectExperiments[T] | undefined> {
    return this.getVariation(
      ABTestType.PROJECT_BASED,
      experimentName,
      projectId,
      enrollmentOptions,
    );
  }

  /**
   * Determines whether or not to enrol a user/project based on a percentage split.
   * Use this function instead of MOD if you want to gradually increase enrolment on an experiment.
   * Enrol/activate the test separately using the appropriate `get*Variation`. function
   *
   * Functionally similar to ABTest::md5sumTestHelper in ABTest.php
   *
   * @param experimentName name of the experiment
   * @param contextId id of user (for user-based tests) or project (for project-based tests) being split on
   * @param testProportion proportion to allow enrolment (eg. 0.2)
   * @returns `true` if the test should be enrolled, false if not
   */
  shouldEnrol<T extends keyof Experiments>(
    experimentName: T,
    contextId: number | string,
    testProportion: EnrolmentProportion,
  ): boolean {
    // always enroll when an override is defined as otheriwise it would break
    // the test overrides logic
    const override = this.getOverride(experimentName);
    if (override) {
      return true;
    }

    const hash = Md5.hashStr(`${contextId}${experimentName}`)
      .toString()
      .substring(0, 8);
    const quotient = parseInt(hash, 16) / 0xff_ff_ff_ff;
    return quotient < testProportion;
  }

  isWhitelistUser(): boolean {
    if (isPlatformServer(this.platformId)) {
      throw new Error(
        'isWhitelistUser() cannot be ran on the server, you must explicitly call ABTest::handleServer() first',
      );
    }
    return this.cookies.get(this.whitelistCookie) === 'true';
  }

  /**
   * Sets the IS_WHITELIST_USER cookie to enrol or remove a user from A/B testing
   * Meant for development purposes only
   * set to true to enrol a user for A/B testing and false to remove them from testing
   */
  setWhitelistUser(isWhitelistUser: boolean): void {
    this.cookies.put(this.whitelistCookie, String(isWhitelistUser));
  }

  /**
   * Handles A/B tests on the server-side, as none of the other A/B test method
   * will work on the server: this must be called on the server-side only,
   * before any method ABTest method call, by guarding it with
   * `isPlatformServer` check, i.e.:
   *
   *
   * if (isPlatformServer(this.platformId)) {
   *   return this.abTest.handleServer.then(() =>
   *     this.redirectToPhp.doRedirect(fallbackUrl),
   *   );
   * }
   *
   * The `then` callback is called when the default variant should be shown
   * (e.g. UA is a Bot). Otherwise, it will render a blank overlay & will defer
   * the A/B test code evaluation to the client-side.
   */
  handleServer(): Promise<void> {
    this.response.append('Vary', 'X-User-Agent-Bot');

    if (this.request.get('X-User-Agent-Bot') === 'true') {
      return Promise.resolve(undefined);
    }
    return this.router
      .navigate(['/internal/blank'], {
        skipLocationChange: true,
      })
      .then(
        () =>
          new Promise((resolve, reject) => {
            // wait forever
          }),
      );
  }

  private getVariation<T extends keyof Experiments>(
    experimentType: ABTestType,
    experimentName: T,
    identifier: string | number,
    enrollmentOptions?: EnrollmentConditions<T>,
  ): Observable<Experiments[T] | undefined> {
    // Clean cache only once. Allowing for no side effects during injection.
    // Approach was similar to how localStorage init() method works.
    // http://misko.hevery.com/code-reviewers-guide/flaw-constructor-does-real-work/
    if (isPlatformBrowser(this.platformId) && !this.isCacheCleaned) {
      this.cleanCache();
    }

    if (enrollmentOptions) {
      if (
        !this.shouldEnrol(
          experimentName,
          identifier,
          enrollmentOptions.enrollmentProportion,
        )
      ) {
        if (this.canPerformVarnishInternalRedirects()) {
          this.ssrRedirectToVariation(
            experimentName,
            enrollmentOptions.controlVariation,
          );
          return NEVER;
        }
        return of(enrollmentOptions.controlVariation);
      }
    }

    // Cache key for the new caching using local storage
    const cacheKey = `${STORAGE_KEY}|${experimentType}|${experimentName}|${identifier}`;
    // Cache key for SSR TransferState
    const transferStateKey = makeStateKey<Experiments[T]>(
      `${STORAGE_KEY}|${experimentType}|${experimentName}`,
    );

    // Immediately create a request object for the experiment to avoid
    // request object duplication, and to correctly dedupe network request
    // for the same experiments and identifier.
    // No network request is made until the observable is subscribed to.
    if (!(cacheKey in this.abTestVariationRequestMap)) {
      // On initial load on the browser, we can check the transfer state cache.
      const transferredVariation = this.transferState.get(
        transferStateKey,
        undefined,
      );

      let abTestRequest$: Observable<Experiments[T] | undefined>;
      if (transferredVariation) {
        // If there's a variation from the server, use it.
        // We can skip the network request and store it in the localstorage cache too.
        abTestRequest$ = of(transferredVariation);
        this.addToCache(cacheKey, transferredVariation);
      } else {
        // If there's no variation, fetch it.
        abTestRequest$ = this.freelancerHttp
          .post<ABTestApiResponse<T>>('abtest/0.1/enrollments/', {
            id_type: experimentType,
            experiment_name: experimentName,
            id: identifier,
          })
          .pipe(
            map(response => {
              if (response.status !== 'success') {
                return undefined;
              }
              return response.result.variation_name;
            }),
            tap(variationName => {
              if (variationName) {
                if (isPlatformBrowser(this.platformId)) {
                  // On browser, save it into the localStorage cache
                  this.addToCache(cacheKey, variationName);
                } else {
                  // On server, save it to the TransferState cache to pass to the browser
                  this.transferState.set(transferStateKey, variationName);
                }
              }
            }),
            catchError(() => of(undefined)),
            switchMap(variation => {
              if (variation && this.canPerformVarnishInternalRedirects()) {
                this.ssrRedirectToVariation(experimentName, variation);
                return NEVER;
              }
              return of(variation);
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
          );
      }

      this.abTestVariationRequestMap = {
        ...this.abTestVariationRequestMap,
        [cacheKey]: abTestRequest$,
      };
    }

    // test overrides functionality
    const override = this.getOverride(experimentName);
    if (override) {
      return of(override);
    }

    // global opt-out flags used by tools (SpeedCurve, Lighthouse, e2e..)
    if (this.hasABTestOptOutCookie() || this.globalOptOut) {
      return of(undefined);
    }

    // A/B test variation cache is only enabled on the client-side
    if (isPlatformBrowser(this.platformId)) {
      // try reading the variation from legacy cache if it exists
      const legacyCacheKey = `${LEGACY_STORAGE_KEY}|${experimentType}|${experimentName}|${identifier}`;

      let variationFromStorage: Experiments[T] | undefined;
      try {
        variationFromStorage =
          (window.sessionStorage.getItem(legacyCacheKey) as Experiments[T]) ||
          '';
      } catch (e: any) {
        // ignore the errors, e.g. quota is full or security error
        console.error(e);
      }

      if (variationFromStorage) {
        return of(variationFromStorage);
      }

      return this.localStorage.get('abTestVariations').pipe(
        map(abTestVariations => {
          if (abTestVariations && cacheKey in abTestVariations) {
            // extract cached variation here, so the `distintUntilChanged` below
            // doesn't have to worry about expiry, overwritten objects, etc.
            const cachedVariation = abTestVariations[cacheKey];
            if (cachedVariation && Date.now() < cachedVariation.expiry) {
              return cachedVariation.variation as Experiments[T];
            }
            return undefined;
          }
          return undefined;
        }),
        distinctUntilChanged(),
        switchMap(cachedVariation => {
          // If there's a cached variation, use it.
          if (cachedVariation) {
            return of(cachedVariation);
          }

          // Otherwise return a pre-prepared abTestVariation request
          // since creating a new request might overload the AB test API
          return this.abTestVariationRequestMap[cacheKey] as Observable<
            Experiments[T] | undefined
          >;
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }

    return this.abTestVariationRequestMap[cacheKey] as Observable<
      Experiments[T] | undefined
    >;
  }

  private ssrRedirectToVariation<T extends keyof Experiments>(
    experimentName: T,
    variation: Experiments[T],
  ): void {
    const url = new URL(this.location.href);
    url.searchParams.set('overrides', `${experimentName}:${variation}`);
    this.response.set('X-Varnish-Follow-Redirect', 'true');
    const params: { [key: string]: string } = {};
    url.searchParams.forEach((value, key) => {
      params[key] = value;
    });
    this.router.navigate([this.location.pathname], {
      queryParams: params,
    });
  }

  /*
   * This allows to override A/B tests using the
   * ?overrides=<test_name>:<test_variant> query param.
   * Overrides can be chained to override multiple tests, e.g.
   * ?overrides=<test1_name>:<test1_variant>,<test1_name>:<test1_variant>.
   */
  private getOverride<T extends keyof Experiments>(
    experimentName: T,
  ): Experiments[T] | undefined {
    const params = new URL(this.location.href).searchParams.get('overrides');

    if (params) {
      const overridesMap: OverridesMap = this.parseOverridesParam(params);
      if (overridesMap[experimentName]) {
        return overridesMap[experimentName] as Experiments[T];
      }
    }

    // fallback to the persisted overrides map if no query param override is found
    if (this.overridesMap) {
      return this.overridesMap[experimentName] as Experiments[T];
    }

    return undefined;
  }

  private hasABTestOptOutCookie(): boolean {
    return isPlatformServer(this.platformId)
      ? this.request.cookies.no_abtest
      : this.cookies.get('no_abtest');
  }
  // Only to be called by the ABTestComponent
  setOverridesMap(overridesParam: string): void {
    this.overridesMap = this.parseOverridesParam(overridesParam);
  }

  private parseOverridesParam<T extends keyof Experiments>(
    overrides: string,
  ): OverridesMap {
    return overrides.split(',').reduce<OverridesMap>((acc, experiment) => {
      const [name, variation] = experiment.split(':');
      acc[name as T] = variation as Experiments[T];
      return acc;
    }, {});
  }

  // Only to be called by the ABTestComponent
  setGlobalOptOut(optOut: boolean): void {
    this.globalOptOut = optOut;
  }

  public canPerformVarnishInternalRedirects(): boolean {
    return (
      isPlatformServer(this.platformId) &&
      this.location.hostname !== 'localhost' &&
      this.location.hostname !== '0.0.0.0' &&
      !this.location.hostname.startsWith('192.168.') &&
      !this.location.hostname.startsWith('10.') &&
      this.request.get('X-Has-No-Varnish') !== 'true'
    );
  }

  /**
   * Adds a variation to the localstorage cache without overriding other existing values
   * @param cacheKey
   * @param variationName
   */
  private addToCache<T extends keyof Experiments>(
    cacheKey: string,
    variationName: Experiments[T],
  ): void {
    firstValueFrom(
      this.localStorage.get('abTestVariations').pipe(untilDestroyed(this)),
    ).then(storedVariations => {
      const newCachedVariations = {
        ...storedVariations,
        ...{
          [cacheKey]: {
            variation: variationName,
            expiry: Date.now() + VARIATION_TTL,
          },
        },
      };

      this.localStorage.set('abTestVariations', newCachedVariations);
    });
  }

  private cleanCache(): void {
    if (isPlatformServer(this.platformId)) {
      throw new Error('cleanCache() cannot be ran on the server');
    }

    // Cleanup all expired cache, and removed experiments
    firstValueFrom(
      this.localStorage.get('abTestVariations').pipe(untilDestroyed(this)),
    ).then(abTestVariations => {
      if (abTestVariations) {
        let cleanCache: ABTestVariationCache = {};
        Object.keys(abTestVariations).forEach(cacheKey => {
          if (Date.now() < abTestVariations[cacheKey].expiry) {
            cleanCache = {
              ...cleanCache,
              [cacheKey]: abTestVariations[cacheKey],
            };
          }
        });

        this.isCacheCleaned = true;
        this.localStorage.set('abTestVariations', cleanCache);
      }
    });
  }
}

import type { EntryApi, EntryFileApi } from 'api-typings/contests/contests';
import { EntryStatusApi } from 'api-typings/contests/contests';
import type { ContestViewEntriesPushResponseAjaxApi } from './contest-entries.backend-model';
import type {
  ContestEntry,
  ContestEntryFile,
  contestEntryRequestFields,
} from './contest-entries.model';

/**
 * Typescript enums generated from Thrift enums by API-typings do not retain
 * the numeric values and instead maps the keys to their string equivalent.
 * We manually re-create the mapping here because we need the numeric values
 * for sorting.
 */
const entryStatusStringToNumberMapping = Object.fromEntries(
  Object.values(EntryStatusApi).map((v, i) => [v, i]),
);

export function mapStatusTextToNumber(statusText: EntryStatusApi): number {
  const statusCode = entryStatusStringToNumberMapping[statusText];
  return statusCode !== undefined ? statusCode : 0;
}

export function transformContestEntry(entry: EntryApi): ContestEntry {
  return {
    id: entry.id,
    contestId: entry.contest_id,
    contestOwnerId: entry.contest_owner_id,
    ownerId: entry.owner_id,
    status: entry.status,
    number: entry.number,
    title: entry.title,
    description: entry.description,
    // The properties below are made required for sorting the DS collection
    rating: entry.rating ? entry.rating : 0,
    timeEntered: entry.time_entered ? entry.time_entered * 1000 : undefined,
    timeEliminated: entry.time_eliminated
      ? entry.time_eliminated * 1000
      : undefined,
    timeWon: entry.time_won ? entry.time_won * 1000 : undefined,
    deleted: entry.deleted,
    likeCount: entry.like_count,
    thumb:
      entry.files && entry.files[0] && entry.files[0].thumbnail_420_url
        ? entry.files[0].thumbnail_420_url
        : undefined,
    sellPrice: entry.sell_price,
    upgrades: entry.upgrades,
    statusNumber: mapStatusTextToNumber(entry.status),
    files: entry.files ? entry.files.map(transformContestEntryFile) : [],
  };
}

export function transformAjaxContestEntry(
  result: ContestViewEntriesPushResponseAjaxApi,
  data: Pick<ContestEntry, contestEntryRequestFields>,
): ContestEntry {
  return {
    ...data,
    id: result.id,
    redirect: result.redirect,
    status: result.entryStatus,
    files: data.files ? data.files.map(transformContestEntryFile) : [],
    sellPrice: data.sellPrice,
    statusNumber: mapStatusTextToNumber(result.entryStatus),
    thumb: data.files[0].fileUrl,
    upgrades: data.upgrades,
    deleted: false,
    likeCount: 0,
    rating: 0,
    timeEliminated: undefined,
    timeEntered: undefined,
    timeWon: undefined,
  };
}

export function transformContestEntryFile(
  file: EntryFileApi,
): ContestEntryFile {
  if (!file.id || !file.name) {
    throw new ReferenceError(
      `Contest entry file has missing properties: ${file}`,
    );
  }

  return {
    id: file.id,
    name: file.name,
    // The contest entry file urls do not have a protocol
    // which is needed to download files properly.
    // We prepend https: here to properly load the resource.
    fileUrl: file.file_url?.startsWith('//')
      ? `https:${file.file_url}`
      : file.file_url,
    thumbnailUrl: file.thumbnail_url?.startsWith('//')
      ? `https:${file.thumbnail_url}`
      : file.thumbnail_url,
    thumbnail420Url: file.thumbnail_420_url?.startsWith('//')
      ? `https:${file.thumbnail_420_url}`
      : file.thumbnail_420_url,
    thumbnail900Url: file.thumbnail_900_url?.startsWith('//')
      ? `https:${file.thumbnail_900_url}`
      : file.thumbnail_900_url,
    thumbnail80Url: file.thumbnail_80_url?.startsWith('//')
      ? `https:${file.thumbnail_80_url}`
      : file.thumbnail_80_url,
    thumbnail80FixUrl: file.thumbnail_80_fix_url?.startsWith('//')
      ? `https:${file.thumbnail_80_fix_url}`
      : file.thumbnail_80_fix_url,
    thumbnailDigestUrl: file.thumbnail_digest_url?.startsWith('//')
      ? `https:${file.thumbnail_digest_url}`
      : file.thumbnail_digest_url,
    thumbnailPreview: file.thumbnail_preview?.startsWith('//')
      ? `https:${file.thumbnail_preview}`
      : file.thumbnail_preview,
  };
}

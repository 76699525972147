import type { DateApi } from 'api-typings/common/common';
import type {
  QuotationAmountApi,
  QuotationApi,
} from 'api-typings/quotations/quotations';
import { QuotationStatusApi } from 'api-typings/quotations/quotations';
import type {
  QuotationAmount,
  QuotationRevision,
} from './quotation-revisions.model';
import { SECONDS_IN_A_DAY } from './quotation-revisions.model';

export function transformQuotationRevision(
  quotation: QuotationApi,
): QuotationRevision {
  return {
    id: quotation.quotation_version_id,
    quotationId: quotation.id,
    quotationUid: quotation.uid,
    versionUid: quotation.quotation_version_uid,
    contextType: quotation.context_type,
    contextId: quotation.context_id,
    creatorId: quotation.creator_id,
    createDate: quotation.create_date * 1000,
    isRequestedByRecipient: quotation.is_requested_by_recipient,
    defaultCurrencyId: quotation.default_currency_id || 1,
    approveDate: quotation.approve_date
      ? quotation.approve_date * 1000
      : undefined,
    paidAmount: transformQuotationAmount(quotation.paid_amount),
    fundedAmount: transformQuotationAmount(quotation.funded_amount),
    quotePrice: transformQuotationAmount(quotation.quote_price),
    title: quotation.title,
    description: quotation.description,
    richDescription: quotation.rich_description,
    versionInvalidDate: quotation.version_invalid_date
      ? quotation.version_invalid_date * 1000
      : undefined,
    parentVersionId: quotation.parent_version_id,
    // Should always be defined. Set invalid in the unlikely case this is undefined.
    status: quotation.status ?? QuotationStatusApi.INVALID,
    estimatedStartDate: transformToTimestamp(quotation.estimated_start_date),
    estimatedEndDate: transformToTimestamp(quotation.estimated_end_date),
    upfrontFundRequestAmount: quotation.upfront_fund_request_amount,
    cancelDate: quotation.cancel_date
      ? quotation.cancel_date * 1000
      : undefined,
    rejectDate: quotation.reject_date
      ? quotation.reject_date * 1000
      : undefined,
    expiryDate: quotation.expiry_date
      ? quotation.expiry_date * 1000
      : undefined,
    // Dividing by seconds in a day to convert to days
    durationInDays: quotation.duration
      ? quotation.duration / SECONDS_IN_A_DAY
      : quotation.duration,
    recipientId: quotation.recipient_id,
    parentTemplateVersionId: quotation.parent_template_version_id,
    logoId: quotation.logo_id,
  };
}

export function transformQuotationAmount(
  quotationAmount: readonly QuotationAmountApi[] = [],
): QuotationAmount {
  return Object.fromEntries(
    quotationAmount.map(cur => [cur.currency_id, cur.amount]),
  );
}

export function transformToDateApi(
  ts: number | undefined,
): DateApi | undefined {
  if (ts) {
    const date = new Date(ts);
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }
  return undefined;
}

export function transformToTimestamp(
  date: DateApi | undefined,
): number | undefined {
  if (date) {
    const d = new Date();
    d.setDate(date.day);
    d.setMonth(date.month - 1);
    d.setFullYear(date.year);
    return d.getTime();
  }
  return undefined;
}

import { Inject, Injectable } from '@angular/core';
import { Location } from '@freelancer/location';
import { isDefined } from '@freelancer/utils';
import { UI_CONFIG } from '../ui.config';
import { UiConfig } from '../ui.interface';

@Injectable({
  providedIn: 'root',
})
export class Assets {
  // Parse custom parameters in the constructor
  // and append it to the url request.
  private readonly extraQueryParameters: string;

  constructor(
    private location: Location,
    @Inject(UI_CONFIG) private uiConfig: UiConfig,
  ) {
    const customQueryParameters = new URLSearchParams();

    const { searchParams } = this.location;
    const fastlyImageOptimizer = searchParams?.get('image-optimizer');
    if (fastlyImageOptimizer) {
      customQueryParameters.append('image-optimizer', fastlyImageOptimizer);
    }

    const cutomQueryParamsStr = customQueryParameters.toString();
    if (cutomQueryParamsStr.length > 0) {
      this.extraQueryParameters = `?${cutomQueryParamsStr}`;
    } else {
      this.extraQueryParameters = '';
    }
  }

  getUrl(assetName: undefined): undefined;
  getUrl(assetName: string): string;
  getUrl(assetName?: string): string | undefined {
    // FIXME: T224449 Remove when we turn on StrictNullInputTypes
    // This check shouldn't be necessary but some components (eg. picture)
    // may pass `undefined` even though their @Input type is string.
    if (!isDefined(assetName)) {
      return undefined;
    }

    // add slash between base URL and file only if file doesn't already have one
    // this prevents double slashes in URLs that might not work in the CDN
    const slash = assetName.startsWith('/') ? '' : '/';

    // if the assets base url is relative, prepend with the current origin for
    // SSR to work
    return this.uiConfig.assetsBaseUrl.startsWith('/')
      ? `${this.location.origin}${this.uiConfig.assetsBaseUrl}${slash}${assetName}${this.extraQueryParameters}`
      : `${this.uiConfig.assetsBaseUrl}${slash}${assetName}${this.extraQueryParameters}`;
  }

  // PRIVATE API only used for SSR
  isAssetUrl(url: string): boolean {
    return this.uiConfig.assetsBaseUrl.startsWith('/')
      ? url.startsWith(`${this.location.origin}${this.uiConfig.assetsBaseUrl}/`)
      : url.startsWith(`${this.uiConfig.assetsBaseUrl}/`);
  }

  // PRIVATE API only used for SSR
  getAssetPath(url: string): string {
    return url.replace(/^.*?(?=\/assets\/)\/assets\//, '').split('?')[0];
  }
}

import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Injectable, Directive, Input } from '@angular/core';
// Duplicated import to work around a TypeScript bug where it'd complain that `Router` isn't imported as a type.
// We need to import it as a value to satisfy Angular dependency injection. So:
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN,
  SEMANTIC_ATTRIBUTE_SENTRY_SOURCE,
} from '@sentry/angular-ivy';
import type { Span } from '@sentry/types';
import { stripUrlQueryAndFragment, timestampInSeconds } from '@sentry/utils';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ErrorTrackingGlobalAccess } from './error-tracking-global-access.service';
import { ErrorTracking } from './error-tracking.service';

const ANGULAR_ROUTING_OP = 'ui.angular.routing';
const ANGULAR_INIT_OP = 'ui.angular.init';
const ANGULAR_OP = 'ui.angular';
const UNKNOWN_COMPONENT = 'unknown';

/************************************
 *
 * This tracking class is modified from
 * https://github.com/getsentry/sentry-javascript/blob/730c79410081d864e3bd686dccda3f8d141cd4b9/packages/angular/src/tracing.ts
 * Instead of calling Sentry directly, it used ErrorTrackingService to track both browser and service
 *
 * ***********************************/

/**
 * Angular's Service responsible for hooking into Angular Router and tracking current navigation process.
 * Creates a new transaction for every route change and measures a duration of routing process.
 */
@Injectable({ providedIn: 'root' })
export class FlTraceService implements OnDestroy {
  private navStart$ = this._router.events.pipe(
    filter(
      (event): event is NavigationStart => event instanceof NavigationStart,
    ),
    tap(navigationEvent => {
      // TODO: T297779 add configuration to enable/disable navigation tracking
      // if (!instrumentationInitialized) {
      //   IS_DEBUG_BUILD &&
      //   logger.error('Angular integration has tracing enabled, but Tracing integration is not configured');
      //   return;
      // }

      if (this._routingSpan) {
        this._errorTrackingService.endInactiveSpan(this._routingSpan);
      }

      const strippedUrl = stripUrlQueryAndFragment(navigationEvent.url);
      if (this._errorTrackingService.isSentryInitialized()) {
        this._routingSpan = this._errorTrackingService.startInactiveSpan({
          name: `${navigationEvent.url}`,
          op: ANGULAR_ROUTING_OP,
          attributes: {
            [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.angular',
            [SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'url',
            url: strippedUrl,
            ...(navigationEvent.navigationTrigger && {
              navigationTrigger: navigationEvent.navigationTrigger,
            }),
          },
        });
      }
    }),
  );

  private navEnd$ = this._router.events.pipe(
    filter(
      event =>
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError,
    ),
    tap(() => {
      if (this._routingSpan) {
        this._errorTrackingService.endInactiveSpan(this._routingSpan);
        this._routingSpan = undefined;
      }
    }),
  );

  private _routingSpan: Span | undefined;

  private _subscription: Subscription;

  public constructor(
    private readonly _router: Router,
    private _errorTrackingService: ErrorTracking,
  ) {
    this._routingSpan = undefined;

    this._subscription = new Subscription();

    this._subscription.add(this.navStart$.subscribe());
    this._subscription.add(this.navEnd$.subscribe());
  }

  /**
   * This is used to prevent memory leaks when the root view is created and destroyed multiple times,
   * since `subscribe` callbacks capture `this` and prevent many resources from being GC'd.
   */
  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}

/**
 * A directive that can be used to capture initialization lifecycle of the whole component.
 */
@Directive({ selector: '[flTrace]' })
export class FlTraceDirective implements OnInit, AfterViewInit {
  @Input('flTrace') public componentName?: string;

  private _tracingSpan?: Span;

  constructor(private errorTracking: ErrorTracking) {}

  /**
   * Implementation of OnInit lifecycle method
   * @inheritdoc
   */
  public ngOnInit(): void {
    if (!this.componentName) {
      this.componentName = UNKNOWN_COMPONENT;
    }
    if (this.errorTracking.getActiveSpan()) {
      this._tracingSpan = this.errorTracking.startInactiveSpan({
        name: `<${this.componentName}>`,
        op: ANGULAR_INIT_OP,
        attributes: {
          [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.angular.trace_directive',
        },
      });
    }
  }

  /**
   * Implementation of AfterViewInit lifecycle method
   * @inheritdoc
   */
  public ngAfterViewInit(): void {
    if (this._tracingSpan) {
      this.errorTracking.endInactiveSpan(this._tracingSpan);
    }
  }
}

interface TraceClassOptions {
  /**
   * Name of the class
   */
  name?: string;
}

/**
 * Decorator function that can be used to capture initialization lifecycle of the whole component.
 */
export function FlTraceClass(options?: TraceClassOptions): ClassDecorator {
  let tracingSpan: Span | undefined;

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  return target => {
    const errorTracking = ErrorTrackingGlobalAccess.getErrorTracking();
    const originalOnInit = target.prototype.ngOnInit;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,func-names
    target.prototype.ngOnInit = function (
      ...args: any[]
    ): ReturnType<typeof originalOnInit> {
      tracingSpan = errorTracking.startInactiveSpan({
        onlyIfParent: true,
        name: `<${options && options.name ? options.name : 'unnamed'}>`,
        op: ANGULAR_INIT_OP,
        attributes: {
          [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]:
            'auto.ui.angular.trace_class_decorator',
        },
      });

      if (originalOnInit) {
        return originalOnInit.apply(this, args);
      }
    };

    const originalAfterViewInit = target.prototype.ngAfterViewInit;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,func-names
    target.prototype.ngAfterViewInit = function (
      ...args: any[]
    ): ReturnType<typeof originalAfterViewInit> {
      this.errorTracking.endInactiveSpan(tracingSpan);
      if (originalAfterViewInit) {
        return originalAfterViewInit.apply(this, args);
      }
    };
  };
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
}

interface TraceMethodOptions {
  /**
   * Name of the method (is added to the tracing span)
   */
  name?: string;
}

/**
 * Decorator function that can be used to capture a single lifecycle methods of the component.
 * NOTE: It is used to count how many times does a method get called. as the span end immediately after it starts.
 */
export function FlTraceMethod(options?: TraceMethodOptions): MethodDecorator {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (
    target: Object,
    propertyKey: string | symbol,
    descriptor: PropertyDescriptor,
  ) => {
    const errorTracking = ErrorTrackingGlobalAccess.getErrorTracking();
    const originalMethod = descriptor.value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,func-names
    descriptor.value = function (
      ...args: any[]
    ): ReturnType<typeof originalMethod> {
      const now = timestampInSeconds();
      const span = errorTracking.startInactiveSpan({
        onlyIfParent: true,
        name: `<${options && options.name ? options.name : 'unnamed'}>`,
        op: `${ANGULAR_OP}.${String(propertyKey)}`,
        startTime: now,
        attributes: {
          [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]:
            'auto.ui.angular.trace_method_decorator',
        },
      });
      errorTracking.endInactiveSpan(span);

      if (originalMethod) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return originalMethod.apply(this, args);
      }
    };
    return descriptor;
  };
}

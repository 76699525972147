import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Location } from '@freelancer/location';
import { ABTest } from './abtest.service';

export const ABTestOverridesGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  const abtest = inject(ABTest);
  const location = inject(Location);

  const { searchParams } = new URL(location.href);
  const overridesParam = searchParams.get('overrides');

  if (overridesParam) {
    abtest.setOverridesMap(overridesParam);
  }

  if (
    searchParams.has('no_abtest') &&
    searchParams.get('no_abtest') !== 'false'
  ) {
    abtest.setGlobalOptOut(true);
  }
  return true;
};

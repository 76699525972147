import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import type {
  AppleSignInErrorResponse,
  AppleSignInResponse,
} from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import {
  ASAuthorizationAppleIDRequest,
  SignInWithApple,
} from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { Device } from '@capacitor/device';
import { Pwa } from '@freelancer/pwa';
import { AppleSignInError } from './apple-sso.interface';
import type { SSOInterface, SSOResponseData, SSOUser } from './sso.interface';

@Injectable({
  providedIn: 'root',
})
export class AppleSSO implements SSOInterface<'apple', AppleSignInResponse> {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private pwa: Pwa,
    private signInWithApple: SignInWithApple,
  ) {}

  async isEnabled(): Promise<boolean> {
    if (
      isPlatformBrowser(this.platformId) &&
      this.pwa.isNative() &&
      this.pwa.getPlatform() === 'ios'
    ) {
      const info = await Device.getInfo();
      // We don't use toNumber()  here, as the osVersion is often a mutlipart
      // version string, e.g. "13.2.2". parseInt() gives us the major version
      // component of that version string.
      const osVersion = parseInt(info.osVersion, 10);

      // Sign in with Apple is only available on iOS 13.0+
      return osVersion >= 13;
    }

    return false;
  }

  async getUserDetails(
    response: AppleSignInResponse,
  ): Promise<SSOUser | undefined> {
    return {
      email: response.email,
      name: response.fullName?.givenName ?? '',
      lastName: response.fullName?.familyName ?? '',
    };
  }

  async login(): Promise<
    SSOResponseData<'apple', AppleSignInResponse, AppleSignInError>
  > {
    if (!(await this.isEnabled())) {
      throw new Error(
        `Cannot use apple login on invalid platform ${this.pwa.getPlatform()} `,
      );
    }

    const response = await this.signInWithApple
      .signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
        ],
      })
      .then(
        (result: AppleSignInResponse) =>
          ({
            provider: 'apple',
            status: 'success',
            result,
          } as const),
      )
      .catch((errorResponse: AppleSignInErrorResponse) => {
        let errorCode: AppleSignInError;
        // the type in the library says "number" but it's actually a string...
        switch ((errorResponse.code as any) ?? undefined) {
          case '1001':
            errorCode = AppleSignInError.CANCELED;
            break;
          case '1002':
            errorCode = AppleSignInError.INVALID;
            break;
          case '1003':
            errorCode = AppleSignInError.NOT_HANDLED;
            break;
          case '1004':
            errorCode = AppleSignInError.FAILED;
            break;
          default:
            errorCode = AppleSignInError.UNKNOWN;
        }

        return {
          provider: 'apple',
          status: 'error',
          errorCode,
        } as const;
      });
    return response;
  }

  loadLoginStatus(): Promise<boolean> {
    if (this.pwa.isNative()) {
      return Promise.resolve(true);
    }

    // TODO: T267853 - implement JS SDK
    return Promise.resolve(false);
  }
}

import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Pwa } from './pwa.service';

export const PwaInstalledModeGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  const pwa = inject(Pwa);
  // Using the `pwa_installed` query param allows to force the installed
  // mode even when the app is not running in a standalone view
  if (route.queryParams.pwa_installed !== undefined) {
    pwa.activateInstalledMode();
  }
  return true;
};

import type {
  Applications,
  ApplicationsConfig,
  CompatApplications,
  DomainsMap,
  Environment,
  SentryConfig,
} from '@freelancer/config'; // interface';
// /!\ New apps & locales MUST be built in CI before being added there, or the
// webapp server will fail to start (a.k.a. deploy would fail) as a safety
// measure against runtime exceptions.
export const appsConfig: ApplicationsConfig = {
  admin: {
    location: '/admin',
    domains: {
      dev: {
        en: 'www.syd1.fln-dev.net',
      },
      stg: {
        en: 'www.freelancer-staging.com',
      },
      sandbox: {
        en: 'www.freelancer-sandbox.com',
      },
      prod: {
        en: 'www.freelancer.com',
      },
    },
    alternateDomains: {
      qabox: {
        dev: {
          en: /^[\w-]+\.syd1\.fln-dev\.net$/,
        },
        stg: {
          en: /^[\w-]+\.freelancer-staging\.com$/,
        },
      },
      loadshift: {
        prod: {
          en: 'www.loadshift.com.au',
        },
      },
    },
    order: 0,
  },
  main: {
    compat: 'compat',
    domains: {
      dev: {
        en: 'www.syd1.fln-dev.net',
        'en-IN': 'www.syd1.fln-dev.net',
        es: 'www.syd1.fln-dev.net',
        fr: 'www.syd1.fln-dev.net',
      },
      stg: {
        en: 'www.freelancer-staging.com',
        'en-IN': 'www.freelancer-staging.com',
        'en-AU': 'www.freelancer-staging.com.au',
        es: 'www.freelancer-staging.com',
        fr: 'www.fr.freelancer-staging.com',
      },
      sandbox: {
        en: 'www.freelancer-sandbox.com',
        'en-IN': 'www.freelancer-sandbox.com',
        es: 'www.freelancer-sandbox.com',
        fr: 'www.freelancer-sandbox.com',
      },
      prod: {
        en: 'www.freelancer.com',
        'en-AU': 'www.freelancer.com.au',
        'en-NZ': 'www.freelancer.co.nz',
        'en-GB': 'www.freelancer.co.uk',
        zh: 'www.freelancer.hk',
        'zh-HK': 'www.freelancer.hk',
        'en-SG': 'www.freelancer.sg',
        'en-PH': 'www.freelancer.ph',
        de: 'www.freelancer.de',
        'de-DE': 'www.freelancer.de',
        'en-CA': 'www.freelancer.ca',
        'en-ZA': 'www.freelancer.co.za',
        'en-IN': 'www.freelancer.in',
        'en-JM': 'www.freelancer.com.jm',
        es: 'www.freelancer.es',
        'es-ES': 'www.freelancer.es',
        'es-MX': 'www.freelancer.mx',
        'es-PE': 'www.freelancer.com.pe',
        'es-EC': 'www.freelancer.ec',
        'es-CL': 'www.freelancer.cl',
        'en-PK': 'www.freelancer.pk',
        id: 'www.freelancer.co.id',
        'id-ID': 'www.freelancer.co.id',
        'en-BD': 'www.freelancer.com.bd',
        'es-UY': 'www.freelancer.uy',
        pt: 'www.freelancer.pt',
        'pt-PT': 'www.freelancer.pt',
        ro: 'www.freelancer.co.ro',
        'ro-RO': 'www.freelancer.co.ro',
        'zh-CN': 'www.freelancer.cn',
        ja: 'www.freelancer.jp',
        'ja-JP': 'www.freelancer.jp',
        el: 'www.freelancer.gr',
        'el-GR': 'www.freelancer.gr',
        'en-IS': 'www.freelancer.is',
        th: 'www.freelancer.co.th',
        'th-TH': 'www.freelancer.co.th',
        cs: 'www.freelancer.cz',
        'cs-CZ': 'www.freelancer.cz',
        'pt-BR': 'www.br.freelancer.com',
        'es-AR': 'www.freelancer.com.ar',
        'es-CO': 'www.freelancer.com.co',
        pl: 'www.freelancer.pl',
        'pl-PL': 'www.freelancer.pl',
        uk: 'www.freelancer.com.ua',
        'uk-UA': 'www.freelancer.com.ua',
        nb: 'www.freelancer.no',
        'nb-NO': 'www.freelancer.no',
        ko: 'www.freelancer.co.kr',
        'ko-KR': 'www.freelancer.co.kr',
        'en-IE': 'www.freelancer.ie',
        fr: 'www.fr.freelancer.com',
        'fr-FR': 'www.fr.freelancer.com',
        it: 'www.freelancer.co.it',
        'it-IT': 'www.freelancer.co.it',
        sl: 'www.freelancer.si',
        'sl-SI': 'www.freelancer.si',
        sv: 'www.freelancer.se',
        'sv-SE': 'www.freelancer.se',
        fi: 'www.fi.freelancer.com',
        'fi-FI': 'www.fi.freelancer.com',
        da: 'www.dk.freelancer.com',
        'da-DK': 'www.dk.freelancer.com',
        ms: 'www.my.freelancer.com',
        'ms-MY': 'www.my.freelancer.com',
        tr: 'www.tr.freelancer.com',
        'tr-TR': 'www.tr.freelancer.com',
        vi: 'www.vn.freelancer.com',
        'vi-VN': 'www.vn.freelancer.com',
        sw: 'www.freelancer.co.ke',
        'sw-KE': 'www.freelancer.co.ke',
        sq: 'www.freelancer.com.al',
        'sq-AL': 'www.freelancer.com.al',
        hu: 'www.freelancer.hu',
        'hu-HU': 'www.freelancer.hu',
        // FIXME: T267853 - these languages have no domain nor subdomain: they aren't
        // discoverable by search engines nor linkable by URL, i.e.  the only
        // way to access them is to have an existing language cookie set
        af: 'www.freelancer.com',
        bn: 'www.freelancer.com',
        ca: 'www.freelancer.com',
        fil: 'www.freelancer.com',
        hi: 'www.freelancer.com',
        nl: 'www.freelancer.com',
      },
    },
    alternateDomains: {
      qabox: {
        dev: {
          en: /^[\w-]+\.syd1\.fln-dev\.net$/,
        },
        stg: {
          en: /^[\w-]+\.freelancer-staging\.com$/,
        },
      },
      externalSandbox: {
        sandbox: {
          // This is a semi-permanent qabox but with a special domain, used to push diffs to
          // for HP to test before release to production.
          en: 'hpis.freelancer-sandbox.com',
        },
      },
      // FIXME: T267853 - surely Freemarket should use SSO intead of just loading the
      // Freelancer login page from a different domain -_-
      freemarket: {
        prod: {
          en: 'www.freemarket.com',
        },
      },
    },
    // The main app must have a greater order than other apps since it will have the
    // most general regex for domain matching. This allows us to find the apps that
    // have stricter domain patterns before falling back to check the main app pattern.
    order: 100,
    hasNativeApp: true,
  },
  developers: {
    domains: {
      dev: {
        en: 'developers.syd1.fln-dev.net',
        de: 'developers.syd1.fln-dev.net',
      },
      stg: {
        en: 'developers.freelancer-staging.com',
        de: 'developers.freelancer-staging.com',
      },
      sandbox: {
        en: 'developers-shadow.freelancer-sandbox.com',
        de: 'developers-shadow.freelancer-sandbox.com',
      },
      prod: {
        // TODO: T267853 - Swap after the release date
        en: 'developers-shadow.freelancer.com',
        de: 'developers-shadow.freelancer.com',
      },
    },
    alternateDomains: {
      qabox: {
        dev: {
          en: /^developers-[\w-]+\.syd1\.fln-dev\.net$/,
        },
        stg: {
          en: /^developers-[\w-]+\.freelancer-staging\.com$/,
        },
      },
    },
    order: 1,
  },
  bits: {
    isStatic: true,
    domains: {
      dev: {
        en: 'bits.syd1.fln-dev.net',
      },
      stg: {
        en: 'bits.freelancer-staging.com',
      },
      prod: {
        en: 'bits.freelancer.com',
      },
    },
    alternateDomains: {
      qabox: {
        dev: {
          en: /^bits-[\w-]+\.syd1\.fln-dev\.net$/,
        },
        stg: {
          en: /^bits-[\w-]+\.freelancer-staging\.com$/,
        },
      },
    },
    order: 2,
  },
  deloitte: {
    compat: 'deloitte-compat',
    domains: {
      dev: {
        en: 'deloitte.syd1.fln-dev.net',
      },
      sandbox: {
        en: 'deloitte.freelancer-sandbox.com',
      },
      stg: {
        en: 'deloitte.freelancer-staging.com',
      },
      prod: {
        en: 'deloitte.freelancer.com',
      },
    },
    alternateDomains: {
      qabox: {
        dev: {
          en: /^deloitte-[\w-]+\.syd1\.fln-dev\.net$/,
        },
        stg: {
          en: /^deloitte-[\w-]+\.freelancer-staging\.com$/,
        },
      },
      externalSandbox: {
        sandbox: {
          // This is a permanent qabox but with a special domain, used to push diffs to
          // for Deloitte to test before release to production.
          en: 'deloitte-test.freelancer-sandbox.com',
        },
      },
    },
    order: 4,
  },
  freightlancer: {
    compat: 'freightlancer-compat',
    domains: {
      dev: {
        'en-CUSTOM': 'freight.syd1.fln-dev.net',
      },
      sandbox: {
        'en-CUSTOM': 'freight.freelancer-sandbox.com',
      },
      stg: {
        'en-CUSTOM': 'freight.freelancer-staging.com',
      },
      prod: {
        'en-CUSTOM': 'www.loadshift.com.au',
      },
    },
    alternateDomains: {
      qabox: {
        dev: {
          'en-CUSTOM': /^freight-[\w-]+\.syd1\.fln-dev\.net$/,
        },
        stg: {
          'en-CUSTOM': /^freight-[\w-]+\.freelancer-staging\.com$/,
        },
      },
      freightlancerLegacy: {
        prod: {
          'en-CUSTOM': 'freight.freelancer.com',
        },
      },
      freightlancerLegacyV2: {
        prod: {
          'en-CUSTOM': 'www.freightlancer.com',
        },
      },
      freightlancerLoadshift: {
        prod: {
          'en-CUSTOM': 'freight.loadshift.com.au',
        },
      },
    },
    order: 5,
    hasCustomLocale: true,
    hasNativeApp: true,
  },
};

type EnvDomainsMap = {
  [appName in Applications | CompatApplications]?: DomainsMap;
};

export function getDomainsMap(env: string): EnvDomainsMap {
  return Object.entries(appsConfig).reduce((acc, [appName, appConfig]) => {
    if (appConfig.domains[env as Environment]) {
      acc[appName as Applications] = appConfig.domains[env as Environment];
      if (appConfig.compat) {
        acc[appConfig.compat as CompatApplications] =
          appConfig.domains[env as Environment];
      }
    }
    return acc;
  }, {} as EnvDomainsMap);
}

export const sentryConfig: SentryConfig = {
  'webapp-browser': {
    dev: {
      tracesSampleRate: 0,
      dsn: 'https://29cfb544702b48e0985e69cd3ef5e84f@o24245.ingest.sentry.io/1410063',
    },
    stg: {
      tracesSampleRate: 0,
      dsn: 'https://29cfb544702b48e0985e69cd3ef5e84f@o24245.ingest.sentry.io/1410063',
    },
    sandbox: {
      tracesSampleRate: 0,
      dsn: 'https://29cfb544702b48e0985e69cd3ef5e84f@sentry.io/1410063',
    },
    prod: {
      // note: this can still be triggered in the browser
      // by whitelist users and a specific query param
      tracesSampleRate: 0,
      dsn: 'https://29cfb544702b48e0985e69cd3ef5e84f@sentry.io/1410063',
    },
  },
  'webapp-server': {
    dev: {
      tracesSampleRate: 0.001,
      dsn: 'https://ebba2801606447779bf121cefa9c5443@o24245.ingest.sentry.io/1432027',
    },
    stg: {
      tracesSampleRate: 0.001,
      dsn: 'https://ebba2801606447779bf121cefa9c5443@o24245.ingest.sentry.io/1432027',
    },
    sandbox: {
      tracesSampleRate: undefined,
      dsn: 'https://ebba2801606447779bf121cefa9c5443@o24245.ingest.sentry.io/1432027',
    },
    prod: {
      tracesSampleRate: 0.0001,
      dsn: 'https://863af835f4bd45d0aefd20d44e299a7d@o24245.ingest.sentry.io/1432027',
    },
  },
};

export interface GoogleSignInConfig {
  clientId?: string;
}

// This is what gets returned in the callback on google button initialize
// https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse
// and we need the credential from it.
export interface GoogleAuthResponse {
  credential: string;
}
// These are user details that are returned from the Google SSO
// response that may be useful in frontend
export interface GoogleAuthDecodedTokenUserDetails {
  email: string;
  given_name: string;
  family_name: string;
  name: string;
  picture: string;
}

export enum GoogleSignInError {
  UNKNOWN = 'google_unknown',
}

import type { NgModuleRef } from '@angular/core';
import { NgZone } from '@angular/core';
import type { ActivatedRouteSnapshot, Params } from '@angular/router';
import { Router } from '@angular/router';
import { ENVIRONMENT_NAME } from '@freelancer/config';

interface URI {
  readonly pathname: string;
  readonly queryParams: Params;
}

type ShouldReuseRouteFunction = (
  future: ActivatedRouteSnapshot,
  curr: ActivatedRouteSnapshot,
) => boolean;

export function registerWebappNavigation<T>(ref: NgModuleRef<T>): void {
  const w = window as any;
  if (!w.webapp) {
    return;
  }

  const env = ref.injector.get(ENVIRONMENT_NAME);
  if (env === 'prod') {
    return;
  }

  const zone = ref.injector.get(NgZone);
  const router = ref.injector.get(Router);

  w.webapp.reload = (jsonQueryParams: string = '') =>
    w.webapp.navigateByUrl(router.url, jsonQueryParams);

  /**
   * Expose the Router#navigate function via the webapp property
   * on the window object.
   *
   * Note: This function will be run in the Angular Zone.
   *
   * @param url - An absolute path for a defined route. The function does not apply any delta to the current URL.
   * @param jsonQueryParams - An optional JSON formatted query params
   */
  w.webapp.navigateByUrl = (url: string, jsonQueryParams: string = '') =>
    zone.run(() => {
      const originalShouldReuseRouteFunction = setupRouter(router);
      const { pathname, queryParams } = parseUri(url, jsonQueryParams);
      return router
        .navigate([pathname], {
          queryParams,
          queryParamsHandling: 'merge',
          onSameUrlNavigation: 'reload',
        })
        .then(() => {
          resetRouter(router, originalShouldReuseRouteFunction);
          return true;
        });
    });
}

/**
 * Parses the query parameters from a json string.
 *
 * @param jsonQueryParams
 */
function parseJsonQueryParams(jsonQueryParams: string): Params {
  if (jsonQueryParams.length === 0) {
    return {};
  }

  const queryParams: Params = {};
  const tempQueryParams = JSON.parse(jsonQueryParams);
  if (tempQueryParams.cacheBustingParam) {
    queryParams[tempQueryParams.cacheBustingParam] = Date.now().toString();
  }

  if (tempQueryParams.customQueryParams) {
    // eslint-disable-next-line guard-for-in
    for (const customQueryParamName in tempQueryParams.customQueryParams) {
      queryParams[customQueryParamName] =
        tempQueryParams.customQueryParams[customQueryParamName];
    }
  }
  return queryParams;
}

/**
 * Parses the url and json query param and creates an uri object
 *
 * @param url
 * @param jsonQueryParams
 */
function parseUri(url: string, jsonQueryParams: string = ''): URI {
  const uri = new URL(url, 'https://dummy');
  const parsedQueryParams: Params = {};
  uri.searchParams.forEach((value, key) => {
    parsedQueryParams[key] = value;
  });
  return {
    pathname: uri.pathname,
    queryParams: {
      ...parsedQueryParams,
      ...parseJsonQueryParams(jsonQueryParams),
    },
  };
}

/**
 * Set router for initial navigation
 *
 * @param router
 */
function setupRouter(router: Router): ShouldReuseRouteFunction {
  const originalShouldReuseRouteFunction =
    // eslint-disable-next-line deprecation/deprecation
    router.routeReuseStrategy.shouldReuseRoute;
  // eslint-disable-next-line deprecation/deprecation
  router.routeReuseStrategy.shouldReuseRoute = () => false;
  return originalShouldReuseRouteFunction;
}

/**
 * Restore router state back to what it was.
 *
 * @param router
 * @param originalShouldReuseRouteFunction
 */
function resetRouter(
  router: Router,
  originalShouldReuseRouteFunction: ShouldReuseRouteFunction,
): void {
  // eslint-disable-next-line deprecation/deprecation
  router.routeReuseStrategy.shouldReuseRoute = originalShouldReuseRouteFunction;
}

import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { MessagingChat } from './messaging-chat.service';

export const MessagingChatOverridesGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  const messagingChatService = inject(MessagingChat);
  const params = route.queryParams;

  if (params.no_auto_chat_open) {
    messagingChatService.disableAutoOpenChat();
  }

  return true;
};

import type {
  CollectionActions,
  CollectionStateSlice,
} from '@freelancer/datastore/core';
import {
  mergeDocuments,
  transformIntoDocuments,
} from '@freelancer/datastore/core';
import { transformUsersProfile } from '../users-profile/users-profile.transformers';
import type { UsersTeamProfileCollection } from './users-team-profile.types';

export function usersTeamProfileReducer(
  state: CollectionStateSlice<UsersTeamProfileCollection> = {},
  action: CollectionActions<UsersTeamProfileCollection>,
): CollectionStateSlice<UsersTeamProfileCollection> {
  switch (action.type) {
    case 'API_FETCH_SUCCESS': {
      if (action.payload.type === 'usersTeamProfile') {
        const { result, ref, order } = action.payload;
        if (result.users) {
          return mergeDocuments<UsersTeamProfileCollection>(
            state,
            transformIntoDocuments(result.users, transformUsersProfile),
            order,
            ref,
          );
        }
      }
      return state;
    }
    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import type { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NavigationEnd, Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import type {
  LoggedInShellConfig,
  LoggedOutShellConfig,
} from './shell-config.types';

/** Keep this in sync with ShellConfigTesting */
@Injectable({
  providedIn: 'root',
})
export class ShellConfig {
  constructor(private router: Router) {}

  getConfig<Config extends LoggedInShellConfig | LoggedOutShellConfig>(
    activatedRoute: ActivatedRoute,
  ): Observable<Config> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => activatedRoute.snapshot),
      startWith(activatedRoute.snapshot),
      map<ActivatedRouteSnapshot, Config>(snapshot =>
        this.getRecursiveRouteData(snapshot),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Get config from nested child routes.
   * Route data is specified in appropriate RoutingModules
   */
  getRecursiveRouteData<
    Config extends LoggedInShellConfig | LoggedOutShellConfig,
  >(snapshot: ActivatedRouteSnapshot): Config {
    let { data } = snapshot;
    snapshot.children.forEach(child => {
      data = { ...data, ...this.getRecursiveRouteData(child) };
    });
    return data as Config;
  }
}

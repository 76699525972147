import type { Backend } from '@freelancer/datastore/core';
import {
  OrderByDirection,
  getQueryParamValue,
} from '@freelancer/datastore/core';
import type { ServiceOfferingShopCategoriesCollection } from './service-offering-shop-categories.types';

export function serviceOfferingShopCategoriesBackend(): Backend<ServiceOfferingShopCategoriesCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (_authUid, ids, query, _order) => {
      const shopId = getQueryParamValue(query, 'shopId')[0];

      if (ids && ids.length === 1) {
        return {
          endpoint: `service_offerings/0.1/service_offerings/shops/${shopId}/categories/${ids[0]}`,
          params: {
            ids,
            limit: query?.searchQueryParams.limit,
            offset: query?.searchQueryParams.offset,
            category_seo_url: query?.searchQueryParams.categorySeoUrl,
          },
        };
      }
      return {
        endpoint: `service_offerings/0.1/service_offerings/shops/${shopId}/categories`,
        params: {
          ids,
          limit: query?.searchQueryParams.limit,
          offset: query?.searchQueryParams.offset,
          category_seo_url: query?.searchQueryParams.categorySeoUrl,
        },
      };
    },
    push: (_authUid, categoryData) => {
      return {
        endpoint: `service_offerings/0.1/service_offerings/shops/${categoryData.shopId}/categories`,
        method: 'POST',
        payload: {
          category: {
            shop_id: categoryData.shopId,
            name: categoryData.name,
            description: categoryData.description,
          },
        },
      };
    },
    remove: (authUid, id, originalDocument) => ({
      endpoint: `service_offerings/0.1/service_offerings/shops/${originalDocument.shopId}/categories/${originalDocument.id}`,
      method: 'DELETE',
      payload: {},
    }),
    set: undefined,
    update: (authUid, partial, original) => {
      return {
        endpoint: `service_offerings/0.1/service_offerings/shops/${original.shopId}/categories/${original.id}`,
        method: 'PUT',
        payload: {
          category: {
            id: original.id,
            shop_id: original.shopId,
            description: partial.description,
            name: partial.name,
          },
        },
      };
    },
  };
}

import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CoreWebVitalsTracking } from './core-web-vitals-tracking.service';

@Component({
  selector: 'fl-core-web-vitals-tracking',
  template: ` <ng-container></ng-container> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreWebVitalsTrackingComponent implements OnDestroy, OnInit {
  constructor(private coreWebVitalsTracking: CoreWebVitalsTracking) {}

  ngOnInit(): void {
    this.coreWebVitalsTracking.trackCoreWebVitalMetrics();
  }

  ngOnDestroy(): void {
    this.coreWebVitalsTracking.destroy();
  }
}

import type { NgZone } from '@angular/core';
import { RepetitiveSubscription } from '@freelancer/decorators';
import type { Timer, TimeUtils } from '@freelancer/time-utils';
import type { Subscriber } from 'rxjs';
import { Observable, Subscription, Subject } from 'rxjs';
import type { SockMessageSend } from './message-send-event.model';
import { Socket } from './sock-js';
import type { SockMessageRawEvent } from './sock-js.model';

/**
 * This error is thrown in the "close" event listener of the WebSocket connection,
 * when the "wasClean" property of the CloseEvent object is false, indicating that
 * the WebSocket connection was closed unexpectedly.
 */
export class NonCleanDisconnectError extends Error {}

/**
 * This class is responsible for creating an observable that maintains
 * the connection and sends messages to the backend after the WebSocket
 * connection has been established.
 *
 * The WebSocket connection can be established by passing in a string
 * representing the URL of the WebSocket server, or by passing in a
 * Socket object representing an established WebSocket connection.
 *
 * When the ObservableWebSocket is subscribed to, it sets up the
 * WebSocket connection and sends messages to the server. It also
 * listens for messages from the server and emits them as values of
 * the ObservableWebSocket.
 *
 * The ObservableWebSocket can be unsubscribed from, which will close
 * the WebSocket connection.
 */
// eslint-disable-next-line rxjs/no-subclass
export class ObservableWebSocket extends Observable<
  Observable<SockMessageRawEvent>
> {
  // Sock-js will send heartbeat every 25s
  public static readonly HEARTBEAT_MISSING_THRESHOLD = 60;

  @RepetitiveSubscription()
  private messagesStreamSubscription?: Subscription;
  private socket: Socket;
  private heartBeatTimer: Timer;

  /**
   * Constructor for the ObservableWebSocket class.
   *
   * @param webSocketUrlOrSocket - a string representing the URL of the WebSocket server,
   *                               or a Socket object representing an established WebSocket connection
   * @param ngZone - an Angular NgZone object, used to run code inside or outside Angular's zone
   * @param timeUtils - a TimeUtils instance with methods for working with time
   * @param handleMissingHeartBeat - a function that is called when the WebSocket connection's heartbeat is missing
   */
  constructor(
    private webSocketUrlOrSocket: string | Socket,
    private ngZone: NgZone,
    private timeUtils: TimeUtils,
    private handleMissingHeartBeat: () => void,
  ) {
    super();
  }

  /**
   * A method that is called when the ObservableWebSocket is subscribed to.
   * It is responsible for setting up the WebSocket connection and sending
   * messages to the server.
   *
   * @param observer - the observer to be notified of the WebSocket connection and messages
   * @returns a function that can be called to unsubscribe from the ObservableWebSocket
   */
  _subscribe(
    observer: Subscriber<Observable<SockMessageRawEvent>>,
  ): () => void {
    return this.ngZone.runOutsideAngular(() => {
      if (typeof this.webSocketUrlOrSocket === 'string') {
        this.socket = new Socket(this.webSocketUrlOrSocket);
      } else {
        this.socket = this.webSocketUrlOrSocket;
      }
      let isClosed = false;
      let forcedClose = false;
      let heartbeatListener: EventListener;
      const serverResponseSubject$ = new Subject<SockMessageRawEvent>();

      /**
       * The event listener for the "open" event of the WebSocket connection.
       * It is called when the WebSocket connection is established.
       *
       * If the connection was closed by calling the unsubscribe method,
       * the WebSocket connection is closed. Otherwise, the
       * serverResponseSubject$ is emitted as the next value of the
       * ObservableWebSocket, the heartbeat listener function is set up,
       * and the heartbeat listener is added to the WebSocket connection.
       */
      this.socket.onopen = () => {
        this.ngZone.run(() => {
          if (forcedClose) {
            this.socket.close();
          } else {
            observer.next(serverResponseSubject$);
            heartbeatListener = this.heartbeatFactory();
            this.socket.addHeartBeatListener(heartbeatListener);
          }
        });
      };

      /**
       * The event listener for the "error" event of the WebSocket connection.
       * It is called when an error occurs on the WebSocket connection.
       *
       * The isClosed flag is set to true and the error is emitted as an
       * error value of the ObservableWebSocket.
       */
      this.socket.onerror = e => {
        this.ngZone.run(() => {
          isClosed = true;
          observer.error(e);
        });
      };

      /**
       * The event listener for the "close" event of the WebSocket connection.
       * It is called when the WebSocket connection is closed.
       *
       * The isClosed flag is set to true. If the WebSocket connection was
       * closed in a clean way or by calling the unsubscribe method, the
       * ObservableWebSocket is completed. If the WebSocket connection was
       * closed unexpectedly, a NonCleanDisconnectError is emitted as an
       * error value of the ObservableWebSocket.
       */
      this.socket.onclose = e => {
        this.ngZone.run(() => {
          // prevent observer.complete() being called after observer.error(...)
          if (isClosed) {
            return;
          }

          isClosed = true;
          if (forcedClose || e.wasClean) {
            observer.complete();
            serverResponseSubject$.complete();
          } else if (!e.wasClean) {
            observer.error(new NonCleanDisconnectError());
          }
        });
      };

      /**
       * The event listener for the "message" event of the WebSocket connection.
       * It is called when a message is received from the WebSocket server.
       *
       * The message is emitted as a value of the serverResponseSubject$.
       */
      this.socket.onmessage = e => {
        this.ngZone.run(() => {
          serverResponseSubject$.next(e);
        });
      };

      return (): void => {
        this.ngZone.run(() => {
          forcedClose = true;

          this.unsubscribe();

          if (!isClosed) {
            clearTimeout(this.heartBeatTimer);
            this.socket.removeHeartBeatListener(heartbeatListener);
            this.ngZone.runOutsideAngular(() => {
              this.socket.close();
            });
          }
        });
      };
    });
  }

  /**
   * This function is responsible for sending messages to the backend
   * by subscribing to the messagesStream$ stream and whenever this
   * stream gets an emission, the emitted value is sent to the
   * backend.
   *
   * @param messagesStream$ - a stream of messages to be sent to the backend
   */
  send(messagesStream$: Observable<SockMessageSend>): void {
    this.messagesStreamSubscription = messagesStream$.subscribe(data => {
      this.ngZone.runOutsideAngular(() => this.socket.send(data));
    });
  }

  private unsubscribe(): void {
    this.messagesStreamSubscription?.unsubscribe();
  }

  /**
   * This function creates a heartbeat listener function that
   * checks for missing heartbeats from the server. If a heartbeat
   * is missing, the handleMissingHeartBeat function is called.
   *
   * @returns a function that listens for heartbeats from the server
   */
  private heartbeatFactory(): EventListener {
    const createHeartbeatTimer = (): Timer =>
      this.timeUtils.setTimeout(
        // Reconnect the WebSocket
        () => this.handleMissingHeartBeat(),
        ObservableWebSocket.HEARTBEAT_MISSING_THRESHOLD * 1000,
      );
    clearTimeout(this.heartBeatTimer);
    this.heartBeatTimer = createHeartbeatTimer();
    return (event: Event) => {
      clearTimeout(this.heartBeatTimer);
      this.heartBeatTimer = createHeartbeatTimer();
    };
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';
import { sessionStorageVersions } from '../collection-versions';
import type {
  DatastoreCollectionType,
  UserCollectionStateSlice,
} from '../store.model';
import { LocalCacheStorage } from './local-cache-storage.service';
import type { CollectionSliceCache } from './local-cache.interface';
import {
  COLLECTION_CACHE_INTERFACE_VERSION,
  isCollectionSliceCache,
} from './local-cache.interface';

function generateStorageKey(collectionType: string): string {
  return `datastore_local_cache_${collectionType}`;
}

/**
 * Merge the given documents and queries into the current user collection state slice by:
 *   - adding missing documents and queries if it is not already exist
 *   - updating existing documents or queries if a newer version is available
 *
 * @param currentUserCollectionSlice The current user collection state slice object.
 * @param docsAndQueriesToAdd Documents and queries which we would like to merge into the current slice.
 */
export function mergeUserCollectionStateSlice<
  C extends DatastoreCollectionType,
>(
  currentUserCollectionSlice: UserCollectionStateSlice<C> = {
    queries: {},
    documents: {},
  },
  docsAndQueriesToAdd: UserCollectionStateSlice<C> = {
    queries: {},
    documents: {},
  },
): UserCollectionStateSlice<C> {
  return {
    queries: {
      ...currentUserCollectionSlice.queries,
      ...Object.fromEntries(
        Object.entries(docsAndQueriesToAdd.queries).filter(
          ([queryString, query]) => {
            const queryInCollection =
              currentUserCollectionSlice.queries[queryString];
            return (
              // Does not currently exist.
              !queryInCollection ||
              // This query result is older than the current query result.
              query.timeUpdated >= queryInCollection.timeUpdated
            );
          },
        ),
      ),
    },
    documents: {
      ...currentUserCollectionSlice.documents,
      ...Object.fromEntries(
        Object.entries(docsAndQueriesToAdd.documents).filter(([id, doc]) => {
          const docInCollection = currentUserCollectionSlice.documents[id];
          return (
            // Does not currently exist.
            !docInCollection ||
            // This document is older than the one currently in the collection.
            doc.timeUpdated >= docInCollection.timeUpdated
          );
        }),
      ),
    },
  };
}

@Injectable({
  providedIn: 'root',
})
export class LocalCacheHelpers {
  constructor(
    private storage: LocalCacheStorage,
    private errorHandler: ErrorHandler,
  ) {}

  /**
   * Get the cached collection state slice from the session storage
   * for the given collection type.
   *
   * This returns an observable so we can test it with code that uses
   * marble diagrams. The issue appears to be with having virtual times
   * of marble diagrams with the definitely not-virtual-times of promises.
   * See the issues from a similar rx-marble library for more explanation:
   * - https://github.com/cartant/rxjs-marbles/issues/11
   * - https://github.com/cartant/rxjs-marbles/issues/71
   */
  getDatastoreCacheByCollectionType<C extends DatastoreCollectionType>(
    collectionType: string,
  ): Observable<CollectionSliceCache<C> | undefined> {
    return from(
      this.storage
        .get(generateStorageKey(collectionType))
        .catch(err => undefined) // Ignore errors in storage.get
        .then(sessionStorageValue => {
          if (!sessionStorageValue) {
            return undefined;
          }

          let cachedCollectionState;
          try {
            // Parse the JSON string from session storage.
            cachedCollectionState = JSON.parse(sessionStorageValue);
          } catch (err: any) {
            this.errorHandler.handleError(
              new JsonParseError(sessionStorageValue, err),
            );
          }

          return isCollectionSliceCache<C>(cachedCollectionState)
            ? cachedCollectionState
            : undefined;
        }),
    );
  }

  /**
   * Store the given cache object into session storage by collection type.
   */
  setDatastoreCacheByCollectionType<C extends DatastoreCollectionType>(
    collectionType: string,
    cacheObj: CollectionSliceCache<C>,
  ): Observable<void> {
    return from(
      this.storage
        .set(generateStorageKey(collectionType), JSON.stringify(cacheObj))
        .catch(err => {
          // Ignore the errors, e.g. quota is full or security error.
        }),
    );
  }

  getJsonSchemaHash(collectionType: string): string {
    return sessionStorageVersions[collectionType];
  }

  getWebappBuildTimestamp(): number | undefined {
    return window?.webapp?.version?.buildTimestamp;
  }

  getCurrentCollectionCacheVersion(): number {
    return COLLECTION_CACHE_INTERFACE_VERSION;
  }
}

class JsonParseError extends Error {
  value: string;

  constructor(value: string, originalError: Error) {
    super(originalError.message);
    this.value = value;
  }
}

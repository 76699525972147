import { Injectable } from '@angular/core';
import type { PreloadingStrategy, Route } from '@angular/router';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import type { PreloadRouteConfig } from './preload-route.config';
import { PreloadRoute } from './preload-route.service';

@Injectable({
  providedIn: 'root',
})
export class OnDemandPreloadingStrategy implements PreloadingStrategy {
  constructor(private preloadRoute: PreloadRoute) {}

  preload(
    route: Route & { data?: PreloadRouteConfig },
    load: () => Observable<any>,
  ): Observable<void> {
    this.preloadRoute._registerRoute(route, load);
    return of(undefined);
  }
}

import type { NgZone } from '@angular/core';
import type { SchedulerAction, SchedulerLike, Subscription } from 'rxjs';

// This is needed for Protractor - time-based RxJS operators need to leave the
// Angular zone. See:
// https://stackoverflow.com/questions/43121400/run-ngrx-effect-outside-of-angulars-zone-to-prevent-timeout-in-protractor/43184760
class LeaveZoneScheduler implements SchedulerLike {
  constructor(private zone: NgZone, private scheduler: SchedulerLike) {}

  schedule<T>(
    work: (this: SchedulerAction<T>, state?: T) => void,
    delay?: number,
    state?: T,
  ): Subscription {
    return this.zone.runOutsideAngular(() =>
      // FIXME: T278133 Catch and unsubscribe from this.
      // eslint-disable-next-line local-rules/no-ignored-subscription
      this.scheduler.schedule(work, delay, state),
    );
  }

  now(): number {
    return this.scheduler.now();
  }
}

class EnterZoneScheduler implements SchedulerLike {
  constructor(private zone: NgZone, private scheduler: SchedulerLike) {}

  schedule<T>(
    work: (this: SchedulerAction<T>, state?: T) => void,
    delay?: number,
    state?: T,
  ): Subscription {
    // FIXME: T278133 Catch and unsubscribe from this.
    // eslint-disable-next-line local-rules/no-ignored-subscription
    return this.zone.run(() => this.scheduler.schedule(work, delay, state));
  }

  now(): number {
    return this.scheduler.now();
  }
}

export function leaveZone(
  zone: NgZone,
  scheduler: SchedulerLike,
): SchedulerLike {
  return new LeaveZoneScheduler(zone, scheduler);
}

export function enterZone(
  zone: NgZone,
  scheduler: SchedulerLike,
): SchedulerLike {
  return new EnterZoneScheduler(zone, scheduler);
}

import { NgModule } from '@angular/core';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { PwaHideInstalledDirective } from './pwa-hide-installed.directive';
import { PwaComponent } from './pwa.component';

@NgModule({
  declarations: [PwaComponent, PwaHideInstalledDirective],
  exports: [PwaComponent, PwaHideInstalledDirective],
  providers: [AppRate],
})
export class PwaModule {}

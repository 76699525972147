import { Injectable } from '@angular/core';
import { SwipeNavigation } from './capacitor-plugins';
import { Pwa } from './pwa.service';

@Injectable({
  providedIn: 'root',
})
export class SwipeNavigationService {
  constructor(private pwa: Pwa) {}

  /**
   * Enables Swipe Gestures thats performs back / forward navigation.
   * This is only available in IOS at the moment.
   */
  async enableSwipeGestures(val: boolean): Promise<void> {
    if (!this.pwa.isNative() || this.pwa.getPlatform() !== 'ios') {
      return;
    }

    // Perform try...catch here so it silently fails when the native app
    // doesn't support swipeNavigationPlugin or some of its features since some
    // users might have an oudated app version.
    try {
      if (val) {
        SwipeNavigation.enable();
      } else {
        SwipeNavigation.disable();
      }
    } catch (e: any) {
      // Do nothing as this feature isn't critical to the users.
    }
  }
}

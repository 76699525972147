import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@freelancer/location';
import { Assets } from '@freelancer/ui/assets';
import {
  FreelancerBreakpoints,
  FreelancerMaxBreakpoint,
} from '@freelancer/ui/breakpoints';
// NOTE: need to import from helpers directly to avoid circular dependency
import { isImageFile } from '@freelancer/ui/helpers';
import { isDefined, toNumber } from '@freelancer/utils';
import type {
  ImageQualityQueryParameters,
  PictureBackgroundImageStyles,
  PreloadInfo,
} from './picture.types';
import {
  AspectRatioValue,
  CropDetails,
  DEFAULT_DPR1_SCALING_FACTOR,
  DPR1_SCALING_FACTOR_QUERY_PARAM,
  PictureBgImageObjectFit,
  PictureDisplay,
  PictureLayout,
  PictureLoadMethod,
  PictureMetadata,
  PictureObjectFit,
  PictureObjectPosition,
  PictureResizeFit,
  deviceSizes,
  imageSizes,
  passthroughParametersMap,
} from './picture.types';

@Component({
  selector: 'fl-picture',
  template: `
    <picture
      *ngIf="largeDesktopSrcset || desktopSrcset || tabletSrcset; else img"
      class="PictureElement"
      [class.IsShown]="!lazyLoad || hasLazyLoadSupport || withinView"
      [attr.data-display]="display"
      [attr.data-border-radius-bottom]="borderRadiusBottom"
      [attr.data-border-radius-top]="borderRadiusTop"
      [attr.data-bounded-height]="boundedHeight"
      [attr.data-aspect-ratio]="fixedAspectRatio"
      [attr.data-zoom-on-hover]="zoomOnHover"
    >
      <source
        *ngIf="
          largeDesktopSrcset && (!lazyLoad || hasLazyLoadSupport || withinView)
        "
        [attr.media]="FreelancerBreakpoints.DESKTOP_LARGE"
        [attr.srcset]="largeDesktopSrcset"
      />
      <source
        *ngIf="desktopSrcset && (!lazyLoad || hasLazyLoadSupport || withinView)"
        [attr.media]="FreelancerBreakpoints.DESKTOP_SMALL"
        [attr.srcset]="desktopSrcset"
      />
      <source
        *ngIf="tabletSrcset && (!lazyLoad || hasLazyLoadSupport || withinView)"
        [attr.media]="FreelancerBreakpoints.TABLET"
        [attr.srcset]="tabletSrcset"
      />

      <ng-container [ngTemplateOutlet]="img"></ng-container>
    </picture>

    <ng-template #img>
      <img
        #nativeImage
        class="ImageElement"
        [class.IsShown]="!lazyLoad || hasLazyLoadSupport || withinView"
        [class.ZoomOnHover]="zoomOnHover"
        [attr.alt]="alt"
        [attr.data-align-center]="alignCenter"
        [attr.data-aspect-ratio]="fixedAspectRatio"
        [attr.data-aspect-ratio-value]="aspectRatioValue"
        [attr.data-bg-object-fit-desktop-large]="bgObjectFitDesktopLarge"
        [attr.data-bg-object-fit-desktop-xlarge]="bgObjectFitDesktopXLarge"
        [attr.data-bg-object-fit-desktop-xxlarge]="bgObjectFitDesktopXXLarge"
        [attr.data-bg-object-fit-desktop]="bgObjectFitDesktop"
        [attr.data-bg-object-fit-tablet]="bgObjectFitTablet"
        [attr.data-bg-object-fit]="bgObjectFit"
        [attr.data-border-radius-bottom]="borderRadiusBottom"
        [attr.data-border-radius-top]="borderRadiusTop"
        [attr.data-bounded-height]="boundedHeight"
        [attr.data-bounded-width]="boundedWidth"
        [attr.data-display]="display"
        [attr.data-full-width]="fullWidth"
        [attr.data-is-bg-image]="isBackgroundImage"
        [attr.data-object-fit]="objectFit"
        [attr.height]="height"
        [attr.loading]="lazyLoad ? 'lazy' : undefined"
        [attr.width]="width"
        [attr.sizes]="sizes"
        [attr.srcset]="imageSrcset"
        [attr.src]="
          !lazyLoad || hasLazyLoadSupport || withinView ? imageSrc : undefined
        "
        [ngStyle]="{
          'object-position': objectPosition
            ? objectPosition.horizontal + ' ' + objectPosition.vertical
            : ''
        }"
        (load)="handleImageLoad()"
        (error)="handleImageError($event)"
      />
    </ng-template>

    <div
      *ngIf="pictureMetadata"
      itemscope
      itemtype="https://schema.org/ImageObject"
    >
      <meta
        itemprop="contentUrl"
        [content]="src"
      />
      <span
        itemprop="author"
        itemscope
        itemtype="https://schema.org/Person"
      >
        <meta
          itemprop="name"
          [content]="pictureMetadata.author"
        />
      </span>
      <meta
        itemprop="name"
        [content]="pictureMetadata.name"
      />
    </div>
  `,
  styleUrls: ['./picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  FreelancerBreakpoints = FreelancerBreakpoints;

  @Input() src: string;
  @Input() alt = '';

  /**
   * NOTE: For `fullWidth`, `boundedWidth`, `boundedHeight`, and `display`
   * may be overwritten when `layout` is set.
   */
  @Input() layout?: PictureLayout;

  /**
   * DEPRECATED - DO NOT USE. Use `layout = PictureLayout.RESPONSIVE` instead.
   *
   * Forces the image to always have 100% of the width of its parent, no matter
   * how big or small it is.
   */
  @Input() fullWidth: boolean;

  /**
   * DEPRECATED - DO NOT USE. Use `layout = PictureLayout.INTRINSIC` or
   * `PictureLayout.FILL` instead.
   *
   * This ensures that the image will not overflow to its parent container by
   * adding `max-width: 100%`. This is mostly useful if you want your image to
   * adjust accordingly on smaller screens while keeping its original width on
   * bigger screens.
   */
  @Input() boundedWidth?: boolean;

  /**
   * DEPRECATED - DO NOT USE. Use `layout = PictureLayout.FILL` instead.
   *
   * This ensures that the image will not overflow to its parent container's
   * height.
   */
  @Input() boundedHeight?: boolean;

  @HostBinding('attr.data-display')
  @Input()
  display: PictureDisplay;

  /** Change the object-fit of the img element inside the component */
  @Input() objectFit?: PictureObjectFit;

  /** Center aligns the image by setting the horizontal margins to auto */
  @Input() alignCenter?: boolean;

  /** Change the object-position of the img element inside the component
   *
   * Used to specify how to position the picture within the container
   * When using the object-fit property, images are positioned at the center of the container by default.
   */
  @Input() objectPosition?: PictureObjectPosition;

  /** Fallback image source if image doesn't load */
  @Input() fallbackSrc: string;

  /** LEGACY - DO NOT USE. Use the `sizes` input instead. */
  @Input() srcTablet?: string;
  /** LEGACY - DO NOT USE. Use the `sizes` input instead. */
  @Input() srcDesktop?: string;
  /** LEGACY - DO NOT USE. Use the `sizes` input instead. */
  @Input() srcLargeDesktop?: string;

  /** Source image width in px.
   *
   * Used with height to allow the browser to calculate the image aspect ratio
   * and reserve space for the image before it is downloaded.
   *
   * Only determines display width if not set with css. */
  @Input() width?: number;

  /** Source image height in px.
   *
   * Used with width to allow the browser to calculate the image aspect ratio
   * and reserve space for the image before it is downloaded.
   *
   * Only determines display height if not set with css. */
  @Input() height?: number;

  /** Forces image to follow our standard aspect ratio with an object fit cover */
  @HostBinding('attr.data-aspect-ratio')
  @Input()
  fixedAspectRatio = false;

  /** Set the aspect ratio on the image*/
  @HostBinding('attr.data-aspect-ratio-value')
  @Input()
  aspectRatioValue?: AspectRatioValue;

  /** This variable defines how an image is to be loaded */
  @Input()
  loadMethod?: PictureLoadMethod;

  /** Metadata related to the picture. Used to create ImageObject metadata for the picture. */
  @Input() pictureMetadata?: PictureMetadata;

  /** Removes pixels from an image, retaining only the specified region.
   * The cropping is done through fastly. See also: https://developer.fastly.com/reference/io/crop/
   */
  @Input() crop?: CropDetails;

  /** How the image will be constrained within the `width` and `height`.
   * The fitting is done through fastly and only works if both `width` and `height` are set.
   * This is different from `objectFit`/`PictureObjectFit`, where `objectFit` is concerned with how the downloaded image will be be positioned in the `<img>`.
   * `resizeFit` is the preprocessing of the image done in fastly.
   *
   * See also: https://developer.fastly.com/reference/io/fit/
   */
  @Input() resizeFit?: PictureResizeFit;

  @Input() borderRadiusBottom = false;
  @Input() borderRadiusTop = false;

  @HostBinding('attr.data-is-bg-image')
  @Input()
  isBackgroundImage?: boolean = false;

  @HostBinding('attr.data-zoom-on-hover')
  @Input()
  zoomOnHover = false;

  @Input() set backgroundStyles(
    value: PictureBackgroundImageStyles | undefined,
  ) {
    if (!value) {
      return;
    }

    this.bgColor = value.color;
    this.bgPosition = value.position || 'center top';
    this.bgPositionTablet = value.positionTablet || this.bgPosition;
    this.bgPositionDesktop = value.positionDesktop || this.bgPositionTablet;
    this.bgPositionDesktopLarge =
      value.positionDesktopLarge || this.bgPositionDesktop;
    this.bgPositionDesktopXLarge =
      value.positionDesktopXLarge || this.bgPositionDesktopLarge;
    this.bgPositionDesktopXXLarge =
      value.positionDesktopXXLarge || this.bgPositionDesktopXLarge;

    this.bgObjectFit = value.objectFit || PictureBgImageObjectFit.COVER;
    this.bgObjectFitTablet = value.objectFitTablet;
    this.bgObjectFitDesktop = value.objectFitDesktop;
    this.bgObjectFitDesktopLarge = value.objectFitDesktopLarge;
    this.bgObjectFitDesktopXLarge = value.objectFitDesktopXLarge;
    this.bgObjectFitDesktopXXLarge = value.objectFitDesktopXXLarge;
  }

  /** Specify the intendend image size at the specified media query.
   * String format is a comma-separated values consisting of a media query then the size of the image.
   * When set, it will generate the `srcset` using `imageSizes` with width descriptors.
   * Samples: `(min-width: 360px) 256px`, `(min-width: 360px) 768px, (min-width: 768px) 368px`.
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-sizes
   */
  @Input() sizes?: string;

  @Output() imageLoaded = new EventEmitter<ElementRef<HTMLImageElement>>();
  @Output() onError = new EventEmitter<void>();

  /** This is to get the largest source image to display for IE */
  imageSrc?: string;
  imageSrcset?: string;
  tabletPreloadSrc?: string;
  tabletSrcset?: string;
  desktopPreloadSrc?: string;
  desktopSrcset?: string;
  largeDesktopPreloadSrc?: string;
  largeDesktopSrcset?: string;

  @HostBinding('style.--bg-color')
  bgColor?: string;
  @HostBinding('style.--bg-position')
  bgPosition?: string;
  @HostBinding('style.--bg-position-tablet')
  bgPositionTablet?: string;
  @HostBinding('style.--bg-position-desktop')
  bgPositionDesktop?: string;
  @HostBinding('style.--bg-position-desktop-large')
  bgPositionDesktopLarge?: string;
  @HostBinding('style.--bg-position-desktop-xlarge')
  bgPositionDesktopXLarge?: string;
  @HostBinding('style.--bg-position-desktop-xxlarge')
  bgPositionDesktopXXLarge?: string;

  bgObjectFit?: PictureBgImageObjectFit;
  bgObjectFitTablet?: PictureBgImageObjectFit;
  bgObjectFitDesktop?: PictureBgImageObjectFit;
  bgObjectFitDesktopLarge?: PictureBgImageObjectFit;
  bgObjectFitDesktopXLarge?: PictureBgImageObjectFit;
  bgObjectFitDesktopXXLarge?: PictureBgImageObjectFit;

  /** This tracks the position of the image relative to the screen. This is needed as a fallback for browsers with no native lazy load support */
  private observer: IntersectionObserver;
  /** This marks that the image should be loaded */
  withinView = false;
  /** This will be used in the template to check if lazyLoad is enabled */
  lazyLoad = false;
  /** This is set to false in SSR */
  hasLazyLoadSupport = false;

  @ViewChild('nativeImage') nativeImage: ElementRef<HTMLImageElement>;

  private dpr1ScalingFactor: number = DEFAULT_DPR1_SCALING_FACTOR;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private doc: Document,
    private activatedRoute: ActivatedRoute,
    private assets: Assets,
    private changeDetector: ChangeDetectorRef,
    private location: Location,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.lazyLoad = this.loadMethod === PictureLoadMethod.LAZY;
    this.hasLazyLoadSupport = 'loading' in HTMLImageElement.prototype;

    if (
      this.loadMethod === PictureLoadMethod.PRELOAD &&
      this.imageSrc &&
      this.imageSrcset
    ) {
      const preloads: PreloadInfo[] = [
        {
          src: this.imageSrc,
          srcset: this.imageSrcset,
        },
      ];

      if (this.tabletPreloadSrc && this.tabletSrcset) {
        const mobilePreload = preloads[0];
        mobilePreload.mediaQuery = FreelancerMaxBreakpoint.TABLET;

        preloads.push({
          src: this.tabletPreloadSrc,
          srcset: this.tabletSrcset,
          mediaQuery: FreelancerBreakpoints.TABLET,
        });
      }

      if (this.desktopPreloadSrc && this.desktopSrcset) {
        // This could be the mobile or tablet preload
        const previousPreload = preloads[preloads.length - 1];
        previousPreload.mediaQuery = previousPreload.mediaQuery
          ? `${previousPreload.mediaQuery} and ${FreelancerMaxBreakpoint.DESKTOP_SMALL}`
          : // This is the mobile preload
            FreelancerMaxBreakpoint.DESKTOP_SMALL;

        preloads.push({
          src: this.desktopPreloadSrc,
          srcset: this.desktopSrcset,
          mediaQuery: FreelancerBreakpoints.DESKTOP_SMALL,
        });
      }

      if (this.largeDesktopPreloadSrc && this.largeDesktopSrcset) {
        // This could be the mobile, tablet or desktop preload
        const previousPreload = preloads[preloads.length - 1];
        previousPreload.mediaQuery = previousPreload.mediaQuery
          ? `${previousPreload.mediaQuery} and ${FreelancerMaxBreakpoint.DESKTOP_LARGE}`
          : // This is the mobile preload
            FreelancerMaxBreakpoint.DESKTOP_LARGE;

        preloads.push({
          src: this.largeDesktopPreloadSrc,
          srcset: this.largeDesktopSrcset,
          mediaQuery: FreelancerBreakpoints.DESKTOP_LARGE,
        });
      }

      preloads.forEach(preload => this.addPreloadImageLink(preload));
    }
  }

  private addPreloadImageLink({ src, srcset, mediaQuery }: PreloadInfo): void {
    const preloadLink = this.doc.createElement('link');
    preloadLink.setAttribute('rel', 'preload');
    preloadLink.setAttribute('as', 'image');
    preloadLink.setAttribute('href', src);
    preloadLink.setAttribute('imagesrcset', srcset);
    if (mediaQuery) {
      preloadLink.setAttribute('media', mediaQuery);
    }
    if (this.sizes) {
      preloadLink.setAttribute('sizes', this.sizes);
    }

    this.doc.head.appendChild(preloadLink);
  }

  ngAfterViewInit(): void {
    if (this.lazyLoad && !this.hasLazyLoadSupport) {
      const config = {
        rootMargin: '100%',
      };
      if (isPlatformBrowser(this.platformId)) {
        this.observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.intersectionRatio === 0) {
              return;
            }
            this.withinView = true;
            this.observer.unobserve(this.nativeImage.nativeElement);
            this.changeDetector.markForCheck();
          });
        }, config);
        this.observer.observe(this.nativeImage.nativeElement);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'src' in changes ||
      'srcTablet' in changes ||
      'srcDesktop' in changes ||
      'srcLargeDesktop' in changes ||
      'crop' in changes ||
      'layout' in changes ||
      'resizeFit' in changes ||
      'width' in changes ||
      'height' in changes
    ) {
      const imageQualityParams = {
        passthroughParams: this.parsePassthroughParameters(),
        crop: this.crop,
        width: this.width,
        fit: this.resizeFit,
      };

      const scalingFactorFromUrl =
        this.activatedRoute.snapshot.queryParamMap.get(
          DPR1_SCALING_FACTOR_QUERY_PARAM,
        );
      this.dpr1ScalingFactor = isDefined(scalingFactorFromUrl)
        ? toNumber(scalingFactorFromUrl)
        : DEFAULT_DPR1_SCALING_FACTOR;

      const { src: mobileSrc, srcset: mobileSrcset } =
        this.appendImageQualityParameters(this.src, imageQualityParams);
      this.imageSrc = mobileSrc;
      this.imageSrcset = mobileSrcset ?? mobileSrc;

      const { src: tabletSrc, srcset: tabletSrcset } =
        this.appendImageQualityParameters(this.srcTablet, imageQualityParams);
      this.tabletPreloadSrc = tabletSrc;
      this.tabletSrcset = tabletSrcset ?? tabletSrc;

      const { src: desktopSrc, srcset: desktopSrcset } =
        this.appendImageQualityParameters(this.srcDesktop, imageQualityParams);
      this.desktopPreloadSrc = desktopSrc;
      this.desktopSrcset = desktopSrcset ?? desktopSrc;

      const { src: largeDesktopSrc, srcset: largeDesktopSrcset } =
        this.appendImageQualityParameters(
          this.srcLargeDesktop,
          imageQualityParams,
        );
      this.largeDesktopPreloadSrc = largeDesktopSrc;
      this.largeDesktopSrcset = largeDesktopSrcset ?? largeDesktopSrc;

      this.imageSrc = mobileSrc ?? largeDesktopSrc ?? desktopSrc ?? tabletSrc;

      if (
        ('resizeFit' in changes || 'width' in changes || 'height' in changes) &&
        this.resizeFit &&
        (!this.width || !this.height)
      ) {
        throw new Error('resizeFit requires both width and height to be set.');
      }

      if (
        ('resizeFit' in changes || 'layout' in changes) &&
        this.resizeFit &&
        this.layout !== PictureLayout.FIXED
      ) {
        throw new Error('resizeFit can only be used for fixed layout.');
      }

      if (
        'layout' in changes &&
        changes.layout.previousValue !== changes.layout.currentValue
      ) {
        switch (this.layout) {
          case PictureLayout.RESPONSIVE:
            if (this.fullWidth !== undefined) {
              throw new Error(
                'Responsive layout should not have fullWidth set.',
              );
            }
            this.fullWidth = true;
            if (this.display !== undefined) {
              throw new Error(
                'Responsive layout should not have display variable set.',
              );
            }
            this.display = PictureDisplay.BLOCK;
            break;
          case PictureLayout.FIXED:
            if (!this.height || !this.width) {
              throw new Error(
                'Fixed layout selected with no height or width set.',
              );
            }
            break;
          case PictureLayout.FILL:
            if (
              this.boundedHeight !== undefined ||
              this.boundedWidth !== undefined
            ) {
              throw new Error(
                'Fill layout should not have boundedHeight or boundedWidth set.',
              );
            }
            this.boundedHeight = true;
            this.boundedWidth = true;
            if (this.display !== undefined) {
              throw new Error(
                'Fill layout should not have display variable set.',
              );
            }
            this.display = PictureDisplay.RELATIVE;
            if (!this.objectFit) {
              throw new Error('Fill layout selected with no objectFit set.');
            }
            break;
          case PictureLayout.INTRINSIC:
            if (this.boundedWidth !== undefined) {
              throw new Error(
                'Intrinsic layout should not have boundedWidth set.',
              );
            }
            this.boundedWidth = true;
            break;
          default:
            break;
        }
      }
    }

    if (
      ('sizes' in changes ||
        'srcTablet' in changes ||
        'srcDesktop' in changes ||
        'srcLargeDesktop' in changes) &&
      this.sizes &&
      (this.srcTablet || this.srcDesktop || this.srcLargeDesktop)
    ) {
      throw new Error(
        'Sizes cannot be used together with the responsive SRC inputs.',
      );
    }

    if (
      ('sizes' in changes || 'layout' in changes) &&
      this.sizes &&
      this.layout !== PictureLayout.RESPONSIVE &&
      this.layout !== PictureLayout.FILL
    ) {
      throw new Error('Sizes can only be used for responsive or fill layout.');
    }
  }

  parsePassthroughParameters(): ImageQualityQueryParameters {
    const params = Object.entries(passthroughParametersMap)
      .map(([passthroughQueryParam, fastlyQueryParam]) => [
        fastlyQueryParam,
        this.activatedRoute.snapshot.queryParamMap.get(passthroughQueryParam),
      ])
      .filter(([, queryParamValue]) => queryParamValue);
    return Object.fromEntries(params);
  }

  isRawImageData(source: string): boolean {
    return source?.startsWith('data:image/');
  }

  appendImageQualityParameters(
    picSource: string | undefined,
    imageQualityParameters: {
      width?: number;
      passthroughParams?: ImageQualityQueryParameters;
      crop?: CropDetails;
      fit?: PictureResizeFit;
    },
  ): { src?: string; srcset?: string } {
    if (!picSource) {
      return {};
    }

    const src = this.getSrc(picSource);
    if (this.isRawImageData(src)) {
      return {
        src: this.sanitizer.bypassSecurityTrustUrl(src) as string,
      };
    }

    if (isImageFile(src, { allowedFileExtensions: ['svg'] })) {
      return { src };
    }

    let processedSrc = new URL(src, this.location.origin);
    if (!processedSrc) {
      return { src };
    }

    const { width, passthroughParams, crop, fit } = imageQualityParameters;
    if (passthroughParams) {
      processedSrc = this.appendPassthroughParameters(
        processedSrc,
        passthroughParams,
      );
    }

    if (crop) {
      processedSrc = this.appendCropParameters(processedSrc, crop);
    }

    if (fit) {
      processedSrc.searchParams.set('fit', fit);
    }

    // FIXME: T262675 - Stop setting `width` through the URL
    if (
      // Any fastly related value is set
      width ||
      crop ||
      fit
    ) {
      if (!processedSrc.searchParams.get('image-optimizer')) {
        processedSrc.searchParams.set('image-optimizer', 'force');
      }

      if (!processedSrc.searchParams.get('format')) {
        processedSrc.searchParams.set('format', 'webply');
      }
    }

    const srcUrl = new URL(processedSrc);
    if (isDefined(width)) {
      srcUrl.searchParams.set(
        'width',
        Math.round(width * this.dpr1ScalingFactor).toString(),
      );
    }

    if (isDefined(this.height) && this.resizeFit) {
      srcUrl.searchParams.set(
        'height',
        Math.round(this.height * this.dpr1ScalingFactor).toString(),
      );
    }

    return {
      src: srcUrl.toString(),
      srcset: this.appendResizerParameters(processedSrc),
    };
  }

  appendPassthroughParameters(
    imageUrl: URL,
    params: ImageQualityQueryParameters,
  ): URL {
    const processedURL = new URL(imageUrl);
    Object.entries(params).forEach(([fastlyQueryParam, value]) =>
      processedURL.searchParams.set(fastlyQueryParam, value),
    );
    return processedURL;
  }

  appendCropParameters(imageUrl: URL, crop: CropDetails): URL {
    const { size, position } = crop;
    const params: string[] = [`${size.width},${size.height}`];
    if (position) {
      params.push(`x${position.x},y${position.y}`);
    }

    const processedUrl = new URL(imageUrl);
    processedUrl.searchParams.set('crop', params.join(','));
    return processedUrl;
  }

  appendResizerParameters(url: URL): string {
    const processedURL = new URL(url);

    // FIXME: T257447 - Remove once width is enforced.
    if (!this.width) {
      return processedURL.toString();
    }

    const srcset =
      this.layout === PictureLayout.RESPONSIVE ||
      this.layout === PictureLayout.FILL
        ? this.generateSrcSetWithDeviceSizes(
            processedURL.toString(),
            this.sizes,
          )
        : this.generateSrcSetWith2x(processedURL.toString(), this.width);

    return srcset;
  }

  handleImageLoad(): void {
    this.imageLoaded.emit(this.nativeImage);
  }

  // Used for the following layout types (acc to Next.js):
  //  - Responsive
  //  - Fill
  generateSrcSetWithDeviceSizes(url: string, sizes?: string): string {
    const widths = sizes ? imageSizes : deviceSizes;
    const urls: string[] = widths.map(width => {
      const processedUrl = new URL(url);
      processedUrl.searchParams.set('width', width.toString());
      return `${processedUrl.toString()} ${width.toString()}w`;
    });

    return urls.join(', ');
  }

  // Used for the following layout types (acc to Next.js):
  //  - Intristic (default)
  //  - Fixed
  generateSrcSetWith2x(url: string, width?: number): string {
    if (!width) {
      return url;
    }

    const urlForSize1x = new URL(url);
    urlForSize1x.searchParams.set(
      'width',
      Math.round(width * this.dpr1ScalingFactor).toString(),
    );
    const urlForSize2x = new URL(url);
    urlForSize2x.searchParams.set('width', (width * 2).toString());

    if (this.resizeFit && this.height) {
      urlForSize1x.searchParams.set(
        'height',
        Math.round(this.height * this.dpr1ScalingFactor).toString(),
      );
      urlForSize2x.searchParams.set('height', (this.height * 2).toString());
    }

    return `${urlForSize1x.toString()} 1x, ${urlForSize2x.toString()} 2x`;
  }

  handleImageError(event: Event): void {
    // For lazy loaded images, the src is set to undefined
    // to prevent them from being downloaded. However,
    // this triggers an error in safari.
    const img = event.target as HTMLElement;
    if (img.getAttribute('src') === '') {
      return;
    }

    if (this.fallbackSrc) {
      this.imageSrcset = this.assets.getUrl(this.fallbackSrc);
    }

    this.onError.emit();
  }

  private getSrc(src: string): string {
    // Check if the src is http(s), data:image, /api or protocol relative.
    if (
      !src ||
      src.startsWith('http://') ||
      src.startsWith('https://') ||
      src.startsWith('data:image/') ||
      src.startsWith('/api') ||
      src.startsWith('//')
    ) {
      return src;
    }

    return this.assets.getUrl(src);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.unobserve(this.nativeImage.nativeElement);
    }
  }
}

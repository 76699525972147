import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  SECURE_ANDROID_STORAGE_PLUGIN_NAME,
  SecureAndroidStorage,
} from './secure-android-storage.plugin';
import type {
  GetResult,
  SetOptions,
  RemoveOptions,
  GetOptions,
} from './secure-android-storage.plugin';

@Injectable({
  providedIn: 'root',
})
export class SecureAndroidStorageService {
  get(options: GetOptions): Promise<GetResult> {
    if (!Capacitor.isPluginAvailable(SECURE_ANDROID_STORAGE_PLUGIN_NAME)) {
      throw new Error(
        `SecureAndroidStorage plugin is not available on ${Capacitor.getPlatform()}`,
      );
    }
    return SecureAndroidStorage.get(options);
  }

  async set(options: SetOptions): Promise<void> {
    if (!Capacitor.isPluginAvailable(SECURE_ANDROID_STORAGE_PLUGIN_NAME)) {
      throw new Error(
        `SecureAndroidStorage plugin is not available on ${Capacitor.getPlatform()}`,
      );
    }
    return SecureAndroidStorage.set(options);
  }

  remove(options: RemoveOptions): Promise<void> {
    if (!Capacitor.isPluginAvailable(SECURE_ANDROID_STORAGE_PLUGIN_NAME)) {
      throw new Error(
        `SecureAndroidStorage plugin is not available on this ${Capacitor.getPlatform()}`,
      );
    }
    return SecureAndroidStorage.remove(options);
  }

  async pluginVersion(): Promise<string> {
    if (!Capacitor.isPluginAvailable(SECURE_ANDROID_STORAGE_PLUGIN_NAME)) {
      throw new Error(
        `SecureAndroidStorage plugin is not available on this ${Capacitor.getPlatform()}`,
      );
    }
    const result = await SecureAndroidStorage.pluginVersion();
    return result.version;
  }
}

import { InjectionToken } from '@angular/core';
import type { Router } from '@angular/router';
import type { AuthServiceInterface } from '@freelancer/auth/interface';
import type { DatastoreInterface } from '@freelancer/datastore/core';
import type { DatastoreTestingController } from './datastore-testing-controller';

export type DatastoreInitializer = (
  auth: AuthServiceInterface,
  datastore: DatastoreInterface,
  datastoreController: DatastoreTestingController,
  router: Router,
) => Promise<void>;

export interface DatastoreFakeConfig {
  readonly debug: boolean;
  readonly initializer?: DatastoreInitializer;
}

export const DATASTORE_FAKE_CONFIG = new InjectionToken<DatastoreFakeConfig>(
  'DatastoreFake Configuration',
);

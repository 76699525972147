import type { TimeUnitApi } from 'api-typings/common/common';
import type { SubscriptionStatusApi } from 'api-typings/memberships/memberships_types';
import type { MembershipTrial } from '../membership-trials/membership-trials.model';
import type {
  MembershipPackagePrice,
  Package,
} from '../recommended-membership/recommended-membership.model';

export interface Duration {
  readonly id: number;
  readonly type?: TimeUnitApi;
  readonly cycle?: number;
}

export interface MembershipPeriod {
  readonly id: number;
  readonly packageId: number;
  readonly userId: number;
  readonly timeStarted: number;
  readonly timeEnded?: number;
}

export enum MembershipCategory {
  LEGACY = 'legacy',
  CORPORATE = 'corporate',
  TEAM = 'teams',
  LOADSHIFT = 'loadshift',
}

/**
 * The membership subscription history of the currently logged in user.
 * This collection is mainly used in the Membership Page and requests
 * the API for the ff. projections to be used in the page's logic:
 *   - Price details
 *   - Period details
 *
 * If you need to fetch the user's history log and don't need the
 * above-stated projections, please consider creating a new collection.
 */
export interface MembershipSubscriptionHistory {
  readonly id: number;
  readonly packageId: number;
  readonly timeStarted: number;
  readonly timeEndedActual?: number;
  readonly timeEndedExpected: number;
  readonly priceId: number;
  readonly periodId: number;
  readonly status: SubscriptionStatusApi;
  readonly userId: number;
  readonly quantity: number;
  readonly lastPaidTransactionId: number;
  readonly isTrial: boolean;
  readonly duration: Duration;
  readonly autoRenew?: boolean;
  readonly trial?: MembershipTrial;
  readonly trialPrice?: MembershipPackagePrice;
  readonly price: MembershipPackagePrice;
  readonly period: MembershipPeriod;
  readonly package: Package;
}

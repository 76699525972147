import { Injectable, NgZone } from '@angular/core';
import { idle } from '@freelancer/operators';
import { TimeUtils } from '@freelancer/time-utils';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { audit, repeat, switchMap, tap } from 'rxjs/operators';
import { Tracking } from './tracking.service';

@Injectable({ providedIn: 'root' })
export class MemoryTracking {
  constructor(
    private ngZone: NgZone,
    private tracking: Tracking,
    private timeUtils: TimeUtils,
  ) {}

  /**
   * Send events for current memory usage.
   *
   * Events are sent:
   *  - On a heartbeat (on average one measurement per 5 minutes)
   *
   * They are send only when the browser is idle.
   */
  monitor(): Observable<unknown> {
    const initialTime = Date.now();
    return of('').pipe(
      /** wait a random amount of time */
      switchMap(_ => this.timeUtils.rxTimer(this.measurementInterval())),
      /** repeat running this forever to create a heartbeat-like source */
      repeat(),
      /** Wait for browser to be idle and drop any extra events if it's not */
      audit(() => idle(this.ngZone)),
      tap(event => {
        if (!window.performance?.memory) {
          return;
        }

        this.tracking.trackMemoryProfiling({
          reason: 'heartbeat',
          timeSinceWebappLoaded: Date.now() - initialTime,
          jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
          totalJSHeapSize: window.performance.memory.totalJSHeapSize,
          usedJSHeapSize: window.performance.memory.usedJSHeapSize,
        });
      }),
    );
  }

  /**
   * Wait a random amount of time using a Poisson distribution with
   * a mean of 5 minutes (so on average we sample every 5 minutes).
   *
   * This uses a Poisson process to ensure a unbiased sample as documented
   * https://web.dev/monitor-total-page-memory-usage/#example
   */
  private measurementInterval(): number {
    const MEAN_INTERVAL_IN_MS = 5 * 60 * 1000; // 5 minutes
    return -Math.log(Math.random()) * MEAN_INTERVAL_IN_MS;
  }
}

import { isDefined } from '@freelancer/utils';
import type {
  CorporateDetailsApi,
  SupportStatusApi,
  UserApi,
  UserOnlineOfflineStatusApi,
  UserStatusApi,
} from 'api-typings/users/users';
import { RisingStarUserStatusApi } from 'api-typings/users/users';
import { transformCustomFieldValues } from '../custom-field-info-configurations/custom-field-info-configurations.transformers';
import { Enterprise } from '../enterprise/enterprise.model';
import type { CorporateDetails } from '../profile-view-users/profile-view-users.model';
import type { UserStatus } from '../project-view-users/user-status.model';
import type {
  BaseUser,
  OnlineOfflineStatus,
  SupportStatus,
  User,
} from './users.model';

interface UseAvatarCdnTrue {
  readonly useAvatarCdn: true;
}

interface UserAvatarCdnFalse {
  readonly useAvatarCdn: false;
  readonly baseUrl?: string;
}

type UserAvatarCdn = UseAvatarCdnTrue | UserAvatarCdnFalse;

export function transformBaseUser(
  user: UserApi,
  config: UserAvatarCdn = {
    useAvatarCdn: true,
  },
): BaseUser {
  if (config.useAvatarCdn && (!user.avatar_cdn || !user.avatar_large_cdn)) {
    throw new ReferenceError(`Missing a required user avatar field.`);
  } else if (!user.avatar || !user.avatar_large) {
    throw new ReferenceError(`Missing a required user avatar field.`);
  }

  if (!user.id || !user.username || !user.role) {
    throw new ReferenceError(`Missing a required user field.`);
  }

  const displayName: string =
    user.support_status && user.support_status.short_name
      ? user.support_status.short_name
      : user.public_name || user.display_name || user.username;

  const avatars = {
    avatar: config.useAvatarCdn ? user.avatar_cdn : user.avatar,
    avatarLarge: config.useAvatarCdn
      ? user.avatar_large_cdn
      : user.avatar_large,
    avatarXLarge: config.useAvatarCdn
      ? user.avatar_xlarge_cdn
      : user.avatar_xlarge,
  };

  const baseUrl = config.useAvatarCdn ? undefined : config.baseUrl;

  return {
    id: user.id,
    avatar: transformUserImage(avatars.avatar, baseUrl),
    avatarLarge: transformUserImage(avatars.avatarLarge, baseUrl),
    avatarXLarge: transformUserImage(avatars.avatarXLarge, baseUrl),
    username: user.username,
    closed: user.closed || false,
    displayName,
    role: user.role,
    chosenRole: user.chosen_role,
    profileUrl: `/u/${user.username}`,
    currency: user.primary_currency,
    enterpriseIds: user.enterprise_ids,
    enterpriseInternalNames: user.enterprises,
    isDeloitteDcUser: user.enterprise_ids?.includes(Enterprise.DELOITTE_DC),
    isHpUser: !!user.enterprise_ids?.includes(Enterprise.HP),
    poolIds: user.pool_ids,
    escrowComInteractionRequired: !!user.escrowcom_interaction_required,
    hasLinkedEscrowComAccount: !!user.escrowcom_account_linked,
    customFieldValues: user.enterprise_metadata_values
      ? user.enterprise_metadata_values.map(value =>
          transformCustomFieldValues(value),
        )
      : [],
    corporate: transformCorporateDetailsApi(user.corporate),
  };
}

export function transformUser(user: UserApi, config: UserAvatarCdn): User {
  if (!user.status || !isDefined(user.limited_account)) {
    throw new ReferenceError(`Missing a required user field.`);
  }

  return {
    ...transformBaseUser(user, config),
    onlineOfflineStatus: user.online_offline_status
      ? transformUserOnlineOfflineStatus(user.online_offline_status)
      : undefined,
    status: transformUserStatus(user.status),
    supportStatus: user.support_status
      ? transformUserSupportStatus(user.support_status)
      : undefined,
    isLimitedAccount: user.limited_account,
    preferredFreelancer: transformPreferredFreelancerStatus(
      user.preferred_freelancer,
    ),
    isRisingStar: transformRisingStarStatus(user.rising_star_status),
    isShareholder: !!user.is_shareholder,
    isStaff: !!user.is_staff,
  };
}

export function transformUserSupportStatus(
  supportStatus: SupportStatusApi,
): SupportStatus {
  return {
    type: supportStatus.type,
    shortName: supportStatus.short_name,
  };
}

export function transformUserOnlineOfflineStatus(
  onlineOfflineStatus: UserOnlineOfflineStatusApi,
): OnlineOfflineStatus {
  return {
    status: onlineOfflineStatus.status,
    lastUpdatedTimestamp: onlineOfflineStatus.last_updated_timestamp
      ? onlineOfflineStatus.last_updated_timestamp * 1000
      : undefined,
  };
}

export function transformUserStatus(status: UserStatusApi): UserStatus {
  if (status.payment_verified === undefined) {
    throw new Error('Missing payment_verified from user status');
  }
  if (status.email_verified === undefined) {
    throw new Error('Missing email_verified from user status');
  }
  if (status.deposit_made === undefined) {
    throw new Error('Missing deposit_made from user status');
  }
  if (status.profile_complete === undefined) {
    throw new Error('Missing profile_complete from user status');
  }
  if (status.phone_verified === undefined) {
    throw new Error('Missing phone_verified from user status');
  }
  if (status.identity_verified === undefined) {
    throw new Error('Missing identity_verified from user status');
  }
  if (status.facebook_connected === undefined) {
    throw new Error('Missing facebook_connected from user status');
  }
  if (status.freelancer_verified_user === undefined) {
    throw new Error('Missing freelancer_verified_user from user status');
  }
  if (status.linkedin_connected === undefined) {
    throw new Error('Missing linkedin_connected from user status');
  }
  if (status.custom_charge_verified === undefined) {
    throw new Error('Missing custom_charge_verified from user status');
  }

  return {
    paymentVerified: status.payment_verified,
    emailVerified: status.email_verified,
    depositMade: status.deposit_made,
    profileComplete: status.profile_complete,
    phoneVerified: status.phone_verified,
    identityVerified: status.identity_verified,
    facebookConnected: status.facebook_connected,
    freelancerVerifiedUser: status.freelancer_verified_user,
    linkedinConnected: status.linkedin_connected,
    customChargeVerified: status.custom_charge_verified,
  };
}

export function transformUserImage(
  image?: string,
  baseUrl?: string,
): string | undefined {
  if (!image || image.endsWith('/img/unknown.png')) {
    return undefined;
  }

  if (image.startsWith('http')) {
    return image.replace(/^\/\//, 'https://');
  }

  if (baseUrl) {
    return `${baseUrl}${image}`.replace(/^\/\//, 'https://');
  }

  return image.replace(/^\/\//, 'https://');
}

export function transformCorporateDetailsApi(
  corporateDetails?: CorporateDetailsApi,
): CorporateDetails | undefined {
  if (!corporateDetails) {
    return undefined;
  }

  if (!corporateDetails.status || !corporateDetails.founder_id) {
    throw new Error(
      'Expecting corporate account status and founder id to be set.',
    );
  }

  return {
    status: corporateDetails.status,
    founderId: corporateDetails.founder_id,
  };
}

export function transformPreferredFreelancerStatus(
  status: boolean | undefined,
): boolean {
  return !!status;
}

export function transformRisingStarStatus(
  status: RisingStarUserStatusApi | undefined,
): boolean {
  return status === RisingStarUserStatusApi.ACCEPTED;
}

import type { Observable } from 'rxjs';
import { merge } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

/**
 * The function uses the merge operator to combine the source$ observable and notifiers$ observable(s).
 *
 *  - First, the source$ observable is piped through the map operator, which applies a
 *    function to each value emitted by the observable, in this case it maps the value
 *    to an array containing the value and a boolean value of false, indicating that
 *    it's not a re-emitted value.
 *
 *  - Second, the notifiers$ are piped through the withLatestFrom operator which
 *    combines each notifier$ observable with the source$ observable, emitting an array of
 *    the latest value from the notifier$ and the source$. Then, it's piped through the map
 *    operator which maps the array to an array containing the latest value from the source$
 *    and a boolean value of true, indicating that it's a re-emitted value.
 *
 * Finally, the merge operator combines the the single source and all notifier observables together,
 * making sure that the values from both the source$ and notifier$ are emitted and the resulting
 * observable emits arrays of values from source$ and a boolean indicating whether
 * it's a re-emitted value or not.
 *
 * @param notifiers$ - Observable(s) that will trigger the re-emission of the last
 *                     emitted value from the source observable
 * @returns - A function that takes in a source observable and returns an observable
 *            that emits the last emitted value from the source observable along with
 *            a boolean value indicating whether it's a re-emitted value or not.
 */
export function reemitWhen<T>(
  ...notifiers$: Observable<unknown>[]
): (source$: Observable<T>) => Observable<readonly [T, boolean]> {
  return (source$: Observable<T>): Observable<readonly [T, boolean]> =>
    merge(
      source$.pipe(map(val => [val, false] as [T, boolean])),
      ...notifiers$.map(notifier$ =>
        notifier$.pipe(
          withLatestFrom(source$),
          map(([, val]) => [val, true] as [T, boolean]),
        ),
      ),
    );
}

import type { Backend } from '@freelancer/datastore/core';
import {
  getQueryParamValue,
  OrderByDirection,
} from '@freelancer/datastore/core';
import type { CampaignsCollection } from './campaigns.types';

export function campaignsBackend(): Backend<CampaignsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => {
      return {
        endpoint: 'users/0.1/campaign',
        isGaf: false,
        method: 'GET',
        params: {
          internal_names: getQueryParamValue(query, 'internalName'),
        },
      };
    },
    push: undefined,
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}

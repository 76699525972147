import { isPlatformServer } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import type { FreelancerHttpABTestOverridesInterface } from '@freelancer/freelancer-http';
import { Location } from '@freelancer/location';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ABTestOverrides implements FreelancerHttpABTestOverridesInterface {
  constructor(
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  getOverridesHeader(): Observable<HttpHeaders> {
    let headers = new HttpHeaders();
    if (isPlatformServer(this.platformId)) {
      return of(headers);
    }
    const overrides = new URL(this.location.href).searchParams.get('overrides');
    if (overrides) {
      headers = headers.set('freelancer-abtest-overrides', overrides);
    }
    return of(headers);
  }
}

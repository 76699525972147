import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-toast-alerts',
  template: `
    <fl-toast-alert-container
      [flSticky]="true"
      [flShowMobile]="true"
    ></fl-toast-alert-container>
    <fl-toast-alert-container
      [flSticky]="true"
      [flHideMobile]="true"
      [flStickyStatic]="true"
    ></fl-toast-alert-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastAlertsComponent {}

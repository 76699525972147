export enum ButtonColor {
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  SUCCESS = 'success',
  DANGER = 'danger',
  TRANSPARENT_DARK = 'transparent-dark',
  TRANSPARENT_LIGHT = 'transparent-light',
  TRANSPARENT_SECONDARY = 'transparent-secondary',
  TRANSPARENT_PRIMARY = 'transparent-primary',
  PRIMARY = 'primary',
  RECRUITER = 'recruiter',
  TRANSPARENT_RECRUITER = 'transparent-recruiter',
  PREFERRED_FREELANCER = 'preferred-freelancer',
  CUSTOM = 'custom',
  TEXT_BLUE = 'text-blue',
  TEXT_PRIMARY = 'text-primary',
  TEXT_DARK = 'text-dark',
  TEXT_LIGHT = 'text-light',
  TEXT_RECRUITER = 'text-recruiter',
  TEXT_RED = 'text-red',
  TEXT_FOREGROUND = 'text-foreground',
}

export enum ButtonGroupPosition {
  FIRST = 'first',
  MIDDLE = 'middle',
  LAST = 'last',
}

export type ButtonDisplay = 'inline' | 'block' | 'flex';

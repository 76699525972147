import { NgModule } from '@angular/core';
import { MODALS } from '@freelancer/ui/modal';
import { appModals } from './app-modal-routes';

@NgModule({
  providers: [
    {
      provide: MODALS,
      useValue: appModals,
      multi: true,
    },
  ],
})
export class AppModalsModule {}

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ApplicationRef,
  ErrorHandler,
  Inject,
  Injectable,
  PLATFORM_ID,
  RendererFactory2,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import type {
  UnrecoverableStateEvent,
  VersionEvent,
} from '@angular/service-worker';
import { SwUpdate } from '@angular/service-worker';
import type { AppInfo } from '@capacitor/app';
import { App } from '@capacitor/app';
// T244825 Remove deprecated import.
// eslint-disable-next-line import/no-deprecated
import type { PluginRegistry } from '@capacitor/core';
import { Capacitor, Plugins } from '@capacitor/core';
import type { DeviceId, DeviceInfo } from '@capacitor/device';
import { Device } from '@capacitor/device';
import { RepetitiveSubscription } from '@freelancer/decorators';
import type { FreelancerLocationPwaInterface } from '@freelancer/location';
import { Location } from '@freelancer/location';
import { TimeUtils } from '@freelancer/time-utils';
import { toNumber } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import compareVersions from 'compare-versions';
import { CookieService } from 'ngx-cookie';
import type { Observable } from 'rxjs';
import {
  EMPTY,
  Subscription,
  combineLatest,
  concat,
  firstValueFrom,
  from,
  of,
} from 'rxjs';
import {
  filter,
  first,
  map,
  retry,
  shareReplay,
  skip,
  switchMap,
} from 'rxjs/operators';
import type {
  AppVersion,
  NativeDeviceInfo,
  NgswDebugInfo,
  Platform,
} from './pwa.model';

/**
 * Custom AppData from the Ngsw config included in the update notifications.
 */
export interface CustomAppData {
  /* Git SHA of the version */
  gitRevision: string;
  /* Build time as a Unix timestamp of the version */
  buildTimestamp: number;
  /* Minimum version build time to force the upgrade to */
  minimumVersionTimestamp: number;
}

export interface NativeAppVersion {
  major: number;
  minor: number;
}

/**
 * Wraps Angular's SwUpdate service & provide additional PWA-related helpers
 */
@UntilDestroy({ className: 'Pwa' })
@Injectable({
  providedIn: 'root',
})
export class Pwa implements FreelancerLocationPwaInterface {
  @RepetitiveSubscription()
  private backgroundUpdateChecksSubscription?: Subscription;
  private isInstalledModeActivated = false;
  private appVersion$: Observable<AppVersion | undefined>;
  private nativeDeviceInfo$: Observable<NativeDeviceInfo>;
  private launchUrl?: string;

  constructor(
    private appRef: ApplicationRef,
    private cookies: CookieService,
    private location: Location,
    private rendererFactory: RendererFactory2,
    private router: Router,
    private timeUtils: TimeUtils,
    private updates: SwUpdate,
    private http: HttpClient,
    private errorHandler: ErrorHandler,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  /**
   * Detects if the PWA is running in installed mode, i.e. launched as a
   * standalone app.
   *
   * Use that to hide "Install app" banners & buttons for instance.
   */
  isInstalled(): boolean {
    return (
      isPlatformBrowser(this.platformId) &&
      (window.navigator.standalone === true ||
        window.matchMedia('(display-mode: standalone)').matches ||
        this.isNative() ||
        this.isInstalledModeActivated)
    );
  }

  /*
   * Detects if the PWA is as a native app (through Capacitor)
   *
   * Use that to guard native only features, i.e. Capacitor plugins access
   */
  isNative(): boolean {
    return Capacitor.isNativePlatform();
  }

  /**
   * Returns the platform the PWA is running as.
   *
   * Use this for specific functionality to comply with requirements on certain app platforms.
   */
  getPlatform(): Platform {
    const platform = Capacitor.getPlatform();
    if (platform === 'web' || platform === 'ios' || platform === 'android') {
      return platform;
    }
    return 'unknown';
  }

  /**
   * Promise of the Capacitor Plugins object allowing the PWA to use native
   * APIs.
   *
   * @deprecated
   */
  // eslint-disable-next-line deprecation/deprecation
  capacitorPlugins(): Promise<PluginRegistry> {
    // eslint-disable-next-line deprecation/deprecation
    return Promise.resolve(Plugins);
  }

  /**
   * Returns the app version info
   */
  appVersion(): Observable<AppVersion | undefined> {
    // This handles non webapp pages where window.webapp is not going to be defined
    if (!window.webapp) {
      return of(undefined);
    }
    const gitRevision = window.webapp.version.gitRevision.substring(0, 7);
    const buildTimestampInSeconds = Math.round(
      window.webapp.version.buildTimestamp / 1000,
    );

    // App.getInfo() is currently not available for the web
    // Ref: https://github.com/ionic-team/capacitor-plugins/pull/71#discussion_r507911090
    if (!this.isNative()) {
      return of({ gitRevision, buildTimestampInSeconds });
    }

    // Setup a hot observable to cache data from Capacitor API.
    if (!this.appVersion$) {
      this.appVersion$ = this.getCapacitorAppInfo().pipe(
        map(appInfo => {
          return {
            gitRevision,
            buildTimestampInSeconds,
            nativeVersion: appInfo.version,
            nativeBuildTimestampInSeconds:
              this.getPlatform() === 'android'
                ? // The Android version code is messed up because people messed it up in
                  // the past (by using a ridiculously high value very close to Google
                  // Play's limit).
                  // We use a convoluted formula to calculate the version code from the
                  // timestamp in webapp/projects/main/native/android/fastlane/Fastfile,
                  // and this logic reverses it into a timestamp.
                  (toNumber(appInfo.build) - 1_901_538_326) * 1000
                : toNumber(appInfo.build),
            nativeBuildNumber: toNumber(appInfo.build),
          };
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    // Serve the same observable to avoid querying the Capacitor API
    // every single time unless we want fresh data by deliberately
    // setting the "refresh" parameter.
    return this.appVersion$;
  }

  /**
   * Return whether the current nativeVersion is greater than or equal to the required version
   */
  async hasMinimumNativeAppVersion(
    version: NativeAppVersion,
    localAppMeetMinimalVersion: boolean = true,
  ): Promise<boolean> {
    if (!this.isNative()) {
      return false;
    }

    // Check if nativeVersion is defined
    const appVersion = await firstValueFrom(
      this.appVersion().pipe(untilDestroyed(this)),
    );

    if (!appVersion || !appVersion.nativeVersion) {
      this.errorHandler.handleError(new Error('nativeVersion is not defined'));
      return false;
    }

    const { nativeVersion: currentVersion } = appVersion;
    const requiredVersion = `${version.major}.${version.minor}`;

    // Return for local and dev builds which uses 0.1.0
    if (currentVersion === '0.1.0') {
      return localAppMeetMinimalVersion;
    }

    return compareVersions.compare(currentVersion, requiredVersion, '>=');
  }

  /**
   * Return whether the current native operating system's major version is greater than or equal to the required version
   * @param majorVersion the major version to be compared. It should be an integer, e.g. 14
   */
  async hasMinimumNativeOsMajorVersion(majorVersion: number): Promise<boolean> {
    if (!Number.isInteger(majorVersion)) {
      throw new Error('majorVersion should be an integer');
    }

    if (!this.isNative()) {
      return false;
    }

    const nativeDeviceInfo = await firstValueFrom(
      this.nativeDeviceInfo().pipe(untilDestroyed(this)),
    );

    return parseInt(nativeDeviceInfo?.osVersion ?? '0', 10) >= majorVersion;
  }

  /**
   * Returns the native device info, or undefined on non-native
   */
  nativeDeviceInfo(): Observable<NativeDeviceInfo | undefined> {
    if (!this.isNative()) {
      return of(undefined);
    }

    if (!this.nativeDeviceInfo$) {
      this.nativeDeviceInfo$ = combineLatest([
        // Need to get stream from BOTH DeviceInfo AND DeviceId as Capacitor 3+
        // no longer provides UUID as part of DeviceInfo.
        this.getCapacitorDeviceInfo(),
        this.getCapacitorDeviceId(),
      ]).pipe(
        map(([info, deviceId]) => {
          // A hack to keep compatibility with capacitor 4 and below which has property `uuid`
          const identifier =
            deviceId.identifier ?? ((deviceId as any).uuid as string);
          return {
            name: info.name,
            model: info.model,
            identifier,
            osVersion: info.osVersion,
            manufacturer: info.manufacturer,
            isVirtual: info.isVirtual,
            memUsed: info.memUsed,
          };
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }

    return this.nativeDeviceInfo$;
  }

  /**
   * @private
   * Activate the PWA installed mode, i.e. tweak a bit the global styles &
   * behaviors
   *
   * This is turned on when the PWA is running in installed mode, or when it is
   * loaded from the `/internal/pwa` route for easier testing.
   */
  activateInstalledMode(): void {
    if (!this.isInstalledModeActivated) {
      // Disable text selection & browser callouts as it's not "app-like"
      const renderer = this.rendererFactory.createRenderer(null, null);
      renderer.setStyle(this.document.body, '-webkit-user-select', 'none');

      if (isPlatformBrowser(this.platformId)) {
        // Turn off the m. redirections
        this.cookies.put('mobile_optout', 'true');
      }

      this.isInstalledModeActivated = true;
    }
  }

  /**
   * @private
   * Use these events to know about various things happening in the SW.
   */
  updateEvents(): Observable<VersionEvent> {
    return this.updates.versionUpdates;
  }

  /**
   * @private
   * Use these events to know if the app is completely cooked.
   */
  unrecoverableEvents(): Observable<UnrecoverableStateEvent> {
    return this.updates.unrecoverable;
  }

  /**
   * @private
   * Check for available updates
   */
  checkForUpdate(): Promise<boolean | void> {
    if (this.updates.isEnabled) {
      return this.updates.checkForUpdate();
    }
    return Promise.resolve();
  }

  /**
   * @private
   * Update to the latest app version immediately,
   * by reloading the page and bypassing the service worker.
   */
  forceActivateUpdate(): Promise<boolean> {
    // location.redirect automatically bypasses the service worker implicitly
    return this.location.redirect(this.location.href);
  }

  /**
   * @private
   * Update to the latest app version on the next navigation,
   * by modifying the navigation to a full-page load.
   */
  activateUpdateOnNavigation(): Promise<void> {
    return firstValueFrom(
      this.router.events.pipe(
        filter((e): e is NavigationStart => e instanceof NavigationStart),
        untilDestroyed(this),
      ),
    ).then(e => {
      // note: this will add ngsw-bypass, which is still fine
      // the service worker will still be updated for later loads
      this.location.redirect(e.url);
    });
  }

  /**
   * @private
   * Ask the service worker to check if any updates have been deployed to the
   * server every hour
   */
  startBackgroundUpdateChecks(): void {
    if (this.updates.isEnabled) {
      const appIsStable$ = this.appRef.isStable.pipe(
        first(isStable => isStable),
      );
      const everyOneHour$ = this.timeUtils.rxInterval(1 * 60 * 60 * 1000);
      const everyOneHourOnceAppIsStable$ = concat(
        appIsStable$,
        everyOneHour$,
      ).pipe(skip(1));
      this.backgroundUpdateChecksSubscription =
        everyOneHourOnceAppIsStable$.subscribe(() => {
          this.updates.checkForUpdate();
        });
    }
  }

  /**
   * @private
   * Cancel the background update checks
   */
  stopBackgroundUpdateChecks(): void {
    if (this.backgroundUpdateChecksSubscription) {
      this.backgroundUpdateChecksSubscription.unsubscribe();
    }
  }

  /**
   * Forward debug information from `/ngsw/state`
   * which is an Angular service worker specific endpoint.
   *
   * FIXME: T236180 Hopefully the Angular service worker returns the debug info
   *        in JSON format in the future so that we don't have to parse formatted plain text.
   */
  getNgswDebugInfo(): Observable<NgswDebugInfo> {
    // Parse the string with the given regex and
    // return a JSON named capturing groups.
    const ngswStateParser = (rawString: string): NgswDebugInfo | undefined => {
      const regex = new RegExp(
        [
          // To allow capturing of an error message that spans across multiple
          // lines, [^] is used.
          'Driver state: (?<driverState>[^]+)\n',
          'Latest manifest hash: (?<latestManifestHash>[^\n]+)\n',
          'Last update check: (?<lastUpdateCheck>[^\n]+)\n',
          '(.|\n)*',
          'Debug log:\n(?<debugLog>(.|\n)+)',
        ].join(''),
      );
      const groups = rawString.match(regex)?.groups ?? {};
      if (
        'driverState' in groups &&
        'latestManifestHash' in groups &&
        'lastUpdateCheck' in groups
      ) {
        return {
          driverState: groups.driverState,
          lastUpdateCheck: groups.lastUpdateCheck,
          latestManifestHash: groups.latestManifestHash,
        } as NgswDebugInfo;
      }
    };

    if (this.updates.isEnabled) {
      return this.http
        .get('/ngsw/state', {
          responseType: 'text',
        })
        .pipe(
          // Retry to avoid cases where the service worker crashed and has
          // restarted but not fully initialised yet.
          retry({
            count: 2,
            delay: 1000,
          }),
          switchMap(state => {
            const ngswState = ngswStateParser(state);
            if (ngswState) {
              return of({
                ...ngswState,
              });
            }
            return EMPTY;
          }),
        );
    }
    return EMPTY;
  }

  /**
   * Returns the app version info
   */
  getCapacitorAppInfo(): Observable<AppInfo> {
    return from(App.getInfo());
  }

  /**
   * This function gets the launch URL set by either notifications or universal
   * link.
   *
   * This should help with UX by going directly to the correct page, rather
   * than the user seeing the dashboard/login screen for a split second before
   * going to the desired page.
   *
   * @returns launch URL.
   */
  async getLaunchUrl(): Promise<string | undefined> {
    return this.launchUrl;
  }

  setLaunchUrl(launchUrl: string): void {
    this.launchUrl = launchUrl;
  }

  /**
   * Obtain the device info from the Capacitor API.
   * Cached data is served unless "refresh" is set.
   *
   * @returns An observable of Capacitor device ID.
   */
  private getCapacitorDeviceId(): Observable<DeviceId> {
    return from(Device.getId());
  }

  /**
   * Obtain the device info from the Capacitor API.
   * Cached data is served unless "refresh" is set.
   *
   * @returns An observable of Capacitor device info.
   */
  private getCapacitorDeviceInfo(): Observable<DeviceInfo> {
    return from(Device.getInfo());
  }
}

import type { AfterViewInit, OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { Tracking } from './tracking.service';

@Component({
  selector: 'fl-translation-usage-tracking',
  template: `
    <div
      id="translation-tracking"
      class="TranslationText"
    >
      {{ TRACKED_TRANSLATION_TEXT }}
    </div>
  `,
  styleUrls: ['./translation-usage-tracking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationUsageTrackingComponent
  implements AfterViewInit, OnDestroy
{
  private mutationObserver: MutationObserver;
  TRACKED_TRANSLATION_TEXT =
    'Translation trigger for this page to tell when this text has been translated by a third party';

  constructor(private ngZone: NgZone, private tracking: Tracking) {}

  ngAfterViewInit(): void {
    this.mutationObserver = new MutationObserver(
      (mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => {
          this.ngZone.run(() => {
            // If the hidden translation trigger text is translated, then send a custom track event
            const charData = mutation.target as CharacterData;
            const isTranslated =
              charData.nodeValue?.trim() !== this.TRACKED_TRANSLATION_TEXT;
            if (isTranslated) {
              this.tracking.trackCustomEvent(
                'TranslationEnabled',
                'TranslationUsageTracker',
                {
                  translatedText: charData.nodeValue?.trim(),
                },
              );
            } else {
              this.tracking.trackCustomEvent(
                'TranslationDisabled',
                'TranslationUsageTracker',
              );
            }
          });
        });
      },
    );

    // Search for translation element for mutation observer to listen to
    const translationElement = document.getElementById('translation-tracking');
    if (!translationElement) {
      throw new Error(`Missing tracking translation element.`);
    }

    // Start observing for character changes in the HTML element
    const config = { characterData: true, subtree: true };
    this.mutationObserver.observe(translationElement, config);
  }

  ngOnDestroy(): void {
    // Stop observing changes when the service is destroyed
    this.mutationObserver.disconnect();
  }
}

import type {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import type { Observable } from 'rxjs';
import { isObservable, of } from 'rxjs';

/**
 * Returns `undefined`, casted to type `T`, to be used as the initial
 * value of a `FormControl`.
 *
 * Use this only when the `required` validator is specified.
 *
 * @example
 * control = new FormControl(EmptyValue<number>(), { nonNullable: true });
 */
export function EmptyValue<T>(): T {
  return undefined as unknown as T;
}

export function isDefined<T>(x: T): x is NonNullable<T> {
  return x !== undefined && x !== null;
}

export function isFieldDefined<
  T extends object,
  K extends keyof T & PropertyKey,
>(key: K): (x: T) => x is T & { readonly [k in K]: NonNullable<T[K]> } {
  return (x: T): x is T & { readonly [k in K]: NonNullable<T[K]> } =>
    key in x && x[key] !== undefined && x[key] !== null;
}

export function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(`AssertionError: ${msg}`);
  }
}

export function assertNever(
  x: never,
  error: string = 'Unexpected object',
): never {
  throw new Error(`${error}: ${JSON.stringify(x)}`);
}

/**
 * Converts a value into a number, provided it is a number or string.
 * Otherwise, convert it to undefined.
 *
 * For general use, but especially for transformer functions where the backend
 * type for "empty/falsey" values should be universally turned into `undefined`
 * to be consumed by the application.
 */
export function toNumber(value: string | number): number;
export function toNumber(
  value: string | number | null | undefined | false,
): number | undefined;
export function toNumber(
  value: string | number | null | undefined | false,
): number | undefined {
  // check type OR truthiness to avoid 0 issues
  return typeof value === 'number' || value ? Number(value) : undefined;
}

/**
 * Rounds numbers to specified decimal places.
 */
export function round(value: number, places: number): number {
  return Number(`${Math.round(toNumber(`${value}e+${places}`))}e-${places}`);
}

/** A version of JSON.stringify that sorts object keys
 * (and optionally array entries) for a stable sort.
 * Useful for using as an object's hash.
 *
 * Based on https://github.com/substack/json-stable-stringify
 */
export function jsonStableStringify(
  node: any,
  sortArrays = false,
): string | undefined {
  if (node && node.toJSON && typeof node.toJSON === 'function') {
    // eslint-disable-next-line no-param-reassign
    node = node.toJSON();
  }

  if (node === undefined) {
    return;
  }

  if (typeof node !== 'object' || node === null) {
    return JSON.stringify(node);
  }

  if (Array.isArray(node)) {
    return `[${(sortArrays ? [...node].sort() : node)
      .map(
        value => jsonStableStringify(value, sortArrays) || JSON.stringify(null),
      )
      .join(',')}]`;
  }

  return `{${Object.keys(node)
    .sort()
    .map(key => {
      const value = jsonStableStringify(node[key], sortArrays);
      return value ? `${JSON.stringify(key)}:${value}` : undefined;
    })
    .filter(isDefined)
    .join(',')}}`;
}

export function objectFilter<V>(
  object: { readonly [key: string]: V },
  predicate: (key: string, v: V) => boolean,
): { readonly [key: string]: V } {
  return Object.entries(object)
    .filter(([key, value]) => predicate(key, value))
    .reduce((obj: { [key: string]: V }, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
}

export function toObservable<T>(x$: T | Observable<T>): Observable<T> {
  return isObservable(x$) ? x$ : of(x$);
}

/**
 * A wrapper around Array.isArray with better types.
 *
 * https://github.com/microsoft/TypeScript/issues/17002
 */

export function isArray(arg: any): arg is readonly any[] {
  return Array.isArray(arg);
}

/**
 * Checks that a value is not an empty string
 */
export function isNotEmptyString<T>(value: '' | T): value is T {
  return value !== '';
}

/**
 * Splits an array into two groups, the first containing all elements which
 * were truthy when evaluated for the given projection function, and the second
 * containing all other elements.
 *
 * Example: Partitioning a list into odd and even numbers
 *
 * partition([1, 2, 3, 4], val => val % 2 !== 0) => [[1, 3], [2, 4]]
 *
 * @param collection An array.
 * @param projection A function to be called for each item in the collection.
 * @returns A two-item array, the first containing a list of truthy values, and
 *          the second containing a list of falsey values.
 */
export function partition<T>(
  collection: readonly T[],
  projection: (value: T) => boolean,
): [readonly T[], readonly T[]] {
  const matching: T[] = [];
  const nonMatching: T[] = [];

  collection.forEach(item => {
    if (projection(item)) {
      matching.push(item);
    } else {
      nonMatching.push(item);
    }
  });

  return [matching, nonMatching];
}

/**
 * Checks if a value can be cast to a number (not that it is of type number).
 *
 * Has a lot of subtleties on how it works and is copied from
 * https://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
 */
export function isNumeric(n: any): boolean {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(Number(n));
}

export interface EqualityComparisonOptions {
  readonly ignoreUndefinedObjectProperties?: boolean;
}

export function isPartialMatch<T>(
  target: T,
  partialTarget: Partial<T>,
): boolean {
  // If any doesn't match partial then it doesn't match
  for (const [key, value] of Object.entries(partialTarget)) {
    // Found one key in the partial that matches the target
    if ((target as { [k: string]: any })[key] !== value) {
      return false;
    }
  }
  return true;
}

/** Performs a deep comparison between two values to determine if they are equivalent.
 *
 * Signature same as lodash
 * @param value The value to compare.
 * @param other The other value to compare.
 * @returns Returns `true` if the values are equivalent, else `false`.
 */
export function isEqual(
  a: any,
  b: any,
  comparisonOptions?: EqualityComparisonOptions,
): boolean {
  if (a === b) {
    return true;
  }

  if (Array.isArray(a)) {
    return (
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((value, i) => isEqual(value, b[i], comparisonOptions))
    );
  }

  if (a instanceof Date) {
    return b instanceof Date && a.getTime() === b.getTime();
  }

  if (a instanceof RegExp) {
    return b instanceof RegExp && a.toString() === b.toString();
  }

  if (a instanceof Object && b instanceof Object) {
    const keysA = Object.keys(a).filter(key =>
      comparisonOptions?.ignoreUndefinedObjectProperties
        ? a[key] !== undefined
        : true,
    );
    const keysB = Object.keys(b).filter(key =>
      comparisonOptions?.ignoreUndefinedObjectProperties
        ? b[key] !== undefined
        : true,
    );

    return (
      keysA.length === keysB.length &&
      keysA.every(
        key =>
          keysB.includes(key) && isEqual(a[key], b[key], comparisonOptions),
      )
    );
  }

  return false;
}

export function sameElements<T>(as: readonly T[], bs: readonly T[]): boolean {
  const setAs = new Set(as);
  const setBs = new Set(bs);
  return as.every(a => setBs.has(a)) && bs.every(b => setAs.has(b));
}

/**
 * An implementation of the Haversine formula used to calculate "great circle"
 * distance.
 *
 * Example:
 *
 * Sydney Airport to London Heathrow Airport
 * `haversineDistance(51.469916, -0.454353, -33.939887, 151.175276) / 1000`
 * => ~17_019 km
 *
 * @see https://en.wikipedia.org/wiki/Haversine_formula
 *
 * @returns {number} Approximate distance in metres between two spatial points on Earth.
 */
export function haversineDistance(
  {
    latitude: latDeg1,
    longitude: lonDeg1,
  }: {
    readonly latitude: number;
    readonly longitude: number;
  },
  {
    latitude: latDeg2,
    longitude: lonDeg2,
  }: {
    readonly latitude: number;
    readonly longitude: number;
  },
): number {
  const toRadians = (degree: number): number => (degree / 180.0) * Math.PI;

  const lat1 = toRadians(latDeg1);
  const lon1 = toRadians(lonDeg1);
  const lat2 = toRadians(latDeg2);
  const lon2 = toRadians(lonDeg2);

  // Radius of earth in meters
  const earthRadius = 6_371_000;

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  return earthRadius * c;
}

/**
 * Creates an object composed of keys generated from the results of running
 * each element of `collection` thru `iteratee`. The corresponding value of
 * each key is the last element responsible for generating the key. The
 * iteratee is invoked with one argument: (value).
 *
 * @param collection The collection to iterate over.
 * @param iteratee The iteratee to transform keys.
 * @returns Returns the composed aggregate object.
 * @example
 *
 * const array = [
 *   { 'dir': 'left', 'code': 97 },
 *   { 'dir': 'right', 'code': 100 }
 * ]
 *
 * keyBy(array, ({ code }) => String.fromCharCode(code))
 * // => { 'a': { 'dir': 'left', 'code': 97 }, 'd': { 'dir': 'right', 'code': 100 } }
 */
export function keyBy<T>(
  collection: readonly T[],
  iteratee: (value: T) => PropertyKey,
): { readonly [x: string]: T } {
  return Object.fromEntries(collection.map(item => [iteratee(item), item]));
}

/* Maps the enum object to a list of its keys */
export function getEnumKeys<T extends Object>(
  enumObject: T,
): readonly (keyof T)[] {
  return Object.keys(enumObject)
    .filter(key => !isNumeric(key))
    .map(key => key as keyof T);
}

/* Maps an enum object to a list of its values */
export function enumToArray<T extends Object>(enumObject: T): T[keyof T][] {
  return getEnumKeys(enumObject).map(key => enumObject[key]);
}

/* Return enum key as string given enum value */
export function getEnumKeyByEnumValue<T extends Object>(
  enumObject: T,
  enumValue: T[keyof T],
): keyof T | undefined {
  const keys: readonly (keyof T)[] = getEnumKeys(enumObject).filter(
    key => enumObject[key] === enumValue,
  );
  if (keys.length === 0) {
    throw new Error(
      `There is no value '${enumValue}' in the enum ${typeof enumObject}.`,
    );
  }
  return keys[0];
}

/**
 * This is used to cast + typeguard from AbstractControl to FormGroup
 * Issue with form controls not being strong typed since 2016
 * FIXME: https://github.com/angular/angular/issues/13721
 */
export function isFormGroup(
  // FIXME: T241170 - fix me in D189832
  // eslint-disable-next-line local-rules/no-untyped-form
  item: AbstractControl | FormGroup | FormControl | FormArray,
  // FIXME: T241170 - Remove this function
  // eslint-disable-next-line local-rules/no-untyped-form
): item is FormGroup {
  // FIXME: T241170 - fix me in D189832
  // eslint-disable-next-line local-rules/no-untyped-form
  const castedItem = item as FormGroup | FormControl | FormArray;
  return (
    'controls' in castedItem &&
    castedItem.controls !== null &&
    typeof castedItem.controls === 'object'
  );
}

/**
 * This is used to cast + typeguard from AbstractControl to FormControl
 * Issue with form controls not being strong typed since 2016
 * FIXME: https://github.com/angular/angular/issues/13721
 */
export function isFormControl(
  // FIXME: T241170 - fix me in D189832
  // eslint-disable-next-line local-rules/no-untyped-form
  item: AbstractControl | FormGroup | FormControl | FormArray,
  // FIXME: T241170 - Remove this function
  // eslint-disable-next-line local-rules/no-untyped-form
): item is FormControl {
  // FIXME: T241170 - fix me in D189832
  // eslint-disable-next-line local-rules/no-untyped-form
  const castedItem = item as FormGroup | FormControl | FormArray;
  return !('controls' in castedItem);
}

/**
 * This is used to cast + typeguard from AbstractControl to FormArray
 * Issue with form controls not being strong typed since 2016
 * FIXME: https://github.com/angular/angular/issues/13721
 */

export function isFormArray(
  // FIXME: T241170 - fix me in D189832
  // eslint-disable-next-line local-rules/no-untyped-form
  item: AbstractControl | FormGroup | FormControl | FormArray,
  // FIXME: T241170 - Remove this function
  // eslint-disable-next-line local-rules/no-untyped-form
): item is FormArray {
  // FIXME: T241170 - fix me in D189832
  // eslint-disable-next-line local-rules/no-untyped-form
  const castedItem = item as FormGroup | FormControl | FormArray;
  return (
    'controls' in castedItem &&
    castedItem.controls !== null &&
    Array.isArray(castedItem.controls)
  );
}

/**
 * TODO: T267853 - This needs to be made private. The image cropper should allow the
 * developer to pass any image input type and the component handles the conversion.
 *
 * Takes an image URL and returns the base64 encoded string of the image.
 * Base64 is the required format for passing images into the image cropper.
 *
 * Taken from: https://stackoverflow.com/a/16566198.
 *
 * @param url Image URL to convert to base64 encoding.
 */
export function convertUrlToBase64(url: string): Promise<string | undefined> {
  return new Promise(resolve => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        resolve(undefined);
        return;
      }

      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL();

      canvas.remove();

      resolve(dataURL);
    };

    img.src = url;
  });
}

/**
 * Takes a object and returns a base64 encoded string of the object
 * If the object is undefined, null is returned
 */
export function convertObjectToBase64String<T>(
  objectToBeEncoded: T,
): string | null {
  if (!isDefined(objectToBeEncoded)) {
    return null;
  }

  return btoa(JSON.stringify(objectToBeEncoded));
}

/**
 * Takes a base64 encoded string and returns an object if the decoded string is a valid object.
 * If the the base64 encoded string is not a valid object after it is decoded, null is returned
 */
export function convertBase64StringToObject<T>(
  base64StringToBeDecoded: string,
): T | null {
  try {
    return JSON.parse(atob(base64StringToBeDecoded));
  } catch (error) {
    return null;
  }
}

export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

/**
 * Maps a type to another type where each of its property
 * is wrapped with a FormControl.
 */
export type FormGroupTypeOf<T> = {
  [K in keyof T]: FormControl<T[K]>;
};

/**
 * Maps a type to another type where each of its property
 * is wrapped with a FormControl.
 */
export type FormGroupNonNullableTypeOf<T> = {
  // The NonNullable handles optional properties
  [K in keyof T]: FormControl<NonNullable<T[K]>>;
};

/**
 * Check if a type is `any`
 *
 * Taken from: https://stackoverflow.com/a/49928360
 */
export type IsAny<T> = 0 extends 1 & T ? true : false;

/**
 * Type guard to check if an object has a given property key
 *
 * See: https://stackoverflow.com/questions/64616994
 */
export function hasProperty<T extends object>(
  obj: T,
  key: PropertyKey,
): key is keyof T {
  return key in obj;
}

/**
 * * Returns an array of values from an object that pass a given filter function.
 *
 * @param object - An object with string keys and values of generic type `V`.
 * @param filter - A function that takes in a value of type `V` and returns a boolean to indicate whether the value should be included in the output.
 * @param keys - An optional array of string keys to filter on. If not provided, all keys in the object will be used.
 *
 * @returns An array of values of type `V` that pass the filter function.
 */
export function filterObjectValues<V>(
  object: { [K in string]: V },
  filter: (value: V) => boolean = () => true,
  keys: readonly string[] | undefined = Object.keys(object),
): readonly V[] {
  const values: V[] = [];

  for (const key of keys) {
    const value = object[key];

    if (filter(value)) {
      values.push(value);
    }
  }

  return values;
}

/**
 * Maps each element of an array to a new value and then filters the resulting array.
 *
 * @param array - The input array to map and filter.
 * @param mapper - The function to map each element of the input array to a new value.
 * @param filter - The function used to filter the results. Returns true if the element should be included in the final array, false otherwise.
 *
 * @returns The new array of mapped and filtered values.
 */
export function mapFilter<T, U>(
  array: readonly T[],
  mapper: (element: T) => U,
  filter: (element: U) => element is NonNullable<U>,
): NonNullable<U>[];
export function mapFilter<T, U>(
  array: readonly T[],
  mapper: (element: T) => U,
  filter: (element: U) => boolean,
): U[];
export function mapFilter<T, U>(
  array: readonly T[],
  mapper: (element: T) => U,
  filter: (element: U) => boolean,
): U[] {
  const result: U[] = [];

  for (const element of array) {
    const mapped = mapper(element);

    if (filter(mapped)) {
      result.push(mapped);
    }
  }

  return result;
}

/**
 * Used to parse csv given a set of separators.
 * It will use regex to match against all the seperators and
 * use the first valid regex to split the csv. If no
 * seperators match then no values exists in the csv hence,
 * we return an empty array. It will not handle cases like
 * a,b,c,s@mail.com,g@mail.com where s@mail.com and g@mail are valid email values.
 * Instead it will return an empty array.
 *
 **/
export function parseEmailCSVValues(
  separators: readonly string[],
  csv: string,
): readonly string[] {
  const validCSVRegexes = separators.map((separator): [string, RegExp] => [
    separator,
    new RegExp(
      `^((([^<>()[\\].,;:\\s@"]+(\\.[^<>()[\\].,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))(${separator})*)+$`,
    ),
  ]);
  const validRegex = validCSVRegexes.find(([_, regex]) => regex.test(csv));
  if (!validRegex) {
    // If not valid regex then there are no values
    return [];
  }
  const [separator, _] = validRegex;
  const dedupedValues = new Set(
    csv
      .trim()
      .split(new RegExp(`${separator}`))
      .filter(email => !!email),
  );
  return Array.from(dedupedValues);
}

import { isPlatformBrowser } from '@angular/common';
import type { Location } from '@freelancer/location';
import type { CookieOptions } from 'ngx-cookie';

export function cookieOptionsFactory(
  platformId: Object,
  location: Location,
): CookieOptions {
  return isPlatformBrowser(platformId)
    ? {
        path: '/',
        // This domain needs to stay in sync with the `cookieDomain` in `generate-capacitor-config.ts`.
        domain: location.hostname.match(/freelancer.*$/)
          ? `.${(location.hostname.match(/freelancer.*$/) as string[])[0]}`
          : `${location.hostname}`,
        secure: location.protocol === 'https:',
        sameSite: 'lax',
      }
    : {};
}

import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { RepetitiveSubscription } from '@freelancer/decorators';
import { FreelancerBreakpoints } from '@freelancer/ui/breakpoints';
import { isDefined } from '@freelancer/utils';
// docs say to use the polyfill as a ponyfill
import ResizeObserver from 'resize-observer-polyfill';
import { Subscription, fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { StickyService } from './sticky.service';

/**
 * ALWAYS
 * - Element is always sticky and visible at all times
 *
 * ONSCROLL
 * - Element will only be sticky once it intersects with the device's viewport
 *
 * APPEAR_AFTER_SCROLL
 * - Element is always sticky but hidden by default, it will only
 *   be visible once the it intersects with the device's viewport
 *
 * STICKY_UNTIL_SCROLL
 * - Element is sticky until it intersects with the device's viewport
 *   and will take its place after.
 *
 */
export enum StickyBehaviour {
  ALWAYS = 'always',
  ONSCROLL = 'onscroll',
  APPEAR_AFTER_SCROLL = 'appear_after_scroll',
  STICKY_UNTIL_SCROLL = 'sticky_until_scroll',
}

export enum StickyPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

@Directive({
  selector: `
    [flSticky],
    [flStickyMobile],
  `,
})
export class StickyDirective implements OnChanges, AfterViewInit, OnDestroy {
  @Input() flSticky = true;
  // Only sticky on mobile
  @Input() flStickyMobile?: boolean;
  @Input() flStickyOffset = 0;
  @Input() flStickyBehaviour = StickyBehaviour.ONSCROLL;
  @Input() flStickyPosition = StickyPosition.TOP;
  @Input() flStickyOrder: number;
  @Input() flStickyFullWidth = true;

  /**
   * Warning
   * This makes the element not included in the stacking and gonna
   * overlap with other sticky elements
   */
  @Input() flStickyStatic = false;

  @Output() activated = new EventEmitter<boolean>();

  private get isStickyTop(): boolean {
    return this.flStickyPosition === StickyPosition.TOP;
  }

  private get isAlwaysSticky(): boolean {
    return this.flStickyBehaviour === StickyBehaviour.ALWAYS;
  }

  private get isAppearAfterScroll(): boolean {
    return this.flStickyBehaviour === StickyBehaviour.APPEAR_AFTER_SCROLL;
  }

  private get isStickyUntilScroll(): boolean {
    return this.flStickyBehaviour === StickyBehaviour.STICKY_UNTIL_SCROLL;
  }

  private get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  private get markerPosition(): number {
    return window.scrollY + this.marker.getBoundingClientRect().top;
  }

  private isSticky = false;
  private offset = this.flStickyOffset;
  @RepetitiveSubscription()
  private stackHeightSubscription?: Subscription;
  private mobileSubscription?: Subscription;
  private scrollSubscription?: Subscription;
  private intersectionObserver: IntersectionObserver;
  private resizeObserver: ResizeObserver;
  private placeholderResizeObserver: ResizeObserver;
  private marker = this.renderer.createElement('div') as HTMLDivElement;
  private placeholder = this.renderer.createElement('div') as HTMLDivElement;
  private resizeAnimationRequestId: number | undefined = undefined;
  private placeholderAnimationRequestId: number | undefined = undefined;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private breakpointObserver: BreakpointObserver,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private stickyService: StickyService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    /**
     * Don't set up other functionalities of the
     * directive in the server as things like
     * getBoundingClientRect will break SSR
     */
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    /**
     * Only run this if flSticky's value dynamically changes
     * from true to false and vice-versa.
     */
    if (
      ('flSticky' in changes && isDefined(changes.flSticky.previousValue)) ||
      ('flStickyMobile' in changes &&
        isDefined(changes.flStickyMobile.previousValue))
    ) {
      if (this.elementRef && this.flSticky) {
        this.initializeSticky();
      } else {
        this.deactivate();
        this.destroyListeners();
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.flSticky && !this.flStickyMobile) {
      return;
    }

    /**
     * For APPEAR_AFTER_SCROLL, set default styling for both server and browser
     * to prevent the element from jumping around since it should be hidden by default
     */
    if (this.isAppearAfterScroll) {
      this.renderer.setStyle(this.element, 'position', 'fixed');
      this.hideStickyElement();
    }

    /**
     * Don't setup other functionalities of the directive
     * ub the server as things like getBoundingClientRect
     * will break SSR
     */
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    /**
     * Initialize sticky on mobile only if flStickyMobile is true
     */
    if (this.flStickyMobile) {
      this.mobileSubscription = this.breakpointObserver
        .observe(FreelancerBreakpoints.MOBILE)
        .subscribe(mobile => {
          if (!mobile.matches) {
            this.initializeSticky();
          } else {
            this.deactivate();
            this.destroyListeners();
          }
        });
    }

    /**
     * To improve on load's performance, wait for the first scroll event to fire
     * before actually initializing the sticky directive
     */
    if (!this.isAlwaysSticky && !this.isStickyUntilScroll) {
      // eslint-disable-next-line local-rules/no-scroll-listener
      this.scrollSubscription = fromEvent(window, 'scroll')
        .pipe(take(1))
        .subscribe(() => {
          this.initializeSticky();
          this.scrollSubscription?.unsubscribe();
        });
    } else {
      this.initializeSticky();
    }
  }

  initializeSticky(): void {
    this.offset = this.getOffset();
    this.createMarker();

    if (this.isElementVisible()) {
      this.toggleStickyState();
      this.detectStackHeightChanges();
    }

    /**
     * Setup resize observer in order to do detect the ff:
     * - If it gets hidden when changing viewport, de-activate.
     * - If it gets shown when changing viewport, re-activate.
     * - If the dimension changes, adjust placeholder.
     */
    if (!isDefined(this.resizeObserver)) {
      this.resizeObserver = new ResizeObserver(
        (entries: ResizeObserverEntry[]) => {
          this.resizeAnimationRequestId = requestAnimationFrame(() => {
            entries.forEach(() => {
              if (!this.isElementVisible()) {
                this.deactivate();
                return;
              }

              this.toggleStickyState();
              this.detectStackHeightChanges();

              if (this.isSticky) {
                this.updateStickyPosition();
                this.updatePlaceholderHeight();
              }
            });
          });
        },
      );

      this.resizeObserver.observe(this.element);
    }
  }

  detectStackHeightChanges(): void {
    if (!isDefined(this.stackHeightSubscription) && !this.flStickyStatic) {
      this.stackHeightSubscription = this.stickyService
        .stackHeightChange(this.isStickyTop)
        .subscribe(() => {
          this.offset = this.getOffset();
          this.updateMarkerPosition();

          if (this.isSticky) {
            this.updateStickyPosition();
          }
        });
    }
  }

  /**
   * Toggle Sticky State
   * Activates instantly for the ff:
   * 1. isAlwaysSticky
   * 2. isAppearAfterScroll should always be sticky so that it doesn't take up space
   * 3. Marker's position is already off the screen, this is for
   * cases when the page is already scrolled way past the marker
   *
   * IntersectionObserver is only used for `ONSCROLL`, `APPEAR_AFTER_SCROLL` and `STICKY_UNTIL_INTERSECT`
   */
  toggleStickyState(): void {
    if (
      this.isAlwaysSticky ||
      this.isAppearAfterScroll ||
      this.markerPosition <= 0 ||
      (this.isStickyUntilScroll &&
        this.marker.getBoundingClientRect().top > 0 &&
        this.marker.getBoundingClientRect().top > window.innerHeight)
    ) {
      this.activate();
    }

    if (!isDefined(this.intersectionObserver) && !this.isAlwaysSticky) {
      this.intersectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const markerTopPos = this.marker.getBoundingClientRect().top;

          if (this.isAppearAfterScroll) {
            this.toggleVisibility(entry, markerTopPos);
            return;
          }

          if (this.isStickyUntilScroll) {
            this.handleStickyUntilScroll(entry, markerTopPos);
            return;
          }

          if (this.isStickyTop) {
            this.toggleStickyTop(entry, markerTopPos);
          } else {
            this.toggleStickyBottom(entry, markerTopPos);
          }
        });
      });
      this.intersectionObserver.observe(this.marker);
    }
  }

  toggleVisibility(
    entry: IntersectionObserverEntry,
    markerTopPos: number,
  ): void {
    if (!entry.isIntersecting && markerTopPos <= 0) {
      this.showStickyElement();
    } else {
      this.hideStickyElement();
    }
  }

  showStickyElement(): void {
    this.renderer.removeStyle(this.element, 'pointer-events');
    this.renderer.removeStyle(this.element, 'visibility');

    this.activated.emit(true);
    this.addToStack();
  }

  hideStickyElement(): void {
    this.renderer.setStyle(this.element, 'pointer-events', 'none');
    this.renderer.setStyle(this.element, 'visibility', 'hidden');

    this.activated.emit(false);
    this.removeFromStack();
  }

  toggleStickyTop(
    entry: IntersectionObserverEntry,
    markerTopPos: number,
  ): void {
    if (!entry.isIntersecting && markerTopPos <= 0) {
      this.activate();
    } else {
      this.deactivate();
    }
  }

  toggleStickyBottom(
    entry: IntersectionObserverEntry,
    markerTopPos: number,
  ): void {
    if (entry.isIntersecting) {
      this.activate();
    } else if (markerTopPos > 0) {
      this.deactivate();
    }
  }

  handleStickyUntilScroll(
    entry: IntersectionObserverEntry,
    markerTopPos: number,
  ): void {
    if (entry.isIntersecting || markerTopPos <= 0) {
      this.deactivate();
    } else {
      this.activate();
    }
  }

  activate(): void {
    if (!this.isSticky) {
      // Don't create a placeholder for APPEAR_AFTER_SCROLL
      // as the element is hidden initially and this will create awkward space
      if (!this.isAppearAfterScroll) {
        this.createPlaceholder();
      }

      this.updateStickyPosition();

      this.renderer.addClass(this.element, 'IsSticky');
      this.renderer.setStyle(this.element, 'position', 'fixed');
      this.renderer.setStyle(this.element, 'z-index', '1000');

      if (this.flStickyFullWidth) {
        this.renderer.setStyle(this.element, 'left', `0`);
        this.renderer.setStyle(this.element, 'width', `100%`);
      } else {
        this.renderer.setStyle(
          this.element,
          'width',
          `${this.element.offsetWidth}px`,
        );
      }

      // Adjusts scroll position in UI tests to prevent sticky header from
      // obscuring targeted element
      if (this.isStickyTop) {
        this.renderer.setAttribute(
          this.element,
          'data-testing',
          'sticky-header',
        );
      }

      this.isSticky = true;
      this.activated.emit(true);
      this.addToStack();
    }
  }

  deactivate(): void {
    if (this.isSticky) {
      this.isSticky = false;

      // We don't create a placeholder for APPEAR_AFTER_SCROLL
      if (!this.isAppearAfterScroll) {
        this.destoryPlaceholder();
      }

      this.renderer.removeStyle(this.element, 'position');
      this.renderer.removeStyle(this.element, this.flStickyPosition);
      this.renderer.removeStyle(this.element, 'z-index');
      this.renderer.removeStyle(this.element, 'width');
      this.renderer.removeClass(this.element, 'IsSticky');

      if (this.isStickyTop) {
        this.renderer.removeAttribute(this.element, 'data-testing');
      }

      this.activated.emit(false);
      this.removeFromStack();
    }
  }

  private addToStack(): void {
    if (!this.flStickyStatic) {
      this.stickyService.add(
        this.element,
        this.isStickyTop,
        this.flStickyOrder,
      );
    }
  }

  private removeFromStack(): void {
    if (!this.flStickyStatic) {
      this.stickyService.remove(this.element, this.isStickyTop);
    }
  }

  private getOffset(): number {
    return !this.flStickyStatic
      ? this.flStickyOffset +
          this.stickyService.getOffset(this.element, this.isStickyTop)
      : this.flStickyOffset;
  }

  private updateStickyPosition(): void {
    this.renderer.setStyle(
      this.element,
      this.flStickyPosition,
      `${this.offset}px`,
    );
  }

  /**
   * Placeholder is created to avoid jump when component becomes sticky
   * and serves as the width basis when user resizes the screen
   */
  private createPlaceholder(): void {
    this.renderer.setStyle(this.placeholder, 'display', 'block');
    this.updatePlaceholderHeight();

    if (this.isAlwaysSticky || this.isStickyTop) {
      this.renderer.appendChild(this.element.parentNode, this.placeholder);
    }

    if (!this.isStickyTop && !this.isAlwaysSticky) {
      this.renderer.insertBefore(
        this.element.parentNode,
        this.placeholder,
        this.marker,
      );
    }

    // Change sticky element width if placeholder width changes
    if (!isDefined(this.placeholderResizeObserver) && !this.flStickyFullWidth) {
      this.placeholderResizeObserver = new ResizeObserver(
        (entries: ResizeObserverEntry[]) => {
          this.placeholderAnimationRequestId = requestAnimationFrame(() => {
            entries.forEach(() => {
              if (this.isSticky) {
                this.renderer.setStyle(
                  this.element,
                  'width',
                  `${this.placeholder.offsetWidth}px`,
                );
              }
            });
          });
        },
      );

      this.placeholderResizeObserver.observe(this.placeholder);
    }
  }

  private updatePlaceholderHeight(): void {
    this.renderer.setStyle(
      this.placeholder,
      'height',
      `${this.element.offsetHeight}px`,
    );
  }

  private destoryPlaceholder(): void {
    // Set display to none as removing child sometimes takes time
    this.renderer.setStyle(this.placeholder, 'display', 'none');
    this.renderer.removeChild(this.element.parentNode, this.placeholder);

    if (this.placeholderResizeObserver) {
      this.placeholderResizeObserver.unobserve(this.placeholder);
    }
  }

  /**
   * Marker serves as the intersection point to decide
   * whether to activate or deactivate sticky state
   */
  private createMarker(): void {
    this.marker.style.position = `relative`;

    if (this.isStickyTop) {
      this.renderer.insertBefore(
        this.element.parentNode,
        this.marker,
        this.element,
      );
    } else {
      this.renderer.appendChild(this.element.parentNode, this.marker);
    }

    this.updateMarkerPosition();
  }

  private updateMarkerPosition(): void {
    this.renderer.setStyle(
      this.marker,
      this.flStickyPosition,
      `-${this.offset}px`,
    );
  }

  private destroyMarker(): void {
    this.renderer.removeChild(this.element.parentNode, this.marker);
  }

  private destroyListeners(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    if (this.placeholderResizeObserver) {
      this.placeholderResizeObserver.disconnect();
    }

    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }

    if (this.stackHeightSubscription) {
      this.stackHeightSubscription.unsubscribe();
    }
  }

  private isElementVisible(): boolean {
    return this.element.offsetWidth > 0 || this.element.offsetHeight > 0;
  }

  ngOnDestroy(): void {
    if (this.placeholderAnimationRequestId) {
      cancelAnimationFrame(this.placeholderAnimationRequestId);
    }
    if (this.resizeAnimationRequestId) {
      cancelAnimationFrame(this.resizeAnimationRequestId);
    }
    this.destroyMarker();
    this.deactivate();
    this.destroyListeners();
    this.mobileSubscription?.unsubscribe();
    this.scrollSubscription?.unsubscribe();
  }
}

import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Router } from '@angular/router';
import { Auth } from '@freelancer/auth';
import { delay, switchMap } from 'rxjs/operators';

export const ShellGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  const auth = inject(Auth);
  const router = inject(Router);

  const shouldBeLoggedIn = route.data.loggedIn;
  return auth.isLoggedIn().pipe(
    switchMap(async isLoggedIn => {
      if (
        isLoggedIn !== shouldBeLoggedIn &&
        route.parent?.routeConfig?.children
      ) {
        // HACK: Reverse the route config so the other route goes first
        // https://github.com/angular/angular/issues/16211
        route.parent.routeConfig.children.reverse();
        // then reload this route to access the reversed config
        // eslint-disable-next-line local-rules/no-navigate-in-guard, local-rules/no-navigate-by-url
        router.navigateByUrl(state.url, {
          onSameUrlNavigation: 'reload',
        });
      }
      return isLoggedIn === shouldBeLoggedIn;
    }),
    // Wait for route animations to play.
    delay(0),
  );
};

import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type { ServiceOfferingBuyerHistoriesCollection } from './service-offering-buyer-histories.types';

export function serviceOfferingBuyerHistoriesBackend(): Backend<ServiceOfferingBuyerHistoriesCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.DESC,
    },
    fetch: (_authUid, ids, query, _order) => {
      const serviceOfferingId = ids ? ids[0] : undefined;
      if (!serviceOfferingId) {
        throw new Error('Service offering ID is missing.');
      }

      return {
        endpoint: `service_offerings/0.1/service_offerings/${serviceOfferingId}/buyer_histories`,
        params: {
          limit: query?.searchQueryParams.limit,
        },
      };
    },
    push: undefined,
    remove: undefined,
    set: undefined,
    update: undefined,
  };
}

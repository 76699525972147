import type {
  QuotationContextTypeApi,
  QuotationStatusApi,
} from 'api-typings/quotations/quotations';

/**
 * Quotation amount of a specific currency
 */
export interface QuotationAmount {
  readonly [currencyId: number]: number;
}

/**
 * A quotation revision
 */
export interface QuotationRevision {
  readonly id: number; // quotation version id
  readonly quotationId: number;
  readonly quotationUid: string;
  readonly versionUid: string;
  readonly contextType: QuotationContextTypeApi;
  readonly contextId: number;
  readonly creatorId: number;
  readonly createDate: number;
  readonly isRequestedByRecipient: boolean;
  readonly defaultCurrencyId: number;
  readonly approveDate?: number;
  readonly paidAmount: QuotationAmount;
  readonly fundedAmount: QuotationAmount;
  readonly quotePrice: QuotationAmount;
  readonly title?: string;
  readonly description?: string;
  readonly richDescription?: string;
  readonly versionInvalidDate?: number;
  readonly parentVersionId?: number;
  readonly status: QuotationStatusApi;
  readonly estimatedStartDate?: number;
  readonly estimatedEndDate?: number;
  readonly upfrontFundRequestAmount?: number;
  readonly rejectDate?: number;
  readonly cancelDate?: number;
  readonly expiryDate?: number;
  readonly durationInDays?: number;
  readonly recipientId?: number;
  readonly parentTemplateVersionId?: number;
  /**
   * This contains the service ID of service-related quotations.
   */
  readonly parentTemplateContextId?: number;
  readonly logoId?: number;
}

export type QuotationCreateParams = Pick<
  QuotationRevision,
  | 'contextId'
  | 'contextType'
  | 'creatorId'
  | 'defaultCurrencyId'
  | 'parentTemplateVersionId'
  | 'parentTemplateContextId'
>;

// Used for converting duration to days from seconds and to seconds from days
// Needed as the frontend handles duration in days, because currently
// that is the min time unit we use for user inputs, but the backend handles it
// in seconds.
export const SECONDS_IN_A_DAY = 60 * 60 * 24;

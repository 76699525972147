import type { TemplateRef } from '@angular/core';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

export interface ViewHeaderTemplateConfig {
  fullReplacement: boolean;
  templateRef: TemplateRef<any>;
  subtitleTemplateRef?: TemplateRef<any>;
  customActionTemplateRef?: TemplateRef<any>;
}

@Injectable({
  providedIn: 'root',
})
export class ViewHeaderTemplate {
  private templateConfigSubject$ = new BehaviorSubject<
    ViewHeaderTemplateConfig | undefined
  >(undefined);

  registerHeader(templateConfig: ViewHeaderTemplateConfig): void {
    if (this.templateConfigSubject$.value?.templateRef) {
      throw new Error(
        'Could not register view header component: a view header already exists!',
      );
    }

    this.templateConfigSubject$.next(templateConfig);
  }

  registerSubtitle(templateRef: TemplateRef<any>): void {
    if (this.templateConfigSubject$.value?.templateRef && templateRef) {
      this.templateConfigSubject$.next({
        ...this.templateConfigSubject$.value,
        subtitleTemplateRef: templateRef,
      });
    }
  }

  registerCustomAction(templateRef: TemplateRef<any>): void {
    if (!this.templateConfigSubject$.value?.templateRef) {
      throw new Error(
        'Could not register view header custom action without the primary view header',
      );
    }

    if (this.templateConfigSubject$.value?.customActionTemplateRef) {
      throw new Error(
        'Could not register view header custom action: a custom action already exists!',
      );
    }

    if (templateRef) {
      this.templateConfigSubject$.next({
        ...this.templateConfigSubject$.value,
        customActionTemplateRef: templateRef,
      });
    }
  }

  deregisterHeader(templateConfig: ViewHeaderTemplateConfig): void {
    if (
      this.templateConfigSubject$.value?.templateRef ===
      templateConfig.templateRef
    ) {
      this.templateConfigSubject$.next(undefined);
    }
  }

  deregisterSubtitle(templateRef: TemplateRef<any>): void {
    if (
      this.templateConfigSubject$.value?.subtitleTemplateRef === templateRef
    ) {
      const { subtitleTemplateRef, ...config } =
        this.templateConfigSubject$.value;
      this.templateConfigSubject$.next(config);
    }
  }

  deregisterCustomAction(templateRef: TemplateRef<any>): void {
    if (
      this.templateConfigSubject$.value?.customActionTemplateRef === templateRef
    ) {
      const { customActionTemplateRef, ...config } =
        this.templateConfigSubject$.value;
      this.templateConfigSubject$.next(config);
    }
  }

  templateChanges(): Observable<ViewHeaderTemplateConfig | undefined> {
    return this.templateConfigSubject$.asObservable();
  }

  /**
   * Manually deregister and register the current template config.
   *
   * Changes in the child component of fl-view-header using the ViewHeaderTemplateDirective
   * is causing NG0100 error (T241462). To prevent this error, call this when the inputs
   * to the child component changes. This will trigger change detection from the
   * navigation child component.
   */
  updateHeader(): void {
    const templateConfig = this.templateConfigSubject$.value;
    this.templateConfigSubject$.next(undefined);
    this.templateConfigSubject$.next(templateConfig);
  }

  /**
   * Deregisters the current template config.
   *
   * @returns The template config that was cleared.
   */
  clearHeader(): ViewHeaderTemplateConfig | undefined {
    const current = this.templateConfigSubject$.value;
    this.templateConfigSubject$.next(undefined);
    return current;
  }
}

import type { OpenGraphDetailsGetResponseAjaxApi } from './open-graph-details.backend-model';
import type { OpenGraphDetails } from './open-graph-details.model';

export function transformOpenGraphDetails(
  response: OpenGraphDetailsGetResponseAjaxApi,
): OpenGraphDetails {
  return {
    id: response.open_graph_details.url,
    title: response.open_graph_details.title,
    url: response.open_graph_details.url,
    imageUrl: response.open_graph_details.image_url,
    siteName: response.open_graph_details.site_name,
    description: response.open_graph_details.description,
    type: response.open_graph_details.type,
    videoUrl: response.open_graph_details.video_url,
    audioUrl: response.open_graph_details.audio_url,
  };
}

export enum LogoSize {
  MID = 'mid',
  SMALL = 'small',
  ICON = 'icon',
  MINI = 'mini',
}

export enum BackgroundColor {
  DARK = 'dark',
  LIGHT = 'light',
  THEME = 'theme',
}

export type BackgroundColourType = 'dark' | 'light' | 'theme';

/**
 * An alert for a user's saved search
 */
export type RecentSavedSearchAlert =
  | SingleProjectSavedSearchAlert
  | MultipleProjectSavedSearchAlert;

export interface SingleProjectSavedSearchAlert {
  readonly id: string; // contains saved search ID and project ID to ensure uniqueness
  readonly projectId: number;
  readonly projectSeoUrl: string;
  readonly projectTitle: string;
  readonly savedSearchName: string;
  readonly time: number;
  readonly type: SavedAlertType.SINGLE_PROJECT;
}

export interface MultipleProjectSavedSearchAlert {
  readonly id: number; // saved search ID
  readonly savedSearchName: string;
  readonly time: number;
  readonly type: SavedAlertType.MULTIPLE_PROJECT;
}

export enum SavedAlertType {
  SINGLE_PROJECT,
  MULTIPLE_PROJECT,
}

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { OnlineOfflineUserStatus as DatastoreOnlineOfflineStatusObject } from '@freelancer/datastore/collections';
// FIXME: T235847
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { OnlineOfflineUserStatusValue as DatastoreOnlineOfflineStatus } from '@freelancer/datastore/collections/online-offline';
import { assertNever } from '@freelancer/utils';

@Pipe({ name: 'isOnline' })
export class IsOnlinePipe implements PipeTransform {
  transform(
    user: { id: number },
    onlineOfflineStatuses?: readonly DatastoreOnlineOfflineStatusObject[],
  ): boolean {
    if (!onlineOfflineStatuses) {
      return false;
    }

    const matchingStatus = onlineOfflineStatuses.find(
      status => status.id === user.id,
    );

    if (!matchingStatus) {
      return false;
    }

    switch (matchingStatus.status) {
      case DatastoreOnlineOfflineStatus.ONLINE:
        return true;
      case DatastoreOnlineOfflineStatus.OFFLINE:
        return false;
      default:
        return assertNever(matchingStatus.status);
    }
  }
}

import { NgModule } from '@angular/core';
import { MODALS } from '@freelancer/ui/modal';
import { componentsModals } from './components-modal-routes';

@NgModule({
  providers: [
    {
      provide: MODALS,
      useValue: componentsModals,
      multi: true,
    },
  ],
})
export class ComponentsModalsModule {}

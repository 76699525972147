/**
 * Next project recommendations generated from the details of a project.
 */
export interface NextProjectRecommendations {
  readonly id: number;
  readonly projectId: number;
  readonly recommendations: readonly ProjectRecommendation[];
}

export interface ProjectRecommendation {
  readonly categoryId: number;
  readonly categorySeoUrl: ProjectCategory;
  readonly projectTitle: string;
  readonly projectDescription: string;
}

export enum ProjectCategory {
  LOGO_DESIGN = 'logo-design',
  DESIGN = 'design',
  DEVELOPER = 'developer',
  WRITING = 'writing',
  SERVICES = 'services',
  GENERIC = 'generic',
  DEFAULT = 'default',
}

import { Injectable, NgZone } from '@angular/core';
import { RepetitiveSubscription } from '@freelancer/decorators';
import { LocationInitialUrl } from '@freelancer/location';
import { isDefined } from '@freelancer/utils';
import {
  Subscription,
  Observable,
  merge,
  fromEvent,
  combineLatest,
} from 'rxjs';
import { debounceTime, filter, map, startWith, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import type {
  CLSMetricWithAttribution,
  FCPMetricWithAttribution,
  FIDMetricWithAttribution,
  INPMetricWithAttribution,
  LCPMetricWithAttribution,
  Metric,
} from 'web-vitals/attribution';
import { onCLS, onFCP, onFID, onINP, onLCP } from 'web-vitals/attribution';
import { Tracking } from './tracking.service';

// https://w3c.github.io/largest-contentful-paint/#sec-largest-contentful-paint-interface
// https://developer.mozilla.org/en-US/docs/Web/API/LargestContentfulPaint
// FIXME: T267853 - This type definition isn't provided by TypeScript yet
interface LargestContentfulPaint {
  element?: Element;
  url?: string;
}

// https://wicg.github.io/layout-instability/#sec-layout-shift
// https://developer.mozilla.org/en-US/docs/Web/API/LayoutShift
// FIXME: T267853 - This type definition isn't provided by TypeScript yet
interface LayoutShift extends PerformanceEntry {
  sources?: LayoutShiftAttribution[];
  value: number;
}

// https://wicg.github.io/layout-instability/#sec-layout-shift-attribution
// https://developer.mozilla.org/en-US/docs/Web/API/LayoutShiftAttribution
// FIXME: T267853 - This type definition isn't provided by TypeScript yet
interface LayoutShiftAttribution {
  node?: Node;
}

@Injectable({ providedIn: 'root' })
export class CoreWebVitalsTracking {
  @RepetitiveSubscription()
  private coreWebVitalsSubscription?: Subscription;
  private hasReportedInitial = false;
  private spaSessionId = uuidv4();

  constructor(
    private ngZone: NgZone,
    private t: Tracking,
    private locationInitialUrl: LocationInitialUrl, // private swUpdate: SwUpdate,
  ) {}

  trackCoreWebVitalMetrics(): void {
    const isPwaPromise =
      window.navigator && window.navigator.serviceWorker
        ? window.navigator.serviceWorker
            .getRegistration('/')
            .then(registration => registration?.active?.state === 'activated')
        : Promise.resolve(false);

    this.ngZone.runOutsideAngular(async () => {
      const lcp$ = new Observable<LCPMetricWithAttribution>(observer => {
        // Reports final on `keydown`, `pointerdown`, `scroll`
        // or `visibilitychange` becomes `hidden`
        // Note that this won't report anything if page is hidden
        // before API initializes.
        // FIXME: T271012 - We have to leave the type definition of `metric` as Metric due to a weird type error
        onLCP((metric: Metric) => {
          observer.next(metric as LCPMetricWithAttribution);
          observer.complete();
        });
      });

      const fid$ = new Observable<FIDMetricWithAttribution>(observer => {
        // Reports final on `keydown`, `pointerdown`
        // Note that this won't report anything if page is hidden
        // before API initializes.
        // FIXME: T271012 - We have to leave the type definition of `metric` as Metric due to a weird type error
        onFID((metric: Metric) => {
          observer.next(metric as FIDMetricWithAttribution);
          observer.complete();
        });
      });

      const inp$ = new Observable<INPMetricWithAttribution>(observer => {
        // Reports final on `keydown`, `pointerdown`
        // Note that this won't report anything if page is hidden
        // before API initializes.
        // FIXME: T271012 - We have to leave the type definition of `metric` as Metric due to a weird type error
        onINP(
          (metric: Metric) => {
            observer.next(metric as INPMetricWithAttribution);
          },
          { reportAllChanges: true },
        );
      });

      const fcp$ = new Observable<FCPMetricWithAttribution>(observer => {
        // Reports final on `keydown`, `pointerdown`
        // Note that this won't report anything if page is hidden
        // before API initializes.
        // FIXME: T271012 - We have to leave the type definition of `metric` as Metric due to a weird type error
        onFCP((metric: Metric) => {
          observer.next(metric as FCPMetricWithAttribution);
          observer.complete();
        });
      });

      const cls$ = new Observable<CLSMetricWithAttribution>(observer => {
        // Report all changes
        onCLS(
          // FIXME: T271012 - We have to leave the type definition of `metric` as Metric due to a weird type error
          (metric: Metric) => {
            observer.next(metric as CLSMetricWithAttribution);
          },
          { reportAllChanges: true },
        );
      });

      // Fallback if user did not interact with page before page is hidden
      const fidOrHidden$ = merge(
        fid$,
        fromEvent(document, 'visibilitychange').pipe(
          filter(() => document.visibilityState === 'hidden'),
          map(() => undefined),
          take(1),
        ),
      ).pipe(take(1));

      // Wait for all three core web vitals before tracking on initial page load
      this.coreWebVitalsSubscription = combineLatest([
        fidOrHidden$,
        // Initial value for LCP and CLS needed for unsupported browsers
        lcp$.pipe(startWith(undefined)),
        cls$.pipe(
          startWith(undefined),
          // Can fire frequently for highly dynamic views
          // This is not switched across to timeUtils as it may break some PJP
          // draft tests.
          // eslint-disable-next-line local-rules/validate-timers
          debounceTime(500),
        ),
        fcp$.pipe(startWith(undefined)),
        inp$.pipe(startWith(undefined)),
      ]).subscribe(async ([fid, lcp, cls, fcp, inp]) => {
        // Don't track if we have no metrics, it's possible
        // if page is loaded in the background
        if (!fid && !lcp && !cls && !fcp && !inp) {
          return;
        }

        // Report FCP, LCP and FID in one event
        const hasInitialMetrics = fid && fcp && lcp;
        if (!hasInitialMetrics && !cls && !inp) {
          return;
        }

        this.t.track('core_web_vitals_timings', {
          fid:
            this.hasReportedInitial || !hasInitialMetrics
              ? undefined
              : fid?.delta, // in ms
          lcp:
            this.hasReportedInitial || !hasInitialMetrics
              ? undefined
              : lcp?.delta, // in ms
          fcp:
            this.hasReportedInitial || !hasInitialMetrics
              ? undefined
              : fcp?.delta,
          cls: cls?.value, // take current value since it changes over time
          inp: inp?.value, // take current value since it changes over time
          lcpElement: this.hasReportedInitial
            ? undefined
            : lcp
            ? this.getLcpElement(lcp)
            : undefined,
          clsElements: cls ? this.getClsElements(cls) : undefined,
          inpElement: inp ? this.getInpElement(inp) : undefined,
          fidElement: this.hasReportedInitial
            ? undefined
            : fid
            ? this.getFidElement(fid)
            : undefined,
          isPwa: await isPwaPromise,
          initialUrl: this.locationInitialUrl.getInitialUrl(),
          spaSessionId: this.spaSessionId,
        });

        // Report FCP, LCP and FID once
        if (hasInitialMetrics) {
          this.hasReportedInitial = true;
        }
      });
    });
  }

  private getLcpElement(metric: LCPMetricWithAttribution): string | undefined {
    // get the latest LCP element
    // TBD: Consider using the recommended way instead
    // https://github.com/GoogleChrome/web-vitals#send-attribution-data
    // return metric.attribution.element;
    const entry = metric.entries[
      metric.entries.length - 1
    ] as LargestContentfulPaint;
    const element = this.stripGeneratedAttributesAndClasses(entry?.element);
    return element ? element.outerHTML?.substring(0, 1000) : entry.url;
  }

  private getClsElements(metric: CLSMetricWithAttribution): string {
    const entries = metric.entries as LayoutShift[];
    // Since we limit the payload size, we prioritize worst offenders
    entries.sort((a, b) => b.value - a.value);
    return entries
      .map((entry: LayoutShift) => {
        const sources = entry.sources?.map((source: LayoutShiftAttribution) => {
          let elementString: string | undefined;
          if (this.nodeIsElement(source.node)) {
            const element = this.stripGeneratedAttributesAndClasses(
              source.node,
            );
            elementString = element?.outerHTML;
          } else {
            elementString = source.node?.textContent ?? undefined; // force null to be undefined
          }

          return elementString?.substring(0, 1000);
        });
        return `${entry.value} ${(sources || []).join(' ')}`;
      })
      .join(' ')
      .substring(0, 2000);
  }

  private getInpElement(metric: INPMetricWithAttribution): string | undefined {
    return metric.attribution.eventTarget;
  }

  private getFidElement(metric: FIDMetricWithAttribution): string {
    return metric.attribution.eventTarget;
  }

  private stripGeneratedAttributesAndClasses(
    element?: Element,
  ): Element | undefined {
    const attributes = element?.getAttributeNames();

    // Make a copy so that we don't directly modify the original element
    const elementCopy = element?.cloneNode(true) as Element;

    // FIXME: T267853 - This operates on the current element only.
    // Child nodes aren't affected
    attributes?.forEach(attribute => {
      if (attribute.match(/^_ng(?:host|content)-webapp-c(?:\d+)$/)) {
        elementCopy?.removeAttribute(attribute);
      }
    });

    elementCopy?.classList.remove('ng-star-inserted');

    return elementCopy;
  }

  private nodeIsElement(node?: Node): node is Element {
    return isDefined((node as Element)?.getAttributeNames);
  }

  destroy(): void {
    this.coreWebVitalsSubscription?.unsubscribe();
  }
}

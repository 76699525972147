import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationInitialUrl {
  private initialUrl: string;

  init(): void {
    this.initialUrl = window.location.href;
  }

  getInitialUrl(): string {
    return this.initialUrl;
  }
}

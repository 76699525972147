import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { Platform } from '@freelancer/pwa';
import { Pwa } from '@freelancer/pwa';
import { ToastAlertColor, ToastAlertType } from './toast-alert.types';

@Component({
  selector: 'fl-toast-alert-global',
  template: `
    <fl-toast-alert
      i18n="File Viewer loading toast alert"
      [id]="'file-viewer-loading'"
      [timeout]="undefined"
      [closeable]="true"
      [type]="ToastAlertType.LOADING"
    >
      Loading preview
    </fl-toast-alert>
    <fl-toast-alert
      i18n="Geolocation Permission Granted toast alert"
      [id]="'geolocation-permission-granted'"
      [timeout]="10000"
      [closeable]="true"
      [type]="ToastAlertType.SUCCESS"
    >
      Permission granted for Geolocation.
    </fl-toast-alert>
    <fl-toast-alert
      i18n="Login session expired toast alert"
      [id]="'login-session-expired'"
      [timeout]="10000"
      [closeable]="true"
      [type]="ToastAlertType.WARNING"
    >
      Your login session has expired and you have been logged out. Please log in
      again.
    </fl-toast-alert>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastAlertGlobalComponent implements OnInit {
  ToastAlertType = ToastAlertType;
  ToastAlertColor = ToastAlertColor;

  platform: Platform;
  isNative: boolean;

  constructor(private pwa: Pwa) {}

  ngOnInit(): void {
    this.platform = this.pwa.getPlatform();
    this.isNative = this.pwa.isNative();
  }
}

import { isPlatformServer } from '@angular/common';
import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthServiceInterface } from '@freelancer/auth/interface';
import { Location } from '@freelancer/location';
import { LOGIN_AUTH_SERVICE } from './login.config';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackEmptyComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(LOGIN_AUTH_SERVICE) private authService: AuthServiceInterface,
  ) {}

  ngOnInit(): void {
    // We render the empty component on the server as we obviously can't set
    // the session there: once returned, the app will bootstrap on the same
    // route, and this guard will be executed again
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const { uid, token, next } = this.route.snapshot.queryParams;

    /**
     * Token is alphanumeric and shouldn't require to be decoded. However, this
     * will be kept there as decoding an alphanumeric token is harmless when it
     * yields the exact same result anyway.
     */
    this.authService.setSession(uid, decodeURIComponent(token));

    /**
     * URLs do not require decoding, as the browser would know when to decode.
     *
     * If URLs are decoded, it causes issues where the queryParams of a target
     * URL would be consumed by linkshim instead of the target URL.
     */
    this.location.navigateByUrl(next);
  }
}

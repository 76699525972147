import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import type { ToastAlertContainerComponentInterface } from './toast-alert-container.types';
import { ToastAlertService } from './toast-alert.service';

@Component({
  selector: 'fl-toast-alert-container',
  template: `
    <fl-container [mobilePadding]="true">
      <div class="ToastAlertInner">
        <div
          class="ToastAlertContainer"
          #container
        ></div>
      </div>
    </fl-container>
  `,
  styleUrls: ['./toast-alert-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastAlertContainerComponent
  implements ToastAlertContainerComponentInterface, OnInit, OnDestroy
{
  @ViewChild('container', { read: ElementRef })
  container: ElementRef<HTMLDivElement>;

  constructor(
    private element: ElementRef,
    private toastAlertService: ToastAlertService,
  ) {}

  ngOnInit(): void {
    this.toastAlertService.registerContainer(this);
  }

  isElementVisible(): boolean {
    const { nativeElement } = this.element;

    return nativeElement.offsetWidth > 0 || nativeElement.offsetHeight > 0;
  }

  ngOnDestroy(): void {
    this.toastAlertService.unregisterContainer(this);
  }
}

import {
  OrderByDirection,
  getQueryParamValue,
  type Backend,
} from '@freelancer/datastore/core';
import { assert } from '@freelancer/utils';
import type { SubscriptionItemsCollection } from './subscription-items.types';

export function subscriptionItemsBackend(): Backend<SubscriptionItemsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.DESC,
    },
    fetch: (authUid, ids, query, order) => ({
      endpoint: 'quotations/0.1/subscription_items/',
      params: {
        ids,
        project_id: getQueryParamValue(query, 'projectId')[0],
        quotation_item_version_id: getQueryParamValue(
          query,
          'quotationItemVersionId',
        )[0],
      },
    }),
    push: undefined,
    set: undefined,
    update: (authUid, subscriptionItem, originalSubscriptionItem) => {
      const subscriptionItemId = originalSubscriptionItem.id;

      assert(
        subscriptionItemId !== undefined,
        'Could not update due to missing subscription item id',
      );

      return {
        endpoint: `quotations/0.1/subscription_items/${subscriptionItemId}`,
        payload: {
          id: subscriptionItemId,
          status: subscriptionItem.status,
        },
        method: 'PUT',
      };
    },
    remove: undefined,
  };
}

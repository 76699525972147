import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeskproChat {
  clearSession(): void {
    window.sessionStorage.removeItem('dpWidget.chat.id');
  }
}

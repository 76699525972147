import { toNumber } from '@freelancer/utils';
import type { GroupActionsGetResultApi } from 'api-typings/groups/groups';
import { GroupActionApi } from 'api-typings/groups/groups';
import type { GroupActions } from './group-actions.model';

export function groupActionsTransformers(
  groupActionsGetResult: GroupActionsGetResultApi,
  authUid: string,
): GroupActions {
  return {
    id: toNumber(authUid),
    createGroup: groupActionsGetResult.group_actions.includes(
      GroupActionApi.GROUP_CREATE,
    ),
  };
}

import type { ActivatedRouteSnapshot } from '@angular/router';
import type { NotFoundRouteConfig } from '@freelancer/404';

/**
 * get config from nested child routes
 * TODO: we should enable paramsInheritanceStrategy instead
 */
export function getRecursiveRouteData(
  snapshot: ActivatedRouteSnapshot,
): NotFoundRouteConfig {
  let { data } = snapshot;
  snapshot.children.forEach(child => {
    data = { ...data, ...getRecursiveRouteData(child) };
  });
  return data;
}

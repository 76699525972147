/**
 * Models the ai consultant chat history. We need the whole history of the
 * chat so that the context is kept. The id is just generated
 */
export interface AiConsultantChatHistory {
  readonly id: string;
  readonly history: readonly AiConsultantMessage[];
}

export enum AiConsultantDisplayMessageType {
  CHAT = 'chat',
  ERROR = 'error',
  LIMIT = 'limit',
  LOADING = 'loading',
  DRAFT_PROJECT = 'draft_project',
}

export interface AiConsultantMessage {
  readonly content: string;
  readonly role: AiConsultantMessageRole;
  // We use the type so we know how to display it when loaded from local storage
  readonly type?: AiConsultantDisplayMessageType;
}

export enum AiConsultantMessageRole {
  USER = 'user',
  ASSISTANT = 'assistant',
}

/** This is the ID returned from backend when the request ends in exception */
export const AI_CONSULTANT_MESSAGE_EXCEPTION_ID = 'chat_exception';
export const AI_CONSULTANT_MESSAGE_EXCEPTION_LIMIT_ID = 'chat_exception_b';

export interface AiConsultantGeneratedProjectDraft {
  readonly title: string;
  readonly description: string;
}

import type {
  HandoverAgreementApi,
  HandoverApi,
} from 'api-typings/contests/contests';

export interface ContestHandoversGetResultApi {
  readonly handovers?: readonly HandoverApi[];
}

export interface ContestHandoversUpdateRawPayload {
  readonly action: ContestHandoverUpdateAction;
}

export enum ContestHandoverUpdateAction {
  SUBMIT = 'submit',
  SIGN_AGREEMENT = 'sign_agreement',
  RELEASE_PRIZE = 'release_prize',
}

export type ContestHandoversUpdateSignAgreementRawPayload = Omit<
  HandoverAgreementApi,
  'handover_id'
>;

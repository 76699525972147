import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type { InvitationBlockExtras } from './invitation-blocks.model';
import type { InvitationBlocksCollection } from './invitation-blocks.types';

export function invitationBlocksBackend(): Backend<InvitationBlocksCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: undefined,
    push: (authUid, document, extraContext?: InvitationBlockExtras) => {
      if (!extraContext?.token) {
        throw new Error('Missing token field on invitation block create.');
      }

      return {
        endpoint: 'users/0.1/invitation_blocks/',
        payload: {
          token: extraContext.token,
        },
      };
    },
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class LocalCacheStorage {
  private initPromise?: Promise<void>;
  private _storage: Storage;

  constructor(private storage: Storage) {}

  private init(): Promise<void> {
    if (this.initPromise) {
      return this.initPromise;
    }

    this.initPromise = Promise.resolve().then(async () => {
      this._storage = await this.storage.create();
    });

    return this.initPromise;
  }

  async set(key: string, value: any): Promise<any> {
    await this.init();
    return this._storage.set(key, value);
  }

  async get(key: string): Promise<any> {
    await this.init();
    return this._storage.get(key);
  }

  async remove(key: string): Promise<any> {
    await this.init();
    return this._storage.remove(key);
  }

  async clear(): Promise<void> {
    await this.init();
    return this._storage.clear();
  }
}

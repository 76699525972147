import { registerPlugin } from '@capacitor/core';

interface SwipeNavigationPlugin {
  enable(): void;
  disable(): void;
}

// Disabling lint rule here to enable the below plugin to align with the
// conventions of naming Capacitor plugins.
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SwipeNavigation =
  registerPlugin<SwipeNavigationPlugin>('SwipeNavigation');

import type { MapCoordinates } from '@freelancer/datastore';
import type {
  EnterpriseMetadataValueApi,
  ResourceTypeApi,
} from 'api-typings/resources/metadata';

/**
 * This collection stores the custom fields associated with a resource type (i.e.
 * user or project). A custom field is an arbitrary property that can be attached
 * to a resource, in order to enrich that resource's data model. This property is
 * unique per enterprise, e.g. a Deloitte user may have an internal ID to trace
 * the user back to their system.
 *
 * Each document represents a specific enterprise's custom field configuration.
 *
 * https://phabricator.tools.flnltd.com/w/product/enterprise/custom-fields/
 */
export interface CustomFieldInfoConfiguration {
  /**
   * Refers to enterprise_resource_metadata_field ID in the DB.
   * Equivalent to enterprise_metadata_field_id in Thrift.
   */
  readonly id: number;
  readonly enterpriseId: number;
  readonly customFieldInfo: CustomFieldInfo;
  readonly defaultValue?: FieldValue;
  readonly searchable: boolean;
  // Included it here to easily use for the query
  // It is difficult to query for resource type given that it is part
  // of CustomField
  readonly resourceType: ResourceTypeApi;
  readonly timeCreated?: number;
  readonly timeEnabled?: number;
}

export interface CustomFieldValueUpdatePayload {
  readonly enterprise_metadata_values: readonly CustomFieldValuePayload[];
}

export type CustomFieldValuePayload = Omit<
  EnterpriseMetadataValueApi,
  'id' | 'time_created' | 'is_default'
>;

export enum FieldType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  FLOAT = 'float',
  INTEGER = 'integer',
  TIMESTAMP = 'timestamp',
  LOCATION = 'location',
  OBJECT = 'object',
  UNDEFINED = 'undefined',
}

/** The enterprise-agnostic custom field configuration */
export interface CustomFieldInfo {
  /**
   * Refers to the resource_metadata_field ID in the DB.
   * Equivalent to metadata_field_id in Thrift.
   */
  readonly id: number;
  readonly name: string;
  readonly isArray: boolean;
  readonly description: string;
  readonly resourceType: ResourceTypeApi;
  readonly fieldType: FieldType;
  readonly timeCreated?: number;
  readonly parentId?: number;
}

/**
 * Equivalent to the MetadataValue Thrift type
 */
export type FieldValue =
  | {
      readonly type: FieldType.STRING;
      readonly value: string;
    }
  | {
      readonly type: FieldType.BOOLEAN;
      readonly value: boolean;
    }
  | {
      readonly type: FieldType.FLOAT;
      readonly value: number;
    }
  | {
      readonly type: FieldType.INTEGER;
      readonly value: number;
    }
  | {
      readonly type: FieldType.TIMESTAMP;
      readonly value: number;
    }
  | {
      readonly type: FieldType.LOCATION;
      readonly value: MapCoordinates;
    }
  | {
      readonly type: FieldType.UNDEFINED;
      readonly value: undefined;
    }
  | {
      readonly type: FieldType.OBJECT;
      readonly value?: number;
    };

/**
 * The concrete value of a custom field attached to a resource.
 * Equivalent to the EnterpriseMetadataValue Thrift type.
 */
export type CustomFieldValue = {
  /**
   * Refers to the CustomFieldInfoConfiguration document's ID, i.e. the
   * enterprise_resource_metadata_field ID
   */
  readonly customFieldInfoConfigurationId: number;
  readonly resourceId: number;
  /**
   * Indicates if the CustomFieldValue is a default value for the custom field.
   * Default value is configurable at the backend.
   */
  readonly isDefaultValue: boolean;
  readonly parentId?: number;
  readonly objectGroup?: number;
  readonly parentObjectGroup?: number;
} & FieldValue;

/**
 * Only used for endpoints backend by ElasticSearch, e.g. project and user
 * search. Custom field filters for ES must provide the CustomFieldInfo.id, i.e.
 * the `resource_metadata_field` ID. The customFieldInfoConfigurationId that's
 * usually present on the project or user's `customFieldValues` is a completely
 * different ID - the `enterprise_resource_metadata_field` ID.
 *
 * This type stores an extra customFieldInfoId so that application code can
 * provide the correct ID to pass to ES.
 *
 * The customFieldInfoConfigurationId must also be provided so that UI tests can
 * match the project's custom field values to the search query parameters.
 */
export type CustomFieldValueForSearch = {
  /** Refers to the CustomFieldInfo ID, i.e. the resource_metadata_field ID*/
  readonly customFieldInfoId?: number;
} & CustomFieldValue;

import type { UpgradeType } from '@freelancer/ui/upgrade-tag';
import { transformUpgradeType } from '@freelancer/ui/upgrade-tag';
import { isDefined, toNumber } from '@freelancer/utils';
import { FrontendProjectStatusApi } from 'api-typings/common/common';
import type {
  ProjectApi,
  ProjectsGetResultApi,
} from 'api-typings/projects/projects';
import {
  ProjectStatusApi,
  ProjectTypeApi,
} from 'api-typings/projects/projects';
import { Enterprise } from '../enterprise';
import {
  transformDisplayedLocation,
  transformProjectLocalDetails,
} from '../project-view-projects/project-view-projects.transformers';
import type { ProjectUpgrades } from '../projects/projects.model';
import {
  transformProject,
  transformProjectUpgrades,
} from '../projects/projects.transformers';
import { transformSkill } from '../skills/skills.transformers';
import { transformLocation } from '../users/users-location.transformers';
import type { WebsocketProjectSearchActiveEvent } from './search-active-projects.backend-model';
import type { SearchActiveProject } from './search-active-projects.model';

export function transformSearchActiveProject(
  project: ProjectApi,
  projectOwners?: ProjectsGetResultApi['users'],
): SearchActiveProject {
  if (
    !project.bidperiod ||
    !project.description ||
    !project.jobs ||
    !project.location ||
    !project.time_submitted ||
    !project.upgrades
  ) {
    throw new ReferenceError(`Missing a required project entry field.`);
  }

  // Active Projects = Open Projects
  // TODO: T178616 - Uncomment the below check when a proper fix is found
  // if (project.frontend_project_status !== FrontendProjectStatusApi.OPEN) {
  //   throw new ReferenceError(
  //     `Invalid status for project in active project search`,
  //   );
  // }

  const location = transformLocation(project.location);
  const clientCountry = projectOwners
    ? projectOwners[project.owner_id]?.location?.country?.code
    : undefined;
  const clientReputation = projectOwners
    ? projectOwners[project.owner_id]?.employer_reputation
    : undefined;

  return {
    ...transformProject(project),
    bidCount: project.bid_stats?.bid_count ?? 0,
    bidTimeLeft: transformBidTimeLeft(
      project.bidperiod,
      project.time_submitted,
    ),
    clientCountry,
    clientReputation: clientReputation
      ? {
          rating: clientReputation.entire_history.overall,
          reviews: clientReputation.entire_history.reviews,
        }
      : undefined,
    country: location.country,
    description: project.description,
    displayStartLocation: transformDisplayedLocation(
      project.true_location || project.location,
    ),
    localDetails: project.local_details
      ? transformProjectLocalDetails(project.local_details)
      : undefined,
    maximumBudget: project.budget?.maximum ?? Number.MAX_SAFE_INTEGER,
    minimumBudget: project.budget?.minimum ?? 0,
    projectId: project.id,
    searchCoordinates: location.mapCoordinates,
    skillIds: project.jobs.map(job => job.id),
    skills: project.jobs.map(transformSkill),
    trueLocation: project.true_location
      ? transformLocation(project.true_location)
      : undefined,
    upgradeNames: transformUpgradeNames(
      transformProjectUpgrades(project.upgrades),
    ),
  };
}

export function transformWebsocketSearchActiveProject(
  event: WebsocketProjectSearchActiveEvent,
): SearchActiveProject {
  const bidPeriod = event.data.bidperiod ?? 0;
  const upgrades = {
    assisted: event.data.recruiter,
    featured: event.data.featured,
    fulltime: event.data.fulltime,
    ipContract: event.data.ip_contract,
    listed: event.data.listed,
    NDA: event.data.NDA,
    nonpublic: event.data.nonpublic,
    projectManagement: event.data.project_management,
    qualified: event.data.qualified,
    sealed: event.data.sealed,
    urgent: event.data.urgent,
    extend: event.data.extend,
    pfOnly: event.data.pfOnly,
  };

  return {
    bidPeriod,
    bidStats: {
      bidCount: event.data.bid_stats.bid_count,
      bidAvg: event.data.bid_stats?.bid_avg ?? undefined,
    },
    budget: { minimum: event.data.minbudget, maximum: event.data.maxbudget },
    currency: event.data.currency,
    deleted: event.data.deleted,
    displayStartLocation: transformDisplayedLocation(event.data.location),
    frontendProjectStatus: transformFrontendProjectStatus(
      event.data.subStatus,
      event.data.subStatus,
    ),
    hideBids: event.data.hideBids,
    hireme: event.data.hireme,
    id: event.data.id,
    language: event.data.language,
    local: event.data.isLocal,
    localDetails: event.data.local_details
      ? transformProjectLocalDetails(event.data.local_details)
      : undefined,
    ownerId: toNumber(event.data.userId),
    previewDescription: '',
    projectCollaborations: [],
    seoUrl: event.data.linkUrl.replace('/projects/', ''),
    status: transformProjectStatus(event.data.state),
    timeSubmitted: event.data.time_submitted * 1000,
    title: event.data.title,
    type: event.data.type,
    upgrades,
    poolIds: event.data.pool_ids,
    enterpriseIds: event.data.enterpriseId
      ? [event.data.enterpriseId]
      : undefined,
    isEscrowProject: event.data.isEscrowProject,
    isSellerKycRequired: false,
    isBuyerKycRequired: false,
    isDeloitteProject: event.data.enterpriseId === Enterprise.DELOITTE_DC,
    isTokenProject: event.data.currency.code === 'TKN',
    isInsourceProject:
      event.data.currency.code === 'TKN' &&
      event.data.type === ProjectTypeApi.FIXED,
    selectedBids: [],
    customFieldValues: [],
    jobs: event.data.jobs_details,
    bidCount: event.data.bid_stats.bid_count,
    bidTimeLeft: transformBidTimeLeft(bidPeriod, event.data.time_submitted),
    clientReputation: {
      rating: event.data.overallReputation,
      reviews: event.data.reviews,
    },
    country: event.data.country
      ? {
          id: event.data.country,
          code: event.data.country,
          name: event.data.country,
        }
      : undefined,
    description: event.data.appended_descr,
    maximumBudget: event.data.maxbudget ?? Number.MAX_SAFE_INTEGER,
    minimumBudget: event.data.minbudget ?? 0,
    searchCoordinates:
      isDefined(event.data.longitude) && isDefined(event.data.latitude)
        ? { latitude: event.data.latitude, longitude: event.data.longitude }
        : undefined,
    skillIds: event.data.jobs_details.map(jobDetails => jobDetails.id),
    skills: event.data.jobs_details.map(transformSkill),
    upgradeNames: transformUpgradeNames(transformProjectUpgrades(upgrades)),
    isHpProject: event.data.enterpriseId === Enterprise.HP,
    projectId: event.data.id,
  };
}

export function transformBidTimeLeft(
  bidPeriod: number,
  timeSubmitted: number,
): number {
  const DAY_IN_MILLISECONDS = 86_400_000;
  return timeSubmitted * 1000 + bidPeriod * DAY_IN_MILLISECONDS;
}

export function transformUpgradeNames(
  upgrades: ProjectUpgrades,
): readonly UpgradeType[] {
  return Object.entries(upgrades)
    .filter(([_, value]) => value)
    .map(([upgrade, _]) => transformUpgradeType(upgrade))
    .filter(isDefined);
}

export function transformProjectStatus(state: string): ProjectStatusApi {
  switch (state) {
    case 'A':
      return ProjectStatusApi.ACTIVE;

    case 'C':
      return ProjectStatusApi.CLOSED;

    case 'F':
      return ProjectStatusApi.FROZEN;

    case 'D':
      return ProjectStatusApi.DRAFT;

    case 'P':
      return ProjectStatusApi.PENDING;

    case 'R':
      return ProjectStatusApi.REJECTED;

    default:
      return ProjectStatusApi.ACTIVE;
  }
}

export function transformFrontendProjectStatus(
  state: string | undefined,
  subStatus: string | undefined,
): FrontendProjectStatusApi {
  switch (state) {
    case 'A':
      return FrontendProjectStatusApi.OPEN;

    case 'C':
      if (subStatus === 'closed_awarded') {
        return FrontendProjectStatusApi.WORK_IN_PROGRESS;
      }
      return FrontendProjectStatusApi.COMPLETE;

    case 'R':
    case 'F':
      return FrontendProjectStatusApi.COMPLETE;

    case 'D':
      return FrontendProjectStatusApi.DRAFT;

    case 'P':
      return FrontendProjectStatusApi.PENDING;

    default:
      return FrontendProjectStatusApi.OPEN;
  }
}

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiModule } from '../ui.module';
import { ModalErrorComponent } from './modal-error.component';
import { ModalComponent } from './modal.component';

@NgModule({
  imports: [CommonModule, UiModule, RouterModule, ScrollingModule],
  declarations: [ModalComponent, ModalErrorComponent],
  exports: [ModalComponent],
})
export class ModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiModule } from '@freelancer/ui';
import { NetworkAlertComponent } from './network-alert.component';
import { NetworkBannerComponent } from './network-banner.component';

@NgModule({
  imports: [UiModule, CommonModule],
  declarations: [NetworkAlertComponent, NetworkBannerComponent],
  exports: [NetworkAlertComponent, NetworkBannerComponent],
})
export class NetworkModule {}

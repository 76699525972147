export enum HorizontalAlignment {
  HORIZONTAL_CENTER = 'center',
  HORIZONTAL_RIGHT = 'right',
}

export enum VerticalAlignment {
  VERTICAL_BOTTOM = 'bottom',
  VERTICAL_CENTER = 'center',
  VERTICAL_STRETCH = 'stretch',
}

import type {
  ReactionApi,
  ReactionContextTypeApi,
  ReactionTypeApi,
  ReactionTypeCountApi,
} from 'api-typings/common/common';
import type { ReactionTypeCount } from './reactions-type-count.model';

export function transformReaction(
  reaction: ReactionTypeCountApi,
): ReactionTypeCount {
  return {
    id: transformReactionTypeCountId(
      reaction.context_type,
      reaction.context_id,
      reaction.reaction_type,
    ),
    contextId: reaction.context_id,
    count: reaction.count,
    contextType: reaction.context_type,
    reactionType: reaction.reaction_type,
    hasUserReacted: reaction.has_user_reacted,
  };
}

/**
 * Constructs a composite ID with context_id, context_type, reaction_type.
 *
 */
export function transformReactionTypeCountId(
  contextType: ReactionContextTypeApi,
  contextId: number,
  reactionType: ReactionTypeApi,
): string {
  return `${contextType}_${contextId}_${reactionType}`;
}

/**
 * The following method is responsible for maintaining the
 * reaction count and the hasUserReacted field values.
 *
 * The basic logic:
 * - if hasUserReacted value is true, we just need to set its
 *   value to false and descrease the count.
 * - if hasUserReacted value is false, we just need to set its
 *   value to true and increase the count.
 */
export function transformReactionTypeCount(
  reactionTypeCount: ReactionTypeCount,
): ReactionTypeCount {
  if (reactionTypeCount.hasUserReacted) {
    return {
      ...reactionTypeCount,
      count: reactionTypeCount.count - 1,
      hasUserReacted: false,
    };
  }

  return {
    ...reactionTypeCount,
    count: reactionTypeCount.count + 1,
    hasUserReacted: true,
  };
}

export const transformReactionTypeCountFromWS =
  (react: boolean, sameUser: boolean) =>
  (reactionTypeCount: ReactionTypeCount): ReactionTypeCount => ({
    ...reactionTypeCount,
    count: react ? reactionTypeCount.count + 1 : reactionTypeCount.count - 1,
    hasUserReacted: sameUser ? react : reactionTypeCount.hasUserReacted,
  });

export const transformReactionToReactionTypeCountFromWS =
  (sameUser: boolean) =>
  (reaction: ReactionApi): ReactionTypeCount => ({
    id: transformReactionTypeCountId(
      reaction.context_type,
      reaction.context_id,
      reaction.reaction_type,
    ),
    contextId: reaction.context_id,
    count: 1,
    contextType: reaction.context_type,
    reactionType: reaction.reaction_type,
    hasUserReacted: sameUser,
  });

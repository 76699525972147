import type { OnDestroy, OnInit } from '@angular/core';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Auth } from '@freelancer/auth';
import { LocalStorage } from '@freelancer/local-storage';
import { Pwa } from '@freelancer/pwa';
import { Tracking } from '@freelancer/tracking';
import { ModalService } from '@freelancer/ui';
import {
  Permissions,
  PermissionStateEnum,
  PermissionType,
} from '@freelancer/ui/permissions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Subscription } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {
  NotificationPermissionErrorCode,
  Notifications,
} from './notifications.service';

/**
 * Manage the push notifications registration, i.e. register/unregister on
 * login/logout
 */
@UntilDestroy({ className: 'NotificationsComponent' })
@Component({
  selector: 'fl-notifications',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private notificationRegistrationSubscription?: Subscription;

  constructor(
    private appRef: ApplicationRef,
    private auth: Auth,
    private errorHandler: ErrorHandler,
    private localStorage: LocalStorage,
    private modalService: ModalService,
    private notifications: Notifications,
    private permissions: Permissions,
    private pwa: Pwa,
    private router: Router,
    private tracking: Tracking,
  ) {}

  ngOnInit(): void {
    if (this.notifications.isSupported()) {
      // On logout, unregister push notifications
      this.auth.registerBeforeLogoutAction(() =>
        this.notifications.unregisterPushNotifications(),
      );

      this.notificationRegistrationSubscription = this.auth
        .isLoggedIn()
        .pipe(
          switchMap(async isLoggedIn => {
            // If logged-in, try registering for push notifications
            if (!isLoggedIn) {
              // This closes the permissions request modal if the user is
              // logged out. This may be necessary in the case that the user
              // reinstalls the app but has an expired token in the keychain.
              this.modalService.close();
              return;
            }

            // Permission has not been granted yet, do nothing,
            // registerPushNotifications() will be called from the push
            // notifications banner
            const notificationsPermissions =
              await this.permissions.checkPermissions(
                PermissionType.PUSH_NOTIFICATIONS,
              );
            this.tracking.trackCustomEvent(
              notificationsPermissions,
              'NotificationPermissionTracker',
            );

            switch (notificationsPermissions) {
              case PermissionStateEnum.GRANTED:
                break;
              case PermissionStateEnum.PROMPT:
              case PermissionStateEnum.PROMPT_WITH_RATIONALE:
                // This is to make sure that we don't try to request permission
                // until the app has finished loading/signing in.
                if (
                  this.router.url.startsWith('/login') ||
                  this.router.url.startsWith('/signup') ||
                  this.router.url.startsWith('/internal/auth/callback')
                ) {
                  await firstValueFrom(
                    this.router.events.pipe(
                      filter(
                        event =>
                          event instanceof NavigationEnd &&
                          event.url !== '/internal/pwa' &&
                          event.url !== '/' &&
                          !event.urlAfterRedirects.includes('/new-freelancer'),
                      ),
                      untilDestroyed(this),
                    ),
                  );
                }
                // also wait for the app to stabilise before prompting.
                await firstValueFrom(
                  this.appRef.isStable.pipe(
                    filter(stable => stable),
                    untilDestroyed(this),
                  ),
                );
                break;
              case PermissionStateEnum.DENIED:
              default:
                return;
            }

            // Register push notifications if eligible.
            // In the case we've asked for notifications permissions before
            // but the permission isn't granted or notifications have
            // specifically been disabled by the user, then don't bother
            // attempting to request for it. Otherwise, if it has been granted,
            // continue or we haven't asked for those permissions, then use
            // the usual checks.
            const shouldRegister = await firstValueFrom(
              this.localStorage.get('notificationsRequested').pipe(
                map(notificationsRequested => {
                  const notificationsRequestedButNotGranted =
                    notificationsRequested &&
                    notificationsPermissions !== PermissionStateEnum.GRANTED;

                  // TODO: T279138 Improve notification permission trigger logic
                  return (
                    (this.pwa.isNative() ||
                      this.router.url.startsWith('/dashboard')) &&
                    !notificationsRequestedButNotGranted &&
                    this.notifications.shouldRegisterPushNotifications()
                  );
                }),
                untilDestroyed(this),
              ),
            );

            if (shouldRegister) {
              const registrationResult =
                await this.notifications.registerPushNotifications();
              this.localStorage.set('notificationsRequested', true);
              if (
                registrationResult.status === 'error' &&
                registrationResult.errorCode !==
                  NotificationPermissionErrorCode.PERMISSION_DENIED
              ) {
                // Log the error as this should not happen as we are checking
                // canShowNotification() first
                const errorMsgString = registrationResult.errorMsg
                  ? `, ${registrationResult.errorMsg}`
                  : '';
                this.errorHandler.handleError(
                  new Error(
                    `registerPushNotifications failed with code ${registrationResult.errorCode}${errorMsgString}`,
                  ),
                );
              }
            }
          }),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.notificationRegistrationSubscription) {
      this.notificationRegistrationSubscription.unsubscribe();
    }
  }
}

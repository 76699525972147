import type { CustomFieldSearchFilterApi } from 'api-typings/resources/metadata';

export interface AnalyticsResultAjax {
  readonly id: string; // userId
  readonly metric_type: MetricType;
  readonly enterprise_id?: number;
  readonly custom_fields_filters?: readonly CustomFieldSearchFilterApi[];
  readonly max_submit_date?: string;
  readonly min_submit_date?: string;
  readonly job_ids?: readonly number[];
  readonly pool_ids?: readonly number[];
  readonly audience?: readonly string[];
  readonly result?: number;
  readonly aggregateResult?: {
    readonly totalHits: number;
    readonly buckets: {
      readonly [index: string]: string | number;
    };
  };
}

export interface AnalyticsGetRawPayload {
  readonly metric_type: MetricType;
  readonly enterprise_id?: number;
  readonly custom_fields_filters?: readonly CustomFieldSearchFilterApi[];
  readonly job_ids?: readonly number[];
  readonly pool_ids?: readonly number[];
  readonly audience?: readonly string[];
  readonly max_submit_date?: string;
  readonly min_submit_date?: string;
}

/**
 * Should be the same as in `AnalyticsHandler`
 */
export enum MetricType {
  PROJECTS = 'projects',
  PROJECTS_AWARDED = 'projects_awarded',
  PROJECTS_UNAWARDED = 'projects_unawarded',
  EXTERNAL_PROJECTS_AWARDED = 'external_projects_awarded',
  SPEND = 'spend',
  SPEND_CUMULATIVE_SUM = 'spend_cumulative_sum',
  HOURS_POSTED = 'hours_posted',
  HOURS_POSTED_CUMULATIVE_SUM = 'hours_posted_cumulative_sum',
  AVG_TIME_TO_AWARD = 'avg_time_to_award',
  HIRED_TALENT_LOCATION = 'hired_talent_location',
  NPS_SCORE = 'nps_score',
  BILLABLE = 'billable',
  TALENT = 'talent',
  TALENT_SKILLS = 'talent_skills',
  INTERNAL_USER_SKILLS = 'internal_user_skills',
  USERS_ACTIVE = 'users_active',
  MEAN_BID_AMOUNT = 'mean_bid_amount',
  MEDIAN_BID_AMOUNT = 'median_bid_amount',
  PROJECT_SKILLS = 'project_skills',
  AWARDED_PROJECT_SKILLS = 'awarded_project_skills',
  MEDIAN_BID_COUNT = 'median_bid_count',
}

/** Frontend audience filter options */
export enum ProjectAudienceType {
  /** Not shown as filter option */
  NONE = 'None',
  INTERNAL_ONLY = 'Internal Only',
  INTERNAL_AND_EXTERNAL = 'Internal And External',
}

/** See scripts/elasticsearch/esanalytics/schema/projects.php for ES schema */
export enum ProjectAudienceElasticSearchValue {
  /** Unlinked projects that are ineligible to go external */
  INELIGIBLE = 'ineligible',
  /** Linked project that does not have an active external project */
  INTERNAL = 'internal',
  /** Linked project that has an active external project */
  EXTERNAL = 'external',
}

/** Maps the frontend audience filter options to the backend elastic search fields */
export const AUDIENCE_FILTER_TO_ES_FIELD_VALUES_MAP: {
  readonly [k in ProjectAudienceType]: readonly string[];
} = {
  [ProjectAudienceType.NONE]: [
    ProjectAudienceElasticSearchValue.INELIGIBLE,
    ProjectAudienceElasticSearchValue.INTERNAL,
    ProjectAudienceElasticSearchValue.EXTERNAL,
  ],
  [ProjectAudienceType.INTERNAL_ONLY]: [
    ProjectAudienceElasticSearchValue.INELIGIBLE,
    ProjectAudienceElasticSearchValue.INTERNAL,
  ],
  [ProjectAudienceType.INTERNAL_AND_EXTERNAL]: [
    ProjectAudienceElasticSearchValue.EXTERNAL,
  ],
};

import { Injectable } from '@angular/core';
import type { BackendErrorResponse } from '@freelancer/freelancer-http';
import { Store } from '@ngrx/store';
import type { ErrorCodeApi } from 'api-typings/errors/errors';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import type { RequestDataPayload } from './actions';
import type {
  DatastoreCollectionType,
  DatastoreFetchCollectionType,
  StoreState,
} from './store.model';

interface RequestStatusWrapper {
  readonly request: RequestDataPayload<any>;
  readonly statusObject: RequestStatusWithoutRetry<any>;
}

export type RequestErrorWithoutRetry<
  C extends DatastoreCollectionType & DatastoreFetchCollectionType,
> = Pick<
  // Technically only datastore.document calls return NOT_FOUND, rather than
  // needing to add this to datastore.collection calls too. This type is
  // separate from BackendErrorResponse because NOT_FOUND is added by the
  // datastore internally, rather than originating from the backend.
  BackendErrorResponse<
    C['Backend']['Fetch']['ErrorType'] | ErrorCodeApi.NOT_FOUND
  >,
  'errorCode' | 'requestId'
>;

export interface RequestError<
  C extends DatastoreCollectionType & DatastoreFetchCollectionType,
> extends RequestErrorWithoutRetry<C> {
  retry(): void;
}

export interface RequestStatusWithoutRetry<C extends DatastoreCollectionType> {
  readonly ready: boolean;
  readonly error?: C extends DatastoreFetchCollectionType
    ? RequestErrorWithoutRetry<C>
    : never;
}

export interface RequestStatus<C extends DatastoreCollectionType> {
  readonly ready: boolean;
  readonly error?: C extends DatastoreFetchCollectionType
    ? RequestError<C>
    : never;
}

@Injectable({
  providedIn: 'root',
})
export class RequestStatusHandler {
  private _statusStreamSubject$ = new Subject<{
    readonly [k: string]: RequestStatusWrapper;
  }>();

  constructor(private store$: Store<StoreState>) {}

  get statusStream$(): Observable<RequestStatusWrapper> {
    return this._statusStreamSubject$
      .asObservable()
      .pipe(switchMap(statusStreamMap => Object.values(statusStreamMap)));
  }

  /**
   * Get an observable for the status of a given request
   *
   * @privateRemarks
   *
   * The original request is spread into the payload member to create a new
   * instance of the object which will not get deduped by the `dedupeRequests`
   * handler which only compared object references to determine whether an
   * object is a duplicate or not.
   */
  get$<C extends DatastoreCollectionType>(
    request: RequestDataPayload<C>,
  ): Observable<RequestStatus<C>> {
    const clientRequestIds = new Set(request.clientRequestIds);
    return this._statusStreamSubject$.pipe(
      map(statusStreamMap => statusStreamMap[request.ref.path.collection]),
      filter(statusStream => {
        // Prefilter the status stream to achieve better performance, and it only deals
        // with status related to the collection.
        if (!statusStream) {
          return false;
        }

        // If the stream's client request IDs match any of the IDs in the passed
        // clientRequestIds set, and only emitting the items that match.
        const statusStreamClientRequestIds = new Set(
          statusStream.request.clientRequestIds,
        );
        for (const statusStreamClientRequestId of statusStreamClientRequestIds) {
          if (clientRequestIds.has(statusStreamClientRequestId)) {
            return true;
          }
        }
        return false;
      }),
      map(e => {
        if (e.statusObject.error) {
          return {
            ...e.statusObject,
            error: {
              ...e.statusObject.error,
              retry: () => {
                const action = {
                  type: 'REQUEST_DATA',
                  payload: { ...e.request },
                };
                this.store$.dispatch(action);
                this.update(e.request, { ready: false });
              },
            },
          } as RequestStatus<C>;
        }
        // FIXME: T267853 - Remove cast
        return e.statusObject as RequestStatus<C>;
      }),
    );
  }

  update<C extends DatastoreCollectionType>(
    request: RequestDataPayload<C>,
    status: RequestStatusWithoutRetry<C>,
  ): void {
    this._statusStreamSubject$.next({
      [request.ref.path.collection]: {
        request,
        statusObject: status,
      },
    });
  }
}

export function requestStatusesEqual<C extends DatastoreCollectionType>(
  a: RequestStatus<C>,
  b: RequestStatus<C>,
): boolean {
  return (
    // Plain ready flag, no error
    (a.ready === b.ready && !a.error && !b.error) ||
    // Not ready, error codes equal
    (!a.ready &&
      !b.ready &&
      !!a.error &&
      !!b.error &&
      a.error.errorCode === b.error.errorCode)
  );
}
/**
 * Derives the status of multiple collections, usually compounded via Rx.combineLatest.
 * This function can determine the loading and error state for the frontend.
 *
 * @param statuses Array of collection statuses, usually from collection.status$
 */
export function deriveCollectionsStatus(
  statuses: readonly RequestStatus<any>[],
): RequestStatus<any> {
  return (
    statuses.find(status => !status.ready || status.error) || {
      ready: true,
    }
  );
}

export enum UpgradeTagSize {
  MID = 'mid',
  SMALL = 'small',
}

/** cart subtype IDs for project upgrade items */
export enum UpgradeSubType {
  RECRUITER = 15,
  EXTEND = 17,
  URGENT = 5,
  NONPUBLIC = 3,
  HIDEBIDS = 2,
  FULLTIME = 4,
  NDA = 7,
  IP_CONTRACT = 10,
  SUCCESS_BUNDLE = 11,
  NON_COMPETE = 12,
  TECHNICAL_COPILOT = 13,
  PF_ONLY = 14,
  FEATURED = 1,
  NOT_FOUND = -1,
}

export enum UpgradeType {
  ASSISTED = 'assisted',
  CUSTOM_JUDGING = 'custom judging',
  EXTENDED = 'extended',
  FEATURED = 'featured',
  FULL_TIME = 'full time',
  GUARANTEED = 'guaranteed',
  HIGHLIGHT = 'highlight',
  IP_AGREEMENT = 'ip agreement',
  NDA = 'nda',
  PRIVATE = 'private',
  TECHNICAL_COPILOT = 'technical co-pilot',
  SEALED = 'sealed',
  TOP_CONTEST = 'top contest',
  URGENT = 'urgent',
  QUALIFIED = 'qualified',
  PREMIUM = 'premium',
  ENTERPRISE = 'enterprise',
}

export enum UpgradeName {
  // not using ASSISTED please, is deprecated
  ASSISTED = 'Recruiter',
  CUSTOM_JUDGING = 'Custom Judging',
  RECRUITER = 'Recruiter',
  EXTENDED = 'Extended',
  FEATURED = 'Featured',
  FULL_TIME = 'Full Time',
  GUARANTEED = 'Guaranteed',
  HIGHLIGHT = 'Highlight',
  IP_AGREEMENT = 'IP Agreement',
  NDA = 'NDA',
  PRIVATE = 'Private',
  TECHNICAL_COPILOT = 'Technical Co-pilot',
  SEALED = 'Sealed',
  TOP_CONTEST = 'Top Contest',
  URGENT = 'Urgent',
  QUALIFIED = 'Qualified',
  PREMIUM = 'Premium',
  ENTERPRISE = 'Enterprise',
}

/** cart subtype IDs for bid upgrade items */
export enum BidUpgradeSubType {
  SPONSORED = 1,
  HIGHLIGHT = 2,
  SEALED = 3,
}

export enum BidUpgradeName {
  SPONSORED = 'Sponsored',
  HIGHLIGHT = 'Highlight',
  SEALED = 'Sealed',
}

export enum BidUpgradeType {
  SPONSORED = 'sponsored',
  HIGHLIGHT = 'highlight',
  SEALED = 'sealed',
}

/** cart context subtype IDs for contest entry upgrade items */
export enum EntryUpgradeSubType {
  SEALED = 0,
  HIGHLIGHT = 1,
}

export enum EntryUpgradeName {
  HIGHLIGHT = 'Highlight',
  SEALED = 'Sealed',
}

export enum EntryUpgradeType {
  HIGHLIGHT = 'highlight',
  SEALED = 'sealed',
}

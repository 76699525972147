import type { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';
import type {
  ExpiringSubscription,
  WebsocketMessage,
  WebsocketServerEvent,
  WebsocketServerEventTParam,
} from './server-event.model';

export function isWebsocketMessage<C>(
  event: WebsocketServerEvent<WebsocketServerEventTParam>,
): event is WebsocketMessage<C> {
  return event.channel === 'user';
}

export function filterWebsocketMessage<C>(): OperatorFunction<
  WebsocketServerEvent<WebsocketServerEventTParam>,
  WebsocketMessage<C>
> {
  return filter<
    WebsocketServerEvent<WebsocketServerEventTParam>,
    WebsocketMessage<C>
  >(isWebsocketMessage);
}

export function isWebsocketSubscriptionExpiring(
  event: WebsocketServerEvent<WebsocketServerEventTParam>,
): event is ExpiringSubscription {
  return (
    event.channel === 'subscribe' &&
    typeof event.body === 'object' &&
    'state' in event.body &&
    event.body.state === 'expiring'
  );
}

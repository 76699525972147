import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type {
  UserCustomEmailCreateResultApi,
  UserCustomEmailTemplateDataApi,
} from 'api-typings/users/users';
import type { UserCustomEmailTemplateDataListType } from './user-custom-emails.model';
import type { UserCustomEmailsCollection } from './user-custom-emails.types';

export function userCustomEmailsBackend(): Backend<UserCustomEmailsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: undefined,
    push: (authUid, userCustomEmailData) => ({
      endpoint: 'users/0.1/user_custom_email/',
      payload: {
        receiving_user_email: userCustomEmailData.receivingUserEmail,
        email_template: userCustomEmailData.emailTemplate,
        context: userCustomEmailData.context,
        context_id: userCustomEmailData.contextId,
        email_subject: userCustomEmailData.emailSubject,
        cc_emails: userCustomEmailData.ccEmails,
        email_template_data_list: userCustomEmailData.emailTemplateDataList
          ? transformEmailTemplateDataListToApi(
              userCustomEmailData.emailTemplateDataList,
            )
          : undefined,
      },
      extractId: (result: UserCustomEmailCreateResultApi) =>
        result.user_custom_email.id,
    }),
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}

export function transformEmailTemplateDataListToApi(
  templateDataList: UserCustomEmailTemplateDataListType,
): readonly UserCustomEmailTemplateDataApi[] {
  const templateDataListApi = Object.entries(templateDataList).map(
    ([templateDataKey, value]) => {
      return {
        data_name: templateDataKey,
        value: value ?? '', // Backend expects all values to be strings
      };
    },
  );

  return templateDataListApi;
}

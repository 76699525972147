import { isEqual, sameElements } from '@freelancer/utils';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Emits a list of element such that the elements won't change
 * but their values will.
 *  - New elements won't be added.
 *  - Removed elements will stay
 *  - Changed elements will have their values changed.
 *
 * Elements are indexed by their `id`.
 *
 * The list will emit the latest value when the `notifier$` emits.
 */
export function emitChangesOnRequest<S, K, T extends { readonly id: K }, N>(
  mapper: (x: S) => readonly T[],
  notifier$: Observable<N>,
  emitSelector: (x: S, y: N) => boolean,
): (source$: Observable<S>) => Observable<{
  readonly values: readonly T[];
  readonly hasChanges: boolean;
}> {
  return (source$: Observable<S>) =>
    new Observable<{
      readonly values: readonly T[];
      readonly hasChanges: boolean;
    }>(observer => {
      /** The value that will be emitted from the stream */
      let currentValue: readonly T[] | undefined;

      /** The value that will be emitted from the stream when the notified next emits  */
      let nextValue: readonly T[] | undefined;

      let notifierValue: N | undefined;
      let sourceValue: S | undefined;

      let sourceAndNotifierMatch = false;

      const notifierSub = notifier$.subscribe({
        next(notifier) {
          notifierValue = notifier;

          if (sourceValue !== undefined) {
            sourceAndNotifierMatch = emitSelector(sourceValue, notifierValue);
          }

          currentValue = nextValue;
          if (currentValue) {
            observer.next({ values: currentValue, hasChanges: false });
          }
        },
      });

      const sourceSub = source$.subscribe({
        next(source) {
          sourceValue = source;
          const newValue = mapper(source);
          const previousSourceAndNotifierMatch = sourceAndNotifierMatch;

          if (notifierValue !== undefined) {
            sourceAndNotifierMatch = emitSelector(sourceValue, notifierValue);
          }

          /**
           * We update either on the very first emitted value,
           * or if the source and notifier match for the first time.
           */
          currentValue =
            currentValue === undefined ||
            (!previousSourceAndNotifierMatch && sourceAndNotifierMatch)
              ? newValue
              : sameObjects(currentValue, newValue);

          nextValue = newValue;
          const hasChanges = !sameElements(
            currentValue.map(x => x.id),
            nextValue.map(x => x.id),
          );

          observer.next({ values: currentValue, hasChanges });
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return new Subscription(() => {
        notifierSub.unsubscribe();
        sourceSub.unsubscribe();
      });
    }).pipe(distinctUntilChanged(isEqual)); // FIXME: T267853 - Why doesn't arrayIsShallowEqual work?
}

export function sameObjects<K, T extends { readonly id: K }>(
  currentValues: readonly T[],
  newValues: readonly T[],
): readonly T[] {
  return currentValues.map(
    currentValue =>
      newValues.find(newValue => newValue.id === currentValue.id) ||
      currentValue,
  );
}

export enum SpinnerType {
  DEFAULT = 'default',
  BIRD = 'bird',
}

export enum SpinnerSize {
  SMALL = 'small',
  MID = 'mid',
  LARGE = 'large',
}

export enum SpinnerBackgroundColor {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum SpinnerColor {
  PRIMARY = 'primary',
  PINK = 'pink',
  GRAY = 'gray',
  LIGHT = 'light',
}

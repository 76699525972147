import {
  type Backend,
  OrderByDirection,
  getQueryParamValue,
} from '@freelancer/datastore/core';
import type { ServiceOfferingShopCategoryServicesCollection } from './service-offering-shop-category-services.types';

export function serviceOfferingShopCategoryServicesBackend(): Backend<ServiceOfferingShopCategoryServicesCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.DESC,
    },
    fetch: (_authUid, ids, query, _order) => {
      const shopId = getQueryParamValue(query, 'shopId')[0];
      const categoryId = getQueryParamValue(query, 'categoryId')[0];

      if (!shopId || !categoryId) {
        throw new Error(
          'Shop ID and Category ID must be provided to fetch category services',
        );
      }

      const categoryServiceId = ids && ids.length === 1 ? ids[0] : undefined;
      const endpoint = categoryServiceId
        ? `service_offerings/0.1/service_offerings/shops/${shopId}/categories/${categoryId}/services/${categoryServiceId}`
        : `service_offerings/0.1/service_offerings/shops/${shopId}/categories/${categoryId}/services/`;

      return {
        endpoint,
        params: {
          ids,
          limit: query?.searchQueryParams.limit,
          offset: query?.searchQueryParams.offset,
        },
      };
    },
    push: (_authUid, categoryServiceData) => ({
      endpoint: `service_offerings/0.1/service_offerings/shops/${categoryServiceData.shopId}/categories/${categoryServiceData.categoryId}/services/`,
      method: 'POST',
      payload: {
        // Only push the service offering id and category id, the other fields are populated from the service offering/quotes API.
        category_service: {
          service_offering_id: categoryServiceData.serviceOfferingId,
          category_id: categoryServiceData.categoryId,
        },
      },
    }),
    remove: (authUid, id, originalDocument) => ({
      endpoint: `service_offerings/0.1/service_offerings/shops/${originalDocument.shopId}/categories/${originalDocument.categoryId}/services/${originalDocument.id}`,
      method: 'DELETE',
      payload: {},
    }),
    set: undefined,
    update: undefined,
  };
}

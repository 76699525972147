import type { SchedulerLike, Observable } from 'rxjs';
import { asyncScheduler, timer, throwError } from 'rxjs';
import { retry } from 'rxjs/operators';

export interface RetryRequestsConfig {
  // Time to first retry
  readonly initialInterval: number;
  // Maximum number of retry attempts
  readonly maxRetries?: number;
  readonly scheduler?: SchedulerLike;
  // Determines how often retries occur - by default this is a constant function
  // equal to the initialInterval
  intervalFn?(iteration: number, initialInterval: number): number;
}

/**
 * Returns an Observable that mirrors the source Observable with the exception
 * of an error. If the source Observable calls error, rather than propagating
 * the error call this method will resubscribe to the source Observable with
 * a given interval and up to a maximum of count resubscriptions (if provided).
 *
 * Mostly replicates https://github.com/alex-okrushko/backoff-rxjs
 */
export function retryRequests(
  config: RetryRequestsConfig,
): <T>(source$: Observable<T>) => Observable<T> {
  const {
    initialInterval,
    maxRetries = Infinity,
    scheduler = asyncScheduler,
    intervalFn = (i: number, initInterval: number) => initInterval,
  } = config;
  return <T>(source$: Observable<T>) =>
    source$.pipe(
      retry({
        delay: (error, retryCount) =>
          retryCount <= maxRetries
            ? // eslint-disable-next-line local-rules/validate-timers
              timer(intervalFn(retryCount, initialInterval), scheduler)
            : throwError(() => error),
      }),
    );
}

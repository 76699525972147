import { InjectionToken } from '@angular/core';
import type { SchedulerLike } from 'rxjs';

export const WEBSOCKET_TEST_SCHEDULER = new InjectionToken<SchedulerLike>(
  'WebsocketTestScheduler',
);

export const RECONNECT_CONFIG = new InjectionToken<SchedulerLike>(
  'Reconnect Configuration',
);

export const RANDOM_NUMBER_PROVIDER = new InjectionToken<SchedulerLike>(
  'Random Number Provider',
);

export enum OnMessageTrackedEventTypes {
  MESSAGES_PRIVATE = 'messages-private',
}

export const ON_MESSAGE_TRACKING_CONFIG: {
  readonly [index: string]: boolean;
} = {
  [OnMessageTrackedEventTypes.MESSAGES_PRIVATE]: false,
};

export const WEBSOCKET_BATCH_WINDOW = 1000;

import type { Backend } from '@freelancer/datastore/core';
import {
  getQueryParamValue,
  OrderByDirection,
} from '@freelancer/datastore/core';
import type { CONTEST_UPGRADES } from '@freelancer/search-filters';
import { assertNever, isDefined } from '@freelancer/utils';
import { SearchTypeApi } from 'api-typings/common/common';
import { SearchFiltersContestUpgradeApi } from 'api-typings/users/users';
import type { SearchSavedFiltersPostPayload } from './search-saved-filters.backend-model';
import {
  isSearchSavedFiltersContest,
  isSearchSavedFiltersLoad,
  isSearchSavedFiltersProject,
  isSearchSavedFiltersUser,
} from './search-saved-filters.model';
import type { SearchSavedFiltersCollection } from './search-saved-filters.types';

export function searchSavedFiltersBackend(): Backend<SearchSavedFiltersCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.DESC,
    },
    fetch: (_authUid, _ids, query, _order) => ({
      endpoint: 'users/0.1/search/saved_filters',
      params: {
        type: getQueryParamValue(query, 'type')[0],
      },
    }),
    push: (_authUid, savedFilters) => {
      const { filters, name, type } = savedFilters;

      let payload: SearchSavedFiltersPostPayload = {
        name,
        type,
      };

      switch (type) {
        case SearchTypeApi.PROJECT:
          if (isSearchSavedFiltersProject(filters)) {
            payload = {
              ...payload,
              client_country_codes: filters.clientCountryCodes,
              country_code: filters.countryCode,
              full_address: filters.location?.fullAddress,
              keyword: filters.keyword,
              language_codes: filters.languageCodes,
              latitude: filters.location?.latitude,
              longitude: filters.location?.longitude,
              max_fixed_price: filters.maxFixedPrice,
              max_hourly_rate: filters.maxHourlyRate,
              min_fixed_price: filters.minFixedPrice,
              min_hourly_rate: filters.minHourlyRate,
              pool_ids: filters.poolIds,
              project_types: filters.projectTypes,
              skill_ids: filters.skillIds,
              sorting_option: filters.sortingOption,
              upgrades: filters.upgrades,
            };
          }
          break;

        case SearchTypeApi.USER:
          if (isSearchSavedFiltersUser(filters)) {
            payload = {
              ...payload,
              country_codes: filters.countryCodes,
              insignia_ids: filters.insigniaIds,
              is_online: filters.isOnline,
              job_ids: filters.skillIds,
              keyword: filters.keyword,
              max_hourly_rate: filters.maxHourlyRate,
              max_review_count: filters.maxReviewCount,
              min_hourly_rate: filters.minHourlyRate,
              min_review_count: filters.minReviewCount,
              min_star_rating: filters.minStarRating,
              pool_ids: filters.poolIds,
            };
          }
          break;

        case SearchTypeApi.LOAD:
          if (isSearchSavedFiltersLoad(filters)) {
            payload = {
              ...payload,
              keyword: filters.keyword,
              start_location_administrative_area_codes:
                filters.startLocationAdministrativeAreaCodes,
              end_location_administrative_area_codes:
                filters.endLocationAdministrativeAreaCodes,
              skill_ids: filters.skillIds,
              equipment_ids: filters.equipmentIds,
              start_operating_area_ids: filters.startOperatingAreaIds,
              end_operating_area_ids: filters.endOperatingAreaIds,
              max_width: filters.maxWidth,
              max_length: filters.maxLength,
              min_weight: filters.minWeight,
              max_weight: filters.maxWeight,
            };
          }
          break;

        case SearchTypeApi.CONTEST:
          if (isSearchSavedFiltersContest(filters)) {
            payload = {
              ...payload,
              keyword: filters.keyword,
              max_prize: filters.maxPrize,
              min_prize: filters.minPrize,
              skill_ids: filters.skillIds,
              sorting_option: filters.sortingOption,
              upgrades: filters.upgrades
                ?.map(upgrade =>
                  transformToSearchFiltersContestUpgradeApi(upgrade),
                )
                .filter(isDefined),
            };
          }
          break;

        default:
          assertNever(type);
      }

      return {
        endpoint: 'users/0.1/search/saved_filters',
        method: 'POST',
        payload,
        extractId: response => response.saved_search.id,
      };
    },
    remove: (_authUid, id, originalDocument) => ({
      endpoint: 'users/0.1/search/saved_filters',
      method: 'DELETE',
      params: {
        id,
        type: originalDocument.type,
      },
      payload: {},
    }),
    set: undefined,
    update: undefined,
  };
}

function transformToSearchFiltersContestUpgradeApi(
  upgrade: CONTEST_UPGRADES,
): SearchFiltersContestUpgradeApi | undefined {
  switch (upgrade) {
    case 'featured': {
      return SearchFiltersContestUpgradeApi.FEATURED;
    }
    case 'sealed': {
      return SearchFiltersContestUpgradeApi.SEALED;
    }
    case 'highlight': {
      return SearchFiltersContestUpgradeApi.HIGHLIGHT;
    }
    case 'guaranteed': {
      return SearchFiltersContestUpgradeApi.GUARANTEED;
    }
    case 'topContest': {
      return SearchFiltersContestUpgradeApi.TOP_CONTEST;
    }
    case 'NDA': {
      return SearchFiltersContestUpgradeApi.NDA;
    }
    case 'urgent': {
      return SearchFiltersContestUpgradeApi.URGENT;
    }
    default: {
      // Return undefined instead of assertNever so webapp build doesn't break
      // whenever new contest upgrade is added to thrift.
      return undefined;
    }
  }
}

import type {
  CollectionActions,
  CollectionStateSlice,
} from '@freelancer/datastore/core';
import {
  mergeDocuments,
  transformIntoDocuments,
} from '@freelancer/datastore/core';
import { transformOpenGraphDetails } from './open-graph-details.transformers';
import type { OpenGraphDetailsCollection } from './open-graph-details.types';

export function openGraphDetailsReducer(
  state: CollectionStateSlice<OpenGraphDetailsCollection> = {},
  action: CollectionActions<OpenGraphDetailsCollection>,
): CollectionStateSlice<OpenGraphDetailsCollection> {
  switch (action.type) {
    case 'API_FETCH_SUCCESS':
      if (action.payload.type === 'openGraphDetails') {
        const { result, ref, order } = action.payload;
        return mergeDocuments<OpenGraphDetailsCollection>(
          state,
          transformIntoDocuments([result], transformOpenGraphDetails),
          order,
          ref,
        );
      }
      return state;

    default:
      return state;
  }
}

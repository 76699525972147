import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimateOnScrollDirective } from './animate-on-scroll/animate-on-scroll.directive';
import { AutoGrowDirective } from './auto-grow/auto-grow.directive';
import { ClickDirective } from './click/click.directive';
import { ControlValueAccessorDirective } from './control-value-accessor/control-value-accessor.directive';
import { HideDirective } from './hide/hide.directive';
import { NativeCopyDirective } from './native-copy/native-copy.directive';
import { OutsideClickDirective } from './outside-click/outside-click.directive';
import { RepeatDirective } from './repeat/repeat.directive';
import { TaggingDirective } from './tagging/tagging.directive';
import { VisibilityDirective } from './visibility/visibility.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AnimateOnScrollDirective,
    AutoGrowDirective,
    ClickDirective,
    ControlValueAccessorDirective,
    HideDirective,
    NativeCopyDirective,
    RepeatDirective,
    TaggingDirective,
    VisibilityDirective,
    OutsideClickDirective,
  ],
  exports: [
    AnimateOnScrollDirective,
    AutoGrowDirective,
    ClickDirective,
    ControlValueAccessorDirective,
    RepeatDirective,
    HideDirective,
    NativeCopyDirective,
    TaggingDirective,
    VisibilityDirective,
    OutsideClickDirective,
  ],
})
export class DirectivesModule {}

import { HttpErrorResponse } from '@angular/common/http';
import type { Renderer2 } from '@angular/core';
import { Injectable } from '@angular/core';
import { SvgService, cloneSvg } from '@freelancer/svg-service';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private svgService: SvgService) {}

  /**
   * Get an icon SVG element
   *
   * @param {string} url The URL of the SVG element
   * @param {string} fallback The alt text to display if the URL is unfetchable
   * @returns {SVGElement} The SVG element.
   *
   * @memberof IconService
   */
  getIcon(
    url: string,
    renderer: Renderer2,
    fallback: string,
  ): Observable<Element> {
    const cachedIcon = this.svgService.getCacheSvg(url);
    if (cachedIcon) {
      return of(cloneSvg(cachedIcon));
    }

    return this.svgService.fetchSvg(url).pipe(
      map(markup => {
        const svgElement = this.svgService.parseSvgMarkup(markup, renderer);
        this.svgService.setCacheSvg(url, svgElement);
        return cloneSvg(svgElement);
      }),
      catchError((err: Error) => {
        // put a placeholder for network errors
        if (err instanceof HttpErrorResponse) {
          console.error('Failed to load icon', err.message);
          return of(this.createPlaceholder(renderer, fallback));
        }
        throw err;
      }),
    );
  }

  /** Creates a placeholder text node for when svgs aren't loadable */
  private createPlaceholder(
    renderer: Renderer2,
    fallback: string,
  ): HTMLDivElement {
    const div = renderer.createElement('div') as HTMLDivElement;
    div.setAttribute('title', fallback);
    renderer.setStyle(div, 'height', '100%');
    renderer.setStyle(div, 'width', '100%');
    renderer.setStyle(div, 'background-color', '#dedede');
    renderer.setStyle(div, 'border-radius', '50%');
    return div;
  }
}

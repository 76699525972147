import { Injectable } from '@angular/core';
import { Localization } from '@freelancer/localization';
import { formatDistance, formatDistanceStrict } from 'date-fns';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';

interface StrictOptions {
  readonly addSuffix?: boolean;
  readonly unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  readonly roundingMethod?: 'floor' | 'ceil' | 'round';
  readonly locale?: Locale;
}

interface Options {
  includeSeconds?: boolean;
  addSuffix?: boolean;
  locale?: Locale;
}

@Injectable()
export class LocalizedDateFns {
  localePromise: Promise<Locale | undefined>;

  constructor(private localization: Localization) {
    const navigatorLanguage =
      typeof navigator !== 'undefined' && navigator?.language;
    switch (this.localization.languageCode) {
      case 'af':
        this.localePromise = import('date-fns/locale/af').then(m => m.default);
        break;
      case 'bn':
        this.localePromise = import('date-fns/locale/bn').then(m => m.default);
        break;
      case 'ca':
        this.localePromise = import('date-fns/locale/ca').then(m => m.default);
        break;
      case 'cs':
        this.localePromise = import('date-fns/locale/cs').then(m => m.default);
        break;
      case 'da':
        this.localePromise = import('date-fns/locale/da').then(m => m.default);
        break;
      case 'de':
        this.localePromise = import('date-fns/locale/de').then(m => m.default);
        break;
      case 'es':
        this.localePromise = import('date-fns/locale/es').then(m => m.default);
        break;
      case 'el':
        this.localePromise = import('date-fns/locale/el').then(m => m.default);
        break;
      case 'fi':
        this.localePromise = import('date-fns/locale/fi').then(m => m.default);
        break;
      // FIXME: T267853 - locale 'fil' isn't available
      // case 'fil':
      //   this.locale = fil;
      //   break;
      case 'fr':
        this.localePromise = import('date-fns/locale/fr').then(m => m.default);
        break;
      case 'hi':
        this.localePromise = import('date-fns/locale/hi').then(m => m.default);
        break;
      case 'hu':
        this.localePromise = import('date-fns/locale/hu').then(m => m.default);
        break;
      case 'id':
        this.localePromise = import('date-fns/locale/id').then(m => m.default);
        break;
      case 'it':
        this.localePromise = import('date-fns/locale/it').then(m => m.default);
        break;
      case 'ja':
        this.localePromise = import('date-fns/locale/ja').then(m => m.default);
        break;
      case 'ko':
        this.localePromise = import('date-fns/locale/ko').then(m => m.default);
        break;
      case 'ms':
        this.localePromise = import('date-fns/locale/ms').then(m => m.default);
        break;
      case 'nb':
        this.localePromise = import('date-fns/locale/nb').then(m => m.default);
        break;
      case 'nl':
        this.localePromise = import('date-fns/locale/nl').then(m => m.default);
        break;
      case 'pl':
        this.localePromise = import('date-fns/locale/pl').then(m => m.default);
        break;
      case 'pt':
        this.localePromise = import('date-fns/locale/pt').then(m => m.default);
        break;
      case 'ro':
        this.localePromise = import('date-fns/locale/ro').then(m => m.default);
        break;
      case 'sl':
        this.localePromise = import('date-fns/locale/sl').then(m => m.default);
        break;
      // FIXME: T267853 - locale 'sq' isn't available
      // case 'sq':
      // this.locale = sq;
      //   break;
      case 'sv':
        this.localePromise = import('date-fns/locale/sv').then(m => m.default);
        break;
      // FIXME: T267853 - locale 'sw' isn't available
      // case 'sw':
      // this.locale = sw;
      //   break;
      case 'th':
        this.localePromise = import('date-fns/locale/th').then(m => m.default);
        break;
      case 'tr':
        this.localePromise = import('date-fns/locale/tr').then(m => m.default);
        break;
      case 'uk':
        this.localePromise = import('date-fns/locale/en-GB').then(
          m => m.default,
        );
        break;
      case 'vi':
        this.localePromise = import('date-fns/locale/vi').then(m => m.default);
        break;
      case 'zh':
        this.localePromise = import('date-fns/locale/zh-CN').then(
          m => m.default,
        );
        break;
      case 'en':
        switch (navigatorLanguage) {
          case 'en-AU':
            this.localePromise = import('date-fns/locale/en-AU').then(
              m => m.default,
            );
            break;
          case 'en-CA':
            this.localePromise = import('date-fns/locale/en-CA').then(
              m => m.default,
            );
            break;
          case 'en-GB':
            this.localePromise = import('date-fns/locale/en-GB').then(
              m => m.default,
            );
            break;
          case 'en-IN':
            this.localePromise = import('date-fns/locale/en-IN').then(
              m => m.default,
            );
            break;
          case 'en-NZ':
            this.localePromise = import('date-fns/locale/en-NZ').then(
              m => m.default,
            );
            break;
          case 'en-ZA':
            this.localePromise = import('date-fns/locale/en-ZA').then(
              m => m.default,
            );
            break;
          default:
            this.localePromise = import('date-fns/locale/en-US').then(
              m => m.default,
            );
            break;
        }
        break;
      default:
        this.localePromise = Promise.resolve(undefined);
        console.error(
          `Locale ${this.localization.languageCode} isn't configure for date-fns, not all dates will be localized`,
        );
        break;
    }
  }

  /**
   * Returns the format used to display numeric longform (day + month + year) dates.
   * Changes based on the locale.
   */
  get dateFormat(): Promise<string> {
    return this.localePromise.then(locale =>
      locale?.formatLong
        ? locale.formatLong.date({ width: 'short' })
        : 'MM/dd/yyyy',
    );
  }

  /**
   * Returns the format used to display numeric longform (day + month + year) date ranges
   * Changes based on the locale.
   */
  get dateRangeFormat(): Promise<string> {
    return this.localePromise.then(locale => {
      const single = locale?.formatLong
        ? locale.formatLong.date({ width: 'short' })
        : 'MM/dd/yyyy';
      return `${single} - ${single}`;
    });
  }

  /**
   * Returns a list of localised months, if no locale is set English months are
   * returned.
   */
  getLocalisedMonths(): Observable<readonly string[]> {
    return from(
      this.localePromise.then(locale =>
        locale?.localize
          ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month =>
              locale.localize?.month(month),
            )
          : [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
      ),
    );
  }

  formatDistance(
    date: Date | number,
    baseDate: Date | number,
    options?: Options,
  ): Promise<string> {
    return this.localePromise.then(locale =>
      formatDistance(date, baseDate, {
        ...options,
        locale,
      }),
    );
  }

  formatDistanceStrict(
    date: Date | number,
    baseDate: Date | number,
    options?: StrictOptions,
  ): Promise<string> {
    return this.localePromise.then(locale =>
      formatDistanceStrict(date, baseDate, {
        ...options,
        locale,
      }),
    );
  }
}

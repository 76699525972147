import type { ComponentRef } from '@angular/core';
import { Injectable } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import type { Timer } from '@freelancer/time-utils';
import { TimeUtils } from '@freelancer/time-utils';
import type { ViewHeaderTemplateConfig } from '@freelancer/view-header-template';
import { ViewHeaderTemplate } from '@freelancer/view-header-template';

// /!\ CONSULT WITH UI-ENG OR FRONTEND INFRA FIRST BEFORE MODFYING THIS FILE /!\

interface DetachedState {
  readonly handle: DetachedRouteHandle;
  readonly headerConfig?: ViewHeaderTemplateConfig;
  readonly timeout: Timer;
}

/**
 * A route reuse strategy that caches routes that have the `cacheOnNavigation` set to `true`.
 * The route will be cached for 15 minutes or until the route is reattached by Angular.
 *
 * The cache works by allowing angular to detach the route and store it in memory.
 * This means that a component's `ngOnDestory` lifecycle hook is not triggered while the route is cached.
 * A detached component also means that it is still running in the background.
 * If your route/component has any of the following properties:
 * - Anything running continuously such as `setInterval` or `setTimeout` functions
 * - Subscriptions to observables that can deterioriate performance
 * - A high memory usage, especially in its cached state
 * Then caching the route will not be a good idea.
 *
 * Implementation details:
 * Angular API Reference: https://angular.io/api/router/RouteReuseStrategy
 * Parts of the implementation based here: https://github.com/angular/angular/issues/13869#issuecomment-441054267
 * For every redirect, the order of operations is as follows:
 * shouldDetach -> store -> shouldAttach -> retrieve -> store(null)
 *
 * shouldDetach - Called to check if the view in the current route should be cached.
 * store - Called to store the detached view.
 * shouldAttach - Called to check if the view in the route can be reattached.
 * retrieve - Called to retrieve the stored view.
 */
@Injectable({
  providedIn: 'root',
})
export class FlRouteReuseStrategy implements RouteReuseStrategy {
  private static CACHE_TIMEOUT_MS = 1000 * 60 * 15; // 15 minutes

  private readonly handlerStore = new Map<string, DetachedState>();

  constructor(
    private readonly viewHeaderTemplate: ViewHeaderTemplate,
    private readonly time: TimeUtils,
  ) {}

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.cacheOnNavigation ?? false;
  }

  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null,
  ): void {
    const key = this.getStoreKey(route);

    if (!handle) {
      this.clearCache(key, false);
      return;
    }

    const headerConfig = this.viewHeaderTemplate.clearHeader();
    this.handlerStore.set(key, {
      handle,
      headerConfig,
      timeout: this.time.setTimeout(
        () => this.clearCache(key),
        FlRouteReuseStrategy.CACHE_TIMEOUT_MS,
      ),
    });
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.handlerStore.has(this.getStoreKey(route));
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const key = this.getStoreKey(route);
    const cached = this.handlerStore.get(key);

    if (cached?.headerConfig) {
      // Swap in the cached header config
      this.viewHeaderTemplate.clearHeader();
      this.viewHeaderTemplate.registerHeader(cached.headerConfig);
    }

    return cached?.handle ?? null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private getStoreKey(route: ActivatedRouteSnapshot): string {
    return `/${route.pathFromRoot
      .map((el: ActivatedRouteSnapshot) => el.routeConfig?.path)
      .filter(str => !!str)
      .join('/')}`;
  }

  /**
   * Removes the cached view for the route.
   *
   * @param key
   * @param destroyView If the view's destroy lifecycle should also be triggered.
   */
  private clearCache(key: string, destroyView = true): void {
    const cached = this.handlerStore.get(key);

    this.handlerStore.delete(key);

    // Clear any lingering timeouts
    clearTimeout(cached?.timeout);

    /**
     * Forcefully expose the componentRef to be able to destroy the component.
     *
     * This is a hacky way to destroy the component, but it's the only way to do it at the moment.
     * It's an issue in Angular where the componentRef is not exposed to the public API.
     * and has already been brought up in the Angular repo multiple times.
     * https://github.com/angular/angular/issues/16713#issuecomment-323090623
     * https://github.com/angular/angular/issues/15873#issuecomment-375410962
     */
    const handle = cached?.handle as {
      componentRef: ComponentRef<any>;
    };
    if (handle && destroyView) {
      handle.componentRef.destroy();
    }
  }
}

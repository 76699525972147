import { AsyncPipe } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { Observable, Subscribable } from 'rxjs';

// FIXME: T267853 - switch back to async after https://github.com/angular/angular/issues/16982
// eslint-disable-next-line @angular-eslint/no-pipe-impure
@Pipe({ name: 'flAsync', pure: false })
export class FreelancerAsyncPipe extends AsyncPipe implements PipeTransform {
  transform<T>(obj$: null | undefined): undefined;
  transform<T>(
    obj$: Observable<T> | Subscribable<T> | Promise<T> | null | undefined,
  ): T | undefined;
  transform<T>(
    obj$: Observable<T> | Subscribable<T> | Promise<T> | null | undefined,
  ): T | undefined {
    return super.transform<T>(obj$) ?? undefined;
  }
}

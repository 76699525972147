import { Inject, Injectable } from '@angular/core';
import type {
  InAppBrowserEvent,
  InAppBrowserObject,
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { InAppBrowser as NativeInAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Auth } from '@freelancer/auth';
import { RepetitiveSubscription } from '@freelancer/decorators';
import {
  BLACKLISTED_IOS_FEATURES,
  FeatureFlagsService,
} from '@freelancer/feature-flags';
import {
  FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER,
  Location,
} from '@freelancer/location';
import { Pwa } from '@freelancer/pwa';
import { TrackingConsent } from '@freelancer/tracking';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import { firstValueFrom, from, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export class InAppBrowserRef {
  @RepetitiveSubscription()
  private loadStartSubscription?: Subscription;

  /**
   * This is a bit messy: because authState is async,
   * any browser we construct using a URL that requires auth is also async.
   *
   * Taking a Promise here and managing it internally
   * makes the usages in other services much neater,
   * as you can do `inAppBrowser.open().onMessage(...)` and so on.
   **/
  constructor(private browser: Promise<InAppBrowserObject>) {}

  /** Close the browser */
  async close(): Promise<void> {
    (await this.browser).close();
  }

  /**
   * Run arbitrary JavaScript code in the browser.
   * Avoid using if possible.
   */
  async executeScript(code: string): Promise<void> {
    const browser = await this.browser;
    browser.executeScript({ code });
  }

  /**
   * Set up a listener that automatically closes the browser
   * after it navigates to URLs matching a certain string or pattern.
   **/
  async closeOnNavigation(match: string | RegExp): Promise<void> {
    const browser = await this.browser;
    this.loadStartSubscription = browser.on('loadstart').subscribe(event => {
      if (event.url.match(match)) {
        this.close();
      }
    });

    // unsubscribe when the browser is closed via other means as well
    this.onExit().then(() => this.loadStartSubscription?.unsubscribe());
  }

  /**
   * Set up a listener that automatically closes the in-app browser after it
   * navigates back to the web app. The returning promise will resolved with
   * `undefined` when the user close the browser manually.
   */
  async closeOnWebapp(): Promise<string | undefined> {
    const subscriptions = new Subscription();
    let lastVisitedUrl: URL | undefined;
    let isAutoClose = false;

    const loadStopEvents$ = from(this.browser).pipe(
      /**
       * Get a stream of navigation start events from the browser.
       *
       * This returns the loadstart event once the browser has finished
       * loading.
       */
      switchMap(browser =>
        browser.on('loadstart').pipe(
          switchMap(loadStartEvent =>
            browser.on('loadstop').pipe(
              /**
               * Avoiding returning the loadstop event ensures that
               * Android's behaviour of returning multiple loadstop events
               * per loadstart does not cause this function to behave
               * unexpectedly.
               */
              map(_ => loadStartEvent),
              take(1),
            ),
          ),
        ),
      ),
    );

    subscriptions.add(
      loadStopEvents$.subscribe(e => {
        if (e.url) {
          lastVisitedUrl = new URL(e.url);
          /**
           * When finishing navigation in the in-app browser, record the URL
           * and check whether it is a web-app route by inspecting
           * `window.webapp`.
           */
          this.executeScript(`
            const messageObj = {isWebApp: !!window.webapp};
            const stringifiedMessageObj = JSON.stringify(messageObj);
            webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);`);
        }
      }),
    );
    subscriptions.add(
      this.onMessage().subscribe(e => {
        /**
         * /internal/auth/callback is a webapp route that is used to transfer the
         * authentication state to a non-webapp page. As this route is a webapp
         * route, it would incorrectly close the in-app browser, hence we need to
         * ignore the route.
         */
        if (
          e.data.isWebApp &&
          lastVisitedUrl &&
          lastVisitedUrl.pathname !== '/internal/auth/callback' &&
          // Disabling this so linkshim doesn't cause an infinite loop.
          lastVisitedUrl.pathname !== '/users/l.php'
        ) {
          isAutoClose = true;
          this.close();
        }
      }),
    );

    // Listen to the browser close event and return the last visited URL if it
    // was close automatically.
    await this.onExit();
    subscriptions.unsubscribe();
    if (isAutoClose && lastVisitedUrl) {
      // Use the last visited URL from the in-app browser.
      // Convert it back to a relative URL string.
      const relativeUrl = lastVisitedUrl.pathname + lastVisitedUrl.search;
      return relativeUrl;
    }
    return undefined;
  }

  /** Get a Promise that resolves when the browser is closed */
  async onExit(): Promise<InAppBrowserEvent> {
    const browser = await this.browser;
    return firstValueFrom(browser.on('exit'));
  }

  /** Get a stream of message events from the browser */
  onMessage(): Observable<InAppBrowserEvent> {
    return from(this.browser).pipe(switchMap(browser => browser.on('message')));
  }
}

@UntilDestroy({ className: 'InAppBrowser' })
@Injectable({
  providedIn: 'root',
})
export class InAppBrowser {
  constructor(
    private nativeInAppBrowser: NativeInAppBrowser,
    private auth: Auth,
    private location: Location,
    private featureFlags: FeatureFlagsService,
    private pwa: Pwa,
    private trackingConsent: TrackingConsent,
    @Inject(FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER)
    private freelancerLocationHttpBaseUrl: string,
  ) {}

  open(url: string): InAppBrowserRef {
    // Parse URL, defaulting to current origin if relative
    const redirectUrl = new URL(url, this.location.origin);

    // If it's an internal redirect, we want to set auth
    const isInternalRedirect =
      redirectUrl.origin === this.location.origin ||
      redirectUrl.origin === this.freelancerLocationHttpBaseUrl;

    const authedRedirectUrlPromise = (async () => {
      const authState = await firstValueFrom(
        this.auth.authState$.pipe(untilDestroyed(this)),
      );
      // if not allowed to track, append no tracking query param
      const trackingConsent = await this.trackingConsent.getThirdPartyStatus();
      if (trackingConsent !== 'authorized') {
        redirectUrl.searchParams.set('no_third_party_tracking', 'true');
      }

      // Pass the existing feature flags overrides to the in-app browser as query params
      // For iOS, also pass all the iOS blacklisted feature flags
      let overrides = await firstValueFrom(
        this.featureFlags.featureFlagBlacklist$.pipe(untilDestroyed(this)),
      );
      if (this.pwa.getPlatform() === 'ios') {
        overrides = overrides.concat(
          BLACKLISTED_IOS_FEATURES.filter(
            feature => !overrides.includes(feature),
          ),
        );
      }
      overrides.forEach(feature => {
        redirectUrl.searchParams.append(
          'feature_flag_blacklist_overrides[]',
          feature,
        );
      });

      if (isInternalRedirect && authState) {
        // if internal and authed, go via auth callback to set auth state
        return `${redirectUrl.origin}/internal/auth/callback?uid=${
          authState.userId
        }&token=${encodeURIComponent(
          authState.token,
        )}&next=${encodeURIComponent(
          redirectUrl.pathname + redirectUrl.search,
          // Ngsw-bypass is being used here to prevent ServiceWorker from being
          // used in the in-app-browser.
        )}&ngsw-bypass=`;
      }
      // otherwise just go straight to URL.
      return redirectUrl.toString();
    })();

    const browserPromise = authedRedirectUrlPromise.then(authedUrl =>
      this.nativeInAppBrowser.create(authedUrl, '_blank', {
        // The default text is `Done`, but we have to localise this manually.
        closebuttoncaption: $localize`Done`,
        // (iOS only) This matches the @capacitor/browser plugin style where the
        // Close button is on the top left hand corner.
        toolbarposition: 'top',
        // (iOS only) Allow in-line HTML5 media playback, displaying within the browser window rather than a device-specific playback interface.
        // The HTML's video element must also include the webkit-playsinline attribute (defaults to no)
        allowInlineMediaPlayback: 'yes',
      }),
    );

    return new InAppBrowserRef(browserPromise);
  }
}

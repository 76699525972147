import { registerPlugin } from '@capacitor/core';

export interface GetOptions {
  key: string;
}

export interface GetResult {
  value: string | null;
}

export interface SetOptions {
  key: string;
  value: string;
}

export interface RemoveOptions {
  key: string;
}

export interface Version {
  version: string;
}

export interface SecureAndroidStoragePlugin {
  pluginVersion(): Promise<Version>;
  set(options: SetOptions): Promise<void>;
  get(options: GetOptions): Promise<GetResult>;
  remove(options: RemoveOptions): Promise<void>;
}
export const SECURE_ANDROID_STORAGE_PLUGIN_NAME = 'SecureAndroidStorage';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SecureAndroidStorage = registerPlugin<SecureAndroidStoragePlugin>(
  SECURE_ANDROID_STORAGE_PLUGIN_NAME,
);

import type {
  CONTEST_UPGRADES,
  PROJECT_UPGRADES,
} from '@freelancer/search-filters';
import type * as common from 'api-typings/common/common';
import type {
  SearchFiltersContestSortingOptionApi,
  SearchFiltersProjectSortingOptionApi,
  SearchFiltersProjectTypeApi,
} from 'api-typings/users/users';

/**
 * Represents a single named set of search filters
 */
export interface SearchSavedFilters {
  readonly id: number;
  readonly filters:
    | SearchSavedFiltersProject
    | SearchSavedFiltersUser
    | SearchSavedFiltersLoad
    | SearchSavedFiltersContest;
  readonly name: string;
  readonly type: common.SearchTypeApi;
  readonly userId: number;
}

export interface SearchSavedFiltersProject {
  readonly clientCountryCodes?: readonly string[];
  readonly countryCode?: string;
  readonly keyword?: string;
  readonly languageCodes?: readonly string[];
  readonly location?: SearchSavedFiltersProjectLocation;
  readonly maxFixedPrice?: number;
  readonly maxHourlyRate?: number;
  readonly minFixedPrice?: number;
  readonly minHourlyRate?: number;
  readonly poolIds?: readonly number[];
  readonly projectTypes?: readonly SearchFiltersProjectTypeApi[];
  readonly skillIds?: readonly number[];
  readonly sortingOption?: SearchFiltersProjectSortingOptionApi;
  /**
   * This property's type was changed from `SearchFiltersProjectUpgradeApi` due to inconsistencies with the NDA filter value between search filters ('NDA') and saveable filters ('nda'). Refer to T260614 and its diff
   */
  readonly upgrades?: readonly PROJECT_UPGRADES[];
}

export interface SearchSavedFiltersUser {
  readonly countryCodes?: readonly string[];
  readonly insigniaIds?: readonly number[];
  readonly isOnline?: boolean;
  readonly keyword?: string;
  readonly maxHourlyRate?: number;
  readonly maxReviewCount?: number;
  readonly minHourlyRate?: number;
  readonly minReviewCount?: number;
  readonly minStarRating?: number;
  readonly poolIds?: readonly number[];
  readonly skillIds?: readonly number[];
}

export interface SearchSavedFiltersLoad {
  readonly keyword?: string;
  readonly startLocationAdministrativeAreaCodes?: readonly string[];
  readonly endLocationAdministrativeAreaCodes?: readonly string[];
  readonly skillIds?: readonly number[];
  readonly equipmentIds?: readonly number[];
  readonly startOperatingAreaIds?: readonly number[];
  readonly endOperatingAreaIds?: readonly number[];
  readonly maxWidth?: number;
  readonly maxLength?: number;
  readonly minWeight?: number;
  readonly maxWeight?: number;
}

export interface SearchSavedFiltersContest {
  readonly keyword?: string;
  readonly maxPrize?: number;
  readonly minPrize?: number;
  readonly skillIds?: readonly number[];
  readonly sortingOption?: SearchFiltersContestSortingOptionApi;
  readonly upgrades?: readonly CONTEST_UPGRADES[];
}

export interface SearchSavedFiltersProjectLocation {
  readonly fullAddress: string;
  readonly latitude: number;
  readonly longitude: number;
}

export function isSearchSavedFiltersProject(
  filters:
    | SearchSavedFiltersProject
    | SearchSavedFiltersUser
    | SearchSavedFiltersLoad
    | SearchSavedFiltersContest,
): filters is SearchSavedFiltersProject {
  return 'projectTypes' in filters;
}

export function isSearchSavedFiltersUser(
  filters:
    | SearchSavedFiltersProject
    | SearchSavedFiltersUser
    | SearchSavedFiltersLoad
    | SearchSavedFiltersContest,
): filters is SearchSavedFiltersUser {
  return 'insigniaIds' in filters;
}

export function isSearchSavedFiltersLoad(
  filters:
    | SearchSavedFiltersProject
    | SearchSavedFiltersUser
    | SearchSavedFiltersLoad
    | SearchSavedFiltersContest,
): filters is SearchSavedFiltersLoad {
  return true;
}

export function isSearchSavedFiltersContest(
  filters:
    | SearchSavedFiltersProject
    | SearchSavedFiltersUser
    | SearchSavedFiltersLoad
    | SearchSavedFiltersContest,
): filters is SearchSavedFiltersContest {
  return 'minPrize' in filters;
}

import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type { DeliveryContactsSensitiveDetailsCollection } from './delivery-contacts-sensitive-details.types';

export function deliveryContactsSensitiveDetailsBackend(): Backend<DeliveryContactsSensitiveDetailsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, projectId) => ({
      endpoint: `projects/0.1/projects/${projectId}/delivery_contacts/`,
      isGaf: false,
    }),
    push: undefined,
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import type {
  MonoTypeOperatorFunction,
  Observable,
  OperatorFunction,
  SchedulerLike,
} from 'rxjs';
import { asyncScheduler, interval, pipe, range, timer } from 'rxjs';
import {
  bufferTime,
  debounceTime,
  delay as rxDelay,
  observeOn,
  take,
  tap,
  toArray,
} from 'rxjs/operators';
import { enterZone, leaveZone } from './zone-utils';

export type Timer = ReturnType<typeof setTimeout>;

/** Keep this in sync with TimeUtilsTesting */
@Injectable({ providedIn: 'root' })
export class TimeUtils {
  readonly HOURS_IN_DAY = 24;
  readonly MILLIS_IN_SEC = 1000;
  readonly SECS_IN_DAY = 86_400;
  readonly SECS_IN_HOUR = 3600;

  constructor(
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  setTimeout(callback: (...args: any[]) => void, timeout = 0): Timer {
    return this.ngZone.runOutsideAngular(() =>
      setTimeout(() => this.ngZone.run(callback), timeout),
    );
  }

  setInterval(callback: (...args: any[]) => void, delay = 0): Timer {
    return this.ngZone.runOutsideAngular(() =>
      setInterval(() => this.ngZone.run(callback), delay),
    );
  }

  /**
   * Returns a list of years between the given years.
   *
   * @param start Start year
   * @param end End year
   */
  getListOfYears(start: number, end: number): Observable<readonly number[]> {
    return range(start, end - start + 1).pipe(take(end - start), toArray());
  }

  /**
   * Returns a duration in milliseconds rounded up to the nearest day.
   * Duration must be passed in as milliseconds.
   *
   * @param millis Duration
   */
  roundDuration(millis: number): number {
    let seconds = millis / this.MILLIS_IN_SEC;
    const hours = Math.floor((seconds % this.SECS_IN_DAY) / this.SECS_IN_HOUR);
    seconds += (this.HOURS_IN_DAY - hours) * this.SECS_IN_HOUR;
    return seconds * this.MILLIS_IN_SEC;
  }

  rxTimer(
    dueTime: number,
    intervalOrScheduler?: number,
    scheduler: SchedulerLike = asyncScheduler,
  ): Observable<number> {
    if (!intervalOrScheduler) {
      return timer(dueTime, leaveZone(this.ngZone, scheduler)).pipe(
        observeOn(enterZone(this.ngZone, scheduler)),
      );
    }

    return timer(
      dueTime,
      intervalOrScheduler,
      leaveZone(this.ngZone, scheduler),
    ).pipe(observeOn(enterZone(this.ngZone, scheduler)));
  }

  rxInterval(
    period: number,
    scheduler: SchedulerLike = asyncScheduler,
  ): Observable<number> {
    return interval(period, leaveZone(this.ngZone, scheduler)).pipe(
      observeOn(enterZone(this.ngZone, scheduler)),
    );
  }

  rxDebounceTime<T>(
    dueTime: number,
    scheduler: SchedulerLike = asyncScheduler,
  ): MonoTypeOperatorFunction<T> {
    return pipe(
      tap(() => {
        if (isPlatformBrowser(this.platformId) && window.webapp) {
          if (window.webapp.webappE2ePendingTasksOutsideNgZone) {
            window.webapp.webappE2ePendingTasksOutsideNgZone += 1;
          }
          window.webapp.webappE2ePendingTasksOutsideNgZone = 1;
        }
      }),
      observeOn(leaveZone(this.ngZone, scheduler)),
      debounceTime<T>(dueTime, scheduler),
      observeOn(enterZone(this.ngZone, scheduler)),
      tap(() => {
        if (isPlatformBrowser(this.platformId) && window.webapp) {
          if (window.webapp.webappE2ePendingTasksOutsideNgZone) {
            window.webapp.webappE2ePendingTasksOutsideNgZone -= 1;
          }
          window.webapp.webappE2ePendingTasksOutsideNgZone = 0;
        }
      }),
    );
  }

  rxDelay<T>(
    dueTime: number,
    scheduler: SchedulerLike = asyncScheduler,
  ): MonoTypeOperatorFunction<T> {
    return pipe(
      observeOn(leaveZone(this.ngZone, scheduler)),
      rxDelay<T>(dueTime, scheduler),
      observeOn(enterZone(this.ngZone, scheduler)),
    );
  }

  rxBufferTime<T>(
    time: number,
    scheduler: SchedulerLike = asyncScheduler,
  ): OperatorFunction<T, T[]> {
    return pipe(
      bufferTime<T>(time, leaveZone(this.ngZone, scheduler)),
      observeOn(enterZone(this.ngZone, scheduler)),
    );
  }
}

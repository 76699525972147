import type { Type } from '@angular/core';
import type { HttpAdapter } from '@freelancer/freelancer-http';
import type { SchedulerLike } from 'rxjs';
import type { RequestDataOptions } from './request-data/request-data.config';

/** Auth user ID for logged-out use of the datastore */
export const LOGGED_OUT_KEY = '0';
export const DEFAULT_NON_FETCH_WINDOW = 100;

export interface DatastoreConfig {
  readonly webSocketUrl: string;
  readonly enableStoreFreeze: boolean;
  readonly httpAdapter: Type<HttpAdapter>;
  readonly requestData: RequestDataOptions;
  readonly backendScheduler?: SchedulerLike;
}

export interface RefetchConfig {
  readonly supportedCollections: readonly string[];
}

export interface NonFetchRequestConfig {
  readonly windowTime: number;
}

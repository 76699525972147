export type RichTextElement = HtmlElement | RelatedItemsBlade;

export enum HtmlElementType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  OL = 'ol',
  UL = 'ul',
  LI = 'li',
  DIV = 'div',
  LINK = 'a',
  SPAN = 'span',
  TEXT = '#text',
  BREAK = 'br',
  EMPHASIS = 'em',
  STRONG = 'strong',
  PARAGRAPH = 'p',
  VIDEO = 'video',
  IMAGE = 'img',
  TABLE = 'table',
  TABLE_BODY = 'tbody',
  TABLE_CENTER = 'center',
  TABLE_COL = 'col',
  TABLE_COL_GROUP = 'colgroup',
  TABLE_DATA = 'td',
  TABLE_HEADER = 'th',
  TABLE_ROW = 'tr',
  SMALL = 'small',
  SUBSCRIPT = 'sub',
  SUPERSCRIPT = 'sup',
  PREFORMATTED_TEXT = 'pre',
  CODE = 'code',
  BLOCK_QUOTE = 'blockquote',
  FIGURE = 'figure',
  RELATED_PORTFOLIOS = 'related_portfolios',
  RELATED_FREELANCERS = 'related_freelancers',
  YOUTUBE_VIDEO = 'youtube_video',
  UNDERLINE = 'u',
}

/**
 * Union of all supported HTML Elements
 */
export type HtmlElement =
  | PhrasingElement
  | Paragraph
  | Heading
  | List
  | Media
  | Table
  | PreformattedText
  | BlockQuote
  | Figure
  | Code
  | YoutubeVideo;
export type PhrasingElement = StaticPhrasingElement | HtmlLink;
export type StaticPhrasingElement =
  | Text
  | Break
  | Container
  | Strong
  | Emphasis
  | Small
  | Subscript
  | Superscript
  | Underline;
export type Media = Image | Video;
export type TableContent = TableColGroup | TableBody;
export type ParentDependentElement =
  | ListItem
  | TableContent
  | TableData
  | TableHeader
  | TableCol
  | TableRow
  | TableCenter;

export interface RootElement {
  readonly id?: string;
}

export interface Paragraph extends RootElement {
  readonly type: HtmlElementType.PARAGRAPH;
  readonly children: readonly (PhrasingElement | Image)[];
}
export interface Heading extends RootElement {
  readonly type:
    | HtmlElementType.H1
    | HtmlElementType.H2
    | HtmlElementType.H3
    | HtmlElementType.H4
    | HtmlElementType.H5
    | HtmlElementType.H6;
  readonly children: readonly PhrasingElement[];
}

export interface List extends RootElement {
  readonly type: HtmlElementType.OL | HtmlElementType.UL;
  readonly children: readonly (ListItem | List)[];
}

export interface ListItem extends RootElement {
  readonly type: HtmlElementType.LI;
  readonly children: readonly RichTextElement[];
}

export interface HtmlLink extends RootElement {
  readonly type: HtmlElementType.LINK;
  readonly children: readonly (StaticPhrasingElement | Image)[];
  readonly url: string;
  readonly rel?: string;
}

export interface Container extends RootElement {
  readonly type: HtmlElementType.DIV | HtmlElementType.SPAN;
  readonly children: readonly RichTextElement[];
}

export interface Text extends RootElement {
  readonly type: HtmlElementType.TEXT;
  readonly value: string;
}

export interface Emphasis extends RootElement {
  readonly type: HtmlElementType.EMPHASIS;
  readonly children: readonly PhrasingElement[];
}
export interface Strong extends RootElement {
  readonly type: HtmlElementType.STRONG;
  readonly children: readonly PhrasingElement[];
}

export interface Underline extends RootElement {
  readonly type: HtmlElementType.UNDERLINE;
  readonly children: readonly PhrasingElement[];
}

export interface Break extends RootElement {
  readonly type: HtmlElementType.BREAK;
}

export interface Video extends RootElement {
  readonly type: HtmlElementType.VIDEO;
  readonly url: string;
  readonly fileId?: string;
  readonly height?: string;
  readonly width?: string;
}

export interface Image extends RootElement {
  readonly type: HtmlElementType.IMAGE;
  readonly url: string;
  readonly fileId?: string;
  readonly alt?: string;
  readonly height?: string;
  readonly width?: string;
}

export interface Small extends RootElement {
  readonly type: HtmlElementType.SMALL;
  readonly children: readonly PhrasingElement[];
}

export interface Subscript extends RootElement {
  readonly type: HtmlElementType.SUBSCRIPT;
  readonly children: readonly PhrasingElement[];
}

export interface Superscript extends RootElement {
  readonly type: HtmlElementType.SUPERSCRIPT;
  readonly children: readonly PhrasingElement[];
}
export interface Table extends RootElement {
  readonly type: HtmlElementType.TABLE;
  readonly children: readonly TableContent[];
}

export interface TableColGroup extends RootElement {
  readonly type: HtmlElementType.TABLE_COL_GROUP;
  readonly children: readonly TableCol[];
}

export interface TableCol extends RootElement {
  readonly type: HtmlElementType.TABLE_COL;
  readonly children: readonly RichTextElement[];
}

export interface TableBody extends RootElement {
  readonly type: HtmlElementType.TABLE_BODY;
  readonly children: readonly TableRow[];
}

export interface TableRow extends RootElement {
  readonly type: HtmlElementType.TABLE_ROW;
  readonly children: readonly (TableData | TableHeader)[];
}
export interface TableData extends RootElement {
  readonly type: HtmlElementType.TABLE_DATA;
  readonly children: readonly (TableCenter | HtmlElement)[];
}

export interface TableHeader extends RootElement {
  readonly type: HtmlElementType.TABLE_HEADER;
  readonly children: readonly (TableCenter | Text)[];
}

export interface TableCenter extends RootElement {
  readonly type: HtmlElementType.TABLE_CENTER;
  readonly children: readonly Text[];
}

export interface PreformattedText extends RootElement {
  readonly type: HtmlElementType.PREFORMATTED_TEXT;
  readonly children: readonly RichTextElement[];
}

export interface Code extends RootElement {
  readonly type: HtmlElementType.CODE;
  readonly children: readonly Text[];
}

export interface BlockQuote extends RootElement {
  readonly type: HtmlElementType.BLOCK_QUOTE;
  readonly children: readonly RichTextElement[];
}

export interface Figure extends RootElement {
  readonly type: HtmlElementType.FIGURE;
  readonly children: readonly RichTextElement[];
}

export interface RelatedItemsBlade extends RootElement {
  readonly type:
    | HtmlElementType.RELATED_FREELANCERS
    | HtmlElementType.RELATED_PORTFOLIOS;

  readonly ids: readonly number[];
  readonly title?: string;
}

export interface YoutubeVideo extends RootElement {
  type: HtmlElementType.YOUTUBE_VIDEO;
  url: string;
}

import type { ComponentRef } from '@angular/core';
import { RepetitiveSubscription } from '@freelancer/decorators';
import type { Location } from '@freelancer/location';
import type { Subject } from 'rxjs';
import { SubscriptionLike } from 'rxjs';
import type { ModalCloseConfig, ModalResult } from './modal.types';

export class ModalRef<T> {
  private _afterOpenPromiseResolver: () => void;
  private _afterClosedPromiseResolver: (value: ModalResult) => void;
  private _beforeClosedPromiseResolver: () => void;
  private _afterErrorPromiseResolver: (reason: any) => void;

  /** Promise that resolves when the modal finishes opening. */
  private _afterOpenPromise = new Promise<void>(resolve => {
    this._afterOpenPromiseResolver = resolve;
  });

  /** Promise that resolves before the modal closes */
  private _beforeClosedPromise = new Promise<void>(resolve => {
    this._beforeClosedPromiseResolver = resolve;
  });

  /** Promise that resolves after the modal finishes closing and returns a result */
  private _afterClosedPromise = new Promise<ModalResult>(resolve => {
    this._afterClosedPromiseResolver = resolve;
  });

  /** Promise that resolves after the modal encounters an error */
  private _afterErrorPromise = new Promise<void>(resolve => {
    this._afterErrorPromiseResolver = resolve;
  });

  private componentRef: ComponentRef<T>;
  private closed = false;

  @RepetitiveSubscription()
  private modalPopCloseSubscription?: SubscriptionLike;
  private result: ModalResult;
  private fromRouteChange: boolean;
  private uuid?: string;

  constructor(
    private _openStreamSubject$: Subject<any>,
    private location: Location,
  ) {}

  open(): void {
    this.modalPopCloseSubscription = this.location._createBackButtonState({
      onPop: () => this.close(undefined, { fromRouteChange: true }),
    });
  }

  set(componentRef: ComponentRef<T>): void {
    this.componentRef = componentRef;
    this._afterOpenPromiseResolver();
  }

  getUuid(): string | undefined {
    return this.uuid;
  }
  setUuid(uuid: string): void {
    this.uuid = uuid;
  }

  close(
    result?: ModalResult,
    { fromRouteChange = false }: ModalCloseConfig = {},
  ): void {
    // already closed: don't close again.
    if (this.closed) {
      return;
    }
    this.closed = true;
    this.fromRouteChange = fromRouteChange;

    if (result !== undefined) {
      this.result = result;
    }
    this._beforeClosedPromiseResolver();
  }

  setResult(result: ModalResult): void {
    this.result = result;
  }

  getResult(): ModalResult {
    return this.result;
  }

  setError(error: any): void {
    this.result = undefined; // clear the result since it might not be valid anymore

    this._afterErrorPromiseResolver(error);
    this.close();
  }

  _destroy(): void {
    // if the modal failed to load (e.g. network error), we might not have a
    // componentRef
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    if (this.modalPopCloseSubscription) {
      this.modalPopCloseSubscription.unsubscribe();
    }

    // close `openStreamSubject` immediately
    this._openStreamSubject$.next(false);

    if (!this.fromRouteChange) {
      this.location.back().then(() => {
        // emit to afterClosed after back-navigation completes
        this._afterClosedPromiseResolver(this.result);
      });
      return;
    }

    this._afterClosedPromiseResolver(this.result);
  }

  /**
   * Observable to tell the modal component to start running the hide animation
   * NOTE: This is private and should not be used by app code
   */
  _beforeClose(): Promise<void> {
    return this._beforeClosedPromise;
  }

  afterOpen(): Promise<void> {
    return this._afterOpenPromise;
  }

  afterClosed(): Promise<ModalResult> {
    return this._afterClosedPromise;
  }

  afterError(): Promise<any> {
    return this._afterErrorPromise;
  }
}

import { isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule, PLATFORM_ID, TransferState } from '@angular/core';
// TODO: T269699 fix deep imports by changing CoreWebVitalsTracking `web-vitals` import to
// import webVitalsModule from 'web-vitals';
// const { ... } = webVitalsModule;
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { ErrorTracking } from '@freelancer/tracking/error-tracking.service';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { PerformanceTracking } from '@freelancer/tracking/performance-tracking.service';
// This kind of import is needed because ts-node can only
// resolve the main property from the package.json and
// that is in umd format which doesn't allow to import
// esm and Angular 13 modules are.
import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import type { MetaReducer } from '@ngrx/store';
import {
  StoreModule,
  USER_PROVIDED_META_REDUCERS,
  USER_RUNTIME_CHECKS,
} from '@ngrx/store';
import type { TypedAction } from './actions';
import { ApiHttp } from './api-http.service';
import { BackendModule } from './backend';
import { Datastore } from './datastore';
import {
  DATASTORE_CONFIG,
  HTTP_ADAPTER,
  NON_FETCH_REQUEST_CONFIG,
  REFETCH_CONFIG,
} from './datastore.config';
import type { DatastoreConfig } from './datastore.interface';
import { DEFAULT_NON_FETCH_WINDOW } from './datastore.interface';
import { errorHandlerReducerFactory } from './error-handler.reducer';
import { datastoreHydrationReducerFactory } from './local-cache/datastore-hydration.reducer';
import { LocalCacheModule } from './local-cache/local-cache.module';
import { REQUEST_DATA_INITIAL_CONFIG } from './request-data';
import { RequestDataModule } from './request-data/request-data.module';
import { stateTransferReducerFactory } from './state-transfer.reducer';
import { RECONNECT_CONFIG } from './websocket/websocket.config';

export function getMetaReducers(
  errorTracking: ErrorTracking,
  performanceTracking: PerformanceTracking,
  platformId: Object,
  transferState: TransferState,
): readonly MetaReducer<any, TypedAction>[] {
  const metaReducers: MetaReducer<any, TypedAction>[] = [
    errorHandlerReducerFactory(errorTracking),
    stateTransferReducerFactory(transferState, platformId),
  ];
  // Store session cache is disabled in local dev
  if (
    isPlatformBrowser(platformId) &&
    window.location.hostname !== 'localhost' &&
    !window.location.hostname.startsWith('192.168.') &&
    !window.location.hostname.startsWith('10.')
  ) {
    metaReducers.push(datastoreHydrationReducerFactory());
  }
  return metaReducers;
}

@NgModule({
  imports: [
    HttpClientModule,
    IonicStorageModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    LocalCacheModule,
    RequestDataModule.initialize(),
    BackendModule.forRoot(),
  ],
  providers: [
    ApiHttp,
    {
      provide: REFETCH_CONFIG,
      useValue: {
        supportedCollections: ['messages', 'threads'],
      },
    },
    {
      provide: RECONNECT_CONFIG,
      useValue: {
        reconnectCountResetMilliseconds: 60 * 60 * 1000, // 1 hour
        reconnectEmitLimit: 20,
        signalDelayMaxMilliseconds: 30_000,
      },
    },
    {
      provide: NON_FETCH_REQUEST_CONFIG,
      useValue: {
        windowTime: DEFAULT_NON_FETCH_WINDOW,
      },
    },
  ],
})
export class DatastoreModule {
  static initialize(
    config: DatastoreConfig,
  ): ModuleWithProviders<DatastoreModule> {
    return {
      ngModule: DatastoreModule,
      providers: [
        Datastore,
        {
          provide: DATASTORE_CONFIG,
          useValue: config,
        },
        {
          provide: USER_RUNTIME_CHECKS,
          useValue: {
            strictStateImmutability: config.enableStoreFreeze,
            strictActionImmutability: config.enableStoreFreeze,
          },
        },
        {
          provide: USER_PROVIDED_META_REDUCERS,
          deps: [
            ErrorTracking,
            PerformanceTracking,
            PLATFORM_ID,
            TransferState,
          ],
          useFactory: getMetaReducers,
        },
        {
          provide: HTTP_ADAPTER,
          useClass: config.httpAdapter,
        },
        {
          provide: REQUEST_DATA_INITIAL_CONFIG,
          useValue: config.requestData,
        },
      ],
    };
  }
}

import { Injectable } from '@angular/core';
import { Haptics as CapacitorHaptics, ImpactStyle } from '@capacitor/haptics';

@Injectable({
  providedIn: 'root',
})
export class Haptics {
  /**
   * To be used for giving feedback after the user clicks something.
   */
  async impact(): Promise<void> {
    try {
      await CapacitorHaptics.impact({ style: ImpactStyle.Medium });
    } catch {
      // Do nothing. This is only to catch for if the user has a new version of the webapp
      // but an old version of the app. (hence not having the native haptics plugin).
    }
  }

  /**
   * To be used for giving feedback after the user clicks something.
   */
  async light(): Promise<void> {
    try {
      await CapacitorHaptics.impact({ style: ImpactStyle.Light });
    } catch {
      // Do nothing. This is only to catch for if the user has a new version of the webapp
      // but an old version of the app. (hence not having the native haptics plugin).
    }
  }
}

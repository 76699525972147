import { NgModule } from '@angular/core';
import { MODALS } from './modal/modal.config';
import { uiModals } from './ui-modal-routes';

@NgModule({
  providers: [
    {
      provide: MODALS,
      useValue: uiModals,
      multi: true,
    },
  ],
})
export class UiModalsModule {}

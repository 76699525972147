import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ErrorTracking } from './error-tracking.service';

@Component({
  selector: 'fl-error-tracking',
  template: ` <ng-container></ng-container> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorTrackingComponent implements OnInit {
  constructor(private errorTracking: ErrorTracking) {}

  ngOnInit(): void {
    this.errorTracking.setupSentrySDK();
  }
}

import type {
  CategoryApi,
  JobApi,
  SkillQuestionApi,
} from 'api-typings/common/common';
import { SkillQuestionContextTypeApi } from 'api-typings/common/common';
import type { Category, Skill, SkillQuestion } from './skills.model';

export function transformSkill(job: JobApi): Skill {
  if (!job.name || !job.seo_url) {
    throw new ReferenceError(`Job missing required fields`);
  }

  return {
    id: job.id,
    name: job.name,
    category: transformCategory(job.category),
    local: job.local || false,
    seoUrl: job.seo_url,
    activeProjectCount: job.active_project_count ? job.active_project_count : 0,
    questions: job.questions?.map(question => transformSkillQuestion(question)),
  };
}

// Transform skill associated with a pool, ATN pool to be specific
export function transformPoolSkill(job: JobApi): Skill {
  if (!job.name) {
    throw new ReferenceError(`Job missing required field: 'name'`);
  }

  return {
    id: job.id,
    name: job.name,
    category: transformCategory(job.category),
    local: job.local || false,
    seoUrl: job.seo_url ?? '',
    activeProjectCount: job.active_project_count ?? 0,
  };
}

function transformCategory(category?: CategoryApi): Category | undefined {
  return category
    ? {
        id: category.id,
        name: category.name,
      }
    : undefined;
}

function transformSkillQuestion(question: SkillQuestionApi): SkillQuestion {
  return {
    id: question.id,
    internalName: question.internal_name,
    displayName: question.display_name,
    contextType: questionContextTypeStringToEnum(question.context_type),
  };
}

function questionContextTypeStringToEnum(
  type: string,
): SkillQuestionContextTypeApi {
  switch (type) {
    case 'delivery_item':
      return SkillQuestionContextTypeApi.DELIVERY_ITEM;
    default:
      throw new Error('Invalid skill question context type.');
  }
}

import { keyBy } from '@freelancer/utils';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Convert a list into a map.
 */
export function asObject<
  T extends { readonly id: K },
  K extends string | number,
>(): (
  list$: Observable<readonly T[]>,
) => Observable<{ readonly [id: string]: T }> {
  return map(list => keyBy(list, item => item.id));
}

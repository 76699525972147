import type { RichTextElement } from '@freelancer/html-renderer';
import type {
  ContestExtendUpgradeDurationApi,
  ContestStatusApi,
  ContestTypeApi,
} from 'api-typings/contests/contests';
import type { Currency } from '../currencies/currencies.model';
import type { Skill } from '../skills/skills.model';

export interface ContestUpgrade {
  readonly guaranteed: boolean;
  readonly featured: boolean;
  readonly topContest: boolean;
  readonly highlight: boolean;
  readonly sealed: boolean;
  readonly nda: boolean;
  readonly private: boolean;
  readonly urgent: boolean;
  readonly customJudging: boolean;
  readonly extraForUpdate?: {
    readonly extended?: ContestExtendUpgradeDurationApi;
  };
}

/**
 * A contest.
 *
 * Projections: `entry_counts`
 */
export interface Contest {
  readonly id: number;
  readonly currency: Currency;
  readonly description: string;
  readonly skills: readonly Skill[];
  readonly ownerId: number;
  readonly seoUrl: string;
  readonly status: ContestStatusApi;
  readonly title: string;
  readonly type: ContestTypeApi;
  readonly timeSubmitted?: number;
  readonly timePosted?: number;
  readonly timeEnded?: number;
  readonly timeSigned?: number;
  readonly entryCount?: number;
  readonly duration?: number;
  readonly upgrades?: ContestUpgrade;
  readonly prize?: number;
  readonly draft?: boolean;
  readonly fileIds?: readonly number[];
  readonly enterpriseId?: number;
  readonly poolIds?: readonly number[];
  readonly judgingPeriodDays?: number;
  readonly descriptionRichText?: readonly RichTextElement[];
  /**
   * Freelancer count varies depending if user is logged in or out
   * Logged in: number of freelancers who submitted entries that are NOT deleted or in draft state
   * Logged out: number of freelancers who submitted entries that are NOT deleted, draft, withdrawn, eliminated, or withdrawn eliminated
   */
  readonly freelancerCount?: number;
}

export enum ContestSteps {
  AWARD = 2,
  HANDOVER = 3,
  FEEDBACK = 4,
  ALL_STEPS_COMPLETED = 5,
}

export const CONTEST_DEFAULT_JUDGING_PERIOD_DAYS = 30;

import type { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

/**
 * Adds a startWith with an empty array of the right type.
 */
export function startWithEmptyList<T>(): (
  source$: Observable<readonly T[]>,
) => Observable<readonly T[]> {
  return startWith([] as readonly T[]);
}

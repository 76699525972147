import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type { UsersTeamProfileCollection } from './users-team-profile.types';

export function usersTeamProfileBackend(): Backend<UsersTeamProfileCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => ({
      endpoint: 'users/0.1/users',
      params: {
        users: ids,
        avatar: 'true',
        display_info: 'true',
        location_details: 'true',
        profile_description: 'true',
        search_language_details: 'true',
        status: 'true',
      },
    }),
    push: undefined,
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}

/**
 * A supported currency, pulled from the API.
 * See `currenciesIncludingExternal` for the projection that includes external currencies.
 */
export interface Currency {
  readonly code: string;
  readonly country?: string;
  readonly id: number;
  readonly name?: string;
  readonly sign: string;
  readonly isExternal?: boolean;
  readonly exchangeRate?: number;
}

/**
 * A supported currency, but from GAF.
 * TODO: Unify this with `Currency` by normalising AJAX and REST API responses
 */
export interface CurrencyAjax {
  readonly id: string;
  readonly name?: string;

  readonly code: string;
  readonly country?: string;
  readonly is_external?: '0' | '1' | 0 | 1;
  readonly seq?: string;
  readonly sign: string;
}

export enum CurrencyCode {
  USD = 'USD',
  NZD = 'NZD',
  AUD = 'AUD',
  GBP = 'GBP',
  HKD = 'HKD',
  SGD = 'SGD',
  EUR = 'EUR',
  CAD = 'CAD',
  INR = 'INR',
  TKN = 'TKN',
}

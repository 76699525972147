export interface PictureImage {
  src: string;
  alt: string;
}

export enum PictureLayout {
  INTRINSIC = 'INTRINSIC',
  FIXED = 'FIXED',
  RESPONSIVE = 'RESPONSIVE',
  FILL = 'FILL',
}

export enum PictureLoadMethod {
  LAZY = 'lazy',
  PRELOAD = 'preload',
}

export enum PictureDisplay {
  INLINE = 'inline',
  BLOCK = 'block',
  RELATIVE = 'relative',
}

export enum PictureObjectFit {
  CONTAIN = 'contain',
  COVER = 'cover',
  NONE = 'none',
  SCALE_DOWN = 'scale-down',
}

export enum PictureObjectPositionHorizontal {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum PictureObjectPositionVertical {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export type AspectRatioValue =
  | 'square'
  | 'rectangle-small'
  | 'rectangle-medium'
  | 'rectangle-large'
  | 'rectangle-xlarge'
  | 'rectangle-xxlarge';

export interface PictureObjectPosition {
  horizontal: PictureObjectPositionHorizontal;
  vertical: PictureObjectPositionVertical;
}
export interface PreloadInfo {
  src: string;
  srcset: string;
  mediaQuery?: string;
}

export const deviceSizes: readonly number[] = [
  640, 750, 828, 1080, 1200, 1920, 2048, 3840,
];

export const imageSizes: readonly number[] = [
  16,
  32,
  48,
  64,
  96,
  128,
  256,
  384,
  ...deviceSizes,
];

export interface PictureMetadata {
  name: string;
  author: string;
}

/**
 * Used when setting fl-picture as a background image
 * - `color` : Used for background color of transparent images
 * - `position`: corresponds to `object-position` css property. Can be px, %, or keyword value. Defaults to 'top center'
 * - `object-fit`: corresponds to `object-fit` css property. Supports 'contain' and 'cover'. Defaults to 'cover'
 */
export interface PictureBackgroundImageStyles {
  color?: string;
  position?: string;
  positionTablet?: PictureBgImagePosition | string;
  positionDesktop?: PictureBgImagePosition | string;
  positionDesktopLarge?: PictureBgImagePosition | string;
  positionDesktopXLarge?: PictureBgImagePosition | string;
  positionDesktopXXLarge?: PictureBgImagePosition | string;
  objectFit?: PictureBgImageObjectFit;
  objectFitTablet?: PictureBgImageObjectFit;
  objectFitDesktop?: PictureBgImageObjectFit;
  objectFitDesktopLarge?: PictureBgImageObjectFit;
  objectFitDesktopXLarge?: PictureBgImageObjectFit;
  objectFitDesktopXXLarge?: PictureBgImageObjectFit;
}

export enum PictureBgImageObjectFit {
  CONTAIN = 'contain',
  COVER = 'cover',
  NONE = 'none',
}

export enum PictureBgImagePosition {
  LEFT_TOP = 'left top',
  LEFT_CENTER = 'left center',
  LEFT_BOTTOM = 'left bottom',
  RIGHT_TOP = 'right top',
  RIGHT_CENTER = 'right center',
  RIGHT_BOTTOM = 'right bottom',
  CENTER_TOP = 'center top',
  CENTER = 'center',
  CENTER_BOTTOM = 'center bottom',
}

/**
 * Mapping of Freelancer's URL query param to Fastly's query params
 */
export const passthroughParametersMap = {
  'passthrough-param-picture-dpr': 'dpr',
  'passthrough-param-picture-enable': 'enable',
  'passthrough-param-picture-optimize': 'optimize',
  'passthrough-param-picture-quality': 'quality',
  'passthrough-param-picture-format': 'format',
  'passthrough-param-picture-resize-filter': 'resize-filter',
};

export interface ImageQualityQueryParameters {
  /**
   * Device Pixel Ratio: https://developer.fastly.com/reference/io/dpr/
   */
  dpr?: string;
  /**
   * Features to enable: https://developer.fastly.com/reference/io/enable/
   */
  enable?: string;
  /**
   * Optimization level for the image: https://developer.fastly.com/reference/io/optimize/
   */
  optimize?: string;
  /**
   * Quality level for the image. Overrides optimize: https://developer.fastly.com/reference/io/quality/
   */
  quality?: string;
  /**
   * Output encoding of the image: https://developer.fastly.com/reference/io/format/
   */
  format?: string;
  /**
   * Resizing filter used when generating a new image with a new size: https://developer.fastly.com/reference/io/resize-filter/
   */
  'resize-filter'?: string;
}

export interface CropSize {
  readonly width: number;
  readonly height: number;
}

export interface CropPosition {
  readonly x: number;
  readonly y: number;
}

export interface CropDetails {
  readonly size: CropSize;
  readonly position?: CropPosition;
}

/**
 * https://developer.fastly.com/reference/io/fit/#allowed-values
 */
export type PictureResizeFit = 'bounds' | 'cover' | 'crop';

/**
 * Multiplier used for overscaling images in devices that have a pixel ratio (DPR) of 1.
 */
export const DEFAULT_DPR1_SCALING_FACTOR = 1.2;
/**
 * Name of the URL query parameter that can override the default multipler of the scaling factor used for generating 1x images.
 */
export const DPR1_SCALING_FACTOR_QUERY_PARAM = 'dpr1-scaling-factor';

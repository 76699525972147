import type { ErrorTracking } from '@freelancer/tracking';
import type { MetaReducer } from '@ngrx/store';
import type { TypedAction } from './actions';
import type { StoreState } from './store.model';

export function errorHandlerReducerFactory(
  errorTracking: ErrorTracking,
): MetaReducer<StoreState, TypedAction> {
  return reducer => (state, action) => {
    let nextState = state;
    try {
      nextState = reducer(state, action);
    } catch (e: any) {
      console.error('REDUCER ERROR: ', e);
      console.error('Broken state & action: ', state, action);
      errorTracking.captureException(e, {
        extra: { action },
      });
    }
    return nextState ?? {};
  };
}

import type { GroupApi } from 'api-typings/groups/groups';
import {
  GroupStatusApi,
  UNRESTRICTED_GROUPSApi,
} from 'api-typings/groups/groups';
import type { Group } from './groups.model';

export function transformGroup(group: GroupApi): Group {
  if (!group.updated) {
    throw new Error('Missing updated param on a group');
  }
  return {
    id: group.id,
    name: group.name,
    creatorId: group.creator_id,
    creatorType: group.creator_type,
    seoUrl: group.seo_url,
    groupType: group.group_type,
    description: group.description,
    created: group.created,
    isDeleted: group.is_deleted,
    updated: group.updated,
    isFeedRead: group.is_feed_read,
    orderByFeedReadLatestTimeInMs: getGroupOrderByFeedReadLatestTimeInMs(
      group.last_active_time,
    ),
    coverImageUrl: group.cover_image_url_new ?? group.cover_image_url,
    lastActiveTimeInMs: group.last_active_time,
    isPublic: UNRESTRICTED_GROUPSApi.includes(group.group_type),
    status: group.status ?? GroupStatusApi.LIVE,
    poolIds: group.pool_ids,
  };
}

export function getGroupOrderByFeedReadLatestTimeInMs(
  lastActiveTime?: number,
): number {
  return lastActiveTime || 0;
}

import { animate, animation, style } from '@angular/animations';

export const slideOutHorizontal = animation(
  [
    style({
      opacity: '{{ startOpacity }}',
      transform: 'translateX(0)',
    }),
    animate(
      '{{ duration }}ms {{ delay }}ms {{ easing }}',
      style({
        opacity: '{{ finishOpacity }}',
        transform: 'translateX({{ translateX }})',
      }),
    ),
  ],
  {
    params: {
      duration: '200',
      delay: '0',
      easing: 'ease-in',
      translateX: '10%', // can be any unit
      startOpacity: 1,
      finishOpacity: 0,
    },
  },
);

export const slideOutVertical = animation(
  [
    style({
      opacity: '{{ startOpacity }}',
      transform: 'translateY(0)',
    }),
    animate(
      '{{ duration }}ms {{ delay }}ms {{ easing }}',
      style({
        opacity: '{{ finishOpacity }}',
        transform: 'translateY({{ translateY }})',
      }),
    ),
  ],
  {
    params: {
      duration: '200',
      delay: '0',
      easing: 'ease-in',
      translateY: '10%', // can be any unit
      startOpacity: 1,
      finishOpacity: 0,
    },
  },
);

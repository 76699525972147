import type { ComponentType } from '@angular/cdk/portal';
import type { Injector, TemplateRef, ViewContainerRef } from '@angular/core';
import {
  ErrorHandler,
  Inject,
  Injectable,
  Optional,
  createNgModule,
} from '@angular/core';
import { Location } from '@freelancer/location';
import { SwipeNavigationService } from '@freelancer/pwa';
import { retryBackoff } from 'backoff-rxjs';
import { BehaviorSubject, Subject, from, of } from 'rxjs';
import { catchError, retry, switchMap, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { TESTING_CLOSE_ANIMATION_DISABLED } from '../ui.config';
import { ModalRef } from './modal-ref';
import type { ModalName } from './modal-types';
import type { LazyModal } from './modal.config';
import { MODALS } from './modal.config';
import type {
  ModalCloseConfig,
  ModalConfig,
  ModalInfo,
  ModalLoadingStatus,
} from './modal.types';

/**
 * Custom injector to be used when providing custom
 * injection tokens to components inside a portal.
 */
export class PortalInjector implements Injector {
  constructor(
    private _parentInjector: Injector,
    private _customTokens: WeakMap<typeof ModalRef, ModalRef<any>>,
  ) {}

  get(token: typeof ModalRef, notFoundValue?: any): any {
    const value = this._customTokens.get(token);

    if (value !== undefined) {
      return value;
    }

    return this._parentInjector.get(token, notFoundValue);
  }
}

@Injectable({ providedIn: 'root' })
export class ModalService {
  private _openStreamSubject$ = new Subject<any>();
  openStream$ = this._openStreamSubject$.asObservable();
  private _statusStreamSubject$ = new Subject<ModalLoadingStatus>();
  statusStream$ = this._statusStreamSubject$.asObservable();
  private _beforeCloseStreamSubject$ = new Subject<void>();
  beforeCloseStream$ = this._beforeCloseStreamSubject$.asObservable();
  private retryStreamSubject$: Subject<undefined>;
  private _titleStreamSubject$ = new BehaviorSubject<
    TemplateRef<any> | undefined
  >(undefined);
  titleStream$ = this._titleStreamSubject$.asObservable();

  // rendering container
  private injector: Injector;
  private viewContainerRef: ViewContainerRef;
  private openModalRef?: ModalRef<any>;
  private modalConfig: ModalConfig;

  private allModals: { [k: string]: LazyModal };
  // stores modal info if keepModalInfo is used when opening the modal.
  private modalStack: ModalInfo[] = [];

  constructor(
    @Inject(MODALS)
    private readonly providedModals: { [k: string]: LazyModal }[],
    private errorHandler: ErrorHandler,
    private location: Location,
    private swipeNavigation: SwipeNavigationService,
    /** This should only be injected in UI tests */
    @Optional()
    @Inject(TESTING_CLOSE_ANIMATION_DISABLED)
    private readonly testingCloseAnimationDisabled?: boolean,
  ) {}

  close(config: ModalCloseConfig = {}): void {
    // Compare the current modal name with the last modal in the stack when the user is closing the modal manually,
    // if they are the same, remove the last modal in the stack.
    if (!config.autoCloseOnOpen) {
      const currentModalUuid = this.openModalRef?.getUuid();
      const lastModalInStack = this.modalStack?.[this.modalStack.length - 1];
      if (currentModalUuid === lastModalInStack?.uuid) {
        this.modalStack?.pop();
      }
    }
    if (this.openModalRef) {
      this.openModalRef.close(undefined, config);
      this.swipeNavigation.enableSwipeGestures(true);
    }
  }

  /**
   * Destroys the component after running the hide animation in the modal component
   * NOTE: This is private and should not be used by app code, call .close() instead
   */
  _destroy(): void {
    if (this.openModalRef) {
      this.openModalRef._destroy();
      this.openModalRef = undefined;
    }
    if (this.retryStreamSubject$) {
      this.retryStreamSubject$.complete();
    }
  }

  /**
   * Open a modal store in the modal stack.
   * Open the last one in default.
   * This is used when the previous modal is opened with keepModalInfo, and we want to open the previous modal.
   */
  openModalInStack(index?: number, additionalInputs?: Object): void {
    // don't reopen the modal if there's already an opened modal.
    if (this.openModalRef) {
      return;
    }

    // If the user would like to open a specific modal in the stack,
    // we need to make sure that the index is valid and remove the modals after the index.
    if (index && index < this.modalStack.length) {
      this.modalStack = this.modalStack.slice(0, index + 1);
    }

    // Open the last one if no index is provided or index is invalid.
    const modal = this.modalStack?.pop();

    if (modal) {
      if (additionalInputs) {
        this.open(modal.modalName, {
          ...modal.modalConfig,
          inputs: {
            ...modal.modalConfig.inputs,
            ...additionalInputs,
          },
        });
      } else {
        this.open(modal.modalName, modal.modalConfig);
      }
    }
  }

  open(component: ModalName, modalConfig: ModalConfig): ModalRef<any> {
    this.modalConfig = modalConfig;

    const modalRef = new ModalRef(this._openStreamSubject$, this.location);

    if (modalConfig.keepModalInfo) {
      const uuid = uuidv4();
      this.pushModalToStack(component, modalConfig, uuid);
      modalRef.setUuid(uuid);
    }

    this._titleStreamSubject$.next(undefined);

    // close any open modals and wait for them to close
    let ready$ = of(true);
    if (this.openModalRef) {
      ready$ = from(this.openModalRef.afterClosed());
      this.close({ autoCloseOnOpen: true });
    }

    this.retryStreamSubject$ = new Subject<undefined>();
    // asynchronously set the componentRef in the modal
    // we return immediately to make the API nicer.
    ready$
      .pipe(
        tap(() => {
          // mark modal as open after closing previous one
          this._openStreamSubject$.next(
            modalConfig.showBackdrop !== undefined
              ? modalConfig.showBackdrop
              : true,
          );
          this.openModalRef = modalRef;
          this.swipeNavigation.enableSwipeGestures(false);
          modalRef.open();
          this.openModalRef._beforeClose().then(_ => {
            if (this.testingCloseAnimationDisabled) {
              // Immediately destroys the open modal rather than waiting for
              // the `animationDone` callback. Animations are currently broken
              // in UI tests, specifically the timing of animation callbacks
              // being later than expected.
              this._destroy();
            } else {
              this._beforeCloseStreamSubject$.next();
            }
          });
        }),
        // reset the loading status
        tap(() => this._statusStreamSubject$.next({ ready: false })),
        switchMap(() => this.getModalNgModule(component)),
        // on failure retry twice with exponential backoff
        retryBackoff({
          initialInterval: 200,
          maxRetries: 2,
        }),
        catchError(err => {
          // report the error to Sentry since it failed 3 times
          this.errorHandler.handleError(err);
          // inform the modal component that the modal couldn't be loaded
          this._statusStreamSubject$.next({
            ready: true,
            errorMessage: err.message,
          });
          throw err;
        }),
        // this subject allows the user to manual retry on errors
        retry({ delay: () => this.retryStreamSubject$.asObservable() }),
      )
      // eslint-disable-next-line local-rules/no-ignored-subscription
      .subscribe(({ modalModule, componentType }) => {
        /**
         * Dynamically create the provided component using the loaded module.
         *
         * Note: we need both the componentType and module here.
         *  - The module is needed for its providers.
         *  - The componentType is needed, so we have something to generate
         */

        // if the modal has changed (most likely closed), don't inject the old component
        if (this.openModalRef !== modalRef) {
          return;
        }

        // create custom injector to pass in the ModalRef
        const injectionTokens = new WeakMap<typeof ModalRef, ModalRef<any>>();
        injectionTokens.set(ModalRef, modalRef);
        const customInjector = new PortalInjector(
          this.injector,
          injectionTokens,
        );

        // create component
        const componentRef = this.viewContainerRef.createComponent(
          componentType,
          {
            index: 0,
            injector: customInjector,
            ngModuleRef: createNgModule(modalModule, this.injector),
          },
        );

        // set inputs
        if (modalConfig.inputs) {
          Object.entries(modalConfig.inputs).forEach(([key, value]) => {
            componentRef.instance[key] = value;
          });
        }
        modalRef.set(componentRef);

        // inform modal component that loading succeeded
        this._statusStreamSubject$.next({ ready: true });
      });
    return modalRef;
  }

  retryOpen(): void {
    this.retryStreamSubject$.next(undefined);
  }

  setContainer(viewContainerRef: ViewContainerRef, injector: Injector): void {
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
  }

  getCurrentConfig(): ModalConfig {
    return this.modalConfig;
  }

  setTitleTemplate(template?: TemplateRef<any>): void {
    this._titleStreamSubject$.next(template);
  }

  private async getModalNgModule(
    component: ModalName,
  ): Promise<{ modalModule: any; componentType: ComponentType<any> }> {
    if (!this.allModals) {
      // merge the `multi` providers into one map.
      this.allModals = this.providedModals.reduce(
        (acc, routes) => ({ ...acc, ...routes }),
        {},
      );
    }

    if (!this.allModals[component]) {
      throw new Error(`missing modal module configuration for ${component}`);
    }

    return {
      modalModule: await this.allModals[component].loadModule(),
      componentType: await this.allModals[component].loadComponent(),
    };
  }

  private pushModalToStack(
    component: ModalName,
    modalConfig: ModalConfig,
    uuid: string,
  ): void {
    const modalInfo: ModalInfo = {
      modalName: component,
      modalConfig,
      uuid,
    };
    // If the modal is the same as the last one, don't push it to the stack.
    if (
      this.modalStack.length &&
      this.modalStack[this.modalStack.length - 1].modalName === component
    ) {
      return;
    }

    this.modalStack.push(modalInfo);
  }
}

import { Camera } from '@capacitor/camera';
import { Geolocation } from '@capacitor/geolocation';
import { PushNotifications } from '@capacitor/push-notifications';
import { Microphone } from '@mozartec/capacitor-microphone';

export enum PermissionStateEnum {
  GRANTED = 'granted',
  DENIED = 'denied',
  // To see the difference between `PROMPT` vs `PROMPT_WITH_RATIONALE`, read
  // https://capacitorjs.com/docs/plugins/web#aliases for more details.
  PROMPT = 'prompt',
  PROMPT_WITH_RATIONALE = 'prompt-with-rationale',
  LIMITED = 'limited',
}

export type PermissionStateType =
  | PermissionState
  | PermissionStateEnum.PROMPT_WITH_RATIONALE
  | PermissionStateEnum.LIMITED;

export enum PermissionType {
  CAMERA = 'Camera',
  MICROPHONE = 'Microphone',
  PUSH_NOTIFICATIONS = 'Push Notifications',
  GEOLOCATION = 'Geolocation',
}

export const permissionReason = {
  CAMERA: $localize`We need this in order to take a clear picture of your face which will be used to verify your identity.`,
  VIDEO: $localize`We need access to your camera to proceed.`,
  MICROPHONE: $localize`We need access to your microphone to proceed.`,
  PHOTO: $localize`We need this in order to upload a photo for your display or cover photo.`,
  GEOLOCATION: $localize`We need this in order to help find jobs more relevant to you.`,
  PUSH_NOTIFICATIONS: $localize`We need this in order to alert you when you get new messages.`,
  VOICE_CALL: $localize`We need this in order to enable voice calling functionality.`,
  VIDEO_CALL: $localize`We need this in order to enable video calling functionality.`,
};

export enum PermissionDenyReason {
  APP = 'app',
  TIMEOUT = 'timeout',
  OTHER = 'other',
  USER_DENIED_FROM_APP = 'user',
  NOT_AVAILABLE = 'camera',
  UNSUPPORTED = 'unsupported',
}

export const permissionCheck = {
  [PermissionType.CAMERA]: {
    nativeCheck: async () =>
      (await Camera.checkPermissions()).camera as PermissionStateType,
    nativeRequest: async () =>
      (await Camera.requestPermissions({ permissions: ['camera'] }))
        .camera as PermissionStateType,
    webCheck: async () =>
      (await window.navigator.permissions.query({
        name: 'camera' as PermissionName,
      })) as PermissionStatus & { state: PermissionStateType },
  },
  [PermissionType.MICROPHONE]: {
    nativeCheck: async () =>
      (await Microphone.checkPermissions()).microphone as PermissionStateType,
    nativeRequest: async () =>
      (await Microphone.requestPermissions()).microphone as PermissionStateType,
    webCheck: async () =>
      (await window.navigator.permissions.query({
        name: 'microphone' as PermissionName,
      })) as PermissionStatus & { state: PermissionStateType },
  },
  [PermissionType.PUSH_NOTIFICATIONS]: {
    nativeCheck: async () =>
      (await PushNotifications.checkPermissions())
        .receive as PermissionStateType,
    nativeRequest: async () =>
      (await PushNotifications.requestPermissions())
        .receive as PermissionStateType,
    webCheck: async () =>
      (await window.navigator.permissions.query({
        name: 'notifications',
      })) as PermissionStatus & { state: PermissionStateType },
  },
  [PermissionType.GEOLOCATION]: {
    nativeCheck: async () =>
      (await Geolocation.checkPermissions()).location as PermissionStateType,
    nativeRequest: async () =>
      (await Geolocation.requestPermissions()).location as PermissionStateType,
    webCheck: async () =>
      (await window.navigator.permissions.query({
        name: 'geolocation',
      })) as PermissionStatus & { state: PermissionStateType },
  },
};

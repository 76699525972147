import { isDefined, toNumber } from '@freelancer/utils';
import type {
  MessageApi,
  SourceTypeApi,
  RichMessageApi,
} from 'api-typings/messages/messages_types';
import type {
  WebsocketNewMessageEvent,
  WebsocketUserAttachEvent,
} from './messages.backend-model';
import type { Message, MessageFileAttachment } from './messages.model';
import { MessageSendStatus } from './messages.model';
import type { RichMessage, RichMessageElement } from './rich-message.model';

const transformRichMessage = (
  richMessageApi: RichMessageApi | undefined,
): RichMessage | undefined => {
  if (richMessageApi) {
    // TODO: T38296 Remove `any` from reviver in rich message transformation.
    const contentJSON = JSON.parse(
      richMessageApi.content,
      function reviver(
        this: RichMessageElement & { [index: string]: any },
        k: string,
        v,
      ) {
        switch (k) {
          case 'content-type':
          case 'display-type': {
            // transform dash-case to camelCase
            const parts = k.split('-');
            const caps = parts
              .slice(1)
              .map((s: string) => `${s[0].toUpperCase()}${s.slice(1)}`)
              .join('');
            this[`${parts[0]}${caps}`] = v;
            break;
          }
          case 'action': {
            return v.toUpperCase();
          }
          case 'from_user': {
            // transform snake_case to camelCase
            const parts = k.split('_');
            const caps = parts
              .slice(1)
              .map((s: string) => `${s[0].toUpperCase()}${s.slice(1)}`)
              .join('');
            this[`${parts[0]}${caps}`] = v;
            break;
          }
          case 'type': {
            return v.toLowerCase();
          }
          default: {
            return v;
          }
        }
      },
    ) as RichMessage;
    const submissionJSON = richMessageApi.latest_submissions
      ? JSON.parse(richMessageApi.latest_submissions)
      : undefined;
    if (
      contentJSON &&
      contentJSON.long &&
      contentJSON.short &&
      contentJSON.email
    ) {
      return {
        category: richMessageApi.category,
        long: contentJSON.long.map(e => (e instanceof Array ? e : [e])),
        short: contentJSON.short.map(e => (e instanceof Array ? e : [e])),
        email: contentJSON.email,
        latestSubmissions: submissionJSON,
        fromUser: contentJSON.fromUser,
        types: contentJSON.types,
      };
    }
  }
  return undefined;
};

export function transformMessage(item: MessageApi): Message {
  const attachments = item.attachments
    ? item.attachments.map(a => a.filename)
    : [];

  const files = item.attachments
    ? item.attachments
        .map(a => {
          if (a.attachment_file_id === undefined) {
            return undefined;
          }

          const file: MessageFileAttachment = {
            uuid: a.attachment_file_id,
            name: a.filename,
          };

          return file;
        })
        .filter(isDefined)
    : [];

  return {
    attachments,
    clientMessageId: toNumber(item.client_message_id),
    files,
    fromUser: item.from_user,
    id: item.id,
    message: item.message,
    messageId: item.id,
    messageSource: item.message_source,
    removeReason: item.remove_reason,
    richMessage: transformRichMessage(item.rich_message),
    sendStatus: MessageSendStatus.SENT,
    threadId: item.thread_id,
    timeCreated: item.time_created * 1000,
    quotedMessageId: item.quoted_message_id,
  };
}

export function transformWebsocketMessage(
  payload: WebsocketNewMessageEvent,
): Message {
  const item = payload.data;
  return {
    attachments: [],
    clientMessageId: toNumber(item.client_message_id),
    fromUser: item.from_user,
    id: item.id,
    message: item.message,
    messageId: item.id,
    messageSource: item.message_source,
    parentId: item.parent_id,
    removeReason: item.remove_reason,
    richMessage: transformRichMessage(item.rich_message),
    sendStatus: MessageSendStatus.SENT,
    threadId: item.thread_id,
    timeCreated: item.time_created * 1000,
    quotedMessageId: item.quoted_message_id,
  };
}

export function transformWebsocketAttachment(
  payload: WebsocketUserAttachEvent,
  existingDocument?: Message,
): Message {
  return existingDocument
    ? {
        ...existingDocument,
        attachments: [
          // FIXME: T267853 - This works under the assumption that an attachment
          // couldn't be added to an existing message. Consider uncommenting
          // the next line, then add a logic to remove duplicates
          // ...(existingDocument.attachments || []),
          ...payload.data.filenames,
        ],
        files: [
          // FIXME: T267853 - This works under the assumption that an attachment
          // couldn't be added to an existing message. Consider uncommenting
          // the next line, then add a logic to remove duplicates
          // ...(existingDocument.files || []),
          ...payload.data.attachments.map(a => ({
            uuid: a.file_uuid,
            name: a.filename,
          })),
        ],
      }
    : {
        attachments: payload.data.filenames,
        id: payload.data.message_id,
        files: payload.data.attachments.map(a => ({
          uuid: a.file_uuid,
          name: a.filename,
        })),
        fromUser: payload.data.from_user,
        message: '',
        messageSource: 'chat_box' as SourceTypeApi,
        sendStatus: MessageSendStatus.SENT,
        threadId: payload.data.thread_id,
        timeCreated: payload.timestamp,
      };
}

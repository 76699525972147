import type { Backend } from '@freelancer/datastore/core';
import {
  OrderByDirection,
  getQueryParamValue,
} from '@freelancer/datastore/core';
import { toNumber } from '@freelancer/utils';
import type { ServiceOfferingShopsCollection } from './service-offering-shops.types';

export function serviceOfferingShopsBackend(): Backend<ServiceOfferingShopsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.DESC,
    },
    fetch: (_authUid, ids, query, _order) => {
      const shopId = ids ? toNumber(ids[0]) : 0;
      const ownerId = getQueryParamValue(query, 'ownerId')[0];

      if (shopId) {
        return {
          endpoint: `service_offerings/0.1/service_offerings/shops/${shopId}`,
          params: {
            ids,
            owner_id: ownerId,
            limit: query?.searchQueryParams.limit,
            offset: query?.searchQueryParams.offset,
            shop_seo_url: query?.searchQueryParams.seoUrl,
          },
        };
      }
      return {
        endpoint: 'service_offerings/0.1/service_offerings/shops',
        params: {
          ids,
          owner_id: ownerId,
          limit: query?.searchQueryParams.limit,
          offset: query?.searchQueryParams.offset,
          shop_seo_url: query?.searchQueryParams.seoUrl,
        },
      };
    },
    push: (authUid, shopData) => {
      return {
        endpoint: 'service_offerings/0.1/service_offerings/shops',
        method: 'POST',
        payload: {
          shop: {
            owner_id: toNumber(authUid),
            name: shopData.name,
            description: shopData.description,
            banner_image_url: shopData.bannerImageUrl,
            banner_image_uuid: shopData.bannerImageUuid,
          },
        },
      };
    },
    remove: (authUid, id, originalDocument) => ({
      endpoint: `service_offerings/0.1/service_offerings/shops/${originalDocument.id}`,
      method: 'DELETE',
      payload: {},
    }),
    set: undefined,
    update: (authUid, partial, original) => {
      return {
        endpoint: `service_offerings/0.1/service_offerings/shops/${original.id}`,
        method: 'PUT',
        payload: {
          shop: {
            id: original.id,
            owner_id: toNumber(authUid),
            description: partial.description,
            name: partial.name,
            banner_image_url: partial.bannerImageUrl,
            banner_image_uuid: partial.bannerImageUuid,
          },
        },
      };
    },
  };
}

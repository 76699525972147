// The role of the user who is being reviewed, matching `toUserId`
export enum ContestUserReviewRole {
  FREELANCER = 'freelancer',
  EMPLOYER = 'employer',
}

export type ContestUserHasGivenFeedbackResultAjax =
  readonly ContestUserHasGivenFeedbackAjax[];

export interface ContestUserHasGivenFeedbackAjax {
  readonly feedbackLeft: boolean;
  readonly fromUserId: number;
  readonly role: ContestUserReviewRole;
  readonly entryId: number;
  readonly toUserId: number;
}

export interface ContestUserHasGivenFeedbackPayload {
  readonly fromUserId: number;
  readonly role: ContestUserReviewRole;
  readonly entryAndToUserIds: readonly {
    readonly entryId: number;
    readonly toUserId: number;
  }[];
}

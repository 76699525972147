import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type { PayoutAccountSetupDataApi } from 'api-typings/payments/payments';
import { PayoutMethodTypeEnumApi } from 'api-typings/payments/payments';
import type { WirePayoutAccount } from './payout-accounts.model';
import type { PayoutAccountsCollection } from './payout-accounts.types';

export function payoutAccountsBackend(): Backend<PayoutAccountsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => ({
      endpoint: 'payments/0.1/payout_accounts',
      isGaf: false,
      params: {},
    }),
    push: (authUid, payoutAccount) => {
      let fieldData: PayoutAccountSetupDataApi['field_data'];

      switch (payoutAccount.method) {
        case PayoutMethodTypeEnumApi.WIRE:
          fieldData = buildWirePayoutAccountFieldData(
            payoutAccount as WirePayoutAccount,
          );
          break;
        default:
          fieldData = [];
          break;
      }

      return {
        endpoint: 'payments/0.1/payout_account',
        payload: {
          country_code: payoutAccount.countryCode,
          currency_id: payoutAccount.currencyId,
          payout_method: payoutAccount.method,
          account_alias: payoutAccount.alias,
          field_data: fieldData,
        },
      };
    },
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}

function buildWirePayoutAccountFieldData(
  payoutAccount: WirePayoutAccount,
): PayoutAccountSetupDataApi['field_data'] {
  return [
    {
      field_identifier: 'beneficiary_account',
      value: payoutAccount.fieldData.beneficiaryAccount,
    },
    {
      field_identifier: 'beneficiary_address_1',
      value: payoutAccount.fieldData.beneficiaryAddress1,
    },
    {
      field_identifier: 'beneficiary_address_2',
      value: payoutAccount.fieldData.beneficiaryAddress2,
    },
    {
      field_identifier: 'beneficiary_bank_name',
      value: payoutAccount.fieldData.beneficiaryBankName,
    },
    {
      field_identifier: 'beneficiary_name',
      value: payoutAccount.fieldData.beneficiaryName,
    },
    {
      field_identifier: 'beneficiary_swift_code',
      value: payoutAccount.fieldData.beneficiarySwiftCode,
    },
    {
      field_identifier: 'wire_additional_info',
      value: payoutAccount.fieldData.wireAdditionalInfo,
    },
  ];
}

import { inject, NgModule } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { RouterModule } from '@angular/router';
import { NotFoundGuard } from '@freelancer/404';
import { AuthCallbackEmptyComponent } from './auth-callback-empty.component';
import { DummyComponent } from './dummy.component';
import { LoginGuard } from './login-guard.service';
import { LogoutGuard } from './logout-guard.service';

const routes: Routes = [
  {
    path: 'login',
    children: [
      {
        path: '',
        data: {
          bypassLoggedOutRestricted: {
            deloitte: true,
            native: true,
          },
        },
        canActivate: [LoginGuard],
        component: DummyComponent,
      },
      {
        path: '**',
        canActivate: [NotFoundGuard],
        component: DummyComponent,
      },
    ],
  },
  {
    path: 'logout',
    children: [
      {
        path: '',
        data: {
          bypassLoggedOutRestricted: {
            deloitte: true,
            native: true,
          },
          isLogoutPage: true,
        },
        canActivate: [
          // TODO: T280954 - Convert to functional guard - see logout-guard.service.ts
          (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(LogoutGuard).canActivate(route, state),
        ],
        component: DummyComponent,
      },
      {
        path: '**',
        canActivate: [NotFoundGuard],
        component: DummyComponent,
      },
    ],
  },
  {
    path: 'internal/auth/callback',
    data: {
      bypassLoggedOutRestricted: {
        deloitte: true,
        native: true,
      },
    },
    component: AuthCallbackEmptyComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import type { Renderer2 } from '@angular/core';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  TransferState,
  makeStateKey,
} from '@angular/core';
import type { Observable } from 'rxjs';
import { finalize, first, of, share, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvgService {
  private cachedIconsByUrl = new Map<string, SVGElement>();
  private inProgressUrlFetches = new Map<string, Observable<string>>();

  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  /**
   * Fetches the SVG markup from the given URL
   * @param url
   * @returns SVG markup
   */
  fetchSvg(url: string): Observable<string> {
    const serverCacheKey = makeStateKey<string>(`SVG_CACHE_${url}`);
    if (
      isPlatformBrowser(this.platformId) &&
      this.transferState.hasKey<string>(serverCacheKey)
    ) {
      return of(this.transferState.get<string>(serverCacheKey, ''));
    }
    return this.fetchUrl(url).pipe(
      tap(markup => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.onSerialize(serverCacheKey, () => markup);
        }
      }),
    );
  }

  parseSvgMarkup(markup: string, renderer: Renderer2): SVGElement {
    const svg = this.createSvgElement(markup, renderer);
    return this.setSvgAttributes(svg);
  }

  getCacheSvg(url: string): SVGElement | undefined {
    const cachedIcon = this.cachedIconsByUrl.get(url);
    if (cachedIcon) {
      return cachedIcon;
    }
  }

  setCacheSvg(url: string, svg: SVGElement): void {
    this.cachedIconsByUrl.set(url, svg);
  }

  /**
   * Fetch a URL to get an icon SVG string
   *
   * @param url The URL to the SVG
   * @returns The SVG markup
   */
  private fetchUrl(url: string): Observable<string> {
    // Store in-progress fetches to avoid sending a duplicate
    // request for a URL when there is already a request in progress for that
    //  URL. It's necessary to call share() on the Rx.Observable returned by
    // http.get() so that multiple subscribers don't cause multiple XHRs.
    const inProgressUrlFetch$ = this.inProgressUrlFetches.get(url);
    if (inProgressUrlFetch$) {
      return inProgressUrlFetch$;
    }

    const request$ = this.http.get(url, { responseType: 'text' }).pipe(
      first(),
      finalize(() => {
        this.inProgressUrlFetches.delete(url);
      }),
      share(),
    );

    this.inProgressUrlFetches.set(url, request$);

    return request$;
  }

  /**
   * Create an SVG element from an SVG markup
   *
   * @param {string} markup
   * @returns {SVGElement} The SVGElement Node rendered
   */
  private createSvgElement(markup: string, renderer: Renderer2): SVGElement {
    const div = renderer.createElement('div') as HTMLDivElement;
    // TODO: T37429 Is there a better way than innerHTML? Renderer doesn't appear
    // to have a method for creating an element from an HTML string.
    div.innerHTML = markup;
    const svg = div.querySelector('svg') as SVGElement;

    if (!svg) {
      throw new Error(
        `<svg> tag not found. Markup is ${markup.slice(0, 100)}.`,
      );
    }

    return svg;
  }

  /**
   * Set the necessary SVG attributes.
   *
   * @param {SVGElement} svg The SVG element
   */
  private setSvgAttributes(svg: SVGElement): SVGElement {
    if (!svg.getAttribute('xmlns')) {
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    }

    svg.removeAttribute('class');
    svg.setAttribute('fit', '');
    svg.setAttribute('height', '100%');
    svg.setAttribute('width', '100%');
    svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    // Disable IE11 default behavior to make SVGs focusable.
    svg.setAttribute('focusable', 'false');

    return svg;
  }
}

import { Injectable } from '@angular/core';
import { ErrorTracking } from './error-tracking.service';

/**
 * This class is used to provide a global access to the ErrorTracking service.
 * It will be used in non-injectable classes/functions like Decorators.
 * See https://stackoverflow.com/a/52667101
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorTrackingGlobalAccess {
  private static errorTracking: ErrorTracking | undefined = undefined;
  constructor(private errorTracking: ErrorTracking) {
    errorTracking.setupSentrySDK();
    ErrorTrackingGlobalAccess.errorTracking = this.errorTracking;
  }

  static getErrorTracking(): ErrorTracking {
    if (ErrorTrackingGlobalAccess.errorTracking === undefined) {
      throw new Error('ErrorTracking Service is not initialized');
    }
    return ErrorTrackingGlobalAccess.errorTracking;
  }
}

import { Inject, Injectable } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthServiceInterface } from '@freelancer/auth/interface';
import { Facebook } from '@freelancer/facebook';
import { Location } from '@freelancer/location';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { LOGIN_AUTH_SERVICE, LOGIN_CONFIG } from './login.config';
import { LoginConfig } from './login.interface';

/**
 * TODO: T280954 - Convert to functional guard.
 *
 * Currently, when converted, it throws no provider for Facebook service when logging
 * out in UI tests. Unsure why, but maybe somehow related to using inject() or the
 * logged-in/out shells.
 */
@Injectable({
  providedIn: 'root',
})
export class LogoutGuard {
  constructor(
    private location: Location,
    @Inject(LOGIN_AUTH_SERVICE) private authService: AuthServiceInterface,
    @Inject(LOGIN_CONFIG) private loginConfig: LoginConfig,
    private facebook: Facebook,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<UrlTree> {
    return this.authService.isLoggedIn().pipe(
      switchMap(isLoggedIn => {
        const { next } = route.queryParams;
        // already logged out - just progress to next/homepage
        if (!isLoggedIn) {
          return of(
            this.location.createUrlTree(
              [next || this.loginConfig.defaultLogoutRedirectUrl],
              { initialStateIsPrimary: true },
            ),
          );
        }
        return this.authService.logout().pipe(
          take(1),
          map(() =>
            this.location.createUrlTree(
              [next || this.loginConfig.defaultLogoutRedirectUrl],
              { initialStateIsPrimary: true },
            ),
          ),
          finalize(() => {
            this.facebook.logout();
          }),
        );
      }),
    );
  }
}

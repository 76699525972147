import type { CreatedBidSalesTaxApi } from 'api-typings/projects/projects';
import type { UserSalesTaxSettingApi } from 'api-typings/users/users';
import type { CreatedBidSalesTax, SalesTax } from './sales-tax.model';

export function transformSalesTax(salesTax: UserSalesTaxSettingApi): SalesTax {
  return {
    id: salesTax.id,
    countryCode: salesTax.country_code,
    taxNumber: salesTax.tax_number,
    taxType: salesTax.tax_type,
    taxRate: salesTax.tax_rate,
  };
}

export function transformCreatedBidSalesTax(
  salesTax: CreatedBidSalesTaxApi,
): CreatedBidSalesTax {
  return {
    taxNumber: salesTax.tax_number,
    taxType: salesTax.tax_type,
    taxRate: salesTax.tax_rate,
  };
}

import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Auth } from '@freelancer/auth';
import { APP_NAME } from '@freelancer/config';
import { Location } from '@freelancer/location';
import { LOGIN_CONFIG } from '@freelancer/login';
import { Pwa } from '@freelancer/pwa';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShellConfig } from './shell-config.service';

/**
 * Guard which restricts access to logged-out pages
 *
 * When enabled, redirects to login on (almost) every page,
 * even ones that normally can be accessed when logged out.
 */
export const LoggedOutRestrictedGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const appName = inject(APP_NAME);
  const auth = inject(Auth);
  const location = inject(Location);
  const loginConfig = inject(LOGIN_CONFIG);
  const pwa = inject(Pwa);
  const shellConfig = inject(ShellConfig);

  return auth.isLoggedIn().pipe(
    map(isLoggedIn => {
      const config = shellConfig.getRecursiveRouteData(route);
      // Capacitor apps always have restricted logged-out
      if (
        !isLoggedIn &&
        !config.bypassLoggedOutRestricted?.native &&
        pwa.isInstalled()
      ) {
        // Restricted access to homepage should not redirect back to
        // homepage once logged in/signed up, and instead let backend handle
        // next route
        const next =
          state.url === '/' ? undefined : encodeURIComponent(state.url);

        return location.createUrlTree(['/login'], {
          queryParams: {
            next,
          },
          // Skip returning 301 here since we want to 301 to `saml_sso/sso.php`
          // when using `ssoIntegration` which is done by the LoginGuard
          skipSSRRedirection: true,
        });
      }

      if (
        !isLoggedIn &&
        // this build has restricted logged out
        loginConfig.loggedOutRestricted &&
        // and it's not bypassed in this specific app
        !config.bypassLoggedOutRestricted?.deloitte &&
        appName === 'deloitte'
      ) {
        return location.createUrlTree(['/login'], {
          queryParams: {
            next: encodeURIComponent(state.url),
          },
          skipSSRRedirection: true,
        });
      }
      return true;
    }),
  );
};

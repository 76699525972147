import { isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  NgZone,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Auth } from '@freelancer/auth';
import type { Applications, CompatApplications } from '@freelancer/config';
import { APP_NAME, ENVIRONMENT_NAME, Environment } from '@freelancer/config';
import {
  FREELANCER_HTTP_CONFIG,
  FreelancerHttpConfig,
} from '@freelancer/freelancer-http';
import { Pwa } from '@freelancer/pwa';
import { UserAgent } from '@freelancer/user-agent';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Sentry from '@sentry/angular-ivy';
import { hasTracingEnabled } from '@sentry/core';
import * as SentryIntegrations from '@sentry/integrations';
import type { Span, StartSpanOptions } from '@sentry/types';
import type { SamplingContext } from '@sentry/types/types/transaction';
import { CookieService } from 'ngx-cookie';
import { combineLatest, firstValueFrom, of } from 'rxjs';
import { DISABLE_ERROR_TRACKING, TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';

export interface ExtraData {
  [k: string]: any;
}

/**
 * This class is used to track errors/performance on the browser side.
 * Any change to this class should be updated to the counterpart ErrorTrackingServer as well.
 */
@UntilDestroy({ className: 'ErrorTracking' })
@Injectable({
  providedIn: 'root',
})
export class ErrorTracking {
  constructor(
    private auth: Auth,
    private ngZone: NgZone,
    private pwa: Pwa,
    private userAgent: UserAgent,
    @Inject(FREELANCER_HTTP_CONFIG) private httpConfig: FreelancerHttpConfig,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(APP_NAME) private appName: Applications & CompatApplications,
    @Inject(ENVIRONMENT_NAME) private environment: Environment,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DISABLE_ERROR_TRACKING)
    @Optional()
    private disableErrorTracking: boolean,
    private cookies: CookieService,
  ) {}

  getActiveSpan(): Span | undefined {
    return Sentry.getActiveSpan();
  }

  isSentryInitialized(): boolean {
    return Sentry.isInitialized();
  }

  hasTracingEnabled(): boolean {
    return hasTracingEnabled();
  }

  setupSentrySDK(): void {
    if (this.isSentryInitialized()) {
      return;
    }
    const { tracesSampleRate } = this.config;
    const isWhitelistUser = this.cookies.get('IS_WHITELIST_USER') === 'true';
    const isProd = this.environment === 'prod';

    function getTracesSampler(): (
      samplingContext: SamplingContext,
    ) => number | boolean {
      return function traceSampler(context): number | boolean {
        if (context.location?.search.includes('sentry-tracing=true')) {
          return 1;
        }
        if (isProd) {
          if (isWhitelistUser) {
            return 0.01;
          }
          if (context.location?.href.includes('deposit')) {
            return 0.001;
          }
        }
        return tracesSampleRate ?? 0;
      };
    }
    if (
      isPlatformBrowser(this.platformId) &&
      this.config.enableErrorTracking &&
      !this.disableErrorTracking &&
      this.isWhitelistedBrowsersAndPlatforms()
    ) {
      const sentryTracingEnabled = tracesSampleRate !== undefined;
      // do not set tracesampler if it's disabled, otherwise Sentry will treat it as enabled.
      const traceSampler = sentryTracingEnabled
        ? {
            tracesSampler: getTracesSampler(),
          }
        : {};

      this.ngZone.runOutsideAngular(() => {
        const tracingIntegrations = sentryTracingEnabled
          ? [
              Sentry.browserTracingIntegration({
                // This prevents the app from stabilising and affects e2e test runtime and our TBT tracking.
                instrumentNavigation: false,
              }),
            ]
          : [];

        Sentry.addTracingExtensions();
        Sentry.init({
          dsn: this.config.sentryDsn,
          release:
            window.webapp &&
            window.webapp.version &&
            window.webapp.version.gitRevision
              ? window.webapp.version.gitRevision
              : 'invalid revision',
          environment: this.environment,
          ignoreErrors: [
            // This is caused by anchorScrolling, see
            // https://github.com/angular/angular/issues/26854.
            // It's harmless though.
            /SyntaxError: Failed to execute 'querySelector' on 'Document': '.*' is not a valid selector/,
            /SyntaxError: '.*' is not a valid selector/,
            /SyntaxError: SyntaxError/,
            /SyntaxError: The string did not match the expected pattern/,
            // These are caused browsers cancelling ongoing requests due to a
            // location change
            'Http failure response for (unknown url): 0',
            /Error: Loading chunk \d+ failed/,
            'NS_ERROR_NOT_INITIALIZED',
            // Random client errors we can't do much about but show a banner at
            // some point
            'Out of memory',
            // The Angular Router currently throws on URLs with scripts tags or
            // invalid characters
            // See https://github.com/angular/angular/issues/21032
            'Error: Cannot match any routes. URL Segment:',
            // This is likely caused by some broken Kaspersky security product,
            // which wraps `XMLHttpRequest` in order to analyze web pages
            // behaviors, hence why the Error ends up t in the Angular Zone.
            'ns.GetCommandSrc is not a function',
            // FIXME: T267853 - agm-core is broken as the Maps SDK isn't always loaded from
            // outside the Angular Zone
            // (https://github.com/SebastianM/angular-google-maps/blob/master/packages/core/services/fit-bounds.ts#L42)
            // This is caused by clients blocking the Google Maps SDK
            'Event: {"isTrusted":true}',
            // This is suspected to be caused by some broken Chrome Desktop
            // browser extension, see T121550
            'target parent not found',
          ],
          /**
           * Only whitelist exceptions in /assets/** for non-native platforms.
           * We retain the whitelist for other platforms because we would like
           * to reduce noise from exceptions outside of the Angular app.
           *
           * The intention for removing the whitelist for native is because
           * the Capacitor service worker plugin in iOS deploy user scripts
           * into the Capacitor WebView. Exceptions from user scripts are
           * not associated to any URL so the only way to capture it is
           * allowing Sentry to capture exceptions in the global context.
           *
           * In the case for Android, there isn't any practical use cases for
           * not using whitelist, because we didn't implement any custom
           * Javascript to deploy into the Android WebView. However, we still
           * capture exceptions from the global context just in case there are
           * any compatibility issues with the Android WebView.
           */
          allowUrls: !this.pwa.isNative() ? [/\/assets\//] : undefined,
          denyUrls: [/^file:\/\//, /^moz-extension:\/\//],
          integrations: integrations =>
            // integrations will be all default integrations
            [
              ...integrations.filter(
                integration =>
                  !['TryCatch', 'GlobalHandlers'].includes(integration.name),
              ),
              SentryIntegrations.extraErrorDataIntegration(),
              SentryIntegrations.reportingObserverIntegration(),
              ...tracingIntegrations,
            ],
          ...traceSampler,
          tracePropagationTargets: [
            'localhost',
            this.httpConfig.baseUrl,
            /^\//,
          ],
          beforeSend(event) {
            if (
              event.message &&
              event.message.startsWith('ReportingObserver')
            ) {
              // There's a Boomerang bug where getEntriesByType('navigation')
              // (Nav Timings 2) is called before it's ready, leading Boomerang
              // to fallback to chrome.loadTimes()
              if (event.message.includes('chrome.loadTimes() is deprecated')) {
                return null;
              }
              // This is caused by perfect-scrolling, remove when/if
              // https://github.com/utatti/perfect-scrollbar/pull/810 ever get
              // merged.
              if (
                event.message.includes(
                  'Ignored attempt to cancel a touchmove event',
                )
              ) {
                return null;
              }
              // Only include events generated from the webapp source files in
              // order to cut off the noise
              if (
                (event.extra?.body as any)?.sourceFile?.includes(
                  '/assets/webapp/',
                )
              ) {
                return event;
              }
              return null;
            }
            return event;
          },
        });
        this.configSentryScope();
      });
    }
  }

  startInactiveSpan(context: StartSpanOptions): Sentry.Span | undefined {
    if (!this.isSentryInitialized()) {
      return undefined;
    }

    return this.ngZone.runOutsideAngular(() =>
      Sentry.startInactiveSpan(context),
    );
  }

  endInactiveSpan(span: Sentry.Span | undefined): void {
    if (span === undefined) {
      return;
    }
    return this.ngZone.runOutsideAngular(() => {
      span.end();
    });
  }

  startSpan<T>(
    context: StartSpanOptions,
    callback: (span: Span | undefined) => T,
  ): T {
    if (!this.isSentryInitialized()) {
      return callback(undefined);
    }

    return Sentry.startSpan(context, callback);
  }

  captureMessage(msg: string, extras?: ExtraData): void {
    this.sentryCapture(msg, extras);
  }

  captureException(error: Error, extras?: ExtraData): void {
    this.sentryCapture(error, extras);
  }

  private sentryCapture(data: Error | string, extras?: ExtraData): void {
    if (!this.isSentryInitialized()) {
      return;
    }

    // sometimes this service isn't fully ready when we want to log errors
    const authState$ = this.auth ? this.auth.authState$ : of(undefined);
    firstValueFrom(authState$.pipe(untilDestroyed(this))).then(auth => {
      Sentry.withScope(scope => {
        if (auth) {
          scope.setUser({ id: auth.userId });
        }
        if (extras) {
          scope.setExtras(extras);
        }
        if (typeof data === 'string') {
          Sentry.captureMessage(data);
        } else {
          Sentry.captureException(data);
        }
      });
    });
  }

  // yeah UA parsing is bad blah blah but catching large failures quickly is
  // more important than being flooded by exotic browsers.
  private isWhitelistedBrowsersAndPlatforms(): boolean {
    const { name, version = '' } = this.userAgent.getUserAgent().getBrowser();

    return (
      // Desktop or mobile browsers.
      (name === 'IE' && parseInt(version, 10) >= 11) ||
      (name === 'Edge' && parseInt(version, 10) >= 17) ||
      (name === 'Firefox' &&
        parseInt(version, 10) >= 65 &&
        !(this.userAgent.getUserAgent().getOS().name === 'Android')) ||
      (name === 'Chrome' && parseInt(version, 10) >= 72) ||
      (name === 'Chromium' && parseInt(version, 10) >= 72) ||
      (name === 'Safari' && parseInt(version, 10) >= 11) ||
      // Native platforms, ie. iOS and Android.
      this.pwa.isNative()
    );
  }

  private configSentryScope(): void {
    const scope = Sentry.getCurrentScope();
    if (
      window.webapp &&
      window.webapp.version &&
      window.webapp.version.buildTimestamp
    ) {
      scope.setTag(
        'releaseDate',
        new Date(window.webapp.version.buildTimestamp).toUTCString(),
      );
    }
    scope.setTag('app', this.appName);

    // This is needed to identify the platform that runs the current JS context.
    scope.setTag('platform', this.pwa.getPlatform()); // ios, android, or web.

    // Tag the native app version and OS version for native Apps.
    if (this.pwa.isNative()) {
      firstValueFrom(
        combineLatest([
          this.pwa.appVersion(),
          this.pwa.nativeDeviceInfo(),
        ]).pipe(untilDestroyed(this)),
      ).then(([appVersion, nativeDeviceInfo]) => {
        if (appVersion) {
          if (appVersion.nativeVersion) {
            scope.setTag('native-app-version', appVersion.nativeVersion);
          }
          if (appVersion.nativeBuildNumber) {
            scope.setTag('native-build-number', appVersion.nativeBuildNumber);
          }
        }
        if (nativeDeviceInfo) {
          scope.setTag('native-os-version', nativeDeviceInfo.osVersion);
        }
      });
    }
  }
}

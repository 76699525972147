import type {
  NotificationSettingApi,
  NotificationSettingsGetResultApi,
  NotificationSettingsMetadataApi,
  NotificationSettingsUpdateResultApi,
} from 'api-typings/users/users';
import type {
  UserNotificationSetting,
  UserNotificationSettingMetadata,
} from './notification-settings.model';

export function transformNotificationSettingGetResult(
  result: NotificationSettingsGetResultApi,
): readonly UserNotificationSetting[] {
  return result.notification_settings.map(setting => ({
    id: getUserNotificationSettingId(setting),
    target: setting.target,
    targetId: setting.target_id,
    value: setting.value,
    parentId: setting.parent_id,
    deliveryType: setting.delivery_type,
    userId: setting.user_id,
    metadata:
      setting.metadata && Object.keys(setting.metadata).length !== 0
        ? transformNotificationSettingMetadata(setting.metadata)
        : undefined,
  }));
}

export function transformNotificationSettingUpdateResult(
  result: NotificationSettingsUpdateResultApi,
): UserNotificationSetting {
  const value = result.notification_setting;
  return {
    id: getUserNotificationSettingId(value),
    target: value.target,
    targetId: value.target_id,
    deliveryType: value.delivery_type,
    value: value.value,
    parentId: value.parent_id,
    userId: value.user_id,
    metadata:
      result.notification_setting.metadata &&
      Object.keys(result.notification_setting.metadata).length !== 0
        ? transformNotificationSettingMetadata(
            result.notification_setting.metadata,
          )
        : undefined,
  };
}

export function transformNotificationSettingMetadata(
  metadata: NotificationSettingsMetadataApi,
): UserNotificationSettingMetadata {
  return {
    frequency: metadata?.frequency,
  };
}

export function getUserNotificationSettingId(
  setting: NotificationSettingApi,
): string {
  let id = `${setting.target}-${setting.delivery_type}`;

  if (setting.target_id !== undefined) {
    id += `-${setting.target_id}`;
  }

  if (setting.parent_id !== undefined) {
    id += `-${setting.parent_id}`;
  }

  return id;
}

import { InjectionToken } from '@angular/core';

export const ABTEST_WHITELIST_COOKIE = new InjectionToken<string>(
  'ABTestWhitelistCookie',
);

export const LEGACY_STORAGE_KEY = 'WEBAPP_ABTEST_CACHE_V1';
export const STORAGE_KEY = 'WEBAPP_ABTEST_CACHE_V2';

// Timestamp 2 hours in milliseconds since timestamp in
// javascript is in milliseconds.
export const VARIATION_TTL = 2 * 60 * 60 * 1000;

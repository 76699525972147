import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@freelancer/activated-route';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';

@Injectable({
  providedIn: 'root',
})
export class MgidTracking {
  private isEnabled = false;
  private isInitialized = false;

  constructor(
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private trackingConsent: TrackingConsent,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    this.isEnabled =
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED;
    this.isInitialized = true;
  }

  async trackCustomEvent(buttonId: string): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }
    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.loadTrackingSnippet();
      window._mgq?.push(['MgSensorInvoke', buttonId]);
    });
  }

  async loadTrackingSnippet(): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }
    if (
      window._mgq ||
      !this.isEnabled ||
      this.route.snapshot.queryParams.utm_source !== 'mgid'
    ) {
      return;
    }

    window.MgSensorData = window.MgSensorData || [];
    window._mgq = window._mgq || [];

    window.MgSensorData.push({
      cid: 644_483,
      lng: 'us',
      project: 'a.mgid.com',
    });
    const siblingScriptTag = document.getElementsByTagName('script')[0];
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    const dateTime = Date.now();
    scriptTag.src = `https://a.mgid.com/mgsensor.js?d=${dateTime}`;
    (siblingScriptTag.parentNode as Node).insertBefore(
      scriptTag,
      siblingScriptTag,
    );
  }
}

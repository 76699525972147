import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { ToastAlertService } from './toast-alert.service';

export const ToastAlertOverridesGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  const toastAlertService = inject(ToastAlertService);
  const params = route.queryParams;

  if (params.no_toast_auto_close) {
    toastAlertService.disableAutoCloseGlobally();
  }

  return true;
};

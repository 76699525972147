import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { isDefined } from '@freelancer/utils';

const NUM_ZERO_PER_GROUP = 3;
const NUM_ZERO_GROUP_THOUSAND = 2;
const NUM_ZERO_GROUP_MILLION = 3;
const MAX_NUM_ZERO_GROUP = NUM_ZERO_GROUP_MILLION;

/**
 * TODO: T237419 - Support localization
 *
 * This pipe transforms a number to the compact decimal format.
 * Currently, the compact logic supports from ones up to the hundred-millions,
 * anything bigger will only be truncated up to the hundred-millions place.
 *
 * This pipe always:
 * - removes decimal numbers
 * - rounds down the value to the nearest highest place value
 *
 *
 * Examples:
 *
 * 5 => '5'
 * 10 => '10'
 * 97 => '97'
 * 190 => '190'
 * 1890 => '1K'
 * 2500 => '2K'
 * 54321 => '54K'
 * 698221 => '698K'
 * 1499211 => '1M'
 * 68000101 =>  '68M'
 * 848101292 => '848M'
 *
 * Over hundred million:
 * 3999000111 => 3999M
 *
 * Numbers with decimal places
 * 1234.234 => 1K
 *
 * Negative numbers work the same as positive numbers.
 */
@Pipe({ name: 'compactNumber' })
export class CompactNumberPipe implements PipeTransform {
  transform(value: number | undefined): string | undefined {
    if (!isDefined(value)) {
      return undefined;
    }

    const newValue = Math.floor(Math.abs(value));
    const stringValue = newValue.toString();
    let numZeroGroups = Math.ceil(stringValue.length / NUM_ZERO_PER_GROUP);
    numZeroGroups =
      numZeroGroups > MAX_NUM_ZERO_GROUP
        ? NUM_ZERO_GROUP_MILLION
        : numZeroGroups;
    const placeValueSymbol = this.getPlaceValueSymbol(numZeroGroups);

    const divideValueBy = 10 ** ((numZeroGroups - 1) * 3);

    const compactedNumber = Math.floor(newValue / divideValueBy);

    const sign = value < 0 && compactedNumber > 0 ? '-' : '';

    return `${sign}${compactedNumber}${placeValueSymbol}`;
  }

  getPlaceValueSymbol(numZeroGroups: number): string {
    if (numZeroGroups >= NUM_ZERO_GROUP_MILLION) {
      return 'M';
    }

    if (numZeroGroups >= NUM_ZERO_GROUP_THOUSAND) {
      return 'k';
    }

    return '';
  }
}

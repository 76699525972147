export enum ViewHeaderBackground {
  DARK = 'dark',
  LIGHT = 'light',
  TRANSPARENT = 'transparent',
  WHITE = 'white',
}

export enum ViewHeaderType {
  BACK = 'back',
  CLOSE = 'close',
}

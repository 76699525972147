import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Haptics } from '@freelancer/haptics';
import { Location } from '@freelancer/location';
import { VerticalAlignment } from '@freelancer/ui/grid';
import { HeadingColor, HeadingType } from '@freelancer/ui/heading';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { TextAlign, TextSize } from '@freelancer/ui/text';
import { BackgroundColor, LogoSize } from '../logo';
import { ViewHeaderBackground, ViewHeaderType } from './view-header.types';

@Component({
  selector: 'fl-view-header',
  template: `
    <div
      class="ViewHeaderContainer"
      [attr.data-background]="background"
    >
      <div
        class="ViewHeaderContent"
        [attr.data-side-notch]="sideNotch"
      >
        <div
          class="ViewHeaderContent-inner"
          [class.HasLogo]="logo"
        >
          <fl-logo
            *ngIf="logo"
            [backgroundColor]="logoColor"
            [size]="LogoSize.SMALL"
          ></fl-logo>
          <div
            *ngIf="showBackButton"
            class="ViewHeaderContent-action ViewHeaderContent-action--back"
            [flHideDesktop]="true"
            [flHideTablet]="backButtonHideTablet"
            (click)="handleIconClick()"
          >
            <fl-icon
              *ngIf="type === 'back'"
              i18n-title="Header back button"
              title="Back"
              [size]="IconSize.SMALL"
              [name]="'ui-chevron-left'"
              [color]="IconColor.INHERIT"
              [clickAnimation]="'shrink'"
              [clickable]="true"
            ></fl-icon>
            <fl-icon
              *ngIf="type !== 'back'"
              i18n-title="Header close button"
              title="Close"
              [size]="IconSize.SMALL"
              [name]="'ui-close'"
              [color]="IconColor.INHERIT"
              [clickable]="true"
            ></fl-icon>
          </div>

          <div
            *ngIf="!logo"
            class="ViewHeaderContent-title"
            [class.ViewHeaderContent-title--indented]="showBackButton"
          >
            <ng-content></ng-content>

            <fl-heading
              *ngIf="heading"
              [headingType]="HeadingType.H1"
              [size]="TextSize.MID"
              [sizeDesktop]="TextSize.LARGE"
              [textAlignDesktopSmall]="TextAlign.CENTER"
              [color]="HeadingColor.INHERIT"
            >
              {{ heading }}
            </fl-heading>
          </div>

          <fl-button
            *ngIf="showMenu"
            class="ViewHeaderContent-action"
            (click)="menuClick.emit()"
          >
            <fl-icon
              i18n-title="Header menu button"
              title="Menu"
              [name]="'ui-show-more'"
              [size]="IconSize.SMALL"
              [color]="IconColor.INHERIT"
            ></fl-icon>
          </fl-button>
        </div>
      </div>

      <ng-content select=".banner"></ng-content>
    </div>
  `,
  styleUrls: ['./view-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewHeaderComponent {
  BackgroundColor = BackgroundColor;
  IconSize = IconSize;
  IconColor = IconColor;
  LogoSize = LogoSize;
  VerticalAlignment = VerticalAlignment;
  HeadingType = HeadingType;
  TextSize = TextSize;
  HeadingColor = HeadingColor;
  TextAlign = TextAlign;

  @Input() heading?: string;
  @Input() logo = false;

  @Input() logoColor: BackgroundColor = BackgroundColor.DARK;

  @Input() type = ViewHeaderType.BACK;
  @Input() showBackButton = true;
  @Input() showMenu = false;
  @Input() sideNotch = true;
  @Input() customBackAction = false;
  @Input() backButtonHideTablet = true;

  @HostBinding('attr.data-background')
  @Input()
  background = ViewHeaderBackground.DARK;

  @Output() menuClick = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() customBack = new EventEmitter();

  constructor(private haptics: Haptics, private location: Location) {}

  handleIconClick(): void {
    this.haptics.light();
    if (this.type === ViewHeaderType.BACK) {
      // TODO: T267853 - handle route changes under one heading (eg. pvp tabs)
      if (this.customBackAction) {
        this.customBack.emit();
      } else {
        this.location.back();
      }
    } else {
      this.close.emit();
    }
  }
}

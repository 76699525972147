import type { DocumentFieldTypeApi } from 'api-typings/users/users';

/**
 * Data model for the required documents types and information
 * required for verifying a particular verification process type
 */
export interface VerificationProcessType {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly subTitle?: string;
  readonly subDescription?: string;
  readonly url: string;
  readonly documentTypes: readonly DocumentType[];
}

export interface DocumentType {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly instructions: string;
  readonly instructionsImage?: string;
  readonly documentTypeFiles: readonly DocumentTypeFile[];
  readonly documentTypeFields: readonly DocumentTypeField[];
  readonly hasExpiration?: boolean;
  readonly isRequired?: boolean;
}

export interface DocumentTypeFile {
  readonly id: number;
  readonly name: string;
}

export interface DocumentTypeField {
  readonly id: number;
  readonly name: string;
  readonly type: DocumentFieldTypeApi;
  readonly isRequired: boolean;
}

export enum VERIFICATION_PROCESS_TYPES {
  LOADSHIFT_CARRIER_VERIFICATION = 'loadshift-carrier-verification',
  INSURANCES_AND_CERTIFICATES = 'insurances-and-certificates',
  FREELANCER_VIDEO_VERIFICATION = 'video-verification',
}

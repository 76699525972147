import { Injectable } from '@angular/core';
import { Location } from '@freelancer/location';
import { Pwa } from '@freelancer/pwa';
import { TrackingConsentStatus } from './interface';

/**
 * A service which fetches tracking consent from users,
 * ie. whether they have allowed us, or third parties, to track them.
 *
 * Currently, this does not ask the user and only uses platform
 * or a specialised no_third_party_tracking query param for third-party
 */
@Injectable({
  providedIn: 'root',
})
export class TrackingConsent {
  isDenied: boolean;

  constructor(private pwa: Pwa, private location: Location) {}

  /**
   * Get the user's current authorization status for third-party tracking.
   * eg. Facebook pixel, Google Analytics
   */
  async getThirdPartyStatus(): Promise<TrackingConsentStatus> {
    // If explicitly disabled via query param, deny.
    // Also save state so if the query param disappears in later navigations,
    // the user's choice to deny takes priority over the current query param.
    this.isDenied =
      this.isDenied ||
      !!new URL(this.location.href).searchParams.get('no_third_party_tracking');
    if (this.isDenied) {
      return TrackingConsentStatus.DENIED;
    }

    if (this.pwa.isNative()) {
      // On native platforms, we set tracking as denied by default.
      // This could use a native API like iOS App Tracking Transparency,
      // but most users deny those anyway so it's probably a waste of time.
      return TrackingConsentStatus.DENIED;
    }

    // Default to authorized for everything else.
    return TrackingConsentStatus.AUTHORIZED;
  }
}

/**
 * Additional insights for a freelancer's bid
 */
export interface InsightsBid {
  readonly id: number;
  readonly projectId: number;
  readonly rating: number;
  readonly rank: number;
  readonly actionsTaken: InsightsBidsActionsTaken;
  readonly timeSubmitted: number;
  readonly projectInvites: number;
  readonly projectChatsInitiated: number;
}

export interface InsightsBidsActionsTaken {
  readonly clientSawYourBid: boolean;
  readonly clientSawYourProfile: boolean;
}

export enum ClientViewedBidNotificationType {
  BETTER_BID_QUALITY = 'better_bid_quality',
  RELEVANT_SKILLS = 'relevant_skills',
  CHECK_NEW_PROJECTS = 'check_new_projects',
}

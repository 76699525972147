import {
  OrderByDirection,
  getQueryParamValue,
  type Backend,
} from '@freelancer/datastore/core';
import type { RecurringBillingInformationCollection } from './recurring-billing-information.types';

export function recurringBillingInformationBackend(): Backend<RecurringBillingInformationCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.DESC,
    },
    fetch: (authUid, ids, query, order) => ({
      endpoint: 'quotations/0.1/recurring_billing_information/',
      params: {
        ids,
        subscription_item_ids: getQueryParamValue(query, 'subscriptionItemId'),
      },
    }),
    push: (authUid, recurringBillingInformation) => {
      return {
        isGaf: false,
        endpoint: 'quotations/0.1/recurring_billing_information/',
        payload: {
          subscription_item_id: recurringBillingInformation.subscriptionItemId,
          token: recurringBillingInformation.token,
          payment_method: recurringBillingInformation.paymentMethod,
        },
      };
    },
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}

import { InjectionToken } from '@angular/core';

export interface AuthConfig {
  authHashCookie: string;
  authHeaderName: string;
  authIosKeychainToken: string;
  baseUrl: string;
  csrfCookie?: string;
  switchAccountHackyEndpoint?: string;
  userIdCookie: string;
  gdprCookie?: string;
  referralCookie?: string;
}

export type NavigationSwitchAccountResultAjax = { gotoUrl?: string } | []; // Because an empty associative array is just an array

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('AuthConfig');
export const ADMIN_AUTH_CONFIG = new InjectionToken<AuthConfig>(
  'AdminAuthConfig',
);

import type {
  RemovalReasonApi,
  SourceTypeApi,
} from 'api-typings/messages/messages_types';
import type { RichMessage } from './rich-message.model';

export enum MessageSendStatus {
  SENDING,
  SENT,
  FAILED,
}

export interface MessageFileAttachment {
  readonly uuid: string;
  readonly name: string;
}

/**
 * A chat message.
 *
 * Projections: `thread_details`, `user_details`
 */
export interface Message {
  // TODO: T237244. Deprecate `attachments` after migration to the new File Service
  readonly attachments: readonly string[]; // filenames
  readonly files?: readonly MessageFileAttachment[];
  readonly clientMessageId?: number;
  readonly fromUser: number;
  readonly id: number; // Either client or messageId
  readonly message: string;
  readonly messageId?: number;
  readonly messageSource?: SourceTypeApi;
  readonly parentId?: number;
  readonly quotedMessageId?: number;
  readonly removeReason?: RemovalReasonApi;
  readonly richMessage?: RichMessage;
  readonly threadId: number;
  readonly timeCreated: number;
  readonly sendStatus: MessageSendStatus;
}

import { asObject, emitChangesOnRequest } from '@freelancer/operators';
import { sameElements } from '@freelancer/utils';
import type { Observable } from 'rxjs';
import { Subject, combineLatest, firstValueFrom, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import type { RequestDataPayload } from './actions';
import { DatastoreDocument } from './datastore-document';
import type { RecursivePartial } from './helpers';
import { arrayIsShallowEqual } from './helpers';
import type { RequestStatus } from './request-status-handler.service';
import type { StoreBackendInterface } from './store-backend.interface';
import type {
  BackendDeleteResponse,
  BackendPushResponse,
  BackendSetResponse,
  BackendUpdateResponse,
} from './store-backend.model';
import type { QueryResultWithMetadata } from './store.helpers';
import {
  getEqualOrInIdsFromQuery,
  isIdQuery,
  isTransformedIdsQuery,
  removeEqualOrInIdsFromQuery,
  stringifyReference,
} from './store.helpers';
import type {
  ApproximateTotalCountType,
  DatastoreCollectionType,
  DatastorePushCollectionType,
  DatastoreSetCollectionType,
  MaybeDatastoreDeleteCollectionType,
  MaybeDatastorePushCollectionType,
  MaybeDatastoreSetCollectionType,
  MaybeDatastoreUpdateCollectionType,
  PushDocumentType,
  Reference,
  SetDocumentType,
} from './store.model';

export interface ValueChangesLessOften<C extends DatastoreCollectionType> {
  readonly values$: Observable<readonly C['DocumentType'][]>;
  readonly hasChanges$: Observable<boolean>;
  refresh(): void;
}

export class DatastoreCollection<C extends DatastoreCollectionType> {
  private valueChanges$: Observable<readonly C['DocumentType'][]>;

  constructor(
    private requestDataConfig: {
      readonly dedupeWindowTime: number;
      readonly batchWindowTime: number;
      readonly maxBufferTime: number;
    },
    private ref$: Observable<Reference<C>>,
    private storeBackend: StoreBackendInterface,
    public status$: Observable<RequestStatus<C>>,
    private queryResult$: Observable<
      QueryResultWithMetadata<C> & {
        readonly request: RequestDataPayload<C> | undefined;
      }
    >,
    private approximateTotalCount$: Observable<
      ApproximateTotalCountType<C> | undefined
    >,
  ) {
    /**
     * This should be moved to `valueChanges()` but it causes issues
     * when you do `valueChanges() | flasync` in a template.
     * We should wean people off doing this, but until then
     * this needs to be in the constructor.
     */
    this.valueChanges$ = this.queryResult$.pipe(
      map(queryResult =>
        queryResult.documentsWithMetadata.map(
          documentWithMetadata => documentWithMetadata.rawDocument,
        ),
      ),
      distinctUntilChanged(arrayIsShallowEqual),
    );
  }

  /**
   * Return an array of all documents in a collection for a given query,
   * emitting changes as the occur.
   */
  valueChanges(): Observable<readonly C['DocumentType'][]> {
    return this.valueChanges$;
  }

  /**
   * Return a map of all documents in a collection for a given query,
   * emitting changes as the occur.
   */
  valueChangesAsObject(): Observable<{
    readonly [id: string]: C['DocumentType'];
  }> {
    return this.valueChanges().pipe(asObject());
  }

  /**
   * Return an array of all documents in a collection for a given query,
   * emitting changes to documents as they occur, but only adding/removing
   * documents when requested by the `notifier$`, or when the query changes.
   *
   * Returns an object with both the `values` themselves and a `changes` flag
   * as to if there are new elements that have been added or removed.
   *
   * This DOES NOT return cached values older than a `maximumStaleness`
   * so that on loading a page with an old cache you don't first get a
   * cached value that immediately "changes". This staleness CAN NOT
   * be set to be smaller than the internal datastore network request batching
   * or deduplication logic.
   */
  valueChangesLessOften({
    maximumStaleness = 300_000, // Five minutes
  }: {
    readonly maximumStaleness?: number;
  } = {}): ValueChangesLessOften<C> {
    if (maximumStaleness <= this.requestDataConfig.batchWindowTime) {
      throw new Error(
        'Cannot request a staleness less than the window for batching requests',
      );
    }
    if (maximumStaleness <= this.requestDataConfig.dedupeWindowTime) {
      throw new Error(
        'Cannot request a staleness less than the window for de-duplicating requests',
      );
    }

    const recently = Date.now() - maximumStaleness;
    const notifier$ = new Subject<void>();
    const refresh = (): void => notifier$.next();

    const valuesAndHasChanges$ = this.queryResult$.pipe(
      /**
       * Don't return old queries.
       *
       * This effectively disables the initial cache while emitting whenever
       * the list changes. This relies on that fact that subscribing for the
       * first time makes a new network request. Subsequent subscribing is fine
       * due to the `publishReplay`/`refCount` at the end.
       *
       * It will emit not just for network fetches but also if the list is
       * updated in any way (network fetch, websockets or user actions).
       *
       * That said, when requesting ids we don't actually construct the query
       * in the store and so we don't have a `timeUpdated`. For `id` based
       * queries you should probably not be using `valueChangesLessOften`
       * but if you are it's probably reasonable to just return what you have.
       */
      filter(
        queryResult =>
          (queryResult.request &&
            isTransformedIdsQuery(queryResult.request.ref)) ||
          (queryResult.timeUpdated !== undefined &&
            queryResult.timeUpdated > recently),
      ),

      // Emit a new list either when asked, or when the query changes
      emitChangesOnRequest(
        queryResult =>
          queryResult.documentsWithMetadata.map(
            documentWithMetadata => documentWithMetadata.rawDocument,
          ),
        /* Emit the ref$ when ref$ OR notifier$ emits. */
        combineLatest([this.ref$, notifier$.pipe(startWith(undefined))]).pipe(
          map(([ref, notifier]) => ref),
        ),

        /**
         * When the datastore emits data with the query matching
         * what the user requests, then emit the first data emitted.
         */
        (queryResult, ref) => {
          if (queryResult.request === undefined) {
            return false;
          }
          if (isIdQuery(ref.query)) {
            return sameElements(
              getEqualOrInIdsFromQuery(ref.query) ?? [],
              queryResult.request.ref.path.ids ?? [],
            );
          }
          return (
            queryResult.request !== undefined &&
            stringifyReference(queryResult.request.ref) ===
              stringifyReference(moveIdsFromQuery(ref))
          );
        },
      ),

      /**
       * We want someone who subscribes late to still get the last emission
       * (as the filter would otherwise mean that they would not).
       */
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    return {
      values$: valuesAndHasChanges$.pipe(
        map(x => x.values),
        distinctUntilChanged(arrayIsShallowEqual),
      ),
      hasChanges$: valuesAndHasChanges$.pipe(
        map(x => x.hasChanges),
        distinctUntilChanged(),
      ), // FIXME: T267853 - add distinctUntilChanged
      refresh,
    };
  }

  approximateTotalCount(): Observable<
    ApproximateTotalCountType<C> | undefined
  > {
    return this.approximateTotalCount$;
  }

  push(
    // Make calling this function fail if you haven't defined `C['Backend']['Push']`
    document: C extends DatastorePushCollectionType
      ? PushDocumentType<C>
      : never,
  ): Promise<BackendPushResponse<MaybeDatastorePushCollectionType<C>>> {
    return firstValueFrom(
      this.ref$.pipe(
        take(1),
        map(ref => ref as unknown as Reference<C>), // Unfortunate type casting
        switchMap(ref => this.storeBackend.push(ref, document)),
      ),
    );
  }

  set(
    id: number | string,
    // Make calling this function fail if you haven't defined `C['Backend']['Set']`
    document: C extends DatastoreSetCollectionType ? SetDocumentType<C> : never,
  ): Promise<BackendSetResponse<MaybeDatastoreSetCollectionType<C>>> {
    return firstValueFrom(
      this.ref$.pipe(
        take(1),
        map(ref => ref as unknown as Reference<C>), // Unfortunate type casting
        switchMap(ref => this.storeBackend.set(ref, id, document)),
      ),
    );
  }

  update(
    id: number | string,
    // Make calling this function fail if you haven't defined `C['Backend']['Update']`
    delta: C['Backend']['Update'] extends never
      ? never
      : RecursivePartial<C['DocumentType']>,
  ): Promise<BackendUpdateResponse<MaybeDatastoreUpdateCollectionType<C>>> {
    return firstValueFrom(
      this.ref$.pipe(
        take(1),
        map(
          // Unfortunate type casting
          ref =>
            ref as unknown as Reference<MaybeDatastoreUpdateCollectionType<C>>,
        ),
        switchMap(ref =>
          this.storeBackend.update<MaybeDatastoreUpdateCollectionType<C>>(
            ref,
            id,
            delta,
          ),
        ),
      ),
    );
  }

  remove(
    // Make calling this function fail if you haven't defined `C['Backend']['Delete']`
    id: C['Backend']['Delete'] extends never ? never : number | string,
  ): Promise<BackendDeleteResponse<MaybeDatastoreDeleteCollectionType<C>>> {
    return firstValueFrom(
      this.ref$.pipe(
        take(1),
        map(
          // Unfortunate type casting
          ref =>
            ref as unknown as Reference<MaybeDatastoreDeleteCollectionType<C>>,
        ),
        switchMap(ref => this.storeBackend.delete(ref, id)),
      ),
    );
  }

  toDatastoreDocumentList(): Observable<readonly DatastoreDocument<C>[]> {
    return this.queryResult$.pipe(
      map(collections =>
        collections.documentsWithMetadata.map(
          collection =>
            new DatastoreDocument<C>(
              this.ref$.pipe(
                map(ref => ({
                  path: {
                    ...ref.path,
                    ids: [collection.rawDocument.id.toString()] as const,
                  },
                })),
              ),
              this.storeBackend,
              this.status$,
              of(collection.rawDocument),
            ),
        ),
      ),
    );
  }
}

/**
 * This function should not be here, if it should exist at all.
 * We should at least have two types for a `Reference`, one
 * with the ids in the query, and one without, so that you
 * can tell by the type system if this has been transformed
 * or not.
 *
 * Then it should become more standard which one is used where.
 *
 * This exists here to make the `ref$` and `queryResult.request.ref` both
 * the same so we can compare them. It would be better to make
 * them the same in what is passed in, but that might be a
 * breaking change and so we do this here for now.
 */
function moveIdsFromQuery<C extends DatastoreCollectionType>(
  ref: Reference<C>,
): Reference<C> {
  return {
    ...ref,
    query: removeEqualOrInIdsFromQuery(ref.query),
    path: {
      ...ref.path,
      ids: getEqualOrInIdsFromQuery(ref.query),
    },
  };
}

import { Inject, Injectable, NgZone } from '@angular/core';
import type {
  ResponseData,
  SuccessResponseData,
} from '@freelancer/freelancer-http';
import { executeSchedule } from '@freelancer/operators-utils';
import { leaveZone } from '@freelancer/time-utils';
import { isDefined } from '@freelancer/utils';
import { Actions, createEffect } from '@ngrx/effects';
import { ErrorCodeApi } from 'api-typings/errors/errors';
import type { Observable } from 'rxjs';
import { combineLatest, merge, of, Subscription, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeAll,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import type {
  CollectionActions,
  RequestDataPayload,
  TypedAction,
} from '../actions';
import { isRequestDataAction } from '../actions';
import type { ApiFetchResponse } from '../api-http.service';
import { StoreBackend } from '../backend';
import { chunk, isObject } from '../helpers';
import type {
  RequestErrorWithoutRetry,
  RequestStatusWithoutRetry,
} from '../request-status-handler.service';
import { RequestStatusHandler } from '../request-status-handler.service';
import {
  isDocumentRef,
  isPlainDocumentRef,
  stringifyReference,
} from '../store.helpers';
import type {
  DatastoreCollectionType,
  DatastoreFetchCollectionType,
} from '../store.model';
import { batchRequests } from './operators/batchRequests';
import { dedupeRequests, splitOnIds } from './operators/dedupeRequests';
import { retryRequests } from './operators/retryRequests';
import { REQUEST_DATA_CONFIG } from './request-data.config';
import { RequestDataConfig } from './request-data.interface';

interface RequestAndResponse<C extends DatastoreCollectionType, E> {
  readonly request: RequestDataPayload<C>;
  readonly res: ResponseData<any, E>;
}

type DedupeRequestBufferMap = Map<
  string,
  {
    readonly payload: RequestDataPayload<any>;
    // The list of requests that were deduped on this leading request
    readonly dedupedPayloads: readonly RequestDataPayload<any>[];
    // The current status of the matching request
    readonly statusObject?: RequestStatusWithoutRetry<any>;
    // The time this matching request was buffered
    readonly bufferTime: number;
  }
>;

type OriginalRequestBufferMap = Map<
  string,
  {
    // The original request from the request effect payload before it was split by ids
    readonly originPayload: RequestDataPayload<any>;
    // The list of document ids it is waiting on to be returned from the API to be ready
    readonly readyIds: readonly number[];
    // The time this matching request was buffered
    readonly bufferTime: number;
  }
>;

@Injectable()
export class RequestDataEffect {
  readonly requestData$: Observable<TypedAction>;

  private readonly ORIGINAL_REQUESTS_WINDOW = 10_000;

  private dedupeRequestBuffer: DedupeRequestBufferMap = new Map();
  private originalRequestBuffer: OriginalRequestBufferMap = new Map();

  constructor(
    private storeBackend: StoreBackend,
    private actions$: Actions<TypedAction>,
    private ngZone: NgZone,
    private requestStatusHandler: RequestStatusHandler,
    @Inject(REQUEST_DATA_CONFIG) private config: RequestDataConfig,
  ) {
    const {
      scheduler,
      dedupeWindowTime,
      batchWindowTime,
      retryConfig,
      maxBufferTime,
    } = this.config;
    const { initialInterval, maxRetries, intervalFn } = retryConfig;

    // this is a bit of a hack, but not leaving the NgZone when the
    // dedupeWindowTime is null enables the unit tests to work
    const dedupeScheduler = dedupeWindowTime
      ? leaveZone(this.ngZone, scheduler)
      : scheduler;

    let originalRequestsMap: {
      readonly [requestId: string]: RequestDataPayload<any>;
    } = {};

    // Optimisation: dedupe requests with limit smaller than the largest in window
    const allRequests$ = this.actions$.pipe(
      filter(isRequestDataAction),
      filter(request => {
        // Filter out any empty requests (i.e. no query or ids) and set them to ready
        if (
          !request.payload.ref.query &&
          (!request.payload.ref.path.ids ||
            request.payload.ref.path.ids.length === 0)
        ) {
          this.requestStatusHandler.update(request.payload, { ready: true });
          return false;
        }
        return true;
      }),
      map(action => action.payload),
    );

    const propagateDedupedRequestStatuses$ =
      this.requestStatusHandler.statusStream$.pipe(
        map(requestStatus => {
          // all requests split into ONE id each
          const splitRequests = splitOnIds(requestStatus.request);
          return splitRequests.map(splitReq => ({ requestStatus, splitReq }));
        }),
        mergeAll(),
        tap(({ requestStatus, splitReq }) => {
          // If no ready or error status to propogate then skip
          if (
            !requestStatus.statusObject.ready &&
            !requestStatus.statusObject.error
          ) {
            return;
          }
          const currTime = dedupeScheduler.now();

          // Check if in dedupe buffer
          const idsOrQueryString = splitReq.ref.path.ids
            ? splitReq.ref.path.ids[0]
            : stringifyReference(splitReq.ref);
          const refString = [
            splitReq.ref.path.collection,
            splitReq.ref.path.authUid,
            idsOrQueryString,
          ].join('~');
          const bufferedRequest = this.dedupeRequestBuffer.get(refString);

          if (!bufferedRequest) {
            return;
          }

          if (requestStatus.statusObject.error) {
            // If request errored, propogate the error status to all deduped requests
            this.dedupeRequestBuffer.delete(refString);
            for (const dedupedRequest of bufferedRequest.dedupedPayloads) {
              const dedupedClientRequestId = dedupedRequest.clientRequestIds[0];
              const originalRequest = this.originalRequestBuffer.get(
                dedupedClientRequestId,
              );

              if (originalRequest) {
                this.originalRequestBuffer.delete(dedupedClientRequestId);
                this.requestStatusHandler.update(
                  originalRequest.originPayload,
                  requestStatus.statusObject,
                );
              }
            }
          } else if (requestStatus.statusObject.ready) {
            // Otherwise if not error, update buffers and add deduped request to their original request's readyIds
            if (bufferedRequest.bufferTime + dedupeWindowTime < currTime) {
              // Remove the buffered request outside window time and it is finished
              this.dedupeRequestBuffer.delete(refString);
            } else {
              // If not expired, clear the deduped payloads and update the status of the request in the buffer
              this.dedupeRequestBuffer.set(refString, {
                ...bufferedRequest,
                dedupedPayloads: [],
                statusObject: requestStatus.statusObject,
              });
            }

            // propogate the ready status to each dedpuped payload and update ready ids
            for (const dedupedRequest of bufferedRequest.dedupedPayloads) {
              const dedupedClientRequestId = dedupedRequest.clientRequestIds[0];
              const originalRequest = this.originalRequestBuffer.get(
                dedupedClientRequestId,
              );
              const isIdQuery =
                !dedupedRequest.ref.query &&
                dedupedRequest.ref.path.ids &&
                dedupedRequest.ref.path.ids[0];

              if (originalRequest) {
                if (isIdQuery) {
                  const updatedReadyIds = [
                    ...originalRequest.readyIds,
                    Number(dedupedRequest.ref.path.ids[0]),
                  ];
                  if (
                    updatedReadyIds.length ===
                    originalRequest.originPayload.ref.path.ids?.length
                  ) {
                    // If all ids are ready, then the original request is ready and we can remove from buffer
                    this.originalRequestBuffer.delete(dedupedClientRequestId);
                    requestStatusHandler?.update(
                      originalRequest.originPayload,
                      requestStatus.statusObject,
                    );
                  } else {
                    // Otherwise append the ready id
                    this.originalRequestBuffer.set(dedupedClientRequestId, {
                      ...originalRequest,
                      readyIds: updatedReadyIds,
                    });
                  }
                } else {
                  // Query requests aren't split up so we don't need to wait for ready ids.
                  this.originalRequestBuffer.delete(dedupedClientRequestId);
                  this.requestStatusHandler.update(
                    originalRequest.originPayload,
                    requestStatus.statusObject,
                  );
                }
              }
            }
          }
        }),
        map(() => undefined),
        startWith<undefined>(undefined),
      );

    const subscriptions = new Subscription();
    const allDedupedRequests$ = combineLatest([
      allRequests$.pipe(
        dedupeRequests(
          dedupeWindowTime,
          maxBufferTime,
          this.dedupeRequestBuffer,
          this.originalRequestBuffer,
          dedupeScheduler,
          this.requestStatusHandler,
        ),
      ),
      propagateDedupedRequestStatuses$,
    ]).pipe(
      map(([request]) => request),
      tap(request => {
        originalRequestsMap = {
          ...originalRequestsMap,
          [request.clientRequestIds[0]]: request,
        };

        // remove request after window elapses
        executeSchedule(
          subscriptions,
          dedupeScheduler,
          () => {
            const {
              [request.clientRequestIds[0]]: _deleted,
              ...cleanedOriginalRequestsMap
            } = originalRequestsMap;
            originalRequestsMap = cleanedOriginalRequestsMap;
          },
          this.ORIGINAL_REQUESTS_WINDOW,
        );
      }),
      distinctUntilChanged(),
      finalize(() => {
        subscriptions.unsubscribe();
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const plainRequests$ = allDedupedRequests$.pipe(
      filter(request => isPlainDocumentRef(request.ref)),
    );
    const queriedRequests$ = allDedupedRequests$.pipe(
      filter(request => !isPlainDocumentRef(request.ref)),
    );

    // Batching only for plain requests (no query)
    // TODO: T267853 - Address queried requests, i.e. regular datastore.list() calls
    const batchedRequests$ = plainRequests$.pipe(
      batchRequests(batchWindowTime, scheduler),
      map(requests =>
        requests.flatMap(request =>
          splitRequestsOnId(request, this.storeBackend.batchSize(request.ref)),
        ),
      ),
      mergeAll(),
    );

    const response$: Observable<
      readonly RequestAndResponse<DatastoreCollectionType, any>[]
    > = merge(batchedRequests$, queriedRequests$).pipe(
      mergeMap(request =>
        this.storeBackend
          .fetch(request.ref, request.resourceGroup, request.isRefetch)
          .pipe(
            map(response => {
              if (request.clientRequestIds.length > 1) {
                const unbatchedRequests = request.clientRequestIds
                  .map(id => originalRequestsMap[id])
                  .filter(isDefined);

                return { res: response, unbatchedRequests };
              }

              return { res: response, unbatchedRequests: [request] };
            }),
            map(({ res, unbatchedRequests }) =>
              this.checkEmptyResponse(res, request, unbatchedRequests),
            ),
            switchMap(({ res, otherRequests, requestsWithMissingItems }) => {
              if (requestsWithMissingItems.length > 0) {
                return throwError(() => ({
                  res,
                  otherRequests,
                  requestsWithMissingItems,
                }));
              }

              // No missing items - dispatch a success action with the original,
              // possibly batched request
              return of([{ request, res }]);
            }),
            // On missing items, retry twice with exponential backoff
            retryRequests({
              initialInterval,
              maxRetries,
              intervalFn,
              scheduler,
            }),
            // After all retries fail, produce an error status for the unbatched
            // requests that were missing items
            catchError(err => {
              const { res, otherRequests, requestsWithMissingItems } = err;

              // Only handle the error thrown by missing item logic above
              if (res && otherRequests && requestsWithMissingItems) {
                console.warn(
                  `Object(s) not found in response from document call to '${request.type}'. Response was`,
                  res,
                );

                return of([
                  ...((otherRequests || []) as RequestDataPayload<any>[]).map(
                    req => ({
                      request: req,
                      res,
                    }),
                  ),
                  ...(
                    (requestsWithMissingItems ||
                      []) as RequestDataPayload<any>[]
                  ).map(req => ({
                    request: req,
                    res: {
                      status: 'error' as const,
                      errorCode: ErrorCodeApi.NOT_FOUND,
                    },
                  })),
                ]);
              }

              // Rethrow other errors - mainly here to so that the missing module
              // error is correctly thrown from `storeBackend.fetch`.
              throw err;
            }),
          ),
      ),
    );

    this.requestData$ = createEffect(() =>
      response$.pipe(
        tap(requests => {
          requests.forEach(({ request, res }) =>
            this.updateRequestStatuses(res, request),
          );
        }),
        map(requests =>
          requests.map(({ request, res }) =>
            this.dispatchResponseAction(res, request),
          ),
        ),
        mergeAll(),
      ),
    );
  }

  /**
   * Inspects the network response to verify all items expected by the request
   * actually exist.
   *
   * FIXME: This is not a comprehensive check for missing items, as it relies on
   * several assumptions about the structure of the result object. These
   * assumptions often don't hold for our inconsistent API. This problem can also
   * be avoided entirely by not batching requests.
   * TODO: Find a generic way to check if a response is missing items.
   */
  private checkEmptyResponse<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(
    response: ApiFetchResponse<C>,
    originalRequest: RequestDataPayload<C>,
    unbatchedRequests: readonly RequestDataPayload<C>[],
  ): {
    res: ApiFetchResponse<C>;
    requestsWithMissingItems: readonly RequestDataPayload<C>[];
    otherRequests: readonly RequestDataPayload<C>[];
  } {
    // If there's an actual network 404, all the data is missing.
    if (response.status === 'error' && response.errorCode === 'NOT_FOUND') {
      return {
        res: response,
        requestsWithMissingItems: unbatchedRequests,
        otherRequests: [] as readonly RequestDataPayload<C>[],
      };
    }

    // If the response succeeded, check the items returned.
    if (isDocumentRef(originalRequest.ref) && response.status === 'success') {
      const { requestsWithMissingItems, otherRequests } =
        this.getRequestsWithMissingItems(response, unbatchedRequests);

      return {
        res: response,
        requestsWithMissingItems,
        otherRequests,
      };
    }

    return {
      res: response,
      requestsWithMissingItems: [] as readonly RequestDataPayload<C>[],
      otherRequests: unbatchedRequests,
    };
  }

  /**
   * Partitions requests into ones that are missing items, and ones that are not.
   */
  private getRequestsWithMissingItems<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(
    response: SuccessResponseData<C['Backend']['Fetch']['ReturnType']>,
    unbatchedRequests: readonly RequestDataPayload<C>[],
  ): {
    readonly requestsWithMissingItems: readonly RequestDataPayload<C>[];
    readonly otherRequests: readonly RequestDataPayload<C>[];
  } {
    const result = response.result as any; // FIXME: T267853 -
    const keys = isObject(result) ? Object.keys(result) : [];

    // Check if result is a single key with an empty Array or Object value
    if (keys.length === 1) {
      const resultItems = result[keys[0]];

      if (
        resultItems &&
        ((Array.isArray(resultItems) && resultItems.length === 0) ||
          (isObject(resultItems) && Object.keys(resultItems).length === 0))
      ) {
        return {
          requestsWithMissingItems: unbatchedRequests,
          otherRequests: [],
        };
      }
    }

    // Assume all other result structures are fine
    return { requestsWithMissingItems: [], otherRequests: unbatchedRequests };
  }

  private updateRequestStatuses(
    response: ApiFetchResponse<any>,
    request: RequestDataPayload<any>,
  ): void {
    if (response.status === 'error') {
      this.requestStatusHandler.update(request, {
        ready: false,
        error: response as RequestErrorWithoutRetry<any>,
      });
    }
  }

  private dispatchResponseAction(
    response: ApiFetchResponse<any>,
    request: RequestDataPayload<any>,
  ): CollectionActions<any> {
    const order =
      request.ref.order ||
      this.storeBackend.defaultOrder(request.ref.path.collection);

    switch (response.status) {
      case 'success': {
        const action: TypedAction = {
          type: 'API_FETCH_SUCCESS',
          payload: {
            type: request.ref.path.collection,
            result: response.result,
            ref: request.ref,
            order,
            clientRequestIds: request.clientRequestIds,
          },
        };
        return action;
      }
      default: {
        const action: TypedAction = {
          type: 'API_FETCH_ERROR',
          payload: {
            type: request.ref.path.collection,
            ref: request.ref,
            order,
            clientRequestIds: request.clientRequestIds,
          },
        };
        return action;
      }
    }
  }

  // Fetch the internal dedupe request buffer state. Only used for unit testing.
  _getDedupeBufferTesting(): DedupeRequestBufferMap {
    return this.dedupeRequestBuffer;
  }

  // Fetch the internal original request buffer state. Only used for unit testing.
  _getOriginalRequestBufferTesting(): OriginalRequestBufferMap {
    return this.originalRequestBuffer;
  }
}

function splitRequestsOnId<C extends DatastoreCollectionType>(
  request: RequestDataPayload<C>,
  size: number,
): readonly RequestDataPayload<C>[] {
  const { ids } = request.ref.path;
  if (!ids) {
    return [request];
  }

  return chunk(ids, size).map(chunkedIds => ({
    ...request,
    ref: {
      ...request.ref,
      path: {
        ...request.ref.path,
        ids: chunkedIds,
      },
    },
  }));
}

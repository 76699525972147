import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from './theme.service';

@Component({
  selector: 'fl-theme',
  standalone: true,
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeComponent implements OnInit, OnDestroy {
  private themeService = inject(ThemeService);
  private subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(
      this.themeService
        .getTheme()
        .subscribe(theme => this.themeService.initializeTheme(theme)),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

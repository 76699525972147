import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
// FIXME: T235847
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type {
  ThreadContext,
  ThreadType,
} from '@freelancer/datastore/collections/threads';
import type {
  ChatBoxDimensions,
  DraftMessage,
} from '@freelancer/local-storage';
import { LocalStorage } from '@freelancer/local-storage';
import { Location } from '@freelancer/location';
import { FreelancerBreakpoints } from '@freelancer/ui/breakpoints';
import { ContainerSize } from '@freelancer/ui/container';
import { isDefined, objectFilter } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContextTypeApi } from 'api-typings/messages/messages_types';
import type { Observable } from 'rxjs';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

const chatDraftExpiryDuration = 7 * 24 * 60 * 60 * 1000; // 7 days

export interface Chat {
  /** Users in the chat. Does not need to include the current user. */
  userIds: readonly number[];
  threadType: ThreadType;
  origin: string;
  context?: ThreadContext;
  threadId?: number;
  focus?: boolean;
}

export enum ChatViewState {
  NONE = 'none',
  COMPACT = 'compact',
  FULL = 'full',
}

export enum InboxViewState {
  PAGE = 'page',
  COLUMN = 'column',
}

type LiveChatHandler = (chat: Chat) => void;

export enum ChatBoxSize {
  MINIMISED = 'minimised',
  REGULAR = 'regular',
  EXPANDED = 'expanded',
}

// const for new chatbox
export const CHATBOX_DIMENSIONS = {
  [ChatBoxSize.MINIMISED]: {
    height: 48,
    width: 320,
  },
  [ChatBoxSize.REGULAR]: {
    height: 456,
    width: 320,
  },
  [ChatBoxSize.EXPANDED]: {
    height: 675,
    width: 475,
  },
} as const;

@UntilDestroy({ className: 'MessagingChat' })
@Injectable({
  providedIn: 'root',
})
export class MessagingChat {
  private liveChatHandler?: LiveChatHandler;
  private closeAutoPoppedChatsHandler?: () => void;
  private hasOpenChatsHandler?: () => boolean;
  private autoOpenChatDisabled = false;

  private disableToastNotificationsSubject$ = new BehaviorSubject(false);
  isToastDisabled$ = this.disableToastNotificationsSubject$.asObservable();

  private canStartChatSubject$ = new BehaviorSubject(false);
  canStartChat$ = this.canStartChatSubject$.asObservable();

  constructor(
    private location: Location,
    private localStorage: LocalStorage,
    private breakpointObserver: BreakpointObserver,
  ) {}

  canStartChat(): boolean {
    return isDefined(this.liveChatHandler);
  }

  /*
   * Use that to start a new chat session
   */
  startChat({
    userIds,
    threadType,
    origin,
    context,
    threadId,
    focus = true,
    // By default the user gets redirected to the Inbox when the live chat
    // isn't loaded. Use that flag to disable that.
    doNotRedirect = false,
    onlyOpenIfNoChatsOpen = false,
  }: Chat & {
    doNotRedirect?: boolean;
    onlyOpenIfNoChatsOpen?: boolean;
  }): void {
    // If the live chat isn't loaded, redirect to the Inbox to start a chat
    if (!this.liveChatHandler) {
      if (doNotRedirect) {
        return;
      }
      if (threadId) {
        this.location.navigateByUrl(`/messages/thread/${threadId}`);
      } else {
        const url = new URL(`${window.location.origin}/messages/new`);
        const params = url.searchParams;

        params.append('thread_type', threadType);

        if (context) {
          params.append('context_type', context.type);
          if (context.type !== ContextTypeApi.NONE) {
            params.append('context_id', context.id.toString());
          }
        }

        userIds.forEach(uid => {
          params.append('members', uid.toString());
        });
        this.location.navigateByUrl(`${url.pathname}${url.search}`);
      }
    } else {
      // Don't auto open chat if the no_auto_chat_open query param is set,
      // but allow it if the origin is not websocket or contactList
      // (e.g. user clicks on the chat).
      if (
        this.autoOpenChatDisabled &&
        (origin === 'websocket' || origin === 'contactList')
      ) {
        return;
      }

      if (
        onlyOpenIfNoChatsOpen &&
        this.hasOpenChatsHandler &&
        this.hasOpenChatsHandler()
      ) {
        return;
      }
      this.liveChatHandler({
        userIds,
        threadType,
        origin,
        context,
        threadId,
        focus,
      });
    }
  }

  /*
   * This allows the on-page live chat to register itself, when it's loaded,
   * e.g. it might not be loaded on mobile/small screens or high-conversion
   * pages.
   *
   * It should not be used by anyone but the live chat component itself.
   */
  registerMessagingComponentHandlers({
    liveChatHandler,
    closeAutoPoppedChatsHandler,
    hasOpenChatsHandler,
  }: {
    liveChatHandler: LiveChatHandler;
    closeAutoPoppedChatsHandler(): void;
    hasOpenChatsHandler(): boolean;
  }): void {
    this.liveChatHandler = liveChatHandler;
    this.closeAutoPoppedChatsHandler = closeAutoPoppedChatsHandler;
    this.hasOpenChatsHandler = hasOpenChatsHandler;
    this.canStartChatSubject$.next(true);
  }

  /**
   * This allows the live chat component to unregister itself when becoming hidden.
   * It should not be used by anyone but the live chat component itself.
   */
  unregisterMessagingComponentHandlers(): void {
    this.liveChatHandler = undefined;
    this.closeAutoPoppedChatsHandler = undefined;
    this.hasOpenChatsHandler = undefined;
    this.canStartChatSubject$.next(false);
  }

  disableToastNotifications(): void {
    this.disableToastNotificationsSubject$.next(true);
  }

  enableToastNotifications(): void {
    this.disableToastNotificationsSubject$.next(false);
  }

  cleanStoredDraftMessages(): void {
    firstValueFrom(
      this.localStorage
        .get('webappChatDraftMessages')
        .pipe(untilDestroyed(this)),
    ).then(async draftMessagesObject => {
      if (!draftMessagesObject) {
        return;
      }

      const cleanedDraftMsgsObject = objectFilter(
        draftMessagesObject,
        (key: string, dm: DraftMessage | null) => {
          if (!dm || !dm.lastUpdated) {
            return false;
          }
          if (Date.now() - dm.lastUpdated > chatDraftExpiryDuration) {
            return false;
          }
          return true;
        },
      );

      await this.localStorage.set(
        'webappChatDraftMessages',
        cleanedDraftMsgsObject,
      );
    });
  }

  getDimensionLimits(isNewChatbox?: boolean): {
    min: { height: number; width: number };
    max: { height: number; width: number };
  } {
    /**
     * IMPORTANT: Do not move this to constant because
     * the max height and width needs to be recomputed based on the
     * browser's size at the time of the function call
     */
    const newInboxWidgetWidth = 356;
    const chatboxInboxWidgetGap = 8;
    const chatboxSpaceFromEdge = 20; // do not make chatbox too close to the browser edge

    return {
      min: {
        // chatbox cannot be smaller than the default size
        height: CHATBOX_DIMENSIONS[ChatBoxSize.REGULAR].height,
        width: CHATBOX_DIMENSIONS[ChatBoxSize.REGULAR].width,
      },
      max: {
        height: window.innerHeight - (64 + 50) - 40,
        width: Math.min(
          window.innerWidth -
            (newInboxWidgetWidth +
              chatboxInboxWidgetGap +
              chatboxSpaceFromEdge),
          620,
        ),
      },
    };
  }

  /**
   * Given dimensions for a chatbox resize, return the constrained dimensions
   */
  constrainChatboxDimensions(dims: { height: number; width: number }): {
    height: number;
    width: number;
  } {
    const limits = this.getDimensionLimits();

    const newDims = { height: dims.height, width: dims.width };

    if (dims.height > limits.max.height) {
      newDims.height = limits.max.height;
    } else if (dims.height < limits.min.height) {
      newDims.height = limits.min.height;
    }

    if (dims.width > limits.max.width) {
      newDims.width = limits.max.width;
    } else if (dims.width < limits.min.width) {
      newDims.width = limits.min.width;
    }

    return newDims;
  }

  /**
   * Given dimensions for a chatbox resize, return whether or not it's valid
   */
  isValidChatboxDimensions(dims: { height: number; width: number }): boolean {
    const limits = this.getDimensionLimits();

    return !(
      dims.height > limits.max.height ||
      dims.height < limits.min.height ||
      dims.width > limits.max.width ||
      dims.width < limits.min.width
    );
  }

  /**
   * Returns the view state for messaging
   * - `NONE` = no messaging (mobiles and mobile viewports)
   * - `COMPACT` = small contact list (tablet to desktop-xxl)
   * - `FULL` = full-height contact list (desktop-xxl and up)
   */
  getViewState(size?: ContainerSize): Observable<ChatViewState> {
    let fullBreakpoint: FreelancerBreakpoints;
    switch (size) {
      case ContainerSize.DESKTOP_XLARGE:
      case ContainerSize.DESKTOP_XXLARGE:
        fullBreakpoint = FreelancerBreakpoints.DESKTOP_XXXXLARGE;
        break;
      default:
        fullBreakpoint = FreelancerBreakpoints.DESKTOP_XXLARGE;
        break;
    }
    return this.breakpointObserver
      .observe([FreelancerBreakpoints.TABLET, fullBreakpoint])
      .pipe(
        map(state => {
          if (!state.breakpoints[FreelancerBreakpoints.TABLET]) {
            return ChatViewState.NONE;
          }
          if (
            !state.breakpoints[fullBreakpoint] ||
            size === ContainerSize.FLUID
          ) {
            return ChatViewState.COMPACT;
          }
          return ChatViewState.FULL;
        }),
      );
  }

  /**
   * Returns the view state for the messaging inbox
   * - `PAGE` = thread list and chats are separate pages (mobile)
   * - `COLUMN` = thread list and chats are columns on the same page (tablet+)
   */
  getInboxViewState(): Observable<InboxViewState> {
    return this.breakpointObserver
      .observe(FreelancerBreakpoints.TABLET)
      .pipe(
        map(state =>
          state.matches ? InboxViewState.COLUMN : InboxViewState.PAGE,
        ),
      );
  }

  /**
   * Given a list of chats with widths, filters it
   * to only include ones that would fit on-screen.
   */
  fitChatsToScreen<T extends { width: number; dimensions?: ChatBoxDimensions }>(
    chats: T[],
    { offset = 0, gap = 0, useDimensions = false } = {},
  ): T[] {
    const spaceFromEdge = 15; // space from left edge of the browser + scrollbar width
    let currentLeftPos = offset + spaceFromEdge;
    return chats.filter(chat => {
      // we use chat.dimensions for the new chat box
      const width =
        useDimensions && chat.dimensions ? chat.dimensions.width : chat.width;
      // add chat size and check if it would still fit on-screen
      currentLeftPos += width + gap;
      return currentLeftPos < window.innerWidth;
    });
  }

  disableAutoOpenChat(): void {
    this.autoOpenChatDisabled = true;
  }

  closeAutoPoppedChats(): void {
    if (this.closeAutoPoppedChatsHandler) {
      this.closeAutoPoppedChatsHandler();
    }
  }
}

import type { KeyValue } from '@angular/common';
import type { AbstractControl } from '@angular/forms';
import type { Assets } from '../assets';
import type { LinkTargetWhitelist } from '../link/link-new-tab-whitelist';
import {
  LINK_NEW_TAB_REGEXP_WHITELIST,
  LINK_NEW_TAB_WHITELIST,
  LINK_SAME_TAB_WHITELIST,
} from '../link/link-new-tab-whitelist';

/**
 * Returns a name / value object for each item in an object.
 * Like `Object.entries` but it returns a mapped object instead of an array
 */
export function entriesMap<V>(e: { [k: string]: V }): {
  name: string;
  value: V;
}[] {
  return Object.entries(e).map(([name, value]) => ({ name, value }));
}

// File helpers

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  OTHERS = 'others',
}

export enum ImageFileType {
  BMP = 'bmp',
  GIF = 'gif',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PNG = 'png',
  SVG = 'svg+xml',
  WEBP = 'webp',
}

export enum VideoFileType {
  MOV = 'quicktime',
  MP3 = 'mp3',
  MP4 = 'mp4',
  OGG = 'ogg',
  MPG = 'mpg',
  MPEG = 'mpeg',
  AVI = 'x-msvideo',
  FLV = 'x-flv',
}

export enum DocumentFileType {
  DOC = 'msword',
  DOCX = 'vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'pdf',
  RTF = 'rtf',
  TXT = 'plain',
}

/**
 * File type is based on the file extension in filename
 * @param filename Filename with file extension
 */
export function getFileType(filename: string): FileType {
  if (isImageFile(filename)) {
    return FileType.IMAGE;
  }

  if (isVideoFile(filename)) {
    return FileType.VIDEO;
  }

  return FileType.OTHERS;
}

export function isImageFile(
  filename: string,
  options?: { allowedFileExtensions: string[] },
): boolean {
  const fileExtension = getFileExtension(filename);

  const fileExtensions = options?.allowedFileExtensions ?? [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'svg',
    'webp',
  ];

  return fileExtensions.includes(fileExtension);
}

export function isVideoFile(filename: string): boolean {
  const fileExtension = getFileExtension(filename);

  return ['mp4', 'mov', 'ogg', 'avi', 'flv', 'webm'].includes(fileExtension);
}

export function isPlayableVideoFile(filename: string): boolean {
  const fileExtension = getFileExtension(filename);

  return ['mp4'].includes(fileExtension);
}

export function isAudioFile(filename: string): boolean {
  const fileExtension = getFileExtension(filename);

  return ['mp3'].includes(fileExtension);
}

export function isDocumentFile(filename: string): boolean {
  const fileExtension = getFileExtension(filename);

  return ['doc', 'pdf'].includes(fileExtension);
}

export function getFileExtension(filename: string): string {
  if (!filename) {
    return '';
  }
  const lowerCaseFilename = filename.toLowerCase();

  return lowerCaseFilename.split('.').pop() || '';
}

function hasMatchedRegexp(
  pattern: string,
  regExpList: readonly RegExp[],
): boolean {
  return regExpList.some(regExp => !!pattern.match(regExp));
}

export function isLinkWhitelisted(
  linkConfig: LinkTargetWhitelist,
  isExternalLink: boolean,
): boolean {
  const linkWhitelist = isExternalLink
    ? LINK_SAME_TAB_WHITELIST
    : LINK_NEW_TAB_WHITELIST;

  // Check internal link against whitelisted regex
  if (
    !isExternalLink &&
    hasMatchedRegexp(linkConfig.destination, LINK_NEW_TAB_REGEXP_WHITELIST)
  ) {
    return true;
  }

  const matches = linkWhitelist
    .filter(whitelistConfig =>
      linkConfig.destination.startsWith(whitelistConfig.destination),
    )
    .filter(
      whitelistConfig =>
        !whitelistConfig.source ||
        linkConfig.source?.startsWith(whitelistConfig.source),
    );

  return matches.length > 0;
}

export function trackByKey(_: number, v: KeyValue<string, any>): string {
  return v.key;
}

export function trackByValue(_: number, v: string | number): string | number {
  return v;
}

export function trackById(_: number, item: any): string | number {
  return item.id;
}

export function trackByIndex(index: number): number {
  return index;
}

export function trackByValueProperty(
  _: number,
  object: { value: string },
): string {
  return object.value;
}

export function trackBySrcProperty(_: number, object: { src: string }): string {
  return object.src;
}

export function trackByAbstractControl(
  _: number,
  control: AbstractControl,
): AbstractControl {
  return control;
}

export function generateUniqueID(): string {
  return Math.random().toString(36).substring(2, 6);
}

export function resolveAssetUrl(assets: Assets, src: string): string {
  // Check if the src is http(s), data:image, /api or protocol relative.
  if (
    !src ||
    src.startsWith('http://') ||
    src.startsWith('https://') ||
    src.startsWith('data:image/') ||
    src.startsWith('/api') ||
    src.startsWith('//')
  ) {
    return src;
  }

  return assets.getUrl(src);
}

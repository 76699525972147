import type {
  QuotationItemRevisionCreateRequestApi,
  QuotationItemStatusApi,
  QuotationItemUnitTypeApi,
} from 'api-typings/quotations/quotations';
import { QuotationItemBillingFrequencyApi } from 'api-typings/quotations/quotations';

/**
 * A quotation item revision
 */
export interface QuotationItemRevision {
  readonly id: number;
  readonly quotationItemId: number;
  readonly quotationId: number;
  readonly name: string;
  readonly createDate: number;
  readonly order: string;
  readonly currencyId: number;
  readonly paidAmount: number;
  readonly fundedAmount: number;
  readonly unitPrice: number;
  readonly quantity: number;
  readonly description: string;
  readonly message?: string;
  readonly invalidDate?: number;
  readonly versionInvalidDate?: number;
  readonly completeDate?: number;
  readonly quotationVersionId?: number;
  readonly unitType?: QuotationItemUnitTypeApi;
  readonly originalQuotationVersionId: number;
  readonly status?: QuotationItemStatusApi;
  readonly totalPrice: number;
  readonly unitLimit?: number;
  readonly billingFrequency?: QuotationItemBillingFrequencyApi;
  readonly quotationItemType: QuotationItemType;
}

export type QuotationItemRevisionCreateRequest =
  QuotationItemRevisionCreateRequestApi & {
    readonly previous_item_version_id: number;
  };

export enum QuotationItemType {
  HOURLY = 'hourly',
  FIXED = 'fixed',
  SUBSCRIPTION = 'subscription',
}

// made optional so that it can be used to sort subscription quotation items without a default value
export const sortedQuotationItemBillingFrequencies: readonly (
  | QuotationItemBillingFrequencyApi
  | undefined
)[] = [
  QuotationItemBillingFrequencyApi.WEEKLY,
  QuotationItemBillingFrequencyApi.MONTHLY,
  QuotationItemBillingFrequencyApi.ANNUALLY,
];

export function formattedBillingFrequency(
  frequency?: QuotationItemBillingFrequencyApi,
): string {
  switch (frequency) {
    case QuotationItemBillingFrequencyApi.ANNUALLY:
      return $localize`Annual`;
    case QuotationItemBillingFrequencyApi.MONTHLY:
      return $localize`Monthly`;
    case QuotationItemBillingFrequencyApi.WEEKLY:
      return $localize`Weekly`;
    default:
      return '';
  }
}

// The amount of time between two billings of a subscription item.
export function billingInterval(
  frequency?: QuotationItemBillingFrequencyApi,
): string {
  switch (frequency) {
    case QuotationItemBillingFrequencyApi.ANNUALLY:
      return $localize`year`;
    case QuotationItemBillingFrequencyApi.MONTHLY:
      return $localize`month`;
    case QuotationItemBillingFrequencyApi.WEEKLY:
      return $localize`week`;
    default:
      return '';
  }
}

export enum NavigationPerformanceCategory {
  INITIAL_PAGE_LOAD = 0,
  ROUTE_CHANGE = 1,
}

export interface NavigationPerformanceEventData {
  category: NavigationPerformanceCategory;
  totalTime: number; // in ms
  backendTime: number; // in ms
  frontendTime: number; // in ms
  previousUrl: string;
  resourceTimings: string; // See https://soasta.github.io/boomerang/doc/api/restiming.html
}

import { assertNever } from '@freelancer/utils';
import { ProjectUpgradeOptionsApi } from 'api-typings/projects/projects';
import {
  BidUpgradeName,
  BidUpgradeSubType,
  BidUpgradeType,
  EntryUpgradeName,
  EntryUpgradeSubType,
  EntryUpgradeType,
  UpgradeName,
  UpgradeSubType,
  UpgradeType,
} from './upgrade-tag.types';

// Mapping bid upgrade types to full names suitable for display.
export function getBidUpgradeName(upgrade: BidUpgradeType): BidUpgradeName {
  switch (upgrade) {
    case BidUpgradeType.SPONSORED:
      return BidUpgradeName.SPONSORED;
    case BidUpgradeType.HIGHLIGHT:
      return BidUpgradeName.HIGHLIGHT;
    case BidUpgradeType.SEALED:
      return BidUpgradeName.SEALED;
    default:
      return assertNever(upgrade);
  }
}

// This function maps upgrade name to subtype, which is a number
export function transformBidUpgradeSubType(
  upgrade: BidUpgradeType,
): BidUpgradeSubType {
  switch (upgrade) {
    case BidUpgradeType.SPONSORED:
      return BidUpgradeSubType.SPONSORED;
    case BidUpgradeType.HIGHLIGHT:
      return BidUpgradeSubType.HIGHLIGHT;
    case BidUpgradeType.SEALED:
      return BidUpgradeSubType.SEALED;
    default:
      return assertNever(upgrade);
  }
}

// Mapping contest entry upgrade types to full names suitable for display.
export function getEntryUpgradeName(
  upgrade: EntryUpgradeType,
): EntryUpgradeName {
  switch (upgrade) {
    case EntryUpgradeType.HIGHLIGHT:
      return EntryUpgradeName.HIGHLIGHT;
    case EntryUpgradeType.SEALED:
      return EntryUpgradeName.SEALED;
    default:
      return assertNever(upgrade);
  }
}

// This function maps upgrade name to subtype, which is a number
export function transformEntryUpgradeSubType(
  upgrade: EntryUpgradeType,
): EntryUpgradeSubType {
  switch (upgrade) {
    case EntryUpgradeType.HIGHLIGHT:
      return EntryUpgradeSubType.HIGHLIGHT;
    case EntryUpgradeType.SEALED:
      return EntryUpgradeSubType.SEALED;
    default:
      return assertNever(upgrade);
  }
}

// Mapping upgrade types to full names suitable for display.
export function getUpgradeName(upgrade: UpgradeType): UpgradeName {
  switch (upgrade) {
    case UpgradeType.ASSISTED:
      return UpgradeName.ASSISTED;
    case UpgradeType.CUSTOM_JUDGING:
      return UpgradeName.CUSTOM_JUDGING;
    case UpgradeType.EXTENDED:
      return UpgradeName.EXTENDED;
    case UpgradeType.FEATURED:
      return UpgradeName.FEATURED;
    case UpgradeType.FULL_TIME:
      return UpgradeName.FULL_TIME;
    case UpgradeType.GUARANTEED:
      return UpgradeName.GUARANTEED;
    case UpgradeType.HIGHLIGHT:
      return UpgradeName.HIGHLIGHT;
    case UpgradeType.IP_AGREEMENT:
      return UpgradeName.IP_AGREEMENT;
    case UpgradeType.NDA:
      return UpgradeName.NDA;
    case UpgradeType.PRIVATE:
      return UpgradeName.PRIVATE;
    case UpgradeType.TECHNICAL_COPILOT:
      return UpgradeName.TECHNICAL_COPILOT;
    case UpgradeType.SEALED:
      return UpgradeName.SEALED;
    case UpgradeType.TOP_CONTEST:
      return UpgradeName.TOP_CONTEST;
    case UpgradeType.URGENT:
      return UpgradeName.URGENT;
    case UpgradeType.QUALIFIED:
      return UpgradeName.QUALIFIED;
    case UpgradeType.PREMIUM:
      return UpgradeName.PREMIUM;
    case UpgradeType.ENTERPRISE:
      return UpgradeName.ENTERPRISE;
    default:
      return assertNever(upgrade);
  }
}

export function isUpgradeType(upgrade: string): upgrade is UpgradeType {
  // FIXME: T136805 - https://phabricator.tools.flnltd.com/T136805#2339368
  // this needs any because the types might not match (since this is a guard)
  return Object.values(UpgradeType).includes(upgrade as any);
}

// Do not change the order unless specs have been modified.
// Specs from https://confluence.flnltd.com/pages/viewpage.action?pageId=20022705
export function getUpgradeOrder(upgrade: UpgradeType): number {
  switch (upgrade) {
    case UpgradeType.URGENT:
      return 1;
    case UpgradeType.FEATURED:
      return 2;
    case UpgradeType.PRIVATE:
      return 3;
    case UpgradeType.HIGHLIGHT:
      return 4;
    case UpgradeType.FULL_TIME:
      return 5;
    case UpgradeType.GUARANTEED:
      return 6;
    case UpgradeType.SEALED:
      return 7;
    case UpgradeType.NDA:
      return 8;
    case UpgradeType.ASSISTED:
      return 9;
    case UpgradeType.TOP_CONTEST:
      return 10;
    case UpgradeType.EXTENDED:
    case UpgradeType.IP_AGREEMENT:
    case UpgradeType.TECHNICAL_COPILOT:
    case UpgradeType.QUALIFIED:
      return 11;
    case UpgradeType.CUSTOM_JUDGING:
      return 12;
    case UpgradeType.PREMIUM:
      return 13;
    case UpgradeType.ENTERPRISE:
      return 14;
    default:
      return assertNever(upgrade);
  }
}

// This function maps upgrade name to subtype, which is a number
export function transformUpgradeSubType(subType: string): UpgradeSubType {
  switch (subType) {
    // TODO: T267853 - the name `assisted` should be deprecated
    case ProjectUpgradeOptionsApi.RECRUITER:
    case 'assisted':
      return UpgradeSubType.RECRUITER;
    case ProjectUpgradeOptionsApi.EXTEND:
    case 'extend':
      return UpgradeSubType.EXTEND;
    case ProjectUpgradeOptionsApi.URGENT:
      return UpgradeSubType.URGENT;
    case ProjectUpgradeOptionsApi.NONPUBLIC:
    case 'private':
      return UpgradeSubType.NONPUBLIC;
    case ProjectUpgradeOptionsApi.HIDEBIDS:
    case 'sealed':
      return UpgradeSubType.HIDEBIDS;
    case ProjectUpgradeOptionsApi.FULLTIME:
    case 'full time':
      return UpgradeSubType.FULLTIME;
    case ProjectUpgradeOptionsApi.NDA:
    case 'NDA':
      return UpgradeSubType.NDA;
    case ProjectUpgradeOptionsApi.IP_CONTRACT:
    case 'ipContract':
    case 'ip agreement':
      return UpgradeSubType.IP_CONTRACT;
    case ProjectUpgradeOptionsApi.SUCCESS_BUNDLE:
      return UpgradeSubType.SUCCESS_BUNDLE;
    case ProjectUpgradeOptionsApi.NON_COMPETE:
      return UpgradeSubType.NON_COMPETE;
    case ProjectUpgradeOptionsApi.PROJECT_MANAGEMENT:
      return UpgradeSubType.TECHNICAL_COPILOT;
    case ProjectUpgradeOptionsApi.PF_ONLY:
      return UpgradeSubType.PF_ONLY;
    case ProjectUpgradeOptionsApi.FEATURED:
      return UpgradeSubType.FEATURED;
    default:
      return UpgradeSubType.NOT_FOUND;
  }
}

// Cases refer to ProjectUpgrades type in Projects collection
// Need to change both if modified type name
export function transformUpgradeType(upgrade: string): UpgradeType | undefined {
  switch (upgrade) {
    // Cases that in the project Upgrade object
    case 'recruiter':
    case 'assisted':
      return UpgradeType.ASSISTED;
    case 'featured':
      return UpgradeType.FEATURED;
    case 'fulltime':
      return UpgradeType.FULL_TIME;
    case 'ipContract':
    case 'ip_agreement':
    case 'ip_contract':
      return UpgradeType.IP_AGREEMENT;
    case 'NDA': // from ProjectUpgrades
    case 'nda': // from ContestUpgrade
      return UpgradeType.NDA;
    case 'nonpublic': // from ProjectUpgrades
    case 'private': // from ContestUpgrade
      return UpgradeType.PRIVATE;
    case 'projectManagement':
      return UpgradeType.TECHNICAL_COPILOT;
    case 'sealed':
      return UpgradeType.SEALED;
    case 'urgent':
      return UpgradeType.URGENT;
    case 'qualified':
      return UpgradeType.QUALIFIED;

    // Cases not yet returned from the API in project object
    case 'topContest':
      return UpgradeType.TOP_CONTEST;
    case 'highlight':
      return UpgradeType.HIGHLIGHT;
    case 'extend':
      return UpgradeType.EXTENDED;
    case 'guaranteed':
      return UpgradeType.GUARANTEED;
    case 'customJudging':
      return UpgradeType.CUSTOM_JUDGING;
    case 'premium':
      return UpgradeType.PREMIUM;
    case 'enterprise':
      return UpgradeType.ENTERPRISE;
    default:
  }
}

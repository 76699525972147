import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Facebook } from '@freelancer/facebook';
import { AuthCallbackEmptyComponent } from './auth-callback-empty.component';
import { DummyComponent } from './dummy.component';
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
  imports: [CommonModule, LoginRoutingModule, RouterModule],
  declarations: [AuthCallbackEmptyComponent, DummyComponent],
  providers: [Facebook],
})
export class LoginModule {}

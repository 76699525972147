import type { OperatingAreaApi } from 'api-typings/users/users';
import type { OperatingArea } from './operating-areas.model';

export function transformOperatingArea(
  operatingAreaApi: OperatingAreaApi,
): OperatingArea {
  return {
    id: operatingAreaApi.id,
    type: operatingAreaApi.type,
    street: operatingAreaApi?.street,
    postcode: operatingAreaApi?.postcode,
    city: operatingAreaApi?.city,
    region: operatingAreaApi?.region,
    stateLongName: operatingAreaApi?.state_long_name,
    stateShortName: operatingAreaApi?.state_short_name,
    countryCode: operatingAreaApi?.country_code,
  };
}

import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { ThemeService } from '@freelancer/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  combineLatest,
  map,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { Assets } from '../assets';
import { FreelancerBreakpoints } from '../breakpoints';
import { BackgroundColor, BackgroundColourType, LogoSize } from './logo.types';

@UntilDestroy({ className: 'LogoComponent' })
@Component({
  selector: 'fl-logo',
  template: `
    <picture *ngIf="logoSrc$ | flAsync as logoSrc">
      <source
        *ngIf="logoSrcDesktop$ | flAsync as logoSrcDesktop"
        [media]="FreelancerBreakpoints.DESKTOP_SMALL"
        [srcset]="logoSrcDesktop"
      />
      <source
        *ngIf="logoSrcTablet$ | flAsync as logoSrcTablet"
        [media]="FreelancerBreakpoints.TABLET"
        [srcset]="logoSrcTablet"
      />
      <source
        *ngIf="logoSrc"
        [srcset]="logoSrc"
      />
      <img
        class="LogoImg"
        alt="Freelancer logo"
        i18n-alt="Freelancer logo alt text"
        [src]="logoSrc"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.id]="id"
      />
    </picture>
  `,
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit, OnChanges {
  FreelancerBreakpoints = FreelancerBreakpoints;

  @Input() size = LogoSize.MID;
  @Input() sizeTablet?: LogoSize;
  @Input() sizeDesktop?: LogoSize;
  @Input() backgroundColor: BackgroundColourType = BackgroundColor.LIGHT;
  @Input() id: string;

  logoSrc$: Observable<string | undefined>;
  logoSrcTablet$: Observable<string | undefined>;
  logoSrcDesktop$: Observable<string | undefined>;

  private readonly assets = inject(Assets);
  private readonly themeService = inject(ThemeService);

  private readonly LOGO_MINI_DARK = this.assets.getUrl(
    'freelancer-logo-icon.svg',
  );
  private readonly LOGO_MINI_LIGHT = this.assets.getUrl(
    'freelancer-logo-icon-light.svg',
  );
  private readonly LOGO_MID_DARK = this.assets.getUrl('freelancer-logo.svg');
  private readonly LOGO_MID_LIGHT = this.assets.getUrl(
    'freelancer-logo-light.svg',
  );

  private readonly sizeSubject$ = new BehaviorSubject<LogoSize>(this.size);
  private readonly sizeTabletSubject$ = new BehaviorSubject<
    LogoSize | undefined
  >(this.sizeTablet);
  private readonly sizeDesktopSubject$ = new BehaviorSubject<
    LogoSize | undefined
  >(this.sizeDesktop);
  private readonly backgroundColorSubject$ = new BehaviorSubject<
    BackgroundColourType | undefined
  >(this.backgroundColor);

  ngOnInit(): void {
    const background$ = this.backgroundColorSubject$.pipe(
      switchMap(backgroundColor =>
        backgroundColor === BackgroundColor.THEME
          ? this.themeService
              .getTheme()
              .pipe(
                map(theme =>
                  theme === 'dark'
                    ? BackgroundColor.DARK
                    : BackgroundColor.LIGHT,
                ),
              )
          : of(backgroundColor),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.logoSrc$ = combineLatest([this.sizeSubject$, background$]).pipe(
      map(([size, backgroundColor]) =>
        this.computeLogoSrc(size, backgroundColor),
      ),
    );
    this.logoSrcTablet$ = combineLatest([
      this.sizeTabletSubject$,
      background$,
    ]).pipe(
      map(([size, backgroundColor]) =>
        this.computeLogoSrc(size, backgroundColor),
      ),
    );
    this.logoSrcDesktop$ = combineLatest([
      this.sizeDesktopSubject$,
      background$,
    ]).pipe(
      map(([size, backgroundColor]) =>
        this.computeLogoSrc(size, backgroundColor),
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      this.sizeSubject$.next(this.size);
    }
    if (changes.sizeTablet) {
      this.sizeTabletSubject$.next(this.sizeTablet);
    }
    if (changes.sizeDesktop) {
      this.sizeDesktopSubject$.next(this.sizeDesktop);
    }

    if (changes.backgroundColor) {
      this.backgroundColorSubject$.next(this.backgroundColor);
    }
  }

  private computeLogoSrc(
    size: LogoSize | undefined,
    backgroundColor: BackgroundColourType | undefined,
  ): string | undefined {
    switch (size) {
      case LogoSize.ICON:
      case LogoSize.MINI:
        return backgroundColor === BackgroundColor.LIGHT
          ? this.LOGO_MINI_DARK
          : this.LOGO_MINI_LIGHT;

      case LogoSize.SMALL:
      case LogoSize.MID:
        return backgroundColor === BackgroundColor.LIGHT
          ? this.LOGO_MID_DARK
          : this.LOGO_MID_LIGHT;

      default:
        return undefined;
    }
  }
}

import type { Backend } from '@freelancer/datastore/core';
import {
  getQueryParamValue,
  OrderByDirection,
} from '@freelancer/datastore/core';
import type { ServiceOfferingsWithOwnerDetailsCollection } from './service-offerings-with-owner-details.types';

export function serviceOfferingsWithOwnerDetailsBackend(): Backend<ServiceOfferingsWithOwnerDetailsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.DESC,
    },
    fetch: (_authUid, ids, query, _order) => {
      const status = getQueryParamValue(query, 'status');

      const projections = {
        job_details: true,
        attachment_details: true,
        user_details: true,
      };

      return {
        endpoint: 'service_offerings/0.1/service_offerings',
        params: {
          ids,
          creator_ids: getQueryParamValue(query, 'creatorId'),
          statuses: status.length ? [status.toString()] : undefined, // The backend accepts only one status filter
          seo_urls: getQueryParamValue(query, 'seoUrl'),
          limit: query?.searchQueryParams.limit,
          offset: query?.searchQueryParams.offset,
          ...projections,
        },
      };
    },
    push: undefined,
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}

import { Injectable } from '@angular/core';
import type {
  ActivatedRoute as AngularActivatedRoute,
  ActivatedRouteSnapshot,
  ParamMap,
  Params,
} from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';

/**
 * This service purpose to provide up-to-date state
 * of the actual snapshot.
 *
 * Details: When we inject ActivatedRoute we get the
 * ActivatedRoute at that level of the component tree
 * where it's injected, meaning there is not one instance
 * of it, there's many.
 */
@Injectable({
  providedIn: 'root',
})
export class ActivatedRoute {
  constructor(private router: Router) {}

  get activatedRoute(): AngularActivatedRoute {
    return this.router.routerState.root;
  }

  get snapshot(): ActivatedRouteSnapshot {
    return this.activatedRoute.snapshot;
  }

  // eslint-disable-next-line rxjs/finnish
  get queryParams(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }

  // eslint-disable-next-line rxjs/finnish
  get queryParamMap(): Observable<ParamMap> {
    return this.activatedRoute.queryParamMap;
  }
}

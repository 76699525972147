/* eslint-disable no-bitwise */

import { deepSpread } from '@freelancer/datastore/core';
import type { ThreadApi } from 'api-typings/messages/messages_types';
import type { Thread } from '../threads/threads.model';
import { transformThread } from '../threads/threads.transformers';

// Define bitmasks using BigInt to operate on 64-bit numbers safely
const PEOPLE_SEARCH_MASK = BigInt(1) << BigInt(52);
const MESSAGE_SEARCH_MASK = BigInt(1) << BigInt(51);
const MAX_SAFE_ID = (BigInt(1) << BigInt(51)) - BigInt(1);

enum SearchType {
  PEOPLE = 'people',
  MESSAGE = 'message',
}

/**
 * Transforms and encodes the thread ID based on the search type.
 *
 * @param thread - The original thread API object.
 * @param userId - The user ID associated with the thread.
 * @returns The transformed thread with the encoded ID.
 * @throws Error if the thread does not have an ID.
 */
export function transformSearchThread(
  thread: ThreadApi,
  userId: string,
): Thread {
  if (!thread.thread.id) {
    throw new Error('Threads should have an id');
  }

  const searchType =
    thread.highlights !== undefined ? SearchType.MESSAGE : SearchType.PEOPLE;
  const encodedId = encodeThreadId(thread.thread.id, searchType);
  return transformThread(
    deepSpread(thread, {
      id: encodedId,
      thread: {
        id: encodedId,
      },
    }),
    userId,
  );
}

/**
 * Encodes the thread ID with a search type identifier using BigInt operations.
 *
 * This function takes an original thread ID and a search type, and encodes the ID by setting
 * specific high bits that represent the search type. The result is returned as a number.
 *
 * The process is safe because the original ID is restricted to a range that ensures
 * the final encoded ID remains within JavaScript's 53-bit safe integer limit. By using BigInt
 * for the bitwise operations and converting the result back to a number, we avoid any loss of
 * precision.
 *
 * @param originalId - The original thread ID as a number.
 * @param searchType - The type of search to be encoded into the thread ID (either 'people' or 'message').
 * @returns The encoded thread ID as a number.
 * @throws Error if the original ID exceeds the safe range for encoding.
 */
export function encodeThreadId(
  originalId: number,
  searchType: SearchType,
): number {
  const bigintOriginalId = BigInt(originalId);
  if (bigintOriginalId > MAX_SAFE_ID) {
    throw new Error('Thread ID exceeds the safe range for encoding.');
  }

  const mask =
    searchType === SearchType.PEOPLE ? PEOPLE_SEARCH_MASK : MESSAGE_SEARCH_MASK;
  return Number(bigintOriginalId | mask);
}

/**
 * Decodes the thread ID by removing the search type identifier.
 *
 * This function reverses the encoding process by clearing the bits that were set during encoding.
 * The original ID is returned as a number.
 *
 * The decoding process is also safe because the encoded ID was kept within the safe range
 * of a JavaScript number. The result after clearing the encoding bits is a valid number
 * within the 53-bit integer limit.
 *
 * @param encodedId - The encoded thread ID as a number.
 * @returns The original thread ID after removing the search type identifier as a number.
 */
export function decodeThreadId(encodedId: number): number {
  const bigintEncodedId = BigInt(encodedId);
  return Number(bigintEncodedId & ~PEOPLE_SEARCH_MASK & ~MESSAGE_SEARCH_MASK);
}

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

/**
 * Convenience pipe for running decodeURIComponent on text in a template
 */
@Pipe({
  name: 'decodeUriComponent',
  standalone: true,
})
export class DecodeUriComponentPipe implements PipeTransform {
  transform(text: string | null): string | null {
    if (text === null) return null;
    return decodeURIComponent(text);
  }
}

import { InjectionToken } from '@angular/core';
import type { AuthServiceInterface } from '@freelancer/auth/interface';
import type { Applications } from '@freelancer/config';
import type {
  FreelancerHttpABTestOverridesInterface,
  FreelancerHttpConfig,
  FreelancerHttpTrackingInterface,
} from './freelancer-http.interface';

export const FREELANCER_HTTP_CONFIG = new InjectionToken<FreelancerHttpConfig>(
  'FreelancerHttpConfig',
);

export const FREELANCER_HTTP_AUTH_PROVIDERS = new InjectionToken<
  readonly AuthServiceInterface[]
>('FreelancerHttpAuthProviders');

export const FREELANCER_HTTP_ABTEST_OVERRIDES_PROVIDER = new InjectionToken<
  readonly FreelancerHttpABTestOverridesInterface[]
>('FreelancerHttpABTestOverridesProvider');

export const FREELANCER_HTTP_TRACKING_PROVIDER = new InjectionToken<
  readonly FreelancerHttpTrackingInterface[]
>('FreelancerHttpTrackingProvider');

/**
 * Copied from src/applications.ts with modifications to avoid matching
 * `www.syd1.fln-dev.` and `www.freelancer-staging.com` domains
 */
export const QABOX_DOMAINS: { [app in Applications]: RegExp[] } = {
  admin: [
    /^(?!www\.)[\w-]+\.syd1\.fln-dev\.net$/,
    /^(?!www\.)[\w-]+\.freelancer-staging\.com$/,
  ],
  main: [
    /^(?!www\.)[\w-]+\.syd1\.fln-dev\.net$/,
    /^(?!www\.)[\w-]+\.freelancer-staging\.com$/,
  ],
  developers: [
    /^developers-[\w-]+\.syd1\.fln-dev\.net$/,
    /^developers-[\w-]+\.freelancer-staging\.com$/,
  ],
  bits: [
    /^bits-[\w-]+\.syd1\.fln-dev\.net$/,
    /^bits-[\w-]+\.freelancer-staging\.com$/,
  ],

  deloitte: [
    /^deloitte-[\w-]+\.syd1\.fln-dev\.net$/,
    /^deloitte-[\w-]+\.freelancer-staging\.com$/,
  ],
  freightlancer: [
    /^freight-[\w-]+\.syd1\.fln-dev\.net$/,
    /^freight-[\w-]+\.freelancer-staging\.com$/,
  ],
};

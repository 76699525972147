import { InjectionToken } from '@angular/core';
import type { AuthServiceInterface } from '@freelancer/auth/interface';
import type {
  FreelancerInAppBrowserInterface,
  FreelancerLocationPwaInterface,
  FreelancerLocationTrackingConsentInterface,
} from './location.interface';

export const FREELANCER_LOCATION_AUTH_PROVIDER =
  new InjectionToken<AuthServiceInterface>('FreelancerLocationAuthProvider');

export const FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER =
  new InjectionToken<string>('FreelancerLocationHttpBaseUrlProvider');

export const FREELANCER_LOCATION_PWA_PROVIDER =
  new InjectionToken<FreelancerLocationPwaInterface>(
    'FreelancerLocationPwaProvider',
  );

export const FREELANCER_LOCATION_TRACKING_CONSENT_PROVIDER =
  new InjectionToken<FreelancerLocationTrackingConsentInterface>(
    'FreelancerLocationTrackingConsentProvider',
  );

export const FREELANCER_LOCATION_IN_APP_BROWSER_PROVIDER =
  new InjectionToken<FreelancerInAppBrowserInterface>(
    'FreelancerInAppBrowserProvider',
  );

import type { SubscriptionSource } from 'observable-profiler';
import {
  getSubscribers,
  Iterator,
  printSubscribers,
  setup,
  track,
} from 'observable-profiler';
import { Observable } from 'rxjs';

interface StopFunctionOptions {
  readonly disableNavigation?: boolean;
  customSubscriberFilter?(stack?: string): boolean;
}

/**
 * This class responsibility to encapsulate some functionalities like
 * start, stop and printSubscribers.
 */
export class MemoryLeakTracking {
  /**
   * Monkey-patching Observable#subscribe() and increases the stacktrace limit
   */
  start(): void {
    console.log('Memory tracking has been started...');
    Error.stackTraceLimit = Infinity;
    // Call `setup` once, passing the Observable class (usually imported from `rxjs`)
    setup(Observable);
    // We're not tracking subscriptions yet, but have the basic support
    // in-place by monkey-patching `Observable#subscribe()`
    track(true); // Subscriptions at this point are now being tracked
  }

  /**
   * Stops tracking subscriptions and reports all the active subscriptions
   */
  async stop({
    disableNavigation = false,
    customSubscriberFilter = this.internalFilter,
  }: StopFunctionOptions = {}): Promise<string[]> {
    if (!disableNavigation) {
      console.log('Logout and navigate to /internal/blank...');
      await (window as any).webapp.navigateByUrl(
        '/logout?w=t&next=/internal/blank',
      );
      console.log('Done');
    }
    const result = this.getSubscribers(customSubscriberFilter);
    track(false);
    return result.map(subscriber => `#${subscriber.id}: ${subscriber.stack}`);
  }

  printSubscribers(): void {
    const subscribers = this.getSubscribers(this.internalFilter);

    // Report active subscriptions as console errors
    printSubscribers({
      subscribers: new Iterator(new Set(subscribers)),
      reportInnerSubscriptions: true,
    });
  }

  getSubscribers(
    customFilter: (stack?: string) => boolean,
  ): SubscriptionSource[] {
    const subscribers = getSubscribers();
    const filteredStackTraces = Array.from(subscribers.current()).filter(
      subscriber => customFilter(subscriber.stack),
    );

    if (filteredStackTraces.length === 0) {
      console.log(
        '%c There is no active subscription!',
        'background: #222; color: #bada55',
      );
    }

    return filteredStackTraces;
  }

  private internalFilter(stack?: string): boolean {
    const lines = stack?.split('\n');
    if (lines && lines.length > 2) {
      // Knowing that subscriber is the instance of SubscriptionSource which
      // extends the JavaScript Error class. We can split the `stack` variable
      // value by \n, and we are interested in the following:
      // lines[0] = 'Error'
      // lines[1] => monkey-patched subscribe from observable-profile
      // lines[2] => we are interested in any Components, Services, Directives or Modules
      return !!lines[2].match(
        /\w+([Cc]omponent|[Ss]ervice|[Dd]irective|[Mm]odule).*\.js/,
      );
    }
    return false;
  }
}

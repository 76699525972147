import { EntryStatusApi } from 'api-typings/contests/contests';
import type { ContestEntry } from '../contest-entries/contest-entries.model';

/**
 * Contest entries model used in the contest view page.
 */
export interface ContestViewEntry extends ContestEntry {
  readonly commentCount: number;
  readonly sellPrice?: number;
  readonly isLiked: boolean;
  readonly seoUrl: string;
  readonly hasFeedback: ContestEntryHasFeedbackStatus;
  readonly likeCount: number;
  readonly freelancerRating: number;
}

/**
 * Used on server notifications model since entry-related websocket events
 * sends the status as a text.
 */
export enum ContestEntryStatusWebsocketEvent {
  WON = 'Won',
  BOUGHT = 'Bought',
  ELIMINATED = 'Eliminated',
  ACTIVE = 'Active',
  WITHDRAWN = 'Withdrawn',
}

export const CONTEST_ENTRY_TEXT_FORMATS: readonly string[] = ['pdf'];

/** Only includes awarded entries */
export const AWARDED_ENTRY_STATUSES: readonly EntryStatusApi[] = [
  EntryStatusApi.WON,
  EntryStatusApi.WON_CLOSED,
  EntryStatusApi.WON_RUNNER_UP,
  EntryStatusApi.WON_BRONZE,
  EntryStatusApi.WON_SILVER,
  EntryStatusApi.WON_GOLD,
  EntryStatusApi.CHOSEN,
];

/** Includes both awarded and bought entries */
export const AWARDED_BOUGHT_ENTRY_STATUSES: readonly EntryStatusApi[] = [
  EntryStatusApi.WON,
  EntryStatusApi.WON_CLOSED,
  EntryStatusApi.WON_RUNNER_UP,
  EntryStatusApi.WON_BRONZE,
  EntryStatusApi.WON_SILVER,
  EntryStatusApi.WON_GOLD,
  EntryStatusApi.CHOSEN,
  EntryStatusApi.BOUGHT,
];

export enum ContestEntryFileType {
  IMAGE,
  VIDEO,
  TEXT,
}

export enum ContestEntryHasFeedbackStatus {
  YES = 'yes',
  NO = 'no',
  UNKNOWN = 'unknown',
}

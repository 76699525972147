import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';

@Injectable({
  providedIn: 'root',
})
export class AdswapperTracking {
  private isEnabled = false;
  private isInitialised = false;

  constructor(
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private cookie: CookieService,
    private trackingConsent: TrackingConsent,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    if (trackingConsentStatus !== TrackingConsentStatus.AUTHORIZED) {
      this.isInitialised = true;
      this.isEnabled = false;
      return;
    }

    if (this.isEnabled || isPlatformServer(this.platformId)) {
      this.isInitialised = true;
      return;
    }
    const hasAdswapperCookie = this.cookie.get('FROM_ADSWAPPER') === 'true';
    // Since the pixel is merely an image network request, I'm not confident it
    // has a mechanism to distinguish from other traffic. Other platforms like google
    // and facebook and other vendors have some sort of click id and have internal
    // logic in their JS.
    if (
      !hasAdswapperCookie &&
      this.route.snapshot.queryParams.utm_source === 'adswapper'
    ) {
      this.cookie.put('FROM_ADSWAPPER', 'true');
      this.isEnabled = true;
    }
    if (hasAdswapperCookie) {
      this.isEnabled = true;
    }
    this.isInitialised = true;
  }

  async trackCustomEvent(): Promise<void> {
    if (!this.isInitialised) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      const trackingUrl =
        'https://adswapper.g2afse.com/success.jpg?offer_id=949&afstatus=1';

      // sendBeacon might not work for this type of pixel.
      // Based on other vendors, the request might return 200 but
      // they're not actually picked up. Creating an image tag
      // ensures it's as close as possible to the instructions
      const img = document.createElement('img');
      img.height = 1;
      img.width = 1;
      img.alt = '';
      img.src = trackingUrl;
    });
  }
}

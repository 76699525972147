import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Permissions } from './permissions.service';

export const PermissionsOverridesGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  const permissionService = inject(Permissions);
  const params = route.queryParams;

  if (params.no_permissions_request) {
    permissionService.disablePermissionRequest();
  }

  return true;
};

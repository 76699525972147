import { PoolApi } from 'api-typings/common/common';
import type { CommunityPool } from '../post-job-page-form-state';

/**
 * This enum only contains enterprises that used in Webapp
 * This is a temporary solution will get it from backend in T153666
 */
export enum Enterprise {
  DELOITTE_DC = 1,
  FACEBOOK = 3,
  PMI = 4,
  FLNLTD = 5,
  YARA = 7,
  FREIGHTLANCER = 19,
  HP = 27,
  GELATO = 182,
  TECH_MAHINDRA = 29,
  NASA = 83,
}

/**
 * Pool IDs
 * This is a temporary solution will get it from backend in T261609
 */
export enum Pool {
  FREELANCER = 1,
  DELOITTE_DC = 2,
  FACEBOOK = 4,
  API_E2E = 5,
  FLNLTD = 6,
  NOKIA = 7,
  JOHNSON_AND_JOHNSON = 8,
  IBM = 9,
  FREIGHTLANCER = 10,
  HP = 13,
  // TODO: T260416
  // Production pool id 16 -> will not work in other environments
  // Change to the dev pool id 1633 when testing
  BAHR = 16,
  DELOITTE_FTN = 89,
  HEYGEN = 99,
}

export enum PoolName {
  BAHR = 'bahr',
  HEYGEN = 'heygen',
  FREELANCER = 'freelancer',
  FACEBOOK = 'facebook',
}

/**
 * Converts the PoolApi string enum to the corresponding integer ID.
 * This is a temporary solution until we clean up backend pool conversions T221545
 */
export const POOL_API_TO_POOL_ID_MAP: {
  readonly [k in Exclude<PoolApi, PoolApi.ARROW_PRIVATE>]: Pool;
} = {
  [PoolApi.FREELANCER]: Pool.FREELANCER,
  [PoolApi.DELOITTE_DC]: Pool.DELOITTE_DC,
  [PoolApi.FACEBOOK]: Pool.FACEBOOK,
  [PoolApi.API_E2E]: Pool.API_E2E,
  [PoolApi.FLNLTD]: Pool.FLNLTD,
  [PoolApi.NOKIA]: Pool.NOKIA,
  [PoolApi.JOHNSON_AND_JOHNSON]: Pool.JOHNSON_AND_JOHNSON,
  [PoolApi.IBM]: Pool.IBM,
  [PoolApi.FREIGHTLANCER]: Pool.FREIGHTLANCER,
  [PoolApi.DELOITTE_FTN]: Pool.DELOITTE_FTN,
  [PoolApi.HEYGEN]: Pool.HEYGEN,
};

/**
 * A mapping of visible pools, i.e. pools that are able to be fetched and
 * posted to without the user being a part of the pool
 */
export type VisiblePool = PoolName.BAHR | PoolName.HEYGEN;
export const VISIBLE_POOL_MAP: {
  readonly [k in VisiblePool]: Pool;
} = {
  [PoolName.BAHR]: Pool.BAHR,
  [PoolName.HEYGEN]: Pool.HEYGEN,
};

/**
 * A mapping of community list items to pool IDs. This must be kept up to date
 * with the CommunityPool type
 */
export const COMMUNITY_POOL_MAP: {
  readonly [k in CommunityPool]: number;
} = {
  [PoolName.FREELANCER]: Pool.FREELANCER,
  [PoolName.FACEBOOK]: Pool.FACEBOOK,
  [PoolName.HEYGEN]: Pool.HEYGEN,
};

import type {
  EquipmentApi,
  EquipmentGroupApi,
} from 'api-typings/common/common';
import type { EquipmentGroup, EquipmentItem } from './equipment-group.model';

export function transformEquipmentGroup(
  response: EquipmentGroupApi,
): EquipmentGroup {
  return {
    id: response.id,
    name: response.name,
    hasUserInput: response.has_user_input,
    isProjectRequired: response.is_project_required,
    isUserRequired: response.is_user_required,
    items: response.items?.map(item => transformEquipmentGroupItems(item)),
  };
}

export function transformEquipmentGroupItems(
  response: EquipmentApi,
): EquipmentItem {
  return {
    id: response.id,
    name: response.name,
  };
}

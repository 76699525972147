import type { Observable } from 'rxjs';
import { merge } from 'rxjs';
import { skip, switchMap, take } from 'rxjs/operators';

/**
 * Duplicate the first thing emitted from an observable so it is emitted twice.
 * Takes two optional tranformations to apply to those two emitted values.
 */
export function duplicateFirst<T>(
  firstTransform?: (from: T) => T,
  secondTransform?: (from: T) => T,
): (source$: Observable<T>) => Observable<T> {
  return (source$: Observable<T>) =>
    merge(
      source$.pipe(
        take(1),
        switchMap(x => [
          firstTransform ? firstTransform(x) : x,
          secondTransform ? secondTransform(x) : x,
        ]),
      ),
      source$.pipe(skip(1)),
    );
}

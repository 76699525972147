import { InjectionToken } from '@angular/core';
import type { HttpAdapter } from '@freelancer/freelancer-http';
import type {
  DatastoreConfig,
  NonFetchRequestConfig,
  RefetchConfig,
} from './datastore.interface';

export const DATASTORE_CONFIG = new InjectionToken<DatastoreConfig>(
  'Datastore Configuration',
);

export const HTTP_ADAPTER = new InjectionToken<HttpAdapter>('HttpAdapter');
export const REFETCH_CONFIG = new InjectionToken<RefetchConfig>(
  'Refetch Configuration',
);
export const NON_FETCH_REQUEST_CONFIG =
  new InjectionToken<NonFetchRequestConfig>('NonFetchRequest Configuration');

export enum ProjectUpgrade {
  RECRUITER = 'recruiter',
  NDA = 'nda',
  IP_AGREEMENT = 'ip agreement',
  FEATURED = 'featured',
  URGENT = 'urgent',
  PRIVATE = 'private',
  SEALED = 'sealed',
}

/**
 * Represents an upgrade recommended by ai
 *
 */
export interface RecommendedUpgrade {
  readonly id: number;
  readonly upgrade: ProjectUpgrade;
  readonly reason: string;
  readonly title: string;
  readonly description: string;
  readonly type: string;
  readonly skillNames: readonly string[];
}

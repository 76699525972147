import { isPlatformBrowser } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import type { Event } from '@angular/router';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Localization, WEBAPP_LANGUAGE_COOKIE } from '@freelancer/localization';
import { Location } from '@freelancer/location';
import { isDefined } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie';
import type { Subscription } from 'rxjs';
import { firstValueFrom, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { ShellConfig } from '../../app/app-shell';
import { FREELANCER_PWA_TRACKING_PROVIDER } from './config';
import { FreelancerPwaTrackingInterface } from './interface';
import type { CustomAppData } from './pwa.service';
import { Pwa } from './pwa.service';
import { SwipeNavigationService } from './swipe-navigation.service';

/**
 * Initialize the app PWA functionalities
 */
@UntilDestroy({ className: 'PwaComponent' })
@Component({
  selector: 'fl-pwa',
  template: ``,
  styleUrls: ['./pwa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwaComponent implements OnInit, OnDestroy {
  private checkForUpdatesOnNavigateSubscription?: Subscription;
  private forceUpgradeSubscription?: Subscription;
  private unrecoverableTrackingSubscription?: Subscription;
  private hideNativeSplashScreenSubscription?: Subscription;
  private configSubscription?: Subscription;
  private beforeInstallPromptSubscription?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private shellConfig: ShellConfig,
    private cookies: CookieService,
    private errorHandler: ErrorHandler,
    private location: Location,
    private localization: Localization,
    private updates: SwUpdate,
    private pwa: Pwa,
    @Inject(FREELANCER_PWA_TRACKING_PROVIDER)
    private pwaTracking: FreelancerPwaTrackingInterface,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WEBAPP_LANGUAGE_COOKIE) private webappLanguageCookie: string,
    private swipeNavigation: SwipeNavigationService,
  ) {}

  async ngOnInit(): Promise<boolean | undefined> {
    if (isPlatformBrowser(this.platformId) && window.webapp) {
      if (this.updates.isEnabled) {
        firstValueFrom(
          this.pwa.appVersion().pipe(filter(isDefined), untilDestroyed(this)),
        ).then(appVersion => {
          console.log(
            `Version ${appVersion.gitRevision} (${
              appVersion.buildTimestampInSeconds
            } / ${new Date(
              appVersion.buildTimestampInSeconds * 1000,
            ).toUTCString()})`,
          );
        });
        // TODO: T267853 - change that to SwUpdate::checkVersion() once it has been
        // implemented
        // This is used by the ngsw webapp e2e test to make it more reliable
        if (navigator.serviceWorker.controller !== null) {
          window.webapp.debugIsServiceWorkerReady = true;
        } else {
          firstValueFrom(
            fromEvent(navigator.serviceWorker, 'controllerchange').pipe(
              take(1),
              untilDestroyed(this),
            ),
          ).then(() => {
            if (navigator.serviceWorker.controller !== null) {
              if (window.webapp) {
                window.webapp.debugIsServiceWorkerReady = true;
              }
            }
          });
        }
      }

      // If the app language doesn't match the user selected one, bypass the
      // service worker to reload the correct app shell from the server.
      const languageCode = this.cookies.get(this.webappLanguageCookie);

      if (
        // Do not do this on native as it might cause the IAB to open instead.
        !this.pwa.isNative() &&
        languageCode &&
        this.localization.languageCode !== languageCode
      ) {
        const url = new URL(this.location.href);
        if (!url.searchParams.has('ngsw-bypass')) {
          console.log(
            `App language "${this.localization.languageCode}" does not match user language "${languageCode}", reloading...`,
          );
          // setting the lang param here isn't stricky necessary but allows to
          // bypass the browser cache (and no, that's a different thing from
          // the service worker cache itself).
          url.searchParams.set('lang', languageCode);
          url.searchParams.set('ngsw-bypass', '');
          return this.location.redirect(url.toString());
        }
        this.errorHandler.handleError(
          new Error(
            `App language "${this.localization.languageCode}" does not match user language "${languageCode}" after reload`,
          ),
        );
      }

      // If the app is running in installed mode, activate the global installed
      // mode overrides
      if (this.pwa.isInstalled()) {
        this.pwa.activateInstalledMode();
      }

      this.forceUpgradeSubscription = this.pwa
        .updateEvents()
        .subscribe(event => {
          switch (event.type) {
            case 'VERSION_DETECTED': {
              if (this.hasCustomAppData(event.version)) {
                this.trackPwaEvent('detected_version', event.version);

                const data = event.version.appData;
                console.log(
                  `New version ${data.gitRevision.slice(0, 7)} (${new Date(
                    data.buildTimestamp,
                  ).toUTCString()}) available for download`,
                );
                // If the user is past the minimum version, force an upgrade immediately.
                // IMPORTANT: Bumping minimumVersionTimestamp is a fail-safe that should only be
                // activated when a version is badly broken, as it would cause data
                // loss on the client-side, since users will be hard-refreshed while
                // potentially filling forms
                if (
                  window.webapp &&
                  data.minimumVersionTimestamp >
                    window.webapp.version.buildTimestamp
                ) {
                  this.pwa.forceActivateUpdate();
                }
              } else {
                this.errorHandler.handleError(
                  new Error(
                    `Invalid SwUpdate available update appData: ${JSON.stringify(
                      event,
                    )}`,
                  ),
                );
              }
              break;
            }
            case 'VERSION_INSTALLATION_FAILED': {
              if (this.hasCustomAppData(event.version)) {
                this.trackPwaEvent('failed_version', event.version);
              } else {
                this.errorHandler.handleError(
                  new Error(
                    `Invalid SwUpdate available update appData: ${JSON.stringify(
                      event,
                    )}`,
                  ),
                );
              }
              break;
            }
            case 'VERSION_READY': {
              if (
                this.hasCustomAppData(event.currentVersion) &&
                this.hasCustomAppData(event.latestVersion)
              ) {
                this.trackPwaEvent(
                  'downloaded_version',
                  event.latestVersion,
                  event.currentVersion,
                );
                const data = event.latestVersion.appData;
                console.log(
                  `New version ${data.gitRevision.slice(0, 7)} (${new Date(
                    data.buildTimestamp,
                  ).toUTCString()}) downloaded and ready for activation.`,
                );
                // If the current version is a week older than the new one, force
                // upgrade the user at the next navigation. This ensures users
                // infrequently using the app still get the latest features quickly,
                // and prevents us from having to maintain our API backwards compatibility
                // for months.
                if (
                  window.webapp &&
                  data.buildTimestamp - window.webapp.version.buildTimestamp >
                    // a week in ms
                    7 * 24 * 60 * 60 * 1000
                ) {
                  console.log(
                    `Current version is older than a week, new version will be activated on the next navigation`,
                  );
                  this.pwa.activateUpdateOnNavigation();
                } else {
                  // In other cases, the user will be updated automatically the NEXT
                  // time they open the site, as the SW will use the latest version.
                  console.log(`New version will be activated on next load.`);
                }
              } else {
                this.errorHandler.handleError(
                  new Error(
                    `Invalid SwUpdate available update appData: ${JSON.stringify(
                      event,
                    )}`,
                  ),
                );
              }
              break;
            }
            default:
              break;
          }
        });

      this.unrecoverableTrackingSubscription = this.pwa
        .unrecoverableEvents()
        .subscribe(e => {
          this.pwaTracking.trackCustomEvent('unrecoverable', 'pwa', {
            reason: e.reason,
          });
          this.errorHandler.handleError(
            new Error(
              `Service worker reached unrecoverable state; ${e.reason}`,
            ),
          );
          // Notify the user to reload?
        });

      // Then start the background update checks (every hour)
      this.pwa.startBackgroundUpdateChecks();

      // Also check for updates every 15 minutes on webapp navigations
      // We don't want to do that at every single navigation as the Service
      // Worker manifest (ngsw.json) still has a decent size (~50kB gzipped)
      let lastNavigationCheckTime = Date.now();
      this.checkForUpdatesOnNavigateSubscription = this.router.events
        .pipe(filter((event: Event) => event instanceof NavigationEnd))
        .subscribe(() => {
          const currentTime = Date.now();
          if (currentTime - lastNavigationCheckTime > 15 * 60 * 1000) {
            this.pwa.checkForUpdate();
            lastNavigationCheckTime = currentTime;
          }
        });

      // Disable the PWA install banners
      this.beforeInstallPromptSubscription = fromEvent(
        window,
        'beforeinstallprompt',
      ).subscribe(e => {
        e.preventDefault();
      });

      if (this.pwa.isNative()) {
        const config$ = this.shellConfig.getConfig(this.activatedRoute);
        this.configSubscription = config$
          .pipe(distinctUntilChanged())
          .subscribe(config => {
            StatusBar.setStyle({
              style: config.lightStatusBar ? Style.Light : Style.Dark,
            });

            if (this.pwa.getPlatform() === 'android') {
              StatusBar.setBackgroundColor({
                color: config.lightStatusBar ? '#FFFFFF' : '#12151b',
              });
            }
          });

        if (this.pwa.getPlatform() === 'android') {
          // Override the default behaviour of triggering webview's native
          // back() function on Android and replace with our implementation.
          App.addListener('backButton', () => {
            this.location.back();
          });
        }

        // Enable swipe to go back / forward gesture in IOS
        this.swipeNavigation.enableSwipeGestures(true);

        if (this.pwa.getPlatform() === 'ios') {
          // Set the keyboard styles for iPhone
          Keyboard.setAccessoryBarVisible({ isVisible: true });
        }

        this.scrollToFieldIfNeeded();
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.pwa.stopBackgroundUpdateChecks();
    }

    if (this.pwa.isInstalled() && this.pwa.isNative()) {
      this.removeKeyboardListeners();
    }

    this.hideNativeSplashScreenSubscription?.unsubscribe();
    this.checkForUpdatesOnNavigateSubscription?.unsubscribe();
    this.forceUpgradeSubscription?.unsubscribe();
    this.unrecoverableTrackingSubscription?.unsubscribe();
    this.configSubscription?.unsubscribe();
    this.beforeInstallPromptSubscription?.unsubscribe();
  }

  private hasCustomAppData(
    version: any,
  ): version is { appData: CustomAppData } {
    return (
      version.appData &&
      version.appData.gitRevision &&
      typeof version.appData.gitRevision === 'string' &&
      version.appData.buildTimestamp &&
      typeof version.appData.buildTimestamp === 'number' &&
      version.appData.minimumVersionTimestamp &&
      typeof version.appData.minimumVersionTimestamp === 'number'
    );
  }

  /**
   * Fix issue where sometimes focused elements
   * are hidden behind the virtual keyboard.
   *
   * Use element.scrollIntoViewIfNeeded() instead of the ff:
   * - `window.scrollTo()` won't work inside modals and callouts
   * - `element.scrollIntoView()` can only position the element to
   *   either start/top or end/bottom if the options isn't supported
   *   which is both not practical as we have sticky header and sometimes
   *   dropdown results for component such as multi select
   */
  private async scrollToFieldIfNeeded(): Promise<void> {
    Keyboard.addListener('keyboardDidShow', () => {
      const element = document.activeElement as Element & {
        scrollIntoViewIfNeeded?(): void;
      };

      if (element.scrollIntoViewIfNeeded) {
        element.scrollIntoViewIfNeeded();
      }
    });
  }

  private async removeKeyboardListeners(): Promise<void> {
    Keyboard.removeAllListeners();
  }

  private trackPwaEvent(
    eventType: string,
    availableVersion: { hash: string; appData: CustomAppData },
    currentVersion?: { hash: string; appData: CustomAppData },
  ): void {
    this.pwaTracking.trackCustomEvent(eventType, 'pwa', {
      available_manifest_hash: availableVersion.hash,
      available_git_revision: availableVersion.appData.gitRevision,
      available_build_timestamp: availableVersion.appData.buildTimestamp,
      available_minimum_version_timestamp:
        availableVersion.appData.minimumVersionTimestamp,
      ...(currentVersion
        ? {
            current_manifest_hash: currentVersion.hash,
            current_git_revision: currentVersion.appData.gitRevision,
            current_build_timestamp: currentVersion.appData.buildTimestamp,
            current_minimum_version_timestamp:
              currentVersion.appData.minimumVersionTimestamp,
          }
        : {}),
    });
  }
}

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import type { SSOInterface, SSOResponseData, SSOUser } from '@freelancer/auth';
import { Auth } from '@freelancer/auth';
import { Localization } from '@freelancer/localization';
import { Pwa } from '@freelancer/pwa';
import { Tracking } from '@freelancer/tracking';
import { ModalService } from '@freelancer/ui';
import { ModalSize } from '@freelancer/ui/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { RoleApi } from 'api-typings/common/common';
import type { Observable } from 'rxjs';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { GOOGLE_SIGN_IN_CONFIG } from './google-sign-in.config';
import type {
  GoogleAuthDecodedTokenUserDetails,
  GoogleAuthResponse,
} from './google-sign-in.interface';
import {
  GoogleSignInConfig,
  GoogleSignInError,
} from './google-sign-in.interface';

declare const google: any;

export type GoogleCallBack = (response: any) => void;
@Injectable({
  providedIn: 'root',
})
@UntilDestroy({ className: 'GoogleSignInService' })
export class GoogleSignInService
  implements SSOInterface<'google', GoogleAuthResponse>
{
  private credential: string;
  private isLoadedSubject$ = new BehaviorSubject<boolean>(false);
  private isLoaded$ = this.isLoadedSubject$.asObservable();

  constructor(
    private auth: Auth,
    private localization: Localization,
    private modalService: ModalService,
    private pwa: Pwa,
    private tracking: Tracking,
    @Inject(GOOGLE_SIGN_IN_CONFIG) private config: GoogleSignInConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  // This will check if the config file has been set
  async isEnabled(): Promise<boolean> {
    return !!this.config.clientId;
  }

  // This returns the credential (JWT) if exists which should be set in the callback
  // function that is passed to loadGoogleSSO.
  // Unlike Facebook & Apple, Google SSO doesn't have a direct login function that we can call.
  // In webapp, the login functionality is handle by the button Google provide
  // and returns the response/credential in the callback function that we set in loadGoogleSSO in the component
  // where the google button is used.
  // So in the callback, we set the credential into this service, then we can call this function to get the credential
  // as we need the auth response to continue.
  async login(): Promise<
    SSOResponseData<'google', GoogleAuthResponse, GoogleSignInError>
  > {
    if (!(await this.isEnabled())) {
      return {
        status: 'error',
        provider: 'google',
        errorCode: GoogleSignInError.UNKNOWN,
      };
    }

    if (this.credential) {
      return {
        status: 'success',
        provider: 'google',
        result: { credential: this.credential },
      };
    }

    return {
      status: 'error',
      provider: 'google',
      errorCode: GoogleSignInError.UNKNOWN,
    };
  }

  // This abstracts away the logic of loading Google SSO from app components
  // and handles the loading of both native and webapp Google SSO depending on the platform.
  // When using this, call the function as you would for the web platform with the parameters,
  // but note that the parameters are not used in native because their APIs are different.
  // The Google button is created by Google's own script on the web platform,
  // but we need to create our own button for native and attach a click handler to it
  loadGoogleSSO(
    buttonId: string,
    callback: GoogleCallBack,
    width?: number,
  ): void {
    if (this.pwa.isNative()) {
      this.loadGoogleSSONative();
    } else {
      this.loadGoogleSSOWeb(buttonId, callback, width);
    }
  }

  loadGoogleSnippet(
    callback: GoogleCallBack,
    afterLoadCallback: () => void,
  ): HTMLScriptElement | undefined {
    if (!document.getElementById('gsi-client')) {
      const e = document.createElement('script');
      e.src = 'https://accounts.google.com/gsi/client';
      e.async = true;
      e.id = 'gsi-client';
      const s = document.getElementsByTagName('script')[0];
      (s.parentNode as Node).insertBefore(e, s);
      e.onload = () => {
        google.accounts.id.initialize({
          client_id: this.config.clientId,
          callback: (response: any) => {
            callback(response);
          },
          cancel_on_tap_outside: false,
          auto_prompt: false,
          context: '',
        });
        afterLoadCallback();
        this.isLoadedSubject$.next(true);
      };
      return e;
    }

    afterLoadCallback();
  }

  // This will load the Google SSO script and render the button
  // buttonId is the id of the div placeholder where the google button will be rendered on
  // callback is the function that will be called when the user done interacting with the pop up window from google
  loadGoogleSSOWeb(
    buttonId: string,
    callback: GoogleCallBack,
    width?: number,
  ): void {
    this.loadGoogleSnippet(callback, () => {
      const buttonLocation = document.getElementById(buttonId);
      google.accounts.id.renderButton(
        buttonLocation,
        {
          theme: 'outline',
          shape: 'rectangle',
          size: 'large',
          width:
            width ||
            (buttonLocation ? Math.floor(buttonLocation.offsetWidth) : '352'),
          text: 'continue_with',
          locale: this.localization.languageCode,
          auto_prompt: false,
          context: '',
          use_fedcm_for_prompt: true,
        }, // customization attributes
      );
    });
  }

  loadGoogleOneTapPrompt(
    shouldRedirect?: boolean,
    forceAccountType?: RoleApi,
  ): void {
    this.loadGoogleSnippet(
      (response: any) => {
        this.setCredential(response.credential);
        this.modalService.open('LoginSignupModal', {
          inputs: {
            forceAccountType,
            oneTapProvider: 'google',
            shouldRedirect,
          },
          size: ModalSize.XSMALL,
          edgeToEdge: true,
          closeable: true,
          mobileFullscreen: true,
        });
      },
      () => {
        if (isPlatformBrowser(this.platformId) && !this.pwa.isNative()) {
          firstValueFrom(
            this.auth.isLoggedIn().pipe(untilDestroyed(this)),
          ).then(response => {
            if (!response)
              google.accounts.id.prompt((notification: any) => {
                if (
                  notification.getMomentType() === 'skipped' ||
                  notification.getMomentType() === 'dismissed'
                ) {
                  this.tracking.trackCustomEvent(
                    'GoogleOneTapDidNotDisplay',
                    'GoogleOneTap',
                    {
                      message: JSON.stringify({
                        getMomentType: notification.getMomentType(),
                      }),
                    },
                  );
                }
              });
          });
        }
      },
    );
  }

  loadGoogleSSONative(): void {
    GoogleAuth.initialize({
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
  }

  async handleGoogleCredential(credential?: string): Promise<void> {
    let googleUser: any;
    if (this.pwa.isNative()) {
      googleUser = await GoogleAuth.signIn();
    }

    this.setCredential(
      credential ?? googleUser.idToken ?? googleUser.authentication.idToken,
    );
  }

  async getUserDetails(): Promise<SSOUser> {
    const decodedJwt = this.parseJwt(this.credential);
    return {
      email: decodedJwt.email,
      name: decodedJwt.given_name,
      lastName: decodedJwt.family_name,
      profileUrl: decodedJwt.picture,
    };
  }

  async loadLoginStatus(): Promise<boolean> {
    const isLoaded = await firstValueFrom(
      this.isLoaded$.pipe(untilDestroyed(this)),
    );
    return isLoaded;
  }

  // In the google button component, we use a temporary button while loading
  // the Google button to avoid CLS. We need an observable for the loaded
  // status because we want the Google button to replace our temporary button
  // only when it is fully loaded because if not, what can happen is that
  // the temporary button is removed while the Google button is not ready (i.e, not done
  // fetching script, or haven't completed rendering the button) then the Google
  // button re-appearing causing more CLS.
  getLoadStatusObservable(): Observable<boolean> {
    return this.isLoaded$;
  }

  setCredential(credential: string): void {
    this.credential = credential;
  }

  parseJwt(token: string): GoogleAuthDecodedTokenUserDetails {
    return JSON.parse(atob(token.split('.')[1]));
  }

  async closeGoogleOneTapPrompt(): Promise<void> {
    const isLoaded = await this.loadLoginStatus();
    if (isLoaded) {
      google.accounts.id.cancel();
    }
  }
}

// FIXME: T235847
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type { TotpMethod } from '@freelancer/datastore/collections/two-factor-details';
import type { ResponseData } from '@freelancer/freelancer-http';
import type { UserCreateResultApi } from 'api-typings/users/users';
import type {
  AuthReactivationResponseAjax,
  AuthResponseAjax,
  AuthSuccessResponseAjax,
  TwoFactorResponseAjax,
} from './login-signup.backend-model';
import type {
  AuthReactivationResponse,
  AuthResponse,
  TwoFactorResponse,
} from './login-signup.model';

interface PreLoginToken {
  user_id: number;
  '2fa_methods': TotpMethod[];
  // plus some other fields but we don't need them
  /**
   *   "iss": "freelancer.com/auth",
   *   "exp": 1540190368,
   *   "iat": 1540188568,
   *   "type": "pre-login-token",
   *   "device_id": "07YVnaQ8zHFxQUSNR6ub//fXySuH2MOh"
   */
}

export function transformResponseData<T, U, E>(
  responseData: ResponseData<T, E>,
  transform: (obj: T) => U,
): ResponseData<U, E> {
  if (responseData.status === 'error') {
    return responseData;
  }
  return {
    ...responseData,
    result: transform(responseData.result),
  };
}

export function transformAuthResponse(
  response: AuthResponseAjax,
): AuthResponse {
  if ('pre_login_token' in response) {
    return transformTwoFactorResponse(response);
  }

  if ('action' in response) {
    return transformReactivationResponse(response);
  }

  return response;
}

export function transformTwoFactorResponse(
  response: TwoFactorResponseAjax,
): TwoFactorResponse {
  // decode the pre_login_token to get the method
  let method: TotpMethod | undefined;
  try {
    const twoFATokenDecoded = decodePreLoginToken(response.pre_login_token);
    [method] = twoFATokenDecoded['2fa_methods'];
  } catch (e: any) {
    console.log(e);
    method = undefined;
  }

  return {
    preLoginToken: response.pre_login_token,
    message: response.message,
    method,
    backoffTimeLeft: response.backoffTimeLeft,
  };
}

export function transformUserCreateResonse(
  response: UserCreateResultApi,
): AuthSuccessResponseAjax {
  return {
    user: response.user.id,
    token: response.auth_hash,
  };
}

export function transformReactivationResponse(
  response: AuthReactivationResponseAjax,
): AuthReactivationResponse {
  return {
    // The `action` property is transformed to `nextStep`
    // since `action` already is part of `SSOActionResponse`
    // and retaining `action` property in
    // `AuthReactivationResponse` will cause a conflict with
    // `SSOActionResponse` because typescript cannot
    // determine which interface to map due to
    // conflicting property name.
    nextStep: response.action,
    userId: response.user_id,
  };
}

/**
 * Returns the decoded JWT token used for 2FA
 */
function decodePreLoginToken(preLoginToken: string): PreLoginToken {
  return JSON.parse(atob(preLoginToken.split('.')[1]));
}

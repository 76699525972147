import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { IconColor } from '@freelancer/ui/icon';
import {
  SpinnerBackgroundColor,
  SpinnerColor,
  SpinnerSize,
  SpinnerType,
} from './spinner.types';

@Component({
  selector: 'fl-spinner',
  template: `
    <div
      role="presentation"
      [class.Spinner]="type === SpinnerType.DEFAULT"
      [class.Bird]="type === SpinnerType.BIRD"
      [attr.data-color]="overlay ? SpinnerColor.PRIMARY : color"
      [attr.data-size]="size"
      [attr.data-type]="type"
    >
      <fl-picture
        *ngIf="type === SpinnerType.BIRD"
        [src]="'bits/gif/bird-animated-css.svg'"
      ></fl-picture>
    </div>
  `,
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  SpinnerColor = SpinnerColor;
  IconColor = IconColor;
  SpinnerType = SpinnerType;

  @Input() size: SpinnerSize = SpinnerSize.MID;
  @Input() color: SpinnerColor = SpinnerColor.PRIMARY;
  @Input() type: SpinnerType = SpinnerType.DEFAULT;

  @HostBinding('attr.data-background')
  @Input()
  backgroundColor: SpinnerBackgroundColor = SpinnerBackgroundColor.LIGHT;

  @HostBinding('class.Overlay')
  @Input()
  overlay = false;

  @HostBinding('class.IsModalOverlay')
  @Input()
  overlayForModal = false;
}

import type { GroupMemberRoleApi } from 'api-typings/groups/groups';
import { GroupPermissionApi } from 'api-typings/groups/groups';

/**
 * Represents the global group permissions for a given group fetched as a superuser.
 */
export interface SuperuserGroupPermissions {
  /** ID here refers to Group ID **/
  readonly id: number;
  readonly permissions: GroupRolePermissions;
}

export type GroupRolePermissions = {
  readonly [j in GroupMemberRoleApi]?: {
    readonly [k in GroupPermissionApi]?: boolean;
  };
};

export type EditableGroupRolePermissions = {
  [j in GroupMemberRoleApi]: {
    [k in EditablePermissions]: boolean;
  };
};

export const ALL_EDITABLE_PERMISSIONS = [
  GroupPermissionApi.POST_ANY_DELETE,
  GroupPermissionApi.POST_CREATE,
  GroupPermissionApi.POST_REACT,
  GroupPermissionApi.POST_OWN_DELETE,
  GroupPermissionApi.POST_OWN_EDIT,
  GroupPermissionApi.COMMENT_ANY_DELETE,
  GroupPermissionApi.COMMENT_CREATE,
  GroupPermissionApi.COMMENT_REACT,
  GroupPermissionApi.COMMENT_OWN_EDIT,
  GroupPermissionApi.USER_MUTE,
  GroupPermissionApi.MEMBERS_GET_BY_ROLES,
  GroupPermissionApi.FEED_GET,
  GroupPermissionApi.COVER_IMAGE_EDIT,
] as const;

export type EditablePermissions = (typeof ALL_EDITABLE_PERMISSIONS)[number];

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';

@Injectable({
  providedIn: 'root',
})
export class Hotjar {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
  ) {}

  insertScript(): void {
    const hotjarScript = this.document.getElementById('hotjar');
    if (isPlatformBrowser(this.platformId) && hotjarScript === null) {
      this.setUpQueue();

      window._hjSettings = { hjid: this.config.hotjarId, hjsv: 6 };

      const script = this.document.createElement('script');
      script.id = 'hotjar';
      script.async = true;
      script.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;

      this.document.head.appendChild(script);
    }
  }

  sendUserAttributes(sessionId: string, userId?: string): void {
    this.setUpQueue();
    window.hj('identify', userId, { session_id: sessionId });
  }

  /**
   * Set up queue to capture any commands being sent before the Hotjar script is loaded.
   * Once the hotjar script is loaded it will send these commands
   */
  setUpQueue(): void {
    window.hj =
      window.hj ||
      function setHj(...args: any): void {
        (window.hj.q = window.hj.q || []).push(args);
      };
  }
}

import { AsyncPipe } from '@angular/common';
import type { Provider } from '@angular/core';
import { ChangeDetectorRef, NgModule } from '@angular/core';
import { FreelancerAsyncPipe } from './async.pipe';

@NgModule({
  declarations: [FreelancerAsyncPipe],
  exports: [FreelancerAsyncPipe],
  providers: [AsyncPipe, ChangeDetectorRef as Provider, FreelancerAsyncPipe],
})
export class FreelancerAsyncPipeModule {}

import type { Renderer2 } from '@angular/core';
import { Inject, Injectable, Optional, RendererFactory2 } from '@angular/core';
import { TimeUtils } from '@freelancer/time-utils';
import { isDefined } from '@freelancer/utils';
import { TESTING_CLOSE_ANIMATION_DISABLED } from '../ui.config';
import type { ToastAlertContainerComponentInterface } from './toast-alert-container.types';
import type { ToastAlertComponentInterface } from './toast-alert.types';

@Injectable({ providedIn: 'root' })
export class ToastAlertService {
  private toastAlerts: { [k: string]: ToastAlertComponentInterface } = {};
  private activeToasts: ToastAlertComponentInterface[] = [];
  private containers: ToastAlertContainerComponentInterface[] = [];
  private renderer: Renderer2;
  private autoCloseDisabled = false;

  constructor(
    rendererFactory: RendererFactory2,
    private timeUtils: TimeUtils,
    /** This should only be injected in UI tests */
    @Optional()
    @Inject(TESTING_CLOSE_ANIMATION_DISABLED)
    private readonly testingCloseAnimationDisabled?: boolean,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  add(toastAlertItem: ToastAlertComponentInterface, id: string): void {
    this.toastAlerts = { ...this.toastAlerts, ...{ [id]: toastAlertItem } };
  }

  remove(id: string): void {
    delete this.toastAlerts[id];
  }

  registerContainer(
    toastAlertContainer: ToastAlertContainerComponentInterface,
  ): void {
    this.containers = [...this.containers, toastAlertContainer];
  }

  unregisterContainer(
    toastAlertContainer: ToastAlertContainerComponentInterface,
  ): void {
    this.containers = this.containers.filter(
      item => item !== toastAlertContainer,
    );
  }

  open(id: string): void {
    // wait a cycle before opening
    // the container might be rendered after the element
    this.timeUtils.setTimeout(() => {
      const toastAlertItem = this.toastAlerts[id];

      if (!isDefined(toastAlertItem)) {
        throw new Error(`Nonexistent toast alert ${id}`);
      }

      if (this.activeToasts.includes(toastAlertItem)) {
        toastAlertItem.resetTimer();
      } else {
        this.containers.forEach(item => {
          if (item.isElementVisible()) {
            this.renderer.appendChild(
              item.container.nativeElement,
              toastAlertItem.element,
            );
          }
        });

        this.renderer.addClass(toastAlertItem.element, 'IsActive');
        toastAlertItem.toggleVisibility('visible');
        toastAlertItem.startTimer();
        this.addToActiveToasts(toastAlertItem);
      }
    });
  }

  close(id: string): void {
    const toastAlertItem = this.toastAlerts[id];

    if (toastAlertItem === undefined) {
      return;
    }
    toastAlertItem.toggleVisibility('hidden');
    this.activeToasts = this.activeToasts.filter(x => x.id !== id);
    this.repositionItems();

    if (this.testingCloseAnimationDisabled) {
      /**
       * Bypass `animationDone` callback which is being triggered later than expected in consecutive UI tests.
       * This effectively removes the close animation of the toast alert.
       */
      this.removeElement(id);
      toastAlertItem.unsubscribeTimer();
    }
  }

  addToActiveToasts(item: ToastAlertComponentInterface): void {
    this.activeToasts = [...this.activeToasts, item];
    this.repositionItems();
  }

  removeElement(id: string): void {
    const toastAlertItem = this.toastAlerts[id];

    if (toastAlertItem) {
      this.containers.forEach(item => {
        if (item.isElementVisible()) {
          this.renderer.removeChild(
            item.container.nativeElement,
            toastAlertItem.element,
          );
        }
      });
    }
  }

  disableAutoCloseGlobally(): void {
    this.autoCloseDisabled = true;
  }

  isAutoCloseDisabled(): boolean {
    return this.autoCloseDisabled;
  }

  private repositionItems(): void {
    const offset = 8;
    let position = offset;

    this.activeToasts.forEach(item => {
      item.move(position);
      position += item.element.offsetHeight + offset;
    });
  }
}

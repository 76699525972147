import { isPlatformBrowser } from '@angular/common';
import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Tracking } from './tracking.service';

@Component({
  selector: 'fl-tracking',
  template: `
    <fl-page-view-tracking></fl-page-view-tracking>
    <fl-navigation-performance-tracking></fl-navigation-performance-tracking>
    <fl-memory-tracking></fl-memory-tracking>
    <fl-synthetic-performance-tracking></fl-synthetic-performance-tracking>
    <fl-core-web-vitals-tracking></fl-core-web-vitals-tracking>
    <fl-long-task-tracking></fl-long-task-tracking>
    <fl-tracking-hotjar-user-attributes></fl-tracking-hotjar-user-attributes>
    <fl-translation-usage-tracking></fl-translation-usage-tracking>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackingComponent implements OnInit {
  constructor(
    private tracking: Tracking,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.tracking.sendEventBacklog();
    }
  }
}

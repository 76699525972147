import type {
  UserSettingApi,
  UserSettingUpdateResultApi,
} from 'api-typings/users/users';
import type { UserSetting } from './user-settings.model';

export function transformUserSettingGetResult(
  user_settings: readonly UserSettingApi[],
): readonly UserSetting[] {
  return user_settings.map(transformUserSetting);
}

export function transformUserSetting(userSetting: UserSettingApi): UserSetting {
  return {
    id: getUserSettingId(userSetting),
    userId: userSetting.user_id,
    context: userSetting.context,
    target: userSetting.target,
    targetId: userSetting.target_id,
    value: userSetting.value,
  };
}

export function transformUserSettingUpdateResult(
  result: UserSettingUpdateResultApi,
): UserSetting {
  return transformUserSetting(result.user_setting);
}

export function getUserSettingId(setting: UserSettingApi): string {
  let id = `${setting.context}-${setting.target}`;

  if (setting.target_id !== undefined) {
    id += `-${setting.target_id}`;
  }

  return id;
}

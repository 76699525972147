import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import type { Subscription } from 'rxjs';
import { getRecursiveRouteData } from './page-view-tracking.helpers';
import { Tracking } from './tracking.service';

@Component({
  selector: 'fl-page-view-tracking',
  template: ` <ng-container></ng-container> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageViewTrackingComponent implements OnInit, OnDestroy {
  private trackPageViewSubscription?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private tracking: Tracking,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.trackPageViewSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.tracking.trackPageView(
          getRecursiveRouteData(this.activatedRoute.snapshot).is404,
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.trackPageViewSubscription?.unsubscribe();
  }
}

import type { Observable } from 'rxjs';
import { of, combineLatest, firstValueFrom } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import type { RecursivePartial } from './helpers';
import type { RequestStatus } from './request-status-handler.service';
import type { StoreBackendInterface } from './store-backend.interface';
import type {
  BackendDeleteResponse,
  BackendSetResponse,
  BackendUpdateResponse,
} from './store-backend.model';
import type {
  DatastoreCollectionType,
  MaybeDatastoreDeleteCollectionType,
  MaybeDatastoreSetCollectionType,
  MaybeDatastoreUpdateCollectionType,
  Reference,
} from './store.model';

export type ReferenceWithId<C extends DatastoreCollectionType> =
  Reference<C> & {
    // The path needs to have a single entry in `ids` for a Document
    readonly path: {
      readonly ids: readonly [string];
    };
  };

export class DatastoreDocument<C extends DatastoreCollectionType> {
  /**
   * There are two types of documents, those referenced by `id` and those
   * referenced by a `secondary id`. The former have a single `id` in the path,
   * the latter have a query and will need to check `valueChanges` to get the
   * `id`.
   *
   * The latter way will do a network request if the observable has not been
   * subscribed to already (which should be rare).
   */
  private id$: Observable<string>;

  constructor(
    private ref$: Observable<Reference<C>>,
    private storeBackend: StoreBackendInterface,
    public status$: Observable<RequestStatus<C>>,
    private valueChanges$: Observable<C['DocumentType']>,
  ) {
    this.id$ = this.ref$.pipe(
      switchMap(ref =>
        ref.path.ids
          ? of(ref.path.ids[0])
          : this.valueChanges$.pipe(
              map(valueChange => valueChange.id.toString()),
            ),
      ),
    );
  }

  valueChanges(): Observable<C['DocumentType']> {
    return this.valueChanges$;
  }

  set(
    // Make calling this function fail if you haven't defined `C['Backend']['Set']`
    document: C['Backend']['Set'] extends never ? never : C['DocumentType'],
  ): Promise<BackendSetResponse<MaybeDatastoreSetCollectionType<C>>> {
    return firstValueFrom(
      combineLatest([this.ref$, this.id$]).pipe(
        take(1),
        map(
          // Unfortunate type casting
          ([ref, id]) =>
            [
              ref as unknown as Reference<MaybeDatastoreSetCollectionType<C>>,
              id,
            ] as const,
        ),
        switchMap(([ref, id]) => this.storeBackend.set(ref, id, document)),
      ),
    );
  }

  update(
    // Make calling this function fail if you haven't defined `C['Backend']['Update']`
    delta: C['Backend']['Update'] extends never
      ? never
      : RecursivePartial<C['DocumentType']>,
  ): Promise<BackendUpdateResponse<MaybeDatastoreUpdateCollectionType<C>>> {
    return firstValueFrom(
      combineLatest([this.ref$, this.id$]).pipe(
        take(1),
        map(
          // Unfortunate type casting
          ([ref, id]) =>
            [
              ref as unknown as Reference<
                MaybeDatastoreUpdateCollectionType<C>
              >,
              id,
            ] as const,
        ),
        switchMap(([ref, id]) =>
          this.storeBackend.update<MaybeDatastoreUpdateCollectionType<C>>(
            ref,
            id,
            delta,
          ),
        ),
      ),
    );
  }

  remove(): Promise<
    BackendDeleteResponse<MaybeDatastoreDeleteCollectionType<C>>
  > {
    return firstValueFrom(
      combineLatest([this.ref$, this.id$]).pipe(
        take(1),
        map(
          // Unfortunate type casting
          ([ref, id]) =>
            [
              ref as unknown as Reference<
                MaybeDatastoreDeleteCollectionType<C>
              >,
              id,
            ] as const,
        ),
        switchMap(([ref, id]) => this.storeBackend.delete(ref, id)),
      ),
    );
  }
}

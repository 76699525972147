import type { Backend } from '@freelancer/datastore/core';
import {
  getQueryParamValue,
  OrderByDirection,
} from '@freelancer/datastore/core';
import { isDefined } from '@freelancer/utils';
import type { InvitationsCollection } from './invitations.types';

export function invitationsBackend(): Backend<InvitationsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => {
      const invitationType = getQueryParamValue(query, 'invitationType')[0];
      const contextIds = getQueryParamValue(query, 'contextId');

      if (contextIds?.length > 0 && !isDefined(invitationType)) {
        throw new Error('Must provide context_ids with invitation_type');
      }

      return {
        endpoint: 'users/0.1/invitations/',
        isGaf: false,
        params: {
          ids,
          invitee_entity_ids: getQueryParamValue(query, 'inviteeEntityId'),
          invitee_entity_type: getQueryParamValue(
            query,
            'inviteeEntityType',
          )[0],
          inviter_user_ids: getQueryParamValue(query, 'inviterUserId'),
          context_ids: contextIds,
          invitation_type: invitationType,
          statuses: getQueryParamValue(query, 'status'),
        },
      };
    },
    push: (authUid, invitation) => ({
      endpoint: 'users/0.1/invitations/',
      payload: {
        context_id: invitation.contextId,
        invitation_type: invitation.invitationType,
        invitee_entity_type: invitation.inviteeEntityType,
        invitee_entity_id: invitation.inviteeEntityId,
        invitee_user_email: invitation.inviteeEmail,
        custom_message: invitation.customMessage,
      },
      extractId: response => response.invitation.id,
    }),
    set: undefined,
    update: (authUid, delta, originalDocument) => {
      if (!delta.status && !delta.inviteeEntityType) {
        throw new Error(
          `Missing status or inviteeEntityType in invitation update.`,
        );
      }
      const payload = {
        invitation_id: originalDocument.id,
        status: delta.status,
        invitee_entity_type: delta.inviteeEntityType,
        token: delta.token,
      };
      return {
        endpoint: 'users/0.1/invitations/',
        method: 'PUT',
        payload,
      };
    },
    remove: (authUid, invitationId) => {
      return {
        endpoint: `users/0.1/invitations/${invitationId}`,
        method: 'DELETE',
        payload: {},
      };
    },
  };
}

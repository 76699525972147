import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Location } from '@freelancer/location';
import { LOGIN_CONFIG, LOGIN_REDIRECT_SERVICE } from './login.config';

export const LoginGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  const location = inject(Location);
  const loginConfig = inject(LOGIN_CONFIG);
  const loginService = inject(LOGIN_REDIRECT_SERVICE);

  if (loginConfig.ssoIntegration) {
    return location.createUrlTree(['/saml_sso/sso.php'], {
      queryParams: {
        ...route.queryParams,
        integration: loginConfig.ssoIntegration,
      },
    });
  }
  return loginService.redirect(state.url, route);
};

import type { Backend } from '@freelancer/datastore/core';
import {
  getQueryParamValue,
  OrderByDirection,
} from '@freelancer/datastore/core';
import type { OpenGraphDetailsCollection } from './open-graph-details.types';

export function openGraphDetailsBackend(): Backend<OpenGraphDetailsCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => {
      const url = getQueryParamValue(query, 'url')[0];
      if (!url) {
        throw new Error('Missing url query param');
      }

      return {
        endpoint: `open-graph/get-details.php`,
        isGaf: true,
        params: {
          url: encodeURI(url),
        },
      };
    },
    push: undefined,
    update: undefined,
    set: undefined,
    remove: undefined,
  };
}

import type {
  RecursivePartial,
  UpdateSuccessPayload,
} from '@freelancer/datastore/core';
import { deepSpread } from '@freelancer/datastore/core';
import { isDefined, toNumber } from '@freelancer/utils';
import type {
  AttachmentApi,
  LocationApi,
  QualificationApi,
  SkillAnswerApi,
} from 'api-typings/common/common';
import type {
  DeliveryContactDetailsApi,
  DeliveryContactSensitiveDetailsGetResultApi,
  DeliveryItemApi,
  EnterpriseExternalProjectApi,
  EnterpriseLinkedProjectsDetailsApi,
  NDADetailsApi,
  NDASignatureApi,
  ProjectApi,
  ProjectAttachmentApi,
  ProjectClientEngagementApi,
  ProjectLocalDetailsApi,
  ProjectsGetResultApi,
} from 'api-typings/projects/projects';
import type { ContractSignaturesApi } from 'api-typings/users/users';
import { transformDeliveryContactSensitiveDetails } from '../delivery-contacts-sensitive-details/delivery-contacts-sensitive-details.transformer';
import { transformEquipmentGroup } from '../equipment-group/equipment-group.transformers';
import type { Attachment } from '../feed-posts';
import type { Location } from '../project-view-users/location.model';
import {
  transformProject,
  transformProjectBudget,
} from '../projects/projects.transformers';
import { transformSkill } from '../skills/skills.transformers';
import type { SuperuserProjectViewProjectsCollection } from '../superuser-project-view-projects';
import { transformTimezoneApi } from '../timezones/timezones.transformers';
import { transformLocation } from '../users/users-location.transformers';
import type {
  DeliveryContactDetails,
  DeliveryItem,
  EnterpriseExternalProject,
  EnterpriseLinkedProjectsDetails,
  NDADetails,
  NDASignature,
  ProjectAttachment,
  ProjectClientEngagement,
  ProjectContractSignatures,
  ProjectLocalDetails,
  ProjectViewProject,
  Qualification,
  SkillAnswer,
} from './project-view-projects.model';
import type { ProjectViewProjectsCollection } from './project-view-projects.types';

// Transforms what the backend returns into a format your components can consume
// Should only be called in this collection's reducer
export function transformProjectViewProjects(
  project: ProjectApi,
  allSelectedBids: ProjectsGetResultApi['selected_bids'],
): ProjectViewProject {
  return {
    ...transformProject(project, allSelectedBids),

    // Extra things for freelancer pvp
    attachments: (project.attachments || []).map(attachment =>
      transformProjectAttachment(attachment),
    ),
    canPostReview: project.can_post_review || {},
    description: project.description,
    location: project.location
      ? transformLocation(project.location)
      : undefined,
    true_location: project.true_location
      ? transformLocation(project.true_location)
      : undefined,
    qualifications: project.qualifications
      ? project.qualifications.map(transformQualification)
      : [],
    skills: (project.jobs || []).map(transformSkill),
    ndaDetails: project.nda_details
      ? transformNDADetails(project.nda_details)
      : { signatures: [] },
    displayLocation: transformDisplayedLocation(
      project.true_location || project.location,
    ),
    localDetails: project.local_details
      ? transformProjectLocalDetails(project.local_details)
      : undefined,
    equipment: project.equipment,
    equipmentGroups: (project.equipment_groups || []).map(
      transformEquipmentGroup,
    ),
    invitedFreelancers: (project.invited_freelancers || []).map(
      freelancer => freelancer.id,
    ),
    repostId: project.repost_id,
    clientEngagement: project.client_engagement
      ? transformClientEngagement(project.client_engagement)
      : undefined,
    contractSignatures: (project.contract_signatures || []).map(
      transformContractSignatures,
    ),
    enterpriseLinkedProjectsDetails: project.enterprise_linked_projects_details
      ? transformEnterpriseLinkedProjectsDetails(
          project.enterprise_linked_projects_details,
        )
      : undefined,
    serviceOfferingId: project.service_offering_id,
  };
}

// exactly the same as transformProjectViewProjects just with less required fields
export function transformProjectViewProjectsPush(
  projectIn: ProjectApi,
): ProjectViewProject {
  // add stuff we might be missing from API
  const project: ProjectApi = {
    ...projectIn,
    bid_stats: { bid_count: 0 },
    time_submitted: projectIn.time_submitted
      ? projectIn.time_submitted * 1000
      : Date.now(),
  };

  return transformProjectViewProjects(project, []);
}

function transformProjectAttachment(
  attachment: ProjectAttachmentApi,
): ProjectAttachment {
  return {
    id: attachment.id,
    filename: attachment.filename,
    timeSubmitted: (attachment.time_submitted || 0) * 1000, // Turn seconds to ms,
    url: `https://${attachment.url}`,
    accessHash: attachment.access_hash,
    contentType: attachment.content_type,
  };
}

export function transformQualification(
  qualification: QualificationApi,
): Qualification {
  return {
    id: qualification.id,
    description: qualification.description,
    iconName: qualification.icon_name,
    iconUrl: qualification.icon_url,
    insigniaId: qualification.insignia_id,
    level: qualification.level,
    name: qualification.name,
    scorePercentage: qualification.score_percentage,
    type: qualification.type,
    userPercentile: qualification.user_percentile,
  };
}

function transformNDADetails(ndaDetails: NDADetailsApi): NDADetails {
  return {
    hiddenDescription: ndaDetails.hidden_description,
    signatures: ndaDetails.signatures
      ? ndaDetails.signatures.map(transformNDASignatures)
      : [],
  };
}

function transformNDASignatures(signatures: NDASignatureApi): NDASignature {
  if (signatures.time_signed === undefined) {
    throw new Error(`NDA signature does not have time signed`);
  }
  if (signatures.project_id === undefined) {
    throw new Error(`NDA signature does not have project id`);
  }
  if (signatures.user_id === undefined) {
    throw new Error(`NDA signature does not have user id`);
  }

  return {
    timeSigned: signatures.time_signed * 1000,
    projectId: signatures.project_id,
    userId: signatures.user_id,
  };
}

export function transformDisplayedLocation(
  location?: LocationApi,
): string | undefined {
  if (location && location.full_address) {
    return location.full_address;
  }

  if (location && location.country) {
    if (location.vicinity && location.administrative_area_code) {
      if (location.postal_code) {
        return `${location.vicinity}, ${location.administrative_area_code} ${location.postal_code}`;
      }

      return `${location.vicinity}, ${location.administrative_area_code}`;
    }

    if (location.administrative_area && location.city) {
      return `${location.city}, ${location.administrative_area}, ${location.country.name}`;
    }

    if (location.city) {
      return `${location.city}, ${location.country.name}`;
    }

    if (location.administrative_area) {
      return `${location.administrative_area}, ${location.country.name}`;
    }

    return location.country.name;
  }

  return undefined;
}

export function transformDisplayedLocationOnUpdate(
  location?: RecursivePartial<Location>,
): string | undefined {
  if (location && location.fullAddress) {
    return location.fullAddress;
  }

  if (location && location.country) {
    if (location.administrativeArea && location.city) {
      return `${location.city}, ${location.administrativeArea}, ${location.country.name}`;
    }

    if (location.city) {
      return `${location.city}, ${location.country.name}`;
    }

    if (location.administrativeArea) {
      return `${location.administrativeArea}, ${location.country.name}`;
    }

    return location.country.name;
  }

  return '';
}

export function transformProjectLocalDetails(
  projectLocalDetails: ProjectLocalDetailsApi,
): ProjectLocalDetails {
  return {
    date: projectLocalDetails.date || undefined,
    dateTimestamp: projectLocalDetails.date
      ? new Date(
          projectLocalDetails.date.year,
          projectLocalDetails.date.month - 1,
          projectLocalDetails.date.day,
        ).getTime()
      : undefined,
    endLocation: projectLocalDetails.end_location
      ? transformLocation(projectLocalDetails.end_location)
      : undefined,
    displayEndLocation: transformDisplayedLocation(
      projectLocalDetails.end_location,
    ),
    workStartDate: projectLocalDetails.work_start_date
      ? projectLocalDetails.work_start_date * 1000
      : undefined,
    workEndDate: projectLocalDetails.work_end_date
      ? projectLocalDetails.work_end_date * 1000
      : undefined,
    // converting distance to km for display
    distance: projectLocalDetails.distance
      ? projectLocalDetails.distance / 1000
      : undefined,
    // converting seconds to milliseconds for display
    travelTime: projectLocalDetails.travel_time
      ? projectLocalDetails.travel_time * 1000
      : undefined,
    deliveryType: projectLocalDetails.delivery_type ?? undefined,
    deliveryItems: projectLocalDetails.delivery_items?.map(
      transformDeliveryItem,
    ),
    pickupDeliveryContact: projectLocalDetails.pickup_delivery_contact
      ? transformDeliveryContactDetails(
          projectLocalDetails.pickup_delivery_contact,
        )
      : undefined,
    dropoffDeliveryContact: projectLocalDetails.dropoff_delivery_contact
      ? transformDeliveryContactDetails(
          projectLocalDetails.dropoff_delivery_contact,
        )
      : undefined,
  };
}

export function transformProjectDeliveryContactSensitiveDetailsIntoProjectViewProjects(
  orginalProject: ProjectViewProject,
  deliveryContactSensitiveDetails: DeliveryContactSensitiveDetailsGetResultApi,
): ProjectViewProject {
  const pickupDeliverySensitiveDetails =
    deliveryContactSensitiveDetails.pickup_delivery_contact_sensitive_details;
  const dropoffDeliverySensitiveDetails =
    deliveryContactSensitiveDetails.dropoff_delivery_contact_sensitive_details;

  let mergedProject = orginalProject;
  if (
    pickupDeliverySensitiveDetails &&
    mergedProject.localDetails &&
    mergedProject.localDetails.pickupDeliveryContact
  ) {
    const projectWithPickUpDetails = {
      localDetails: {
        pickupDeliveryContact: transformDeliveryContactSensitiveDetails(
          pickupDeliverySensitiveDetails,
        ),
      } as ProjectLocalDetails,
    } as ProjectViewProject;

    mergedProject = deepSpread(mergedProject, projectWithPickUpDetails);
  }

  if (
    dropoffDeliverySensitiveDetails &&
    mergedProject.localDetails &&
    mergedProject.localDetails.dropoffDeliveryContact
  ) {
    const projectWithDropoffDetails = {
      localDetails: {
        dropoffDeliveryContact: transformDeliveryContactSensitiveDetails(
          dropoffDeliverySensitiveDetails,
        ),
      } as ProjectLocalDetails,
    } as ProjectViewProject;

    mergedProject = deepSpread(mergedProject, projectWithDropoffDetails);
  }
  return mergedProject;
}

export function transformProjectViewProjectsUpdate(
  delta: RecursivePartial<ProjectViewProject>,
  result:
    | UpdateSuccessPayload<ProjectViewProjectsCollection>['result']
    | UpdateSuccessPayload<SuperuserProjectViewProjectsCollection>['result'],
): RecursivePartial<ProjectViewProject> {
  return {
    ...delta,
    ...('title' in result ? { title: result.title } : {}),
    true_location: delta.true_location,
    localDetails: {
      ...delta.localDetails,
      dateTimestamp:
        delta.localDetails &&
        delta.localDetails.date &&
        delta.localDetails.date.year &&
        delta.localDetails.date.month &&
        delta.localDetails.date.day
          ? new Date(
              delta.localDetails.date.year,
              delta.localDetails.date.month - 1,
              delta.localDetails.date.day,
            ).getTime()
          : undefined,
      displayEndLocation:
        transformDisplayedLocationOnUpdate(
          delta.localDetails ? delta.localDetails.endLocation : undefined,
        ) || undefined,
      // converting distance to km for display
      distance: delta.localDetails?.distance
        ? delta.localDetails.distance / 1000
        : undefined,
    },
    displayLocation:
      transformDisplayedLocationOnUpdate(
        delta.true_location || delta.location || undefined,
      ) || undefined,
    equipment: delta.equipment || undefined,
  };
}

export function transformLocationToApi(
  location: Location | undefined,
): LocationApi | undefined {
  return location && location.mapCoordinates
    ? {
        administrative_area: location.administrativeArea || '',
        country: {
          code: (location.country && location.country.code) || '',
          name: (location.country && location.country.name) || '',
        },
        full_address: location.fullAddress || '',
        latitude: location.mapCoordinates.latitude,
        longitude: location.mapCoordinates.longitude,
        vicinity: location.vicinity || '',
        administrative_area_code: location.administrativeAreaCode,
        postal_code: location.postalCode,
        timezone: location.timezone
          ? transformTimezoneApi(location.timezone)
          : undefined,
      }
    : undefined;
}

export function transformDeliveryContactDetailsToApi(
  deliveryContact: DeliveryContactDetails | undefined,
): DeliveryContactDetailsApi | undefined {
  return {
    id: deliveryContact?.id,
    fullname: deliveryContact?.fullname,
    phone_code: deliveryContact?.phoneCode,
    phone_number: deliveryContact?.phoneNumber,
    site_type: deliveryContact?.siteType,
    loading_facilities: deliveryContact?.loadingFacilities,
    notes: deliveryContact?.notes,
  };
}

export function transformDeliveryContactDetails(
  projectLocalDetailsApi: DeliveryContactDetailsApi | undefined,
): DeliveryContactDetails | undefined {
  return {
    id: projectLocalDetailsApi?.id,
    // The fullname, phoneCode and phoneNumber are merged from DeliveryContactSensitiveDetailsCollection collection
    fullname: projectLocalDetailsApi?.fullname,
    phoneCode: projectLocalDetailsApi?.phone_code,
    phoneNumber: projectLocalDetailsApi?.phone_number,
    siteType: projectLocalDetailsApi?.site_type,
    loadingFacilities: projectLocalDetailsApi?.loading_facilities,
    notes: projectLocalDetailsApi?.notes,
  };
}

export function transformClientEngagement(
  clientEngagement: ProjectClientEngagementApi,
): ProjectClientEngagement {
  return {
    chatsInitiated: clientEngagement.chats_initiated ?? 0,
    invites: clientEngagement.invites ?? 0,
  };
}

export function transformContractSignatures(
  contractSignatures: ContractSignaturesApi,
): ProjectContractSignatures {
  if (contractSignatures.contract_type === undefined) {
    throw new Error(`Contract signature does not have contract_type`);
  }
  if (contractSignatures.context_type === undefined) {
    throw new Error(`Contract signature does not have context_type`);
  }
  if (contractSignatures.context_id === undefined) {
    throw new Error(`Contract signature does not have context_id`);
  }
  if (contractSignatures.display_name === undefined) {
    throw new Error(`Contract signature does not have display_name`);
  }
  if (contractSignatures.user_id_time_signed_map === undefined) {
    throw new Error(`Contract signature does not have user_id_time_signed_map`);
  }
  if (contractSignatures.custom_contract_id === undefined) {
    throw new Error(`Contract signature does not have custom_contract_id`);
  }

  return {
    contractType: contractSignatures.contract_type,
    contextType: contractSignatures.context_type,
    contextId: contractSignatures.context_id,
    displayName: contractSignatures.display_name,
    userIdTimeSignedMap: contractSignatures.user_id_time_signed_map,
    customContractId: contractSignatures.custom_contract_id,
  };
}

export function transformDeliveryItemToApi(
  deliveryItem: DeliveryItem,
): DeliveryItemApi {
  const itemAnswers: {
    [skillId: number]: SkillAnswerApi[];
  } = {};

  if (deliveryItem.skill?.id && deliveryItem.skillAnswers) {
    const skillAnswers = deliveryItem.skillAnswers[deliveryItem.skill.id].map(
      transformSkillAnswerToApi,
    );
    itemAnswers[deliveryItem.skill.id] = skillAnswers;
  }

  return {
    id: deliveryItem.id,
    reference_number: deliveryItem.referenceNumber,
    description: deliveryItem.description,
    quantity: deliveryItem.quantity,
    width: deliveryItem.width,
    length: deliveryItem.length,
    height: deliveryItem.height,
    weight: deliveryItem.weight,
    attachments: deliveryItem.attachments?.map(transformAttachmentToApi),
    skill_answers: itemAnswers,
  };
}

export function transformAttachmentToApi(
  attachment: Attachment,
): AttachmentApi {
  return {
    id: attachment.id,
    attached_by_type: attachment.attachedByType,
    attached_by_id: attachment.attachedById,
    attached_object_type: attachment.attachedObjectType,
    attached_object_id: attachment.attachedObjectId,
    attached_to_type: attachment.attachedToType,
    attached_to_id: attachment.attachedToId,
    created: attachment.created / 1000,
  };
}

function transformDeliveryItem(deliveryItem: DeliveryItemApi): DeliveryItem {
  const skillAnswers: { [skillId: number]: readonly SkillAnswer[] } = {};

  Object.entries(deliveryItem.skill_answers ?? {}).forEach(
    ([skillId, answers]) => {
      const skillIdNumber = toNumber(skillId);
      skillAnswers[skillIdNumber] = answers?.map(transformSkillAnswer) ?? [];
    },
  );

  return {
    id: deliveryItem.id,
    referenceNumber: deliveryItem.reference_number,
    description: deliveryItem.description,
    quantity: deliveryItem.quantity,
    width: deliveryItem.width,
    length: deliveryItem.length,
    height: deliveryItem.height,
    weight: deliveryItem.weight,
    attachments: deliveryItem.attachments?.map(transformAttachment),
    skillAnswers: Object.keys(skillAnswers).length ? skillAnswers : undefined,
  };
}

export function transformSkillAnswer(skillAnswer: SkillAnswerApi): SkillAnswer {
  return {
    id: skillAnswer.id,
    questionId: skillAnswer.question_id,
    content: skillAnswer.content,
    contextId: skillAnswer.context_id,
  };
}

export function transformAttachment(attachment: AttachmentApi): Attachment {
  return {
    id: attachment.id,
    attachedByType: attachment.attached_by_type,
    attachedById: attachment.attached_by_id,
    attachedObjectType: attachment.attached_object_type,
    attachedObjectId: attachment.attached_object_id,
    attachedToType: attachment.attached_to_type,
    attachedToId: attachment.attached_to_id,
    created: attachment.created * 1000,
    position: attachment.position ?? -1,
  };
}

export function transformSkillAnswerToApi(
  skillAnswer: SkillAnswer,
): SkillAnswerApi {
  return {
    id: skillAnswer.id,
    question_id: skillAnswer.questionId,
    content: skillAnswer.content,
    context_id: skillAnswer.contextId,
  };
}

export function transformEnterpriseExternalProject(
  externalProject: EnterpriseExternalProjectApi,
): EnterpriseExternalProject {
  if (externalProject.id === undefined) {
    throw new Error(`External project does not have id specified.`);
  }
  if (externalProject.is_dual_posted === undefined) {
    throw new Error(`External project does not have is_dual_posted specified.`);
  }

  return {
    id: externalProject.id,
    budget: externalProject.budget
      ? transformProjectBudget(externalProject.budget)
      : undefined,
    poolIds: externalProject.pool_ids,
    isDualPosted: externalProject.is_dual_posted,
    description: externalProject.description,
  };
}

export function transformEnterpriseLinkedProjectsDetails(
  linkedProjectsDetails: EnterpriseLinkedProjectsDetailsApi,
): EnterpriseLinkedProjectsDetails {
  // If internal project ID is defined, the project is external
  if (linkedProjectsDetails.internal_project_id) {
    if (!isDefined(linkedProjectsDetails.is_dual_posted)) {
      throw new Error('isDualPosted not defined for external project details');
    }

    return {
      isInternal: false,
      internalProjectId: linkedProjectsDetails.internal_project_id,
      isDualPosted: linkedProjectsDetails.is_dual_posted,
      rejectReasons: linkedProjectsDetails.reject_reasons,
      rejectNote: linkedProjectsDetails.reject_note,
    };
  }

  if (
    !isDefined(linkedProjectsDetails.external_projects) ||
    !isDefined(linkedProjectsDetails.approval_status)
  ) {
    throw new Error(
      'externalProjects or approvalStatus not defined for internal project details',
    );
  }

  const externalProjects = linkedProjectsDetails.external_projects.map(
    transformEnterpriseExternalProject,
  );
  const latestExternalProjectId = Math.max(
    ...externalProjects.map(project => project.id),
  );
  const latestExternalProject = externalProjects.find(
    project => project.id === latestExternalProjectId,
  );

  if (!latestExternalProject) {
    throw new Error('Linked internal project has no external projects');
  }

  // Otherwise it is internal
  return {
    isInternal: true,
    externalProjects,
    latestExternalProject,
    approvalStatus: linkedProjectsDetails.approval_status,
  };
}

export function transformEnterpriseExternalProjectToApi(
  externalProject: EnterpriseExternalProject,
): EnterpriseExternalProjectApi {
  if (externalProject.budget?.currencyId === undefined) {
    throw new Error(
      `External project budget does not have currencyId specified.`,
    );
  }

  return {
    budget: externalProject.budget
      ? {
          minimum: externalProject.budget.minimum,
          maximum: externalProject.budget.maximum,
          name: externalProject.budget.name,
          project_type: externalProject.budget.projectType,
          currency_id: externalProject.budget.currencyId,
        }
      : undefined,
    pool_ids: externalProject.poolIds,
    is_dual_posted: externalProject.isDualPosted,
    description: externalProject.description,
  };
}

export function transformEnterpriseLinkedProjectsDetailsToApi(
  linkedProjectsDetails: EnterpriseLinkedProjectsDetails,
): EnterpriseLinkedProjectsDetailsApi {
  if (linkedProjectsDetails.isInternal) {
    return {
      external_projects: linkedProjectsDetails.externalProjects?.map(
        transformEnterpriseExternalProjectToApi,
      ),
      approval_status: linkedProjectsDetails.approvalStatus,
    };
  }

  return {
    internal_project_id: linkedProjectsDetails.internalProjectId,
    is_dual_posted: linkedProjectsDetails.isDualPosted,
  };
}

import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import type { ErrorResponseData } from '@freelancer/freelancer-http';
import {
  FREELANCER_HTTP_CONFIG,
  FreelancerHttp,
  FreelancerHttpConfig,
  FreelancerHttpService,
} from '@freelancer/freelancer-http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@UntilDestroy({ className: 'IpBlacklist' })
@Injectable({
  providedIn: 'root',
})
export class IpBlacklist {
  constructor(
    private http: HttpClient,
    @Inject(FREELANCER_HTTP_CONFIG)
    private freelancerHttpConfig: FreelancerHttpConfig,
    private router: Router,
    private injector: Injector,
  ) {}

  async unblock(captchaResponse: string): Promise<boolean> {
    return firstValueFrom(
      this.http
        .post<{ success: true }>(
          `${this.freelancerHttpConfig.baseUrl}/unblock`,
          {
            'g-recaptcha-response': captchaResponse,
          },
        )
        .pipe(
          map(result => result.success),
          catchError(() => of(false)),
          untilDestroyed(this),
        ),
    );
  }

  // Check if the user is IP blacklisted and if so, redirect to captcha where they can ublock themselves.
  checkCaptchaRedirect<E = any>(
    error: HttpErrorResponse,
  ): Observable<ErrorResponseData<E | 'UNKNOWN_ERROR'>> {
    const errorResponse = this.formatErrorBody<E | 'UNKNOWN_ERROR'>(error);
    if (error.status !== 403) {
      return of(errorResponse);
    }

    // Check if 403 is because of IP blocking, or something else
    const freelancerHttp = this.injector.get(FreelancerHttp);
    return this.http
      .get<boolean>(
        `${freelancerHttp.getBaseServiceUrl(FreelancerHttpService.API)}/ping`,
      )
      .pipe(
        take(1),
        map(() => {
          return true;
        }),
        catchError(err => {
          if (error.status === 403) {
            // IP is blocked, navigate to unblock-ip page
            this.router.navigate(
              [
                '/internal/ip-blacklist-unblock',
                {
                  request_id: error.error.request_id,
                  recaptcha_key: error.error.recaptcha_key,
                  back_url: this.router.url,
                },
              ],
              {
                skipLocationChange: true,
              },
            );
          }

          return of(true);
        }),
        map(() => errorResponse),
      );
  }

  /**
   * For testing only
   */
  forceTriggerChallenge(): Promise<undefined> {
    const freelancerHttp = this.injector.get(FreelancerHttp);
    return firstValueFrom(
      freelancerHttp
        .get<undefined>('ping', {
          params: {
            'force-blacklist': true,
          },
        })
        .pipe(
          map(() => undefined),
          untilDestroyed(this),
        ),
    );
  }

  private formatErrorBody<E>(error: HttpErrorResponse): ErrorResponseData<E> {
    // If an error is found, but the request ID isn't present, there is likely
    // an issue with the network.
    if (
      error.error &&
      !error.error.request_id &&
      error.error.error === undefined
    ) {
      return {
        status: 'error',
        errorCode: 'NETWORK_ERROR',
      };
    }

    if (error.error && error.error.error !== undefined) {
      return {
        status: 'error',
        errorCode: error.error.error.code,
        requestId: error.error.request_id,
      };
    }

    // Another type of backend error occured, e.g. External LB or Varnish.
    return {
      status: 'error',
      errorCode: 'UNKNOWN_ERROR',
    };
  }
}

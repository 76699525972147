import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PwaInitialState {
  private _hasRenderedOnServer = false;

  setHasRenderedOnServer(hasRenderedOnServer: boolean): void {
    this._hasRenderedOnServer = hasRenderedOnServer;
  }

  hasRenderedOnServer(): boolean {
    return this._hasRenderedOnServer;
  }
}

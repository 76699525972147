/**
 * Used in CVP for navigating on entry quickview (next/previous). We need the
 * list of all entry IDs for the selected contest in order to correctly navigate
 * the quickview modal.
 */
export interface ContestViewEntryIds {
  readonly id: string;
  readonly contestId: number;
  readonly entryIds: readonly number[];
  readonly entryNumbers: readonly number[];
  readonly filter: EntryIdFilterOption;
  readonly sort: EntryIdSortOption;
  /**
   * Flag to indicate whether to fetch only entry ids that the current user is allowed to open the quick view for.
   * For the entry quick view to navigate to the next/previous entry, it needs a list of entry ids that the user
   * is allowed to open the quick view for.
   */
  readonly quickView: boolean;
  readonly entryOwnerIds?: readonly number[];
}

export enum EntryIdFilterOption {
  ACTIVE = 'ACTIVE',
  TOP = 'TOP',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  NO_FEEDBACK = 'NO_FEEDBACK',
  DEFAULT = 'DEFAULT',
}

export enum EntryIdSortOption {
  RATING = 'RATING',
  TIME_SUBMITTED = 'TIME_SUBMITTED',
  LIKES = 'LIKES',
  FREELANCER_RATING = 'FREELANCER_RATING',
  DEFAULT = 'DEFAULT',
}

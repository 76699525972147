import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import type { Subscription } from 'rxjs';
import { Seo } from './seo.service';

@Component({
  selector: 'fl-seo',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoComponent implements OnInit, OnDestroy {
  private resetPageTagsSubscription?: Subscription;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router,
    private seo: Seo,
  ) {}

  ngOnInit(): void {
    this.resetPageTagsSubscription = this.router.events.subscribe(event => {
      // reset page tags to default values on navigation.
      if (event instanceof NavigationEnd) {
        this.seo.setDefaultPageTags();
        this.seo.removeAllStructuredData();
      }
    });
  }

  ngOnDestroy(): void {
    this.resetPageTagsSubscription?.unsubscribe();
  }
}

import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { SwPush } from '@angular/service-worker';
import type { AppState } from '@capacitor/app';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import type { ActionPerformed as LocalNotificationActionPerformed } from '@capacitor/local-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import type {
  ActionPerformed as PushNotificationActionPerformed,
  Channel,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { Auth } from '@freelancer/auth';
import { WebSocketService, isWebsocketMessage } from '@freelancer/datastore';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type {
  BaseServerMessage,
  WebsocketServerEvent,
  WebsocketServerEventTParam,
} from '@freelancer/datastore/core';
import { FreelancerHttp } from '@freelancer/freelancer-http';
import { LocalStorage } from '@freelancer/local-storage';
import { Location } from '@freelancer/location';
import { Pwa } from '@freelancer/pwa';
import { Tracking } from '@freelancer/tracking';
import type { TrackingExtraParams } from '@freelancer/tracking/interface';
import {
  permissionReason,
  Permissions,
  PermissionType,
} from '@freelancer/ui/permissions';
import { UI_CONFIG } from '@freelancer/ui/ui.config';
import { UiConfig } from '@freelancer/ui/ui.interface';
import { toNumber } from '@freelancer/utils';
import { FL_CALL_KIT_PLUGIN_NAME, FLCallkit } from '@freelancer/videochat';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
  from,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
  BACKGROUND_NOTIFICATION_PLUGIN_NAME,
  BackgroundNotification,
  CUSTOM_NOTIFICATION_PLUGIN_NAME,
} from './capacitor-plugins';

export type NotificationPermission = 'granted' | 'denied' | 'default';

export type NotificationRegisterResult =
  | {
      readonly status: 'success';
    }
  | {
      readonly status: 'error';
      readonly errorCode: NotificationPermissionErrorCode;
      // T249142: Required to capture what the problem we're having with
      // some users not registering push notifications correctly.
      readonly errorMsg?: string;
    };

export enum NotificationPermissionErrorCode {
  //  Permission has been denied by the user
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  TOKEN_REQUEST_ERROR = 'TOKEN_REQUEST_ERROR',
}

interface Notification {
  type: 'push' | 'local';
  data?: {
    url?: string;
    resource_id?: string;
    push_notification_type?: string;
  };
}

export type PushNotificationTrackingExtraParams = Partial<
  Pick<
    TrackingExtraParams,
    'pushNotificationType' | 'url' | 'resourceId' | 'clicked'
  >
>;

const TRACK_CLICKED_NOTIF_EVENT_NAME = 'clicked_notification';
const TRACK_DELIVERED_NOTIF_EVENT_NAME = 'delivered_notification';
const TRACK_DELIVERED_BACKGROUND_NOTIF_EVENT_NAME =
  'delivered_background_notification';
const TRACK_NATIVE_PUSH_NOTIF_SECTION_NAME = 'native_push_notifications';

@UntilDestroy({ className: 'Notifications' })
@Injectable({ providedIn: 'root' })
export class Notifications {
  tokenChanges$: Observable<string | undefined>;

  private subscriptions = new Subscription();
  private _isRegistered$ = new BehaviorSubject<boolean>(false);

  constructor(
    private afMessaging: AngularFireMessaging,
    private auth: Auth,
    private errorHandler: ErrorHandler,
    private freelancerHttp: FreelancerHttp,
    private localStorage: LocalStorage,
    private location: Location,
    private permissions: Permissions,
    private push: SwPush,
    private pwa: Pwa,
    private tracking: Tracking,
    private websocket: WebSocketService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(UI_CONFIG) private uiConfig: UiConfig,
  ) {}

  /**
   * Can permission to show notifications be requested. This implies that
   * permission has not been already granted.
   *
   * Used that to display a user prompt to enable notifications
   */
  async canRequestPermission(): Promise<boolean> {
    if (!this.isSupported()) {
      return false;
    }
    const permission = await this.getPermission();
    return permission === 'default';
  }

  /**
   * Has permission to show notifications already been granted
   *
   * Used to automatically register push notifications on login
   */
  async hasGrantedPermission(): Promise<boolean> {
    if (!this.isSupported()) {
      return false;
    }
    const permission = await this.getPermission();
    return permission === 'granted';
  }

  /**
   * Are notifications supported by the platform.
   *
   * If this is true but both canRequestPermission() and hasGrantedPermission()
   * are false, this implies the user has previously denied permission: use
   * that a display a user prompt to try to recover the user.
   */
  isSupported(): boolean {
    // Disable on the server
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }

    // Notifications are always supported on native
    if (this.pwa.isNative()) {
      return true;
    }

    // In the browser, both Service Workers & the Push API need to be supported
    // Technically we don't use the Angular Service Worker but using
    // `SwPush::isEnabled` here allows to share the SW enabling logic.
    // Service Worker is not enabled for localhost, but notifications will still work.
    return (
      (this.push.isEnabled || this.location.hostname === 'localhost') &&
      'PushManager' in window &&
      'Notification' in window
    );
  }

  /**
   * Register the application to receive push notifications. Will request
   * permission to the user if needed, i.e. do not call that on load unless
   * permission has already been granted.
   */
  async registerPushNotifications(): Promise<NotificationRegisterResult> {
    if (!this.isSupported()) {
      throw new Error(
        'Push notifications are not supported on the current platform: make sure to check isSupported() before calling registerPushNotifications()',
      );
    }

    const isLoggedIn = await firstValueFrom(
      this.auth.isLoggedIn().pipe(untilDestroyed(this)),
    );
    if (!isLoggedIn) {
      throw new Error(
        'User must be logged in to register for push notifications',
      );
    }

    // If the user has already denied permission at the browser level, we can't do anything
    if (
      !(await this.hasGrantedPermission()) &&
      !(await this.canRequestPermission())
    ) {
      return {
        status: 'error',
        errorCode: NotificationPermissionErrorCode.PERMISSION_DENIED,
      };
    }

    // Do nothing if already registered
    const permissionGranted = await this.hasGrantedPermission();

    if (
      permissionGranted &&
      (await firstValueFrom(this._isRegistered$.pipe(untilDestroyed(this))))
    ) {
      this._isRegistered$.next(true);
      return {
        status: 'success',
      };
    }

    // Trigger permission request for push notifications (modal then native)
    if (!permissionGranted) {
      const permissionResult = await this.permissions.requestPermissions(
        PermissionType.PUSH_NOTIFICATIONS,
        permissionReason.PUSH_NOTIFICATIONS,
      );
      if (!permissionResult) {
        return {
          status: 'error',
          errorCode: NotificationPermissionErrorCode.PERMISSION_DENIED,
        };
      }
    }

    let foregroundMessages$: Observable<object>;
    // Foreground messages stream specifically for native.
    let nativeForegroundMessages$:
      | Observable<PushNotificationSchema>
      | undefined;
    let notificationClicks$: Observable<{
      action?: string;
      notification: Notification;
    }>;

    if (this.pwa.isNative()) {
      // Notification channel is for Android only. Android notifications have to
      // be bound to a channel in order for it to display with alert/toast style
      // and other configurations including lights/vibration.
      if (this.pwa.getPlatform() === 'android') {
        const newMessageChannel: Channel = {
          // This channel ID is also used as the default channel for firebase
          // messaging notifications, set via
          // `com.google.firebase.messaging.default_notification_channel_id`
          id: 'newMessageChannel',
          name: $localize`New Message`,
          description: $localize`New incoming messages`,
          importance: 5,
          sound: 'message.wav',
          visibility: 1,
          vibration: true,
          lights: true,
          lightColor: '#29B2FE',
        };
        PushNotifications.createChannel(newMessageChannel);
        LocalNotifications.createChannel(newMessageChannel);
      }
      if (Capacitor.isPluginAvailable(FL_CALL_KIT_PLUGIN_NAME)) {
        const { deviceToken } = await FLCallkit.getVOIPDeviceToken();
        const deviceId = await this.getDeviceId();
        const userId = await firstValueFrom(
          this.auth.getUserId().pipe(untilDestroyed(this)),
        );

        this.subscriptions.add(
          this.freelancerHttp
            .post('users/0.1/tokens', {
              kind: 'ios',
              app_type: 'freelancer',
              data: deviceToken,
              device_id: deviceId,
              voip_token: deviceToken,
              user_id: toNumber(userId),
            })
            .subscribe({
              error: err => {
                this.errorHandler.handleError(err);
              },
            }),
        );

        // Unregister voip token on log out
        this.auth.registerBeforeLogoutAction(async () => {
          if (deviceToken.length === 0) {
            return;
          }
          await firstValueFrom(
            this.freelancerHttp
              .delete('users/0.1/tokens', {
                params: {
                  kind: 'ios',
                  app_type: 'freelancer',
                  data: deviceToken,
                },
              })
              .pipe(untilDestroyed(this)),
          );
        });
      }

      // We need to use a replay subject here to cache the registration result until tokenChanges$ is subcribed to
      const registrationSubject$ = new ReplaySubject<string | undefined>(1);
      PushNotifications.addListener('registration', (t: Token) => {
        this.removeNotificationsOnActiveState();

        // Set listener to clear notifications everytime user switches back to the app
        App.addListener('appStateChange', async (state: AppState) => {
          if (state.isActive) {
            await this.trackDeliveredNotifications();
            PushNotifications.removeAllDeliveredNotifications();
            this.handleBackgroundNotifications();
          }
        });

        // Clear the notifications when the user lands on the inbox.
        this.subscriptions.add(
          this.location
            .valueChanges()
            .pipe(filter(location => location.pathname.startsWith('/messages')))
            .subscribe(_ => {
              this.removeNotificationsOnActiveState();
            }),
        );
        registrationSubject$.next(t.value);
      });
      PushNotifications.addListener(
        'registrationError',
        (e: { error: string }) => {
          registrationSubject$.error(e.error);
        },
      );

      this.tokenChanges$ = from(
        PushNotifications.register().catch(error =>
          registrationSubject$.error(error),
        ),
      ).pipe(switchMap(() => registrationSubject$.asObservable()));

      nativeForegroundMessages$ = new Observable(observer => {
        const listener = PushNotifications.addListener(
          'pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            observer.next(notification);
          },
        );
        return async () => (await listener).remove();
      });
      foregroundMessages$ = nativeForegroundMessages$;

      notificationClicks$ = new Observable(observer => {
        const pushNotificationsListener = PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (actionPerformed: PushNotificationActionPerformed) => {
            observer.next({
              action: actionPerformed.actionId,
              notification: {
                type: 'push',
                data: actionPerformed.notification.data,
              },
            });
          },
        );

        const localNotificationsListener = LocalNotifications.addListener(
          'localNotificationActionPerformed',
          (actionPerformed: LocalNotificationActionPerformed) => {
            observer.next({
              action: actionPerformed.actionId,
              notification: {
                type: 'local',
                data: actionPerformed.notification.extra.data,
              },
            });
          },
        );

        return async () => {
          (await pushNotificationsListener).remove();
          (await localNotificationsListener).remove();
        };
      });
    } else {
      this.tokenChanges$ = this.afMessaging.requestToken.pipe(
        map(token => token ?? undefined),
      );

      foregroundMessages$ = this.afMessaging.messages;

      notificationClicks$ = new Observable(observer => {
        const handler = (event: any): void => {
          const internalPayload = event.data;

          if (!internalPayload.firebaseMessaging) {
            return;
          }

          if (internalPayload.firebaseMessaging.payload) {
            const payload = internalPayload.firebaseMessaging.payload.data;

            if (
              this.location.searchParams.has('recordNotifications') &&
              internalPayload.firebaseMessaging.type === 'push-received'
            ) {
              // Mark the message as received (for E2E tests)
              const payloadJSON = JSON.stringify(payload);
              if (!payloadJSON.includes('[e2e]')) {
                return;
              }

              const marker = 'fgPushMessage';
              const existingKids = Array.from(
                document.head.getElementsByClassName(marker),
              );

              // Add node marking message received
              const markerElement = document.createElement('div');
              markerElement.setAttribute('class', marker);
              const markerText = document.createTextNode(payloadJSON);
              markerElement.appendChild(markerText);
              document.head.appendChild(markerElement);

              // Remove previously-present nodes
              existingKids.forEach(childElement => {
                childElement.parentNode?.removeChild(childElement);
              });
            }

            if (
              internalPayload.firebaseMessaging.type === 'notification-clicked'
            ) {
              observer.next({
                // TODO: T267853 - implement custom actions when needed
                notification: {
                  ...internalPayload.firebaseMessaging.payload.notification,
                  data: payload,
                },
              });
            }
          }
        };
        navigator.serviceWorker.addEventListener('message', handler);
        return () => {
          navigator.serviceWorker.removeEventListener('message', handler);
        };
      });
    }

    const result = await firstValueFrom(
      this.tokenChanges$.pipe(untilDestroyed(this)),
    )
      .then(() => ({
        status: 'success' as const,
      }))
      .catch(e => {
        return {
          status: 'error' as const,
          errorCode: NotificationPermissionErrorCode.TOKEN_REQUEST_ERROR,
          // T249142: Required to capture what the problem we're having with
          // some users not registering push notifications correctly.
          errorMsg: e?.toString(),
        };
      });

    // Permission has been granted by the user
    if (result.status === 'success') {
      this._isRegistered$.next(true);

      const deviceId = await this.getDeviceId();

      this.subscriptions.add(
        this.tokenChanges$
          .pipe(
            tap(token => console.log('Device token:', token)),
            switchMap(token =>
              this.auth.getUserId().pipe(
                take(1),
                switchMap(userId =>
                  // The token is automatically updated based on the unique device ID
                  this.freelancerHttp.post('users/0.1/tokens', {
                    kind: 'fcm',
                    app_type: 'freelancer',
                    data: token,
                    device_id: deviceId,
                    user_id: toNumber(userId),
                  }),
                ),
              ),
            ),
          )
          .subscribe({
            error: err => {
              this.errorHandler.handleError(err);
            },
          }),
      );

      this.subscriptions.add(
        foregroundMessages$.subscribe(notification => {
          // This is called when notifications are received while the app is in
          // foreground. Presumably we don't need to do anything here for
          // non-native environment as the same notifications will be received
          // through the WebSocket, which will be used to update the UI(pops up
          // the messaging thread) or show a toast notification.
          console.log('New foreground message: ', notification);
        }),
      );

      // New messages that comes through WebSocket don't pop up in the UI on
      // Android and iOS. Hence, we create local notifications to alert the user.
      if (this.pwa.isNative() && nativeForegroundMessages$) {
        this.subscriptions.add(
          nativeForegroundMessages$.subscribe(async notification => {
            if (notification.body && notification.data.url) {
              // Strip the query params from the relative URL in the notification.
              const redirectURL = notification.data.url.replace(/\?.*$/, '');
              // Ignore notification if we are already in the message thread.
              if (redirectURL !== this.location.pathname) {
                // Forward the push notification to local notification.
                LocalNotifications.schedule({
                  notifications: [
                    {
                      // Generate a random ID.
                      id: Math.floor((Math.random() + 1) * 0x10_00_00_00),
                      title: notification.title ?? $localize`New Message`,
                      body: notification.body,
                      // This set the sound file for iOS.
                      // Android sound file is set via the channel.
                      sound: 'message.wav',
                      channelId: 'newMessageChannel',
                      extra: {
                        data: notification.data,
                      },
                    },
                  ],
                });
              }
            }
          }),
        );
      }

      this.subscriptions.add(
        notificationClicks$.subscribe(async ({ action, notification }) => {
          this.trackClickedNotification(notification);
          this.handleBackgroundNotifications();

          // This is called when the user performs an action on a notification
          console.log('Notification click: ', action, notification);
          // If the url data field is set, navigate to the destination
          const url = notification?.data?.url;

          // This is to avoid reloading the page after the PWA loader has
          // already navigated to the correct route.
          if (url && url !== this.location.href) {
            this.location.navigateByUrl(url, { replaceUrl: false });
          }
        }),
      );
    }

    return result;
  }

  /**
   * Unregister the application to receive push notifications.
   */
  unregisterPushNotifications(): Promise<void> {
    // Reset subscriptions
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this._isRegistered$.next(false);

    // Silently return if notifications haven't been setup
    if (!this.tokenChanges$) {
      return Promise.resolve();
    }

    return firstValueFrom(
      this.tokenChanges$.pipe(
        switchMap(token => {
          if (token) {
            return combineLatest([
              this.freelancerHttp.delete('users/0.1/tokens', {
                params: {
                  kind: 'fcm',
                  app_type: 'freelancer',
                  data: token,
                },
              }),
              // if not native, delete token
              !this.pwa.isNative()
                ? this.afMessaging.deleteToken(token)
                : // otherwise ignore
                  of(undefined),
            ]);
          }
          return EMPTY;
        }),

        map(() => undefined),
        untilDestroyed(this),
      ),
    );
  }

  async shouldRegisterPushNotifications(): Promise<boolean> {
    // Always register in the native apps
    if (this.pwa.isNative()) {
      return true;
    }

    // Don't register on Enterprise sites for now
    if (this.uiConfig.theme) {
      return false;
    }

    return true;
  }

  /**
   * @deprecated
   * Manually trigger a notification
   */
  showNotification(): void {
    // We explicitly DO NOT want to implement that, i.e. create & show
    // Notifications from the webapp itself: all notifications should be
    // transformed/filtered/sent from the backend and are automatically handled
    // in the Service Worker/native background tasks in order to ensure
    // consistency & provide background notifications support accross platforms
    throw new Error('Not implemented on purpose');
  }

  /**
   * Remove notifications when user switches back to the app
   */
  async removeNotificationsOnActiveState(): Promise<void> {
    if (this.pwa.isNative()) {
      // Clear notifications on fresh open of the app
      await App.getState().then(async (state: AppState) => {
        if (state.isActive) {
          await this.trackDeliveredNotifications();
          PushNotifications.removeAllDeliveredNotifications();
          this.handleBackgroundNotifications();
        }
      });
    }
  }

  /*
   * Send tracking for delivered notifications.
   *
   * Note: does not include the notification that was clicked on
   */
  private async trackDeliveredNotifications(): Promise<void> {
    const deliveredNotifications =
      await PushNotifications.getDeliveredNotifications();
    deliveredNotifications.notifications.forEach(
      (notification: PushNotificationSchema) => {
        // Filter out the extra `group_summary` notification that is returned
        // here but is not actually a delivered push notification.
        if (!notification.groupSummary) {
          const extraParams: PushNotificationTrackingExtraParams = {};

          this.tracking.trackCustomEvent(
            TRACK_DELIVERED_NOTIF_EVENT_NAME,
            TRACK_NATIVE_PUSH_NOTIF_SECTION_NAME,
            extraParams,
          );
        }
      },
    );
  }

  get isRegistered$(): Observable<boolean> {
    return this._isRegistered$.asObservable();
  }

  async handleBackgroundNotifications(): Promise<void> {
    const websockets = await this.getBackgroundNotificationAsWebSocket();
    if (websockets.length > 0) {
      const userId = await firstValueFrom(
        this.auth.getUserId().pipe(untilDestroyed(this)),
      );
      websockets.forEach(event => {
        if (isWebsocketMessage<BaseServerMessage>(event)) {
          this.websocket.processServerMessage(event, userId);
        }
      });

      // Track delivered background notifications
      const extraParams = {
        count: websockets.length,
      };
      this.tracking.trackCustomEvent(
        TRACK_DELIVERED_BACKGROUND_NOTIF_EVENT_NAME,
        TRACK_NATIVE_PUSH_NOTIF_SECTION_NAME,
        extraParams,
      );
    }
    this.deleteBackgroundNotifications();
  }

  isBackgroundNotificationSupported(): boolean {
    return (
      this.pwa.isNative() &&
      Capacitor.isPluginAvailable(BACKGROUND_NOTIFICATION_PLUGIN_NAME)
    );
  }

  isCustomNotificationSupported(): boolean {
    return (
      this.pwa.isNative() &&
      Capacitor.isPluginAvailable(CUSTOM_NOTIFICATION_PLUGIN_NAME)
    );
  }

  /**
   * Get the background notifications from native and transform it as an WS message.
   */
  async getBackgroundNotificationAsWebSocket(): Promise<
    WebsocketServerEvent<WebsocketServerEventTParam>[]
  > {
    if (!this.isBackgroundNotificationSupported()) {
      return [];
    }

    const result = await BackgroundNotification.getBackgroundNotificationData();
    const ret: WebsocketServerEvent<WebsocketServerEventTParam>[] = [];
    result.data.forEach(data => {
      try {
        const dataObject = JSON.parse(data);
        if (dataObject.ParentType === 'messages') {
          ret.push({
            body: dataObject.Raw,
            channel: 'user',
          });
        }
      } catch (err) {
        this.errorHandler.handleError(err);
      }
    });

    return ret;
  }

  async deleteBackgroundNotifications(): Promise<void> {
    if (!this.isBackgroundNotificationSupported()) {
      return;
    }
    await BackgroundNotification.deleteDataFiles();
  }

  /*
   * Send tracking for a clicked notifications.
   *
   * Since `trackDeliveredNotifications()` does not include the clicked notification,
   * this function will also send a delivered tracking for this notification
   */
  private async trackClickedNotification(
    notification: Notification,
  ): Promise<void> {
    const extraParams: PushNotificationTrackingExtraParams = {};

    extraParams.clicked = 'true';

    if (notification.data?.url) {
      extraParams.url = notification.data?.url;
    }

    if (notification.data?.push_notification_type) {
      extraParams.pushNotificationType =
        notification.data.push_notification_type;
    }

    if (notification.data?.resource_id) {
      extraParams.resourceId = notification.data.resource_id;
    }

    this.tracking.trackCustomEvent(
      TRACK_CLICKED_NOTIF_EVENT_NAME,
      TRACK_NATIVE_PUSH_NOTIF_SECTION_NAME,
      extraParams,
    );
    this.tracking.trackCustomEvent(
      TRACK_DELIVERED_NOTIF_EVENT_NAME,
      TRACK_NATIVE_PUSH_NOTIF_SECTION_NAME,
    );
  }

  private async getPermission(): Promise<NotificationPermission> {
    if (this.pwa.isNative()) {
      const result = await PushNotifications.checkPermissions();
      if (
        result.receive === 'prompt' ||
        result.receive === 'prompt-with-rationale'
      ) {
        return 'default';
      }
      return result.receive;
    }
    return Notification.permission;
  }

  private async getDeviceId(): Promise<string> {
    const deviceId = firstValueFrom(
      this.localStorage.get('deviceId').pipe(untilDestroyed(this)),
    );
    if (typeof deviceId === 'string' && deviceId) {
      return deviceId;
    }
    const id = uuidv4();
    await this.localStorage.set('deviceId', id);
    return id;
  }
}

import { Directive, HostBinding, Input } from '@angular/core';

export type Margin =
  | 'none'
  | 'xxxsmall'
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'mid'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';

@Directive({
  selector: `
    [flMarginRight],
    [flMarginBottom],
    [flMarginRightTablet],
    [flMarginBottomTablet],
    [flMarginRightDesktop],
    [flMarginBottomDesktop],
    [flMarginRightDesktopLarge],
    [flMarginBottomDesktopLarge],
    [flMarginRightDesktopXLarge],
    [flMarginBottomDesktopXLarge],
    [flMarginRightDesktopXXLarge],
    [flMarginBottomDesktopXXLarge],
  `,
})
export class MarginDirective {
  @HostBinding('attr.data-margin-right')
  @Input()
  flMarginRight?: Margin;

  @HostBinding('attr.data-margin-bottom')
  @Input()
  flMarginBottom?: Margin;

  @HostBinding('attr.data-margin-right-tablet')
  @Input()
  flMarginRightTablet?: Margin;

  @HostBinding('attr.data-margin-bottom-tablet')
  @Input()
  flMarginBottomTablet?: Margin;

  @HostBinding('attr.data-margin-right-desktop')
  @Input()
  flMarginRightDesktop?: Margin;

  @HostBinding('attr.data-margin-bottom-desktop')
  @Input()
  flMarginBottomDesktop?: Margin;

  @HostBinding('attr.data-margin-right-desktop-large')
  @Input()
  flMarginRightDesktopLarge?: Margin;

  @HostBinding('attr.data-margin-bottom-desktop-large')
  @Input()
  flMarginBottomDesktopLarge?: Margin;

  @HostBinding('attr.data-margin-right-desktop-xlarge')
  @Input()
  flMarginRightDesktopXLarge?: Margin;

  @HostBinding('attr.data-margin-bottom-desktop-xlarge')
  @Input()
  flMarginBottomDesktopXLarge?: Margin;

  @HostBinding('attr.data-margin-right-desktop-xxlarge')
  @Input()
  flMarginRightDesktopXXLarge?: Margin;

  @HostBinding('attr.data-margin-bottom-desktop-xxlarge')
  @Input()
  flMarginBottomDesktopXXLarge?: Margin;
}

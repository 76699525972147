import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Auth } from '@freelancer/auth';
import type { Subscription } from 'rxjs';
import { combineLatest, of, switchMap } from 'rxjs';
import { Hotjar } from './hotjar.service';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { Tracking } from './tracking.service';

@Component({
  selector: `fl-tracking-hotjar-user-attributes`,
  template: ` <ng-container></ng-container> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotjarUserAttributesComponent implements OnInit, OnDestroy {
  subscription?: Subscription;

  constructor(
    private auth: Auth,
    private hotjar: Hotjar,
    private tracking: Tracking,
    private trackingConsent: TrackingConsent,
  ) {}

  async ngOnInit(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    if (trackingConsentStatus !== TrackingConsentStatus.AUTHORIZED) {
      return;
    }

    this.subscription = combineLatest([
      this.auth
        .isLoggedIn()
        .pipe(
          switchMap(loggedIn =>
            loggedIn ? this.auth.getUserId() : of(undefined),
          ),
        ),
      this.tracking.getSessionId(),
    ]).subscribe(([userId, sessionId]) => {
      this.hotjar.sendUserAttributes(sessionId, userId);
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
